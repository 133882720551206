/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import {
  COMPLETED_REVIEW_TOGETHER,
  STARTED_REVIEW_TOGETHER,
  USER_AVAILABLE_STATUS_TAGS,
  putClientByClientIdRequest,
  putClientOnboardingSlideProgressByClientIdRequest,
} from '..';
import { useDispatch } from 'react-redux';
import { newUuid } from '@shared';

interface StatusTagMenuProps {
  clientTags: string[];
  client: Client;
}

export default function StatusTagMenu({
  clientTags,
  client,
}: StatusTagMenuProps) {
  const dispatch = useDispatch();
  const tagOptions = USER_AVAILABLE_STATUS_TAGS.filter(
    availableTag => !clientTags.includes(availableTag),
  );
  const onboardingSlideProgress = client.onboardingSlideProgress;
  if (tagOptions.length === 0) {
    return null;
  }

  const addStatusToClientTags = (statusTag: string) => {
    const clientOnboardingSlideProgressId =
      onboardingSlideProgress?.clientOnboardingSlideProgressId ?? newUuid();
    const newTags = [...clientTags.filter(tag => tag), statusTag];
    dispatch(
      putClientByClientIdRequest({
        ...client,
        clientTags: newTags,
      }),
    );
    if (statusTag === STARTED_REVIEW_TOGETHER) {
      dispatch(
        putClientOnboardingSlideProgressByClientIdRequest({
          clientOnboardingSlideProgressId,
          clientId: client.clientId,
          hasClientStartedOnboarding: true,
        }),
      );
    }
    if (statusTag === COMPLETED_REVIEW_TOGETHER) {
      dispatch(
        putClientOnboardingSlideProgressByClientIdRequest({
          clientOnboardingSlideProgressId,
          clientId: client.clientId,
          hasClientCompletedOnboarding: true,
        }),
      );
    }
  };
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button className="m-1 inline-flex items-center rounded-md border-gray-200 bg-white py-1 pl-3 pr-2 text-sm font-medium text-gray-900 hover:bg-gray-100">
          <span className="mr-1">Add Status</span>
          {/** x icon remove tag*/}
          <span className="sr-only">Add status</span>
          <PlusIcon
            className="h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {tagOptions.map(clientTags => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm',
                      )}
                      onClick={() => addStatusToClientTags(clientTags)}
                    >
                      {clientTags}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
