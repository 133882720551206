import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import {
  fetchMagicLinkSessionForClientByTokenForPublicRequest,
  fetchSessionForAuthRequest,
  postVerifySecurityQuestionsForClientByClientIdRequest,
  putMagicLinkSessionByTokenForPublicRequest,
  selectMagicLinkSessionByToken,
  storeMagicLinkSession,
} from '../model';
import { MdOutlineErrorOutline } from 'react-icons/md';

import { useEffect } from 'react';
import { CustomizedTextInput } from '@shared';
import mainStyles from '../styles/main.module.css';
import magicLinkStyles from './VerifyMagicLink.module.css';
import React from 'react';
import expiredImage from '../images/thinking.png';
import { Autocomplete } from '@mui/material';
import { WaterlilyHeaderLink } from '../components/WaterlilyHeaderLink';
import { FaCircleCheck } from 'react-icons/fa6';

const styles = {
  ...mainStyles,
  ...magicLinkStyles,
};

export const MAGIC_LINK_SESSION_STORAGE_KEY = 'magicLinkSession';

export function RequestNewMagicLinkButton({
  token,
}: {
  token: string | undefined;
}) {
  const [newLinkRequested, setNewLinkRequested] = React.useState(false);
  const dispatch = useDispatch();
  const requestFreshMagicLink = () => {
    if (token) {
      localStorage.removeItem(MAGIC_LINK_SESSION_STORAGE_KEY);
      dispatch(putMagicLinkSessionByTokenForPublicRequest({ token }));
      setNewLinkRequested(true);
    }
  };

  return (
    <>
      {newLinkRequested ? (
        <>
          <p
            className={`mt-4 flex items-center justify-center gap-2 rounded-full bg-green-50 px-6 py-2 text-xl text-green-600 shadow-md`}
          >
            Sent
            <FaCircleCheck className="h-5 w-5 text-green-500" />
          </p>
          <p className="mt-4 text-center text-gray-700">
            A new link has been sent to your email. <br /> Please check your
            inbox.
          </p>
        </>
      ) : (
        <div>
          <button
            className={`mt-4 w-full rounded-full bg-darkPurple px-6 py-2 text-xl text-white shadow-md hover:bg-mediumPurple hover:shadow-none`}
            onClick={requestFreshMagicLink}
          >
            Send me a new link
          </button>
        </div>
      )}
    </>
  );
}

function LockoutScreen() {
  return (
    <div className={styles.main}>
      <WaterlilyHeaderLink />
      <div className={styles.h3Text}>Access Temporarily Locked</div>

      <p className={styles.h4Text}>
        Oops! It looks like there have been too many unsuccessful login
        attempts.
      </p>

      <p className={styles.regularText}>
        For your security, we have temporarily locked access to your account.{' '}
        <br /> This is a precautionary measure to protect your personal
        information. <br />
        <b>Don't worry, you can regain access easily.</b>
      </p>
      <p className={styles.h5Text}>What You Can Do Now:</p>
      <ul>
        <li className={styles.regularText}>
          <p>
            Need Help? If you're continuously facing issues or believe this
            lockout is an error, <br />
            please don't hesitate to contact our support team at
            hello@joinwaterlily.com <br />
            or call us at [1-510-239-7411]. We're here to help!
          </p>
        </li>
      </ul>
      <p className={styles.regularText}>
        Security Reminder: We take your privacy and security seriously. <br />{' '}
        If you suspect any unauthorized activity or need assistance, please
        contact us immediately.
      </p>
      <p className={styles.regularText}>
        Thank you for your understanding and for your cooperation in keeping
        your account safe!
      </p>
    </div>
  );
}

export function VerifyMagicLink() {
  const { token } = useParams<{ token: string }>();
  const magicLinkSession = useSelector(selectMagicLinkSessionByToken);
  const dispatch = useDispatch();

  useEffect(() => {
    // if we have a token, fetch the magic link session
    if (token) {
      localStorage.removeItem(MAGIC_LINK_SESSION_STORAGE_KEY);
      dispatch(fetchMagicLinkSessionForClientByTokenForPublicRequest(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    // if we have a magic link session, store it and fetch the session
    if (
      magicLinkSession.clientId &&
      !magicLinkSession.isExpired &&
      !magicLinkSession.hasBeenUsed &&
      token === magicLinkSession.token
    ) {
      dispatch(storeMagicLinkSession(magicLinkSession));
      dispatch(fetchSessionForAuthRequest());
      localStorage.setItem(
        MAGIC_LINK_SESSION_STORAGE_KEY,
        JSON.stringify(magicLinkSession),
      );
    }
  }, [dispatch, magicLinkSession]);

  if (magicLinkSession.isAccountLocked) {
    return <LockoutScreen />;
  }

  if (
    magicLinkSession.clientId &&
    !magicLinkSession.isExpired &&
    !magicLinkSession.hasBeenUsed &&
    token === magicLinkSession.token
  ) {
    return <VerificationForm clientId={magicLinkSession.clientId} />;
  }

  let message = 'Failed to verify magic link.';
  if (magicLinkSession.isLoading) {
    message = 'Verifying magic link...';
  }
  if (magicLinkSession.isExpired || magicLinkSession.hasBeenUsed) {
    message = `Let's get you a new access link. This should only take a few seconds.`;
  }

  const isError = magicLinkSession.isExpired || magicLinkSession.hasBeenUsed;

  return (
    <>
      <WaterlilyHeaderLink
        topMargin={6}
        leftMargin={6}
      />
      <div className={styles.main}>
        {isError ? (
          <img
            src={expiredImage}
            alt="Magic Link Error"
            className={styles.expiredImage}
          />
        ) : null}
        <h1 className="max-w-lg px-2 text-center text-xl text-gray-900 md:text-2xl">
          {message}
        </h1>
        {isError ? (
          <div className="w-full max-w-lg py-2 md:max-w-lg md:py-4">
            <RequestNewMagicLinkButton token={token} />
          </div>
        ) : null}
      </div>
    </>
  );
}

function VerificationForm({ clientId }: { clientId: string }) {
  const [dateOfBirth, setDateOfBirth] = React.useState('');
  const [hasDOBError, setHasDOBError] = React.useState(false);
  const [dobErrorMessage, setDobErrorMessage] = React.useState('');

  // zip code fields
  const [zipCode, setZipCode] = React.useState('');
  const [hasZipCodeError, setHasZipCodeError] = React.useState(false);
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = React.useState('');

  // height fields
  const [height, setHeight] = React.useState<{
    label: string;
    value: string;
  } | null>(null);
  const [hasHeightError, setHasHeightError] = React.useState(false);
  const [heightErrorMessage, setHeightErrorMessage] = React.useState('');

  const magicLinkSession = useSelector(selectMagicLinkSessionByToken);

  const hasVerifiedSecurityQuestions =
    magicLinkSession.hasVerifiedSecurityQuestions;

  const dispatch = useDispatch();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const hasError = validateFields();
    if (hasError) {
      return;
    }

    dispatch(
      postVerifySecurityQuestionsForClientByClientIdRequest({
        clientId,
        dateOfBirth,
        zipCode,
        height: height?.value ?? '',
      }),
    );
  };

  const validateFields = () => {
    let hasError = false;
    if (dateOfBirth.length === 0) {
      setHasDOBError(true);
      setDobErrorMessage('Date of birth is required');
      hasError = true;
    } else {
      setHasDOBError(false);
      setDobErrorMessage('');
    }
    if (zipCode.length === 0) {
      setHasZipCodeError(true);
      setZipCodeErrorMessage('Zip code is required');
      hasError = true;
    } else {
      setHasZipCodeError(false);
      setZipCodeErrorMessage('');
    }
    if (height?.label.length === 0) {
      setHasHeightError(true);
      setHeightErrorMessage('Height is required');
      hasError = true;
    } else {
      setHasHeightError(false);
      setHeightErrorMessage('');
    }
    return hasError;
  };

  const OnChangeDob = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dob = event.target.value;
    setDateOfBirth(dob);
    if (dob.length === 0) {
      setHasDOBError(true);
      setDobErrorMessage('Date of birth is required');
    } else {
      setHasDOBError(false);
      setDobErrorMessage('');
    }
  };

  const onChangeZipCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const zip = event.target.value;
    setZipCode(zip);
    if (zip.length === 0) {
      setHasZipCodeError(true);
      setZipCodeErrorMessage('Zip code is required');
    } else {
      setHasZipCodeError(false);
      setZipCodeErrorMessage('');
    }
  };

  const OnChangeHeight = (_event: any, value: any) => {
    const height = value;
    setHeight(height);
    if (height.label.length === 0) {
      setHasHeightError(true);
      setHeightErrorMessage('Height is required');
    } else {
      setHasHeightError(false);
      setHeightErrorMessage('');
    }
  };

  if (hasVerifiedSecurityQuestions) {
    return (
      <Navigate
        to={`/clients/${magicLinkSession.clientId}/onboarding/results-are-in`}
      />
    );
  }
  return (
    <div className={styles.main}>
      {hasVerifiedSecurityQuestions === false ? (
        <div className="flex max-w-xl flex-col gap-2 px-2">
          <h2 className="flex items-center gap-3 text-4xl font-normal text-gray-900">
            Failed to Verify <MdOutlineErrorOutline className="h-8 w-8" />
          </h2>{' '}
          <p className="py-2 text-xl text-gray-900">
            At least one of your answers was incorrect. Click the link below to
            send an email for a new access link to view your results.
          </p>
          <div className="w-full py-2">
            <RequestNewMagicLinkButton token={magicLinkSession.token} />
          </div>
        </div>
      ) : (
        <>
          <div className="max-w-xl px-3">
            <div className=" text-4xl font-normal text-gray-900">
              Security Questions
            </div>{' '}
            <div className="mb-4 mt-1 text-base text-gray-500">
              Please answer the following security question to verify your
              identity
            </div>
            <form onSubmit={handleSubmit}>
              {dobErrorMessage ? (
                <div className={styles.errorText}>{dobErrorMessage}</div>
              ) : null}
              <div className="inline-flex py-1">
                <p className="text-base text-gray-900 md:text-lg">
                  What is your date of birth?*
                </p>
              </div>
              <CustomizedTextInput
                id="DateOfBirth"
                placeholder="Enter your date of birth"
                variant="outlined"
                fullWidth
                autoFocus
                type="date"
                value={dateOfBirth}
                error={hasDOBError}
                onChange={OnChangeDob}
                required
                InputProps={{
                  classes: {
                    error: styles.textboxError,
                    root: styles.textbox,
                  },
                  inputProps: {
                    'data-private': 'true', // This attribute tells LogRocket to redact this input field
                  },
                }}
              />
              {zipCodeErrorMessage ? (
                <div className={styles.errorText}>{zipCodeErrorMessage}</div>
              ) : null}
              <div className=" py-1">
                <p className="text-base text-gray-900 md:text-lg">
                  What zip code do you live in?*
                </p>
              </div>

              <CustomizedTextInput
                id="zipCode"
                placeholder="Enter your zip code"
                variant="outlined"
                fullWidth
                type="text"
                value={zipCode}
                error={hasZipCodeError}
                onChange={onChangeZipCode}
                required
                InputProps={{
                  classes: {
                    error: styles.textboxError,
                    root: styles.textbox,
                  },
                  inputProps: {
                    'data-private': 'true', // This attribute tells LogRocket to redact this input field
                  },
                }}
              />
              {heightErrorMessage ? (
                <div className={styles.errorText}>{heightErrorMessage}</div>
              ) : null}
              <div className=" py-1">
                <p className="text-base text-gray-900 md:text-lg">
                  How tall are you?*
                </p>
              </div>

              <Autocomplete
                id="height"
                fullWidth
                value={height}
                renderInput={params => (
                  <CustomizedTextInput
                    variant="outlined"
                    placeholder=" Enter your height"
                    required
                    error={hasHeightError}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      inputProps: {
                        ...params.inputProps, // Spread existing inputProps first
                        'data-private': 'true', // Add your custom attribute here
                      },
                      classes: {
                        error: styles.textboxError,
                        root: styles.textbox,
                      },
                    }}
                  />
                )}
                options={heightOptions}
                onChange={OnChangeHeight}
              />

              <button
                className="mt-4 w-full rounded-full bg-darkPurple py-2 text-lg text-white shadow-md hover:bg-mediumPurple hover:shadow-none"
                type="submit"
              >
                Verify
              </button>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

const heightOptions = [
  { label: '4\'10"', value: '4\'10"' },
  { label: '4\'11"', value: '4\'11"' },
  { label: '5\'0"', value: '5\'0"' },
  { label: '5\'1"', value: '5\'1"' },
  { label: '5\'2"', value: '5\'2"' },
  { label: '5\'3"', value: '5\'3"' },
  { label: '5\'4"', value: '5\'4"' },
  { label: '5\'5"', value: '5\'5"' },
  { label: '5\'6"', value: '5\'6"' },
  { label: '5\'7"', value: '5\'7"' },
  { label: '5\'8"', value: '5\'8"' },
  { label: '5\'9"', value: '5\'9"' },
  { label: '5\'10"', value: '5\'10"' },
  { label: '5\'11"', value: '5\'11"' },
  { label: '6\'0"', value: '6\'0"' },
  { label: '6\'1"', value: '6\'1"' },
  { label: '6\'2"', value: '6\'2"' },
  { label: '6\'3"', value: '6\'3"' },
  { label: '6\'4"', value: '6\'4"' },
  { label: '6\'5"', value: '6\'5"' },
  { label: '6\'6"', value: '6\'6"' },
  { label: '6\'7"', value: '6\'7"' },
  { label: '6\'8"', value: '6\'8"' },
  { label: '6\'9"', value: '6\'9"' },
  { label: '6\'10"', value: '6\'10"' },
  { label: '6\'11"', value: '6\'11"' },
  { label: '7\'0"', value: '7\'0"' },
];
