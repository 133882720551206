import vbShopPremiumRates from '../data/vbShopPremiumRates.json';

interface BenefitAmounts {
  [benefitAmount: string]: number;
}

interface AgeRates {
  [clientAge: string]: BenefitAmounts;
}

interface Rates {
  'non-tobacco': AgeRates;
  'tobacco': AgeRates;
}

export function getBiWeeklyPremiumRate(
  tobacco: boolean,
  clientAge: string,
  benefitAmount: string,
): number | undefined {
  const tobaccoKey: 'tobacco' | 'non-tobacco' = tobacco
    ? 'tobacco'
    : 'non-tobacco';
  const ageKey = clientAge.toString();
  const ratesData: Rates = vbShopPremiumRates;

  const ratesEntry = ratesData[tobaccoKey] && ratesData[tobaccoKey][ageKey];
  if (!ratesEntry) {
    return undefined;
  }

  if (ratesEntry[benefitAmount]) {
    return ratesEntry[benefitAmount];
  }

  // calculate bi-weekly premium rate
  const baseBenefitAmount = '10000';
  const baseRate = ratesEntry[baseBenefitAmount];
  if (!baseRate) {
    return undefined;
  }
  const baseRateMultiplier =
    parseInt(benefitAmount) / parseInt(baseBenefitAmount);
  return baseRate * baseRateMultiplier;
}
