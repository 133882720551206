import { useSelector } from 'react-redux';
import { OnboardingChrome } from '../components';
import { selectClient, selectOnboardingSlideTracker } from '../model';
import { formatCurrency, formatPercent } from '@shared';

import { SlideContent } from '../components/SlideContent';
import {
  LearnMoreSlideOut,
  LtcFundingSlideOutContent,
  OnboardingDownloadReport,
  OnboardingFundingSources,
  citations,
  onboardingSlideByIndex,
} from '..';

export function OnboardingFundingCoverage({ clientId }: ClientIdProps) {
  const client = useSelector(selectClient)!;
  const onboardingSlideTracker = useSelector(selectOnboardingSlideTracker);
  const previousSlide =
    onboardingSlideTracker.previousSlideIndex === 0
      ? OnboardingFundingSources
      : onboardingSlideByIndex[onboardingSlideTracker.previousSlideIndex];
  const {
    fundingSources: {
      combinedProjectedCostCoverage,
      combinedProjectedCostCoveragePercent,
      selfFunding: { hasSelfFunding },
    },
  } = client;

  const beginningText = determineBeginningText(client.fundingSources);

  return (
    <OnboardingChrome
      slidePage={OnboardingFundingCoverage}
      nextSlidePage={OnboardingDownloadReport}
      previousSlidePage={previousSlide}
      nextButtonLabel="Next"
      citations={[
        hasSelfFunding
          ? citations.OnboardingFundingCoverageDisclaimer
          : undefined,
      ]}
    >
      <SlideContent headerText={''}>
        <div className="mt-16 flex h-full w-full flex-col items-center justify-center">
          <h1 className="text-2xl font-semibold md:text-4xl">
            {beginningText} is projected to cover{' '}
            <span className="text-purple">
              {combinedProjectedCostCoverage
                ? formatCurrency(combinedProjectedCostCoverage)
                : '$0'}
            </span>
            , or{' '}
            <span className="text-purple">
              {combinedProjectedCostCoverage
                ? formatPercent(combinedProjectedCostCoveragePercent, 0)
                : '0%'}{' '}
            </span>
            of your total predicted LTC costs! 🎉
          </h1>
        </div>
        <LearnMoreSlideOut
          slideOutContent={<LtcFundingSlideOutContent />}
          isModelDescription={false}
        >
          <div className="mt-8 text-center text-base text-darkPurple underline hover:text-purple">
            How did we calculate this?
          </div>
        </LearnMoreSlideOut>
      </SlideContent>
    </OnboardingChrome>
  );
}

function determineBeginningText(fundingSources: FundingSources) {
  const {
    ltcPolicy: { hasPolicyFunding },
    selfFunding: { hasSelfFunding },
  } = fundingSources;

  const hasLtcPolicy = hasPolicyFunding === true;
  const hasOnlySelfFunding = hasSelfFunding && !hasLtcPolicy;
  const hasOnlyPolicyFunding = hasLtcPolicy && !hasSelfFunding;
  const hasBothFunding = hasSelfFunding && hasLtcPolicy;

  if (hasOnlySelfFunding) {
    return 'Your self-funding plan';
  }

  if (hasOnlyPolicyFunding) {
    return 'Your policy';
  }

  if (hasBothFunding) {
    return 'Your policy and self-funding plan';
  }

  return 'Your current plan';
}
