import { defaultChartColors } from '../util';
// import mainStyles from '../styles/main.module.css';
import styles from './CarePhaseDetails.module.css';

import { ThemeProvider } from '@emotion/react';
import {
  CarePhase,
  careEnvironmentDefs,
  carePhaseDefs,
  formatCurrency,
  formatInteger,
  formatPercent,
  formatThousands,
  isCarePhasePast,
  isNullOrUndefined,
  pickClientCareEnvironmentCosts,
} from '@shared';
import { annualHealthcareInflationRate } from '@shared';
import { isValidZipCode } from '@shared';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { createTheme } from '@mui/material';

const listItemTheme = createTheme({
  components: {
    // Name of the component
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontFamily: 'Lato !important',
          },
        },
      },
    },
  },
});

function CustomListItemText({
  primary,
  secondary,
}: {
  primary: string;
  secondary?: string;
}) {
  return (
    <ThemeProvider theme={listItemTheme}>
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
    </ThemeProvider>
  );
}

interface CareCarePhaseDetailsProps {
  client: Client;
  selectedCarePhaseCosts: SinglePhaseCosts | null;
}

export default function CarePhaseDetails({
  client,
  selectedCarePhaseCosts,
}: CareCarePhaseDetailsProps) {
  if (isNullOrUndefined(selectedCarePhaseCosts)) {
    return null;
  }
  const { index } = carePhaseDefs[selectedCarePhaseCosts.carePhase];

  const {
    appliedCareEnvironment,
    phaseCareHoursNeeded,
    phaseCareMonthsNeeded,
  } = selectedCarePhaseCosts;

  // cost and rate period and zip code
  const {
    intakeSurvey: {
      clientLtcZipCode,
      clientBirthYear,
      clientCurrentCarePhase,
      clientPhaseStartAges,
    },
    clientPhasePredictedStartYears,
  } = client;

  const predictedPhaseStartYear =
    clientPhasePredictedStartYears[selectedCarePhaseCosts.carePhase];
  const careEnvironmentLabel =
    careEnvironmentDefs[appliedCareEnvironment].label.toLocaleLowerCase();
  const { rateAmount, ratePeriod } = pickClientCareEnvironmentCosts(
    client,
    appliedCareEnvironment,
  );

  const ratePeriodDescriptor =
    ratePeriod.charAt(0).toUpperCase() + ratePeriod.slice(1).toLowerCase();
  const isRatePeriodMonthly = ratePeriodDescriptor === 'Monthly';
  const ratePeriodText = isRatePeriodMonthly ? 'months' : 'hours';
  const timeDescriptorText = isRatePeriodMonthly ? 'Duration' : 'Care Hours';
  const phaseDuration = isRatePeriodMonthly
    ? formatInteger(phaseCareMonthsNeeded)
    : formatThousands(phaseCareHoursNeeded);

  const color = defaultChartColors[index];
  const predictedPhaseAge = predictedPhaseStartYear! - clientBirthYear;
  const isCurrentPhase =
    clientCurrentCarePhase === selectedCarePhaseCosts.carePhase;
  const isPastPhase = isCarePhasePast(selectedCarePhaseCosts.carePhase, client);
  const nextCarePhase = isPastPhase
    ? selectedCarePhaseCosts.carePhase === CarePhase.earlyCare
      ? CarePhase.moderateCare
      : CarePhase.fullCare
    : null;
  const nextPhaseStartAge = nextCarePhase
    ? clientPhaseStartAges[nextCarePhase]
    : null;

  return (
    <>
      <div className={styles.carePhaseInformationRow}>
        <List
          className={styles.carePhaseInformationText}
          sx={{ padding: 0 }}
        >
          <ListItem sx={{ paddingLeft: 0, paddingTop: '2px' }}>
            <ListItemAvatar sx={{ minWidth: '35px' }}>
              <MonetizationOnOutlinedIcon
                fontSize="medium"
                sx={{ color: color }}
              />
            </ListItemAvatar>
            <CustomListItemText
              primary={
                isPastPhase
                  ? 'Previous costs are not considered'
                  : `${ratePeriodDescriptor} Cost: ${formatCurrency(rateAmount, 2)}`
              }
              secondary={
                isPastPhase
                  ? ''
                  : `Based on ${careEnvironmentLabel} care costs in zip code: ${clientLtcZipCode}${isValidZipCode(clientLtcZipCode) ? '' : ' (Invalid zip code. Using national average costs)'}`
              }
            />
          </ListItem>
          <ListItem sx={{ paddingLeft: 0, paddingTop: '2px' }}>
            <ListItemAvatar sx={{ minWidth: '35px' }}>
              <AccessTimeIcon
                fontSize="medium"
                sx={{ color: color }}
              />
            </ListItemAvatar>
            <CustomListItemText
              primary={
                isPastPhase
                  ? `From age: ${clientPhaseStartAges[selectedCarePhaseCosts.carePhase]}-${nextPhaseStartAge}`
                  : `Estimated ${isCurrentPhase ? 'Remaining ' : ''}${timeDescriptorText}: ${phaseDuration} ${ratePeriodText}`
              }
            />
          </ListItem>
          {!isCurrentPhase && !isPastPhase && (
            <ListItem sx={{ paddingLeft: 0, paddingTop: '2px' }}>
              <ListItemAvatar sx={{ minWidth: '35px' }}>
                <TrendingUpOutlinedIcon
                  fontSize="medium"
                  sx={{ color: color }}
                />
              </ListItemAvatar>
              <CustomListItemText
                primary={`Predicted Begin Age: ${predictedPhaseAge}`}
                secondary={`Adjusted for inflation ${formatPercent(annualHealthcareInflationRate)} until year ${predictedPhaseStartYear}`}
              />
            </ListItem>
          )}
          <ListItem sx={{ paddingLeft: 0, paddingTop: '2px' }}>
            <ListItemAvatar sx={{ minWidth: '35px' }}>
              <DescriptionOutlinedIcon
                fontSize="medium"
                sx={{ color: color }}
              />
            </ListItemAvatar>
            <CustomListItemText
              primary={`${carePhaseDefs[selectedCarePhaseCosts.carePhase].label}`}
              secondary={`Phase requiring assistance with ${carePhaseDefs[selectedCarePhaseCosts.carePhase].tooltip}`}
            />
          </ListItem>
        </List>
      </div>
    </>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  'border': `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  'backgroundColor': '',
  'flexDirection': 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '0px solid rgba(0, 0, 0, .125)',
  paddingTop: '0px',
  paddingBottom: '0px',
}));

interface CarePhaseDetailsAccordionProps {
  client: Client;
  selectedCarePhaseCosts: SinglePhaseCosts | null;
}

export const CarePhaseDetailsAccordion: React.FC<
  CarePhaseDetailsAccordionProps
> = ({ client, selectedCarePhaseCosts }) => {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <span style={{ fontSize: '22px' }}>See Additional Details</span>
      </AccordionSummary>
      <AccordionDetails>
        <CarePhaseDetails
          client={client}
          selectedCarePhaseCosts={selectedCarePhaseCosts}
        />
      </AccordionDetails>
    </Accordion>
  );
};
