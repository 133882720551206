// ////////////////////////////////////////////////////////
//
//
//    GENERATED FILE  -  DO NOT EDIT
//
//    This file is generated by generate-app-model.sh
//    the folder's recursive contents.
//
// ////////////////////////////////////////////////////////

import { upperFirst } from 'lodash';

export enum AppModelObjectType {
  tableDef = 'tableDef',
  enumDef = 'enumDef',
  fieldDef = 'fieldDef',
}

export type AppSqlTypeDef =
  | 'DATE'
  | 'INT'
  | 'NUMERIC'
  | 'TEXT'
  | 'TIMESTAMP'
  | 'UUID';

export interface AppObjectDef<T extends AppModelObjectType> {
  objectType: T | string;
}

export interface AppTableDef<T extends AppModelObjectType = AppModelObjectType.tableDef> extends AppObjectDef<T> {
  tableName: string;
  fields: ObjectMap<AppFieldDef>;
  orderedFieldNames: string[];
  primaryKey?: string;
}

export interface AppFieldDef extends AppObjectDef<AppModelObjectType.fieldDef> {
  fieldName: string;
  sqlType: string; // AppSqlTypeDef
  ownerTableName: string;
  isPrimaryKey: boolean;
  isRequired: boolean;
  hasDefault: boolean;
  referenceTableName?: string;
  referenceFieldName?: string;
}

export interface AppEnumDef extends AppTableDef<AppModelObjectType.enumDef> {
  valuesByLabel: ObjectMap<number>;
  labelsByValue: ObjectMap<string>;
}

export interface AppReferenceDef {
  primaryTableName: string;
  primaryFieldName: string;
  foreignTableName: string;
  foreignFieldName: string;
}

export const appModel = {
  "tablesByName": {
    "walpSchemaVersion": {
      "tableName": "WalpSchemaVersion",
      "objectType": "table",
      "fields": {
        "schemaVersion": {
          "fieldName": "schemaVersion",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "WalpSchemaVersion"
        }
      },
      "orderedFieldNames": [
        "schemaVersion"
      ],
      "primaryKey": "schemaVersion"
    },
    "careEnvironments": {
      "tableName": "CareEnvironments",
      "objectType": "enum",
      "fields": {
        "careEnvironmentId": {
          "fieldName": "careEnvironmentId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CareEnvironments"
        },
        "careEnvironmentLabel": {
          "fieldName": "careEnvironmentLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CareEnvironments"
        }
      },
      "orderedFieldNames": [
        "careEnvironmentId",
        "careEnvironmentLabel"
      ],
      "primaryKey": "careEnvironmentId",
      "valuesByLabel": {
        "Home": 2701,
        "Independent Living": 2703,
        "Assisted Living": 2704,
        "Nursing Home": 2705
      },
      "labelsByValue": {
        "2701": "Home",
        "2703": "Independent Living",
        "2704": "Assisted Living",
        "2705": "Nursing Home"
      }
    },
    "carePhases": {
      "tableName": "CarePhases",
      "objectType": "enum",
      "fields": {
        "carePhaseId": {
          "fieldName": "carePhaseId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CarePhases"
        },
        "carePhaseLabel": {
          "fieldName": "carePhaseLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CarePhases"
        }
      },
      "orderedFieldNames": [
        "carePhaseId",
        "carePhaseLabel"
      ],
      "primaryKey": "carePhaseId",
      "valuesByLabel": {
        "Early Care": 2801,
        "Moderate Care": 2802,
        "Full Care": 2803
      },
      "labelsByValue": {
        "2801": "Early Care",
        "2802": "Moderate Care",
        "2803": "Full Care"
      }
    },
    "clientStatuses": {
      "tableName": "ClientStatuses",
      "objectType": "enum",
      "fields": {
        "clientStatusId": {
          "fieldName": "clientStatusId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientStatuses"
        },
        "clientStatusLabel": {
          "fieldName": "clientStatusLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientStatuses"
        }
      },
      "orderedFieldNames": [
        "clientStatusId",
        "clientStatusLabel"
      ],
      "primaryKey": "clientStatusId",
      "valuesByLabel": {
        "Active": 1101,
        "Inactive": 1102,
        "Archived": 1103
      },
      "labelsByValue": {
        "1101": "Active",
        "1102": "Inactive",
        "1103": "Archived"
      }
    },
    "clientTagDefs": {
      "tableName": "ClientTagDefs",
      "objectType": "enum",
      "fields": {
        "clientTagDefId": {
          "fieldName": "clientTagDefId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientTagDefs"
        },
        "clientTagDefLabel": {
          "fieldName": "clientTagDefLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientTagDefs"
        }
      },
      "orderedFieldNames": [
        "clientTagDefId",
        "clientTagDefLabel"
      ],
      "primaryKey": "clientTagDefId",
      "valuesByLabel": {
        "Fill out the intake form": 1201,
        "Algorithms currently running": 1202,
        "Algorithms finished running": 1203,
        "Start onboarding": 1204,
        "Closing the care gap": 1205,
        "Update your care support structure": 1206,
        "Completed Plan": 1207,
        "Algorithms error": 1208,
        "Client started review": 1209,
        "Client completed review": 1210,
        "Review call scheduled": 1211,
        "Review started together": 1212,
        "Review completed together": 1213
      },
      "labelsByValue": {
        "1201": "Fill out the intake form",
        "1202": "Algorithms currently running",
        "1203": "Algorithms finished running",
        "1204": "Start onboarding",
        "1205": "Closing the care gap",
        "1206": "Update your care support structure",
        "1207": "Completed Plan",
        "1208": "Algorithms error",
        "1209": "Client started review",
        "1210": "Client completed review",
        "1211": "Review call scheduled",
        "1212": "Review started together",
        "1213": "Review completed together"
      }
    },
    "fundingPolicyTypes": {
      "tableName": "FundingPolicyTypes",
      "objectType": "enum",
      "fields": {
        "fundingPolicyTypeId": {
          "fieldName": "fundingPolicyTypeId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingPolicyTypes"
        },
        "fundingPolicyTypeLabel": {
          "fieldName": "fundingPolicyTypeLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingPolicyTypes"
        }
      },
      "orderedFieldNames": [
        "fundingPolicyTypeId",
        "fundingPolicyTypeLabel"
      ],
      "primaryKey": "fundingPolicyTypeId",
      "valuesByLabel": {
        "Long-term Care Insurance": 2901,
        "Life Insurance with Rider": 2902,
        "Hybrid Life Insurance": 2903,
        "Short-term Care Insurance": 2904,
        "Annuity Hybrid": 2905,
        "Joint Hybrid Asset Based": 2906,
        "Joint Hybrid Annuity Based": 2907,
        "Life with Index Account": 2908,
        "Allstate Hybrid Policy": 2909
      },
      "labelsByValue": {
        "2901": "Long-term Care Insurance",
        "2902": "Life Insurance with Rider",
        "2903": "Hybrid Life Insurance",
        "2904": "Short-term Care Insurance",
        "2905": "Annuity Hybrid",
        "2906": "Joint Hybrid Asset Based",
        "2907": "Joint Hybrid Annuity Based",
        "2908": "Life with Index Account",
        "2909": "Allstate Hybrid Policy"
      }
    },
    "annuityTypes": {
      "tableName": "AnnuityTypes",
      "objectType": "enum",
      "fields": {
        "annuityTypeId": {
          "fieldName": "annuityTypeId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "AnnuityTypes"
        },
        "annuityTypeLabel": {
          "fieldName": "annuityTypeLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "AnnuityTypes"
        }
      },
      "orderedFieldNames": [
        "annuityTypeId",
        "annuityTypeLabel"
      ],
      "primaryKey": "annuityTypeId",
      "valuesByLabel": {
        "Basic Annuity": 3001
      },
      "labelsByValue": {
        "3001": "Basic Annuity"
      }
    },
    "supportProviderContributions": {
      "tableName": "SupportProviderContributions",
      "objectType": "enum",
      "fields": {
        "supportProviderContributionId": {
          "fieldName": "supportProviderContributionId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderContributions"
        },
        "supportProviderContributionLabel": {
          "fieldName": "supportProviderContributionLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderContributions"
        }
      },
      "orderedFieldNames": [
        "supportProviderContributionId",
        "supportProviderContributionLabel"
      ],
      "primaryKey": "supportProviderContributionId",
      "valuesByLabel": {
        "Physical Caregiver": 2601,
        "Care Coordinator": 2602,
        "Financial Caregiver": 2603
      },
      "labelsByValue": {
        "2601": "Physical Caregiver",
        "2602": "Care Coordinator",
        "2603": "Financial Caregiver"
      }
    },
    "supportProviderDetailSetSources": {
      "tableName": "SupportProviderDetailSetSources",
      "objectType": "enum",
      "fields": {
        "supportProviderDetailSetSourceId": {
          "fieldName": "supportProviderDetailSetSourceId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetailSetSources"
        },
        "supportProviderDetailSetSourceLabel": {
          "fieldName": "supportProviderDetailSetSourceLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetailSetSources"
        }
      },
      "orderedFieldNames": [
        "supportProviderDetailSetSourceId",
        "supportProviderDetailSetSourceLabel"
      ],
      "primaryKey": "supportProviderDetailSetSourceId",
      "valuesByLabel": {
        "Inferences": 2101,
        "Client": 2102
      },
      "labelsByValue": {
        "2101": "Inferences",
        "2102": "Client"
      }
    },
    "supportProviderTypes": {
      "tableName": "SupportProviderTypes",
      "objectType": "enum",
      "fields": {
        "supportProviderTypeId": {
          "fieldName": "supportProviderTypeId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderTypes"
        },
        "supportProviderTypeLabel": {
          "fieldName": "supportProviderTypeLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderTypes"
        }
      },
      "orderedFieldNames": [
        "supportProviderTypeId",
        "supportProviderTypeLabel"
      ],
      "primaryKey": "supportProviderTypeId",
      "valuesByLabel": {
        "Self": 2200,
        "Professional": 2201,
        "Spouse": 2202,
        "Other": 2203,
        "Child": 2204
      },
      "labelsByValue": {
        "2200": "Self",
        "2201": "Professional",
        "2202": "Spouse",
        "2203": "Other",
        "2204": "Child"
      }
    },
    "surveyQuestionTypes": {
      "tableName": "SurveyQuestionTypes",
      "objectType": "enum",
      "fields": {
        "surveyQuestionTypeId": {
          "fieldName": "surveyQuestionTypeId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyQuestionTypes"
        },
        "surveyQuestionTypeLabel": {
          "fieldName": "surveyQuestionTypeLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyQuestionTypes"
        }
      },
      "orderedFieldNames": [
        "surveyQuestionTypeId",
        "surveyQuestionTypeLabel"
      ],
      "primaryKey": "surveyQuestionTypeId",
      "valuesByLabel": {
        "Text": 2501,
        "Date": 2502,
        "Integer": 2503,
        "Multiple Choice": 2505,
        "Many Multiple Choice": 2506,
        "Ranking": 2507,
        "Boolean": 2508
      },
      "labelsByValue": {
        "2501": "Text",
        "2502": "Date",
        "2503": "Integer",
        "2505": "Multiple Choice",
        "2506": "Many Multiple Choice",
        "2507": "Ranking",
        "2508": "Boolean"
      }
    },
    "surveyStatuses": {
      "tableName": "SurveyStatuses",
      "objectType": "enum",
      "fields": {
        "surveyStatusId": {
          "fieldName": "surveyStatusId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyStatuses"
        },
        "surveyStatusLabel": {
          "fieldName": "surveyStatusLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyStatuses"
        }
      },
      "orderedFieldNames": [
        "surveyStatusId",
        "surveyStatusLabel"
      ],
      "primaryKey": "surveyStatusId",
      "valuesByLabel": {
        "Incomplete": 2301,
        "Complete": 2302,
        "Abandoned": 2303
      },
      "labelsByValue": {
        "2301": "Incomplete",
        "2302": "Complete",
        "2303": "Abandoned"
      }
    },
    "surveySubmitters": {
      "tableName": "SurveySubmitters",
      "objectType": "enum",
      "fields": {
        "surveySubmitterId": {
          "fieldName": "surveySubmitterId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveySubmitters"
        },
        "surveySubmitterLabel": {
          "fieldName": "surveySubmitterLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveySubmitters"
        }
      },
      "orderedFieldNames": [
        "surveySubmitterId",
        "surveySubmitterLabel"
      ],
      "primaryKey": "surveySubmitterId",
      "valuesByLabel": {
        "Advisor": 2401,
        "Client": 2402,
        "SupportProvider": 2403
      },
      "labelsByValue": {
        "2401": "Advisor",
        "2402": "Client",
        "2403": "SupportProvider"
      }
    },
    "advisors": {
      "tableName": "Advisors",
      "objectType": "table",
      "fields": {
        "advisorId": {
          "fieldName": "advisorId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Advisors"
        },
        "organizationName": {
          "fieldName": "organizationName",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "Advisors"
        },
        "organizationDisplayName": {
          "fieldName": "organizationDisplayName",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "Advisors"
        },
        "advisorFirstName": {
          "fieldName": "advisorFirstName",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Advisors"
        },
        "advisorLastName": {
          "fieldName": "advisorLastName",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Advisors"
        },
        "advisorEmail": {
          "fieldName": "advisorEmail",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Advisors"
        },
        "advisorCognitoRef": {
          "fieldName": "advisorCognitoRef",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Advisors"
        },
        "advisorCreatedDateTime": {
          "fieldName": "advisorCreatedDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": true,
          "ownerTableName": "Advisors"
        }
      },
      "orderedFieldNames": [
        "advisorId",
        "organizationName",
        "organizationDisplayName",
        "advisorFirstName",
        "advisorLastName",
        "advisorEmail",
        "advisorCognitoRef",
        "advisorCreatedDateTime"
      ],
      "primaryKey": "advisorId"
    },
    "clients": {
      "tableName": "Clients",
      "objectType": "table",
      "fields": {
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Clients"
        },
        "advisorId": {
          "fieldName": "advisorId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Clients",
          "referenceTableName": "Advisors",
          "referenceFieldName": "advisorId"
        },
        "clientFirstName": {
          "fieldName": "clientFirstName",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "Clients"
        },
        "clientLastName": {
          "fieldName": "clientLastName",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Clients"
        },
        "clientEmail": {
          "fieldName": "clientEmail",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "Clients"
        },
        "clientStatus": {
          "fieldName": "clientStatus",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Clients",
          "referenceTableName": "ClientStatuses",
          "referenceFieldName": "clientStatusId"
        },
        "lastSlideSeen": {
          "fieldName": "lastSlideSeen",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "Clients"
        },
        "planProgressPercent": {
          "fieldName": "planProgressPercent",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "Clients"
        },
        "clientAddedDateTime": {
          "fieldName": "clientAddedDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Clients"
        },
        "partnerClientId": {
          "fieldName": "partnerClientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "Clients",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        }
      },
      "orderedFieldNames": [
        "clientId",
        "advisorId",
        "clientFirstName",
        "clientLastName",
        "clientEmail",
        "clientStatus",
        "lastSlideSeen",
        "planProgressPercent",
        "clientAddedDateTime",
        "partnerClientId"
      ],
      "primaryKey": "clientId"
    },
    "clientTags": {
      "tableName": "ClientTags",
      "objectType": "table",
      "fields": {
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientTags",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "clientTagDefId": {
          "fieldName": "clientTagDefId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientTags",
          "referenceTableName": "ClientTagDefs",
          "referenceFieldName": "clientTagDefId"
        }
      },
      "orderedFieldNames": [
        "clientId",
        "clientTagDefId"
      ]
    },
    "careEnvironmentSelections": {
      "tableName": "CareEnvironmentSelections",
      "objectType": "table",
      "fields": {
        "careEnvironmentSelectionId": {
          "fieldName": "careEnvironmentSelectionId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CareEnvironmentSelections"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CareEnvironmentSelections",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "carePhase": {
          "fieldName": "carePhase",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CareEnvironmentSelections",
          "referenceTableName": "CarePhases",
          "referenceFieldName": "carePhaseId"
        },
        "careEnvironment": {
          "fieldName": "careEnvironment",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "CareEnvironmentSelections",
          "referenceTableName": "CareEnvironments",
          "referenceFieldName": "careEnvironmentId"
        }
      },
      "orderedFieldNames": [
        "careEnvironmentSelectionId",
        "clientId",
        "carePhase",
        "careEnvironment"
      ],
      "primaryKey": "careEnvironmentSelectionId"
    },
    "supportProviders": {
      "tableName": "SupportProviders",
      "objectType": "table",
      "fields": {
        "supportProviderId": {
          "fieldName": "supportProviderId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviders"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviders",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "supportProviderName": {
          "fieldName": "supportProviderName",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviders"
        },
        "supportProviderCareLevel": {
          "fieldName": "supportProviderCareLevel",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SupportProviders"
        },
        "supportProviderType": {
          "fieldName": "supportProviderType",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviders",
          "referenceTableName": "SupportProviderTypes",
          "referenceFieldName": "supportProviderTypeId"
        },
        "supportProviderContribution": {
          "fieldName": "supportProviderContribution",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviders",
          "referenceTableName": "SupportProviderContributions",
          "referenceFieldName": "supportProviderContributionId"
        }
      },
      "orderedFieldNames": [
        "supportProviderId",
        "clientId",
        "supportProviderName",
        "supportProviderCareLevel",
        "supportProviderType",
        "supportProviderContribution"
      ],
      "primaryKey": "supportProviderId"
    },
    "supportProviderDetailSets": {
      "tableName": "SupportProviderDetailSets",
      "objectType": "table",
      "fields": {
        "supportProviderDetailSetId": {
          "fieldName": "supportProviderDetailSetId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetailSets"
        },
        "supportProviderDetailSetDateTime": {
          "fieldName": "supportProviderDetailSetDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "SupportProviderDetailSets"
        },
        "supportProviderDetailSetSource": {
          "fieldName": "supportProviderDetailSetSource",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetailSets",
          "referenceTableName": "SupportProviderDetailSetSources",
          "referenceFieldName": "supportProviderDetailSetSourceId"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetailSets",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "removeFamilyBurden": {
          "fieldName": "removeFamilyBurden",
          "objectType": "field",
          "sqlType": "BOOLEAN",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": true,
          "ownerTableName": "SupportProviderDetailSets"
        }
      },
      "orderedFieldNames": [
        "supportProviderDetailSetId",
        "supportProviderDetailSetDateTime",
        "supportProviderDetailSetSource",
        "clientId",
        "removeFamilyBurden"
      ],
      "primaryKey": "supportProviderDetailSetId"
    },
    "supportProviderDetails": {
      "tableName": "SupportProviderDetails",
      "objectType": "table",
      "fields": {
        "supportProviderDetailId": {
          "fieldName": "supportProviderDetailId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails"
        },
        "supportProviderDetailSetId": {
          "fieldName": "supportProviderDetailSetId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails",
          "referenceTableName": "SupportProviderDetailSets",
          "referenceFieldName": "supportProviderDetailSetId"
        },
        "supportProviderId": {
          "fieldName": "supportProviderId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails",
          "referenceTableName": "SupportProviders",
          "referenceFieldName": "supportProviderId"
        },
        "supportProviderPhaseOneHours": {
          "fieldName": "supportProviderPhaseOneHours",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails"
        },
        "supportProviderPhaseTwoHours": {
          "fieldName": "supportProviderPhaseTwoHours",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails"
        },
        "supportProviderPhaseThreeHours": {
          "fieldName": "supportProviderPhaseThreeHours",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails"
        },
        "supportProviderRateHourly": {
          "fieldName": "supportProviderRateHourly",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails"
        },
        "supportProviderRateMonthly": {
          "fieldName": "supportProviderRateMonthly",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SupportProviderDetails"
        }
      },
      "orderedFieldNames": [
        "supportProviderDetailId",
        "supportProviderDetailSetId",
        "supportProviderId",
        "supportProviderPhaseOneHours",
        "supportProviderPhaseTwoHours",
        "supportProviderPhaseThreeHours",
        "supportProviderRateHourly",
        "supportProviderRateMonthly"
      ],
      "primaryKey": "supportProviderDetailId"
    },
    "surveyDefinitions": {
      "tableName": "SurveyDefinitions",
      "objectType": "table",
      "fields": {
        "surveyDefinitionId": {
          "fieldName": "surveyDefinitionId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyDefinitions"
        },
        "surveyDefinitionLabel": {
          "fieldName": "surveyDefinitionLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyDefinitions"
        },
        "surveySubmittedBy": {
          "fieldName": "surveySubmittedBy",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SurveyDefinitions",
          "referenceTableName": "SurveySubmitters",
          "referenceFieldName": "surveySubmitterId"
        }
      },
      "orderedFieldNames": [
        "surveyDefinitionId",
        "surveyDefinitionLabel",
        "surveySubmittedBy"
      ],
      "primaryKey": "surveyDefinitionId"
    },
    "surveyQuestions": {
      "tableName": "SurveyQuestions",
      "objectType": "questionRef",
      "fields": {
        "surveyQuestionRef": {
          "fieldName": "surveyQuestionRef",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyQuestions"
        },
        "surveyDefinitionId": {
          "fieldName": "surveyDefinitionId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SurveyQuestions",
          "referenceTableName": "SurveyDefinitions",
          "referenceFieldName": "surveyDefinitionId"
        },
        "surveyQuestionType": {
          "fieldName": "surveyQuestionType",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyQuestions",
          "referenceTableName": "SurveyQuestionTypes",
          "referenceFieldName": "surveyQuestionTypeId"
        }
      },
      "orderedFieldNames": [
        "surveyQuestionRef",
        "surveyDefinitionId",
        "surveyQuestionType"
      ],
      "primaryKey": "surveyQuestionRef",
      "valuesByLabel": {
        "clientMaritalStatus": "clientMaritalStatus",
        "partnerFirstName": "partnerFirstName",
        "partnerLastName": "partnerLastName",
        "householdPartnered": "householdPartnered",
        "householdHouseholdSize": "householdHouseholdSize",
        "clientBirthDate": "clientBirthDate",
        "clientGenderIdentity": "clientGenderIdentity",
        "clientGenderAtBirth": "clientGenderAtBirth",
        "clientRace": "clientRace",
        "clientZipCode": "clientZipCode",
        "clientCensusRegion": "clientCensusRegion",
        "clientHighestDegree": "clientHighestDegree",
        "clientLivingSiblingsCount": "clientLivingSiblingsCount",
        "householdLivingChildrenCount": "householdLivingChildrenCount",
        "householdPriorYearIncome": "householdPriorYearIncome",
        "householdTotalAssetsValue": "householdTotalAssetsValue",
        "financialAssetTypes": "financialAssetTypes",
        "legalDocuments": "legalDocuments",
        "clientHealthPlanList": "clientHealthPlanList",
        "clientIndependencePreference": "clientIndependencePreference",
        "clientFinancialSecurityPreference": "clientFinancialSecurityPreference",
        "clientFamilyInvolvementPreference": "clientFamilyInvolvementPreference",
        "clientMentalHealthIssuesList": "clientMentalHealthIssuesList",
        "clientSmokerEver": "clientSmokerEver",
        "clientSmokedRecently": "clientSmokedRecently",
        "clientUsesRegularPrescriptionDrugs": "clientUsesRegularPrescriptionDrugs",
        "clientWeightPounds": "clientWeightPounds",
        "clientHeightInches": "clientHeightInches",
        "clientPriorTwoYearHospitalizationCount": "clientPriorTwoYearHospitalizationCount",
        "clientActivitiesWithDifficultyList": "clientActivitiesWithDifficultyList",
        "clientActivitiesReceivedAssistanceList": "clientActivitiesReceivedAssistanceList",
        "clientAgeReceivedHelpBathing": "clientAgeReceivedHelpBathing",
        "clientAgeReceivedHelpEating": "clientAgeReceivedHelpEating",
        "clientAgeReceivedHelpDressing": "clientAgeReceivedHelpDressing",
        "clientAgeReceivedHelpWalking": "clientAgeReceivedHelpWalking",
        "clientAgeReceivedHelpAccessingBed": "clientAgeReceivedHelpAccessingBed",
        "clientAgeReceivedHelpToileting": "clientAgeReceivedHelpToileting",
        "clientDiagnosesList": "clientDiagnosesList",
        "clientInjuriesList": "clientInjuriesList",
        "clientCanAnswerForFather": "clientCanAnswerForFather",
        "clientFatherIsAlive": "clientFatherIsAlive",
        "clientFatherAgeAtSurveyDate": "clientFatherAgeAtSurveyDate",
        "clientFatherAgeOfDeathAtSurveyDate": "clientFatherAgeOfDeathAtSurveyDate",
        "clientFatherRequiredAssistance": "clientFatherRequiredAssistance",
        "clientFatherAssistanceDurationMonthCount": "clientFatherAssistanceDurationMonthCount",
        "clientFatherNursingHomeEver": "clientFatherNursingHomeEver",
        "clientFatherNursingHomeMonthCount": "clientFatherNursingHomeMonthCount",
        "clientFatherCardiovascularEver": "clientFatherCardiovascularEver",
        "clientFatherMemoryProblemsEver": "clientFatherMemoryProblemsEver",
        "clientFatherMemoryDiagnosesList": "clientFatherMemoryDiagnosesList",
        "clientCanAnswerForMother": "clientCanAnswerForMother",
        "clientMotherIsAlive": "clientMotherIsAlive",
        "clientMotherAgeAtSurveyDate": "clientMotherAgeAtSurveyDate",
        "clientMotherAgeOfDeathAtSurveyDate": "clientMotherAgeOfDeathAtSurveyDate",
        "clientMotherRequiredAssistance": "clientMotherRequiredAssistance",
        "clientMotherAssistanceDurationMonthCount": "clientMotherAssistanceDurationMonthCount",
        "clientMotherNursingHomeEver": "clientMotherNursingHomeEver",
        "clientMotherNursingHomeMonthCount": "clientMotherNursingHomeMonthCount",
        "clientMotherCardiovascularEver": "clientMotherCardiovascularEver",
        "clientMotherMemoryProblemsEver": "clientMotherMemoryProblemsEver",
        "clientMotherMemoryDiagnosesList": "clientMotherMemoryDiagnosesList",
        "financialSecurityPreference": "financialSecurityPreference",
        "independenceandQualityofLifePreference": "independenceandQualityofLifePreference",
        "careEnvironmentPreference": "careEnvironmentPreference",
        "familyInvolvementPreference": "familyInvolvementPreference",
        "riskAssessmentPreference": "riskAssessmentPreference",
        "flexibilityPreference": "flexibilityPreference",
        "costEffectivenessPreference": "costEffectivenessPreference",
        "clientOwnPersonalityPerceptions": "clientOwnPersonalityPerceptions",
        "stressManagementPreference": "stressManagementPreference",
        "disagreementResolutionPreference": "disagreementResolutionPreference",
        "supportProviderInvolvement": "supportProviderInvolvement",
        "rankedFactors": "rankedFactors",
        "incentiveToAcceptCare": "incentiveToAcceptCare",
        "willingnessToAcceptCare": "willingnessToAcceptCare",
        "trustLevel": "trustLevel",
        "currentCommunnicationFrequency": "currentCommunnicationFrequency",
        "emotionalCloseness": "emotionalCloseness",
        "rolePreference": "rolePreference",
        "willingnessToReceiveCare": "willingnessToReceiveCare",
        "clientFinancialStability": "clientFinancialStability",
        "previousExperience": "previousExperience",
        "potentialSupportProvider": "potentialSupportProvider",
        "relationshipCloseness": "relationshipCloseness",
        "relationshipChangeComfortLevel": "relationshipChangeComfortLevel",
        "financialNeedConcernLevel": "financialNeedConcernLevel",
        "receivingCareInterferenceLikelihood": "receivingCareInterferenceLikelihood",
        "physicalStressLevel": "physicalStressLevel",
        "emotionalStressLevel": "emotionalStressLevel",
        "preferredSupportServices": "preferredSupportServices",
        "endOfLifeComfortLevel": "endOfLifeComfortLevel",
        "clientDecisionMakingInvolvement": "clientDecisionMakingInvolvement",
        "setbackImpactLevel": "setbackImpactLevel",
        "clientPreparednessForDurationLevel": "clientPreparednessForDurationLevel",
        "financialResponsibilityHandoverPreparedness": "financialResponsibilityHandoverPreparedness",
        "stressCopingEffectivness": "stressCopingEffectivness",
        "consideredImpactOnOtherRelationships": "consideredImpactOnOtherRelationships",
        "personalCareTaskComfortLevel": "personalCareTaskComfortLevel",
        "supportProviderPersonality": "supportProviderPersonality",
        "supportProviderStressCopingPreference": "supportProviderStressCopingPreference",
        "supportProviderRelationshipWithClient": "supportProviderRelationshipWithClient",
        "supportProviderConflictResolutionWithCareRecipientPreferences": "supportProviderConflictResolutionWithCareRecipientPreferences",
        "supportProviderToClientTrustLevel": "supportProviderToClientTrustLevel",
        "supportProviderCommunicationFrequency": "supportProviderCommunicationFrequency",
        "supportProviderEmotionalClosenessLevel": "supportProviderEmotionalClosenessLevel",
        "supportProviderRolePreference": "supportProviderRolePreference",
        "supportProviderFactorPrioritization": "supportProviderFactorPrioritization",
        "supportProviderValidCircumstancePreferences": "supportProviderValidCircumstancePreferences",
        "supportProviderCareWillingnessCapabilitiyComfortLevel": "supportProviderCareWillingnessCapabilitiyComfortLevel",
        "supportProviderFinancialStabilityLevel": "supportProviderFinancialStabilityLevel",
        "supportProviderExperienceLevel": "supportProviderExperienceLevel",
        "supportProviderSupportNetwork": "supportProviderSupportNetwork",
        "supportProviderRelationshipToCareRecipient": "supportProviderRelationshipToCareRecipient",
        "supportProviderHoursPerWeek": "supportProviderHoursPerWeek",
        "supportProviderRelationshipForecast": "supportProviderRelationshipForecast",
        "supportProviderEmotionalStrainForecast": "supportProviderEmotionalStrainForecast",
        "supportProviderFinancialWorryLevel": "supportProviderFinancialWorryLevel",
        "supportProviderCareInterferenceLevel": "supportProviderCareInterferenceLevel",
        "supportProviderPhysicalStressLevel": "supportProviderPhysicalStressLevel",
        "supportProviderEmotionalStressLevel": "supportProviderEmotionalStressLevel",
        "supportProviderDependentFamilyLevel": "supportProviderDependentFamilyLevel",
        "supportProviderChronicHealthIssueLevel": "supportProviderChronicHealthIssueLevel",
        "supportProviderMedicalFamiliarityLevel": "supportProviderMedicalFamiliarityLevel",
        "supportProviderTrainingComfortLevel": "supportProviderTrainingComfortLevel",
        "supportProviderSupportServiceComfortLevel": "supportProviderSupportServiceComfortLevel",
        "supportProviderEndOfLiveComfortLevel": "supportProviderEndOfLiveComfortLevel",
        "supportProviderDecisionMakingVoiceLevel": "supportProviderDecisionMakingVoiceLevel",
        "supportProviderSetbackComfortLevel": "supportProviderSetbackComfortLevel",
        "supportProviderDurationComfortLevel": "supportProviderDurationComfortLevel",
        "supportProviderFinancialResponsibilityComfortLevel": "supportProviderFinancialResponsibilityComfortLevel",
        "supportProviderAppreciationComfortLevel": "supportProviderAppreciationComfortLevel",
        "supportProviderOverwhelmPreferences": "supportProviderOverwhelmPreferences",
        "supportProviderImpactToOtherRelationshipsComfortLevel": "supportProviderImpactToOtherRelationshipsComfortLevel",
        "supportProviderPersonalCareTasksComfortLevel": "supportProviderPersonalCareTasksComfortLevel",
        "supportProviderHealthcareNavigationComfortLevel": "supportProviderHealthcareNavigationComfortLevel",
        "householdLiquidAssetsValue": "householdLiquidAssetsValue",
        "householdLiquidAssetsAnnualGrowthRate": "householdLiquidAssetsAnnualGrowthRate",
        "clientLtcZipCode": "clientLtcZipCode",
        "clientOtherHealthPlanList": "clientOtherHealthPlanList"
      },
      "labelsByValue": {
        "clientMaritalStatus": "clientMaritalStatus",
        "partnerFirstName": "partnerFirstName",
        "partnerLastName": "partnerLastName",
        "householdPartnered": "householdPartnered",
        "householdHouseholdSize": "householdHouseholdSize",
        "clientBirthDate": "clientBirthDate",
        "clientGenderIdentity": "clientGenderIdentity",
        "clientGenderAtBirth": "clientGenderAtBirth",
        "clientRace": "clientRace",
        "clientZipCode": "clientZipCode",
        "clientCensusRegion": "clientCensusRegion",
        "clientHighestDegree": "clientHighestDegree",
        "clientLivingSiblingsCount": "clientLivingSiblingsCount",
        "householdLivingChildrenCount": "householdLivingChildrenCount",
        "householdPriorYearIncome": "householdPriorYearIncome",
        "householdTotalAssetsValue": "householdTotalAssetsValue",
        "financialAssetTypes": "financialAssetTypes",
        "legalDocuments": "legalDocuments",
        "clientHealthPlanList": "clientHealthPlanList",
        "clientIndependencePreference": "clientIndependencePreference",
        "clientFinancialSecurityPreference": "clientFinancialSecurityPreference",
        "clientFamilyInvolvementPreference": "clientFamilyInvolvementPreference",
        "clientMentalHealthIssuesList": "clientMentalHealthIssuesList",
        "clientSmokerEver": "clientSmokerEver",
        "clientSmokedRecently": "clientSmokedRecently",
        "clientUsesRegularPrescriptionDrugs": "clientUsesRegularPrescriptionDrugs",
        "clientWeightPounds": "clientWeightPounds",
        "clientHeightInches": "clientHeightInches",
        "clientPriorTwoYearHospitalizationCount": "clientPriorTwoYearHospitalizationCount",
        "clientActivitiesWithDifficultyList": "clientActivitiesWithDifficultyList",
        "clientActivitiesReceivedAssistanceList": "clientActivitiesReceivedAssistanceList",
        "clientAgeReceivedHelpBathing": "clientAgeReceivedHelpBathing",
        "clientAgeReceivedHelpEating": "clientAgeReceivedHelpEating",
        "clientAgeReceivedHelpDressing": "clientAgeReceivedHelpDressing",
        "clientAgeReceivedHelpWalking": "clientAgeReceivedHelpWalking",
        "clientAgeReceivedHelpAccessingBed": "clientAgeReceivedHelpAccessingBed",
        "clientAgeReceivedHelpToileting": "clientAgeReceivedHelpToileting",
        "clientDiagnosesList": "clientDiagnosesList",
        "clientInjuriesList": "clientInjuriesList",
        "clientCanAnswerForFather": "clientCanAnswerForFather",
        "clientFatherIsAlive": "clientFatherIsAlive",
        "clientFatherAgeAtSurveyDate": "clientFatherAgeAtSurveyDate",
        "clientFatherAgeOfDeathAtSurveyDate": "clientFatherAgeOfDeathAtSurveyDate",
        "clientFatherRequiredAssistance": "clientFatherRequiredAssistance",
        "clientFatherAssistanceDurationMonthCount": "clientFatherAssistanceDurationMonthCount",
        "clientFatherNursingHomeEver": "clientFatherNursingHomeEver",
        "clientFatherNursingHomeMonthCount": "clientFatherNursingHomeMonthCount",
        "clientFatherCardiovascularEver": "clientFatherCardiovascularEver",
        "clientFatherMemoryProblemsEver": "clientFatherMemoryProblemsEver",
        "clientFatherMemoryDiagnosesList": "clientFatherMemoryDiagnosesList",
        "clientCanAnswerForMother": "clientCanAnswerForMother",
        "clientMotherIsAlive": "clientMotherIsAlive",
        "clientMotherAgeAtSurveyDate": "clientMotherAgeAtSurveyDate",
        "clientMotherAgeOfDeathAtSurveyDate": "clientMotherAgeOfDeathAtSurveyDate",
        "clientMotherRequiredAssistance": "clientMotherRequiredAssistance",
        "clientMotherAssistanceDurationMonthCount": "clientMotherAssistanceDurationMonthCount",
        "clientMotherNursingHomeEver": "clientMotherNursingHomeEver",
        "clientMotherNursingHomeMonthCount": "clientMotherNursingHomeMonthCount",
        "clientMotherCardiovascularEver": "clientMotherCardiovascularEver",
        "clientMotherMemoryProblemsEver": "clientMotherMemoryProblemsEver",
        "clientMotherMemoryDiagnosesList": "clientMotherMemoryDiagnosesList",
        "financialSecurityPreference": "financialSecurityPreference",
        "independenceandQualityofLifePreference": "independenceandQualityofLifePreference",
        "careEnvironmentPreference": "careEnvironmentPreference",
        "familyInvolvementPreference": "familyInvolvementPreference",
        "riskAssessmentPreference": "riskAssessmentPreference",
        "flexibilityPreference": "flexibilityPreference",
        "costEffectivenessPreference": "costEffectivenessPreference",
        "clientOwnPersonalityPerceptions": "clientOwnPersonalityPerceptions",
        "stressManagementPreference": "stressManagementPreference",
        "disagreementResolutionPreference": "disagreementResolutionPreference",
        "supportProviderInvolvement": "supportProviderInvolvement",
        "rankedFactors": "rankedFactors",
        "incentiveToAcceptCare": "incentiveToAcceptCare",
        "willingnessToAcceptCare": "willingnessToAcceptCare",
        "trustLevel": "trustLevel",
        "currentCommunnicationFrequency": "currentCommunnicationFrequency",
        "emotionalCloseness": "emotionalCloseness",
        "rolePreference": "rolePreference",
        "willingnessToReceiveCare": "willingnessToReceiveCare",
        "clientFinancialStability": "clientFinancialStability",
        "previousExperience": "previousExperience",
        "potentialSupportProvider": "potentialSupportProvider",
        "relationshipCloseness": "relationshipCloseness",
        "relationshipChangeComfortLevel": "relationshipChangeComfortLevel",
        "financialNeedConcernLevel": "financialNeedConcernLevel",
        "receivingCareInterferenceLikelihood": "receivingCareInterferenceLikelihood",
        "physicalStressLevel": "physicalStressLevel",
        "emotionalStressLevel": "emotionalStressLevel",
        "preferredSupportServices": "preferredSupportServices",
        "endOfLifeComfortLevel": "endOfLifeComfortLevel",
        "clientDecisionMakingInvolvement": "clientDecisionMakingInvolvement",
        "setbackImpactLevel": "setbackImpactLevel",
        "clientPreparednessForDurationLevel": "clientPreparednessForDurationLevel",
        "financialResponsibilityHandoverPreparedness": "financialResponsibilityHandoverPreparedness",
        "stressCopingEffectivness": "stressCopingEffectivness",
        "consideredImpactOnOtherRelationships": "consideredImpactOnOtherRelationships",
        "personalCareTaskComfortLevel": "personalCareTaskComfortLevel",
        "supportProviderPersonality": "supportProviderPersonality",
        "supportProviderStressCopingPreference": "supportProviderStressCopingPreference",
        "supportProviderRelationshipWithClient": "supportProviderRelationshipWithClient",
        "supportProviderConflictResolutionWithCareRecipientPreferences": "supportProviderConflictResolutionWithCareRecipientPreferences",
        "supportProviderToClientTrustLevel": "supportProviderToClientTrustLevel",
        "supportProviderCommunicationFrequency": "supportProviderCommunicationFrequency",
        "supportProviderEmotionalClosenessLevel": "supportProviderEmotionalClosenessLevel",
        "supportProviderRolePreference": "supportProviderRolePreference",
        "supportProviderFactorPrioritization": "supportProviderFactorPrioritization",
        "supportProviderValidCircumstancePreferences": "supportProviderValidCircumstancePreferences",
        "supportProviderCareWillingnessCapabilitiyComfortLevel": "supportProviderCareWillingnessCapabilitiyComfortLevel",
        "supportProviderFinancialStabilityLevel": "supportProviderFinancialStabilityLevel",
        "supportProviderExperienceLevel": "supportProviderExperienceLevel",
        "supportProviderSupportNetwork": "supportProviderSupportNetwork",
        "supportProviderRelationshipToCareRecipient": "supportProviderRelationshipToCareRecipient",
        "supportProviderHoursPerWeek": "supportProviderHoursPerWeek",
        "supportProviderRelationshipForecast": "supportProviderRelationshipForecast",
        "supportProviderEmotionalStrainForecast": "supportProviderEmotionalStrainForecast",
        "supportProviderFinancialWorryLevel": "supportProviderFinancialWorryLevel",
        "supportProviderCareInterferenceLevel": "supportProviderCareInterferenceLevel",
        "supportProviderPhysicalStressLevel": "supportProviderPhysicalStressLevel",
        "supportProviderEmotionalStressLevel": "supportProviderEmotionalStressLevel",
        "supportProviderDependentFamilyLevel": "supportProviderDependentFamilyLevel",
        "supportProviderChronicHealthIssueLevel": "supportProviderChronicHealthIssueLevel",
        "supportProviderMedicalFamiliarityLevel": "supportProviderMedicalFamiliarityLevel",
        "supportProviderTrainingComfortLevel": "supportProviderTrainingComfortLevel",
        "supportProviderSupportServiceComfortLevel": "supportProviderSupportServiceComfortLevel",
        "supportProviderEndOfLiveComfortLevel": "supportProviderEndOfLiveComfortLevel",
        "supportProviderDecisionMakingVoiceLevel": "supportProviderDecisionMakingVoiceLevel",
        "supportProviderSetbackComfortLevel": "supportProviderSetbackComfortLevel",
        "supportProviderDurationComfortLevel": "supportProviderDurationComfortLevel",
        "supportProviderFinancialResponsibilityComfortLevel": "supportProviderFinancialResponsibilityComfortLevel",
        "supportProviderAppreciationComfortLevel": "supportProviderAppreciationComfortLevel",
        "supportProviderOverwhelmPreferences": "supportProviderOverwhelmPreferences",
        "supportProviderImpactToOtherRelationshipsComfortLevel": "supportProviderImpactToOtherRelationshipsComfortLevel",
        "supportProviderPersonalCareTasksComfortLevel": "supportProviderPersonalCareTasksComfortLevel",
        "supportProviderHealthcareNavigationComfortLevel": "supportProviderHealthcareNavigationComfortLevel",
        "householdLiquidAssetsValue": "householdLiquidAssetsValue",
        "householdLiquidAssetsAnnualGrowthRate": "householdLiquidAssetsAnnualGrowthRate",
        "clientLtcZipCode": "clientLtcZipCode",
        "clientOtherHealthPlanList": "clientOtherHealthPlanList"
      }
    },
    "surveys": {
      "tableName": "Surveys",
      "objectType": "table",
      "fields": {
        "surveyVersionId": {
          "fieldName": "surveyVersionId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Surveys"
        },
        "surveyVersionDateTime": {
          "fieldName": "surveyVersionDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Surveys"
        },
        "surveyId": {
          "fieldName": "surveyId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Surveys"
        },
        "surveyDefinitionId": {
          "fieldName": "surveyDefinitionId",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Surveys",
          "referenceTableName": "SurveyDefinitions",
          "referenceFieldName": "surveyDefinitionId"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Surveys",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "supportProviderId": {
          "fieldName": "supportProviderId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "Surveys",
          "referenceTableName": "SupportProviders",
          "referenceFieldName": "supportProviderId"
        },
        "surveyStatus": {
          "fieldName": "surveyStatus",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "Surveys",
          "referenceTableName": "SurveyStatuses",
          "referenceFieldName": "surveyStatusId"
        },
        "lastPageSeen": {
          "fieldName": "lastPageSeen",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "Surveys"
        },
        "surveySubmittedDateTime": {
          "fieldName": "surveySubmittedDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "Surveys"
        }
      },
      "orderedFieldNames": [
        "surveyVersionId",
        "surveyVersionDateTime",
        "surveyId",
        "surveyDefinitionId",
        "clientId",
        "supportProviderId",
        "surveyStatus",
        "lastPageSeen",
        "surveySubmittedDateTime"
      ],
      "primaryKey": "surveyVersionId"
    },
    "surveyAnswers": {
      "tableName": "SurveyAnswers",
      "objectType": "table",
      "fields": {
        "surveyAnswerId": {
          "fieldName": "surveyAnswerId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyAnswers"
        },
        "surveyVersionId": {
          "fieldName": "surveyVersionId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "SurveyAnswers",
          "referenceTableName": "Surveys",
          "referenceFieldName": "surveyVersionId"
        },
        "surveyQuestionRef": {
          "fieldName": "surveyQuestionRef",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyAnswers",
          "referenceTableName": "SurveyQuestions",
          "referenceFieldName": "surveyQuestionRef"
        },
        "surveyAnswerValue": {
          "fieldName": "surveyAnswerValue",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "SurveyAnswers"
        }
      },
      "orderedFieldNames": [
        "surveyAnswerId",
        "surveyVersionId",
        "surveyQuestionRef",
        "surveyAnswerValue"
      ],
      "primaryKey": "surveyAnswerId"
    },
    "inferenceSets": {
      "tableName": "InferenceSets",
      "objectType": "table",
      "fields": {
        "inferenceSetVersionId": {
          "fieldName": "inferenceSetVersionId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "inferenceSetId": {
          "fieldName": "inferenceSetId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "inferenceSetVersionDateTime": {
          "fieldName": "inferenceSetVersionDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "InferenceSets",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "inferenceSetRunDateTime": {
          "fieldName": "inferenceSetRunDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "ltcLikelihoodEver": {
          "fieldName": "ltcLikelihoodEver",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "ltcLikelihood10Years": {
          "fieldName": "ltcLikelihood10Years",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "ltcAtAge": {
          "fieldName": "ltcAtAge",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "ltcDurationYears": {
          "fieldName": "ltcDurationYears",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "partnerHelperPercent": {
          "fieldName": "partnerHelperPercent",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "professionalHelperPercent": {
          "fieldName": "professionalHelperPercent",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "childHelperPercent": {
          "fieldName": "childHelperPercent",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "otherFamilyHelperPercent": {
          "fieldName": "otherFamilyHelperPercent",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "monthlyHelpHours": {
          "fieldName": "monthlyHelpHours",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "ltcTotalCost": {
          "fieldName": "ltcTotalCost",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "ltcFamilyCareCost": {
          "fieldName": "ltcFamilyCareCost",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "ltcProfessionalShareCost": {
          "fieldName": "ltcProfessionalShareCost",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "totalCareHoursProvided": {
          "fieldName": "totalCareHoursProvided",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "careProvidedPercent": {
          "fieldName": "careProvidedPercent",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "totalCareHoursNeeded": {
          "fieldName": "totalCareHoursNeeded",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "childrenCareHoursProvided": {
          "fieldName": "childrenCareHoursProvided",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "otherFamilyCareHoursProvided": {
          "fieldName": "otherFamilyCareHoursProvided",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "partnerCareHoursProvided": {
          "fieldName": "partnerCareHoursProvided",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "professionalCareHoursProvided": {
          "fieldName": "professionalCareHoursProvided",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "phaseOneCareHoursRatio": {
          "fieldName": "phaseOneCareHoursRatio",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "phaseTwoCareHoursRatio": {
          "fieldName": "phaseTwoCareHoursRatio",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "phaseThreeCareHoursRatio": {
          "fieldName": "phaseThreeCareHoursRatio",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "phaseOneDurationYearsRatio": {
          "fieldName": "phaseOneDurationYearsRatio",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "phaseTwoDurationYearsRatio": {
          "fieldName": "phaseTwoDurationYearsRatio",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        },
        "phaseThreeDurationYearsRatio": {
          "fieldName": "phaseThreeDurationYearsRatio",
          "objectType": "field",
          "sqlType": "NUMERIC",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "InferenceSets"
        }
      },
      "orderedFieldNames": [
        "inferenceSetVersionId",
        "inferenceSetId",
        "inferenceSetVersionDateTime",
        "clientId",
        "inferenceSetRunDateTime",
        "ltcLikelihoodEver",
        "ltcLikelihood10Years",
        "ltcAtAge",
        "ltcDurationYears",
        "partnerHelperPercent",
        "professionalHelperPercent",
        "childHelperPercent",
        "otherFamilyHelperPercent",
        "monthlyHelpHours",
        "ltcTotalCost",
        "ltcFamilyCareCost",
        "ltcProfessionalShareCost",
        "totalCareHoursProvided",
        "careProvidedPercent",
        "totalCareHoursNeeded",
        "childrenCareHoursProvided",
        "otherFamilyCareHoursProvided",
        "partnerCareHoursProvided",
        "professionalCareHoursProvided",
        "phaseOneCareHoursRatio",
        "phaseTwoCareHoursRatio",
        "phaseThreeCareHoursRatio",
        "phaseOneDurationYearsRatio",
        "phaseTwoDurationYearsRatio",
        "phaseThreeDurationYearsRatio"
      ],
      "primaryKey": "inferenceSetVersionId"
    },
    "fundingSources": {
      "tableName": "FundingSources",
      "objectType": "table",
      "fields": {
        "fundingSourceId": {
          "fieldName": "fundingSourceId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingSources"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingSources",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "fundingSourceLabel": {
          "fieldName": "fundingSourceLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingSources"
        }
      },
      "orderedFieldNames": [
        "fundingSourceId",
        "clientId",
        "fundingSourceLabel"
      ],
      "primaryKey": "fundingSourceId"
    },
    "fundingSourceDetails": {
      "tableName": "FundingSourceDetails",
      "objectType": "table",
      "fields": {
        "fundingSourceDetailId": {
          "fieldName": "fundingSourceDetailId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingSourceDetails"
        },
        "fundingSourceId": {
          "fieldName": "fundingSourceId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingSourceDetails",
          "referenceTableName": "FundingSources",
          "referenceFieldName": "fundingSourceId"
        },
        "fundingSourceDetailLabel": {
          "fieldName": "fundingSourceDetailLabel",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingSourceDetails"
        },
        "fundingSourceDetailValue": {
          "fieldName": "fundingSourceDetailValue",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "FundingSourceDetails"
        }
      },
      "orderedFieldNames": [
        "fundingSourceDetailId",
        "fundingSourceId",
        "fundingSourceDetailLabel",
        "fundingSourceDetailValue"
      ],
      "primaryKey": "fundingSourceDetailId"
    },
    "magicLinks": {
      "tableName": "MagicLinks",
      "objectType": "table",
      "fields": {
        "magicLinkId": {
          "fieldName": "magicLinkId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "MagicLinks"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "MagicLinks",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "token": {
          "fieldName": "token",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "MagicLinks"
        },
        "usedDateTime": {
          "fieldName": "usedDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "MagicLinks"
        },
        "expirationDateTime": {
          "fieldName": "expirationDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "MagicLinks"
        },
        "createdDateTime": {
          "fieldName": "createdDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "MagicLinks"
        }
      },
      "orderedFieldNames": [
        "magicLinkId",
        "clientId",
        "token",
        "usedDateTime",
        "expirationDateTime",
        "createdDateTime"
      ],
      "primaryKey": "magicLinkId"
    },
    "clientOnboardingSlideProgress": {
      "tableName": "ClientOnboardingSlideProgress",
      "objectType": "table",
      "fields": {
        "clientOnboardingSlideProgressId": {
          "fieldName": "clientOnboardingSlideProgressId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientOnboardingSlideProgress"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientOnboardingSlideProgress",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "hasClientStartedOnboarding": {
          "fieldName": "hasClientStartedOnboarding",
          "objectType": "field",
          "sqlType": "BOOLEAN",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "ClientOnboardingSlideProgress"
        },
        "hasClientCompletedOnboarding": {
          "fieldName": "hasClientCompletedOnboarding",
          "objectType": "field",
          "sqlType": "BOOLEAN",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "ClientOnboardingSlideProgress"
        }
      },
      "orderedFieldNames": [
        "clientOnboardingSlideProgressId",
        "clientId",
        "hasClientStartedOnboarding",
        "hasClientCompletedOnboarding"
      ],
      "primaryKey": "clientOnboardingSlideProgressId"
    },
    "clientCareEnvironmentCosts": {
      "tableName": "ClientCareEnvironmentCosts",
      "objectType": "table",
      "fields": {
        "clientCareEnvironmentCostId": {
          "fieldName": "clientCareEnvironmentCostId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCareEnvironmentCosts"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCareEnvironmentCosts",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "careEnvironment": {
          "fieldName": "careEnvironment",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCareEnvironmentCosts",
          "referenceTableName": "CareEnvironments",
          "referenceFieldName": "careEnvironmentId"
        },
        "rateAmount": {
          "fieldName": "rateAmount",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCareEnvironmentCosts"
        }
      },
      "orderedFieldNames": [
        "clientCareEnvironmentCostId",
        "clientId",
        "careEnvironment",
        "rateAmount"
      ],
      "primaryKey": "clientCareEnvironmentCostId"
    },
    "emailPreferences": {
      "tableName": "EmailPreferences",
      "objectType": "table",
      "fields": {
        "email": {
          "fieldName": "email",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "EmailPreferences"
        },
        "optOutReminders": {
          "fieldName": "optOutReminders",
          "objectType": "field",
          "sqlType": "BOOLEAN",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "EmailPreferences"
        },
        "optOutTransactions": {
          "fieldName": "optOutTransactions",
          "objectType": "field",
          "sqlType": "BOOLEAN",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "EmailPreferences"
        }
      },
      "orderedFieldNames": [
        "email",
        "optOutReminders",
        "optOutTransactions"
      ],
      "primaryKey": "email"
    },
    "clientCarePhaseDurationSelections": {
      "tableName": "ClientCarePhaseDurationSelections",
      "objectType": "table",
      "fields": {
        "clientCarePhaseDurationSelectionId": {
          "fieldName": "clientCarePhaseDurationSelectionId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCarePhaseDurationSelections"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCarePhaseDurationSelections",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "carePhase": {
          "fieldName": "carePhase",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCarePhaseDurationSelections",
          "referenceTableName": "CarePhases",
          "referenceFieldName": "carePhaseId"
        },
        "durationMonths": {
          "fieldName": "durationMonths",
          "objectType": "field",
          "sqlType": "INT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientCarePhaseDurationSelections"
        }
      },
      "orderedFieldNames": [
        "clientCarePhaseDurationSelectionId",
        "clientId",
        "carePhase",
        "durationMonths"
      ],
      "primaryKey": "clientCarePhaseDurationSelectionId"
    },
    "clientPolicyDataExtractRequests": {
      "tableName": "ClientPolicyDataExtractRequests",
      "objectType": "table",
      "fields": {
        "requestId": {
          "fieldName": "requestId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "policyType": {
          "fieldName": "policyType",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        },
        "prompt": {
          "fieldName": "prompt",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        },
        "status": {
          "fieldName": "status",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        },
        "error": {
          "fieldName": "error",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        },
        "results": {
          "fieldName": "results",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        },
        "createdDateTime": {
          "fieldName": "createdDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        },
        "responseDateTime": {
          "fieldName": "responseDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "ClientPolicyDataExtractRequests"
        }
      },
      "orderedFieldNames": [
        "requestId",
        "clientId",
        "policyType",
        "prompt",
        "status",
        "error",
        "results",
        "createdDateTime",
        "responseDateTime"
      ],
      "primaryKey": "requestId"
    },
    "clientPartnerLinks": {
      "tableName": "ClientPartnerLinks",
      "objectType": "table",
      "fields": {
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientPartnerLinks",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "partnerClientId": {
          "fieldName": "partnerClientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientPartnerLinks",
          "referenceTableName": "Clients",
          "referenceFieldName": "clientId"
        },
        "coupleId": {
          "fieldName": "coupleId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "ClientPartnerLinks"
        },
        "createdDateTime": {
          "fieldName": "createdDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "ClientPartnerLinks"
        }
      },
      "orderedFieldNames": [
        "clientId",
        "partnerClientId",
        "coupleId",
        "createdDateTime"
      ],
      "primaryKey": "clientId"
    },
    "intakeFormPayloads": {
      "tableName": "IntakeFormPayloads",
      "objectType": "table",
      "fields": {
        "typeformPayloadSubmissionId": {
          "fieldName": "typeformPayloadSubmissionId",
          "objectType": "field",
          "sqlType": "UUID",
          "isPrimaryKey": true,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "IntakeFormPayloads"
        },
        "typeformPayload": {
          "fieldName": "typeformPayload",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": false,
          "ownerTableName": "IntakeFormPayloads"
        },
        "advisorId": {
          "fieldName": "advisorId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "IntakeFormPayloads"
        },
        "clientId": {
          "fieldName": "clientId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "IntakeFormPayloads"
        },
        "surveyId": {
          "fieldName": "surveyId",
          "objectType": "field",
          "sqlType": "TEXT",
          "isPrimaryKey": false,
          "isRequired": false,
          "hasDefault": false,
          "ownerTableName": "IntakeFormPayloads"
        },
        "createdDateTime": {
          "fieldName": "createdDateTime",
          "objectType": "field",
          "sqlType": "TIMESTAMP",
          "isPrimaryKey": false,
          "isRequired": true,
          "hasDefault": true,
          "ownerTableName": "IntakeFormPayloads"
        }
      },
      "orderedFieldNames": [
        "typeformPayloadSubmissionId",
        "typeformPayload",
        "advisorId",
        "clientId",
        "surveyId",
        "createdDateTime"
      ],
      "primaryKey": "typeformPayloadSubmissionId"
    }
  },
  "dependencyOrderedTableNames": [
    "WalpSchemaVersion",
    "CareEnvironments",
    "CarePhases",
    "ClientStatuses",
    "ClientTagDefs",
    "FundingPolicyTypes",
    "AnnuityTypes",
    "SupportProviderContributions",
    "SupportProviderDetailSetSources",
    "SupportProviderTypes",
    "SurveyQuestionTypes",
    "SurveyStatuses",
    "SurveySubmitters",
    "Advisors",
    "Clients",
    "ClientTags",
    "CareEnvironmentSelections",
    "SupportProviders",
    "SupportProviderDetailSets",
    "SupportProviderDetails",
    "SurveyDefinitions",
    "SurveyQuestions",
    "Surveys",
    "SurveyAnswers",
    "InferenceSets",
    "FundingSources",
    "FundingSourceDetails",
    "MagicLinks",
    "ClientOnboardingSlideProgress",
    "ClientCareEnvironmentCosts",
    "EmailPreferences",
    "ClientCarePhaseDurationSelections",
    "ClientPolicyDataExtractRequests",
    "ClientPartnerLinks",
    "IntakeFormPayloads"
  ],
  "fieldsByName": {},
  "tableNames": {
    "advisors": "Advisors",
    "annuityTypes": "AnnuityTypes",
    "careEnvironmentSelections": "CareEnvironmentSelections",
    "careEnvironments": "CareEnvironments",
    "carePhases": "CarePhases",
    "clientCareEnvironmentCosts": "ClientCareEnvironmentCosts",
    "clientCarePhaseDurationSelections": "ClientCarePhaseDurationSelections",
    "clientOnboardingSlideProgress": "ClientOnboardingSlideProgress",
    "clientPartnerLinks": "ClientPartnerLinks",
    "clientPolicyDataExtractRequests": "ClientPolicyDataExtractRequests",
    "clientStatuses": "ClientStatuses",
    "clientTagDefs": "ClientTagDefs",
    "clientTags": "ClientTags",
    "clients": "Clients",
    "emailPreferences": "EmailPreferences",
    "fundingPolicyTypes": "FundingPolicyTypes",
    "fundingSourceDetails": "FundingSourceDetails",
    "fundingSources": "FundingSources",
    "inferenceSets": "InferenceSets",
    "intakeFormPayloads": "IntakeFormPayloads",
    "magicLinks": "MagicLinks",
    "supportProviderContributions": "SupportProviderContributions",
    "supportProviderDetailSetSources": "SupportProviderDetailSetSources",
    "supportProviderDetailSets": "SupportProviderDetailSets",
    "supportProviderDetails": "SupportProviderDetails",
    "supportProviderTypes": "SupportProviderTypes",
    "supportProviders": "SupportProviders",
    "surveyAnswers": "SurveyAnswers",
    "surveyDefinitions": "SurveyDefinitions",
    "surveyQuestionTypes": "SurveyQuestionTypes",
    "surveyQuestions": "SurveyQuestions",
    "surveyStatuses": "SurveyStatuses",
    "surveySubmitters": "SurveySubmitters",
    "surveys": "Surveys",
    "walpSchemaVersion": "WalpSchemaVersion"
  }
};

// create title case references to table and field names as well
function copyToUpperFirstKey<T>(map: ObjectMap<T>) {
  Object.entries(map).forEach(copyToUpperFirstKeyIterator);

  function copyToUpperFirstKeyIterator([name, def]: [string, T]) {
    return (map[upperFirst(name)] = def);
  }
};
copyToUpperFirstKey(appModel.tablesByName);
copyToUpperFirstKey(appModel.fieldsByName);

// populate fieldsByName
{
  const fieldsByName = appModel.fieldsByName as ObjectMap<AppFieldDef>;
  Object.values(appModel.tablesByName)
    .forEach(
      (tableDef) => {
        Object.entries(tableDef.fields as ObjectMap<AppFieldDef>).forEach(
          ([ fieldName, fieldDef ]: [ string, AppFieldDef ]) => {
            if (fieldDef.referenceTableName) {
              return;
            }
            fieldsByName[ fieldName ] = fieldDef;
          });
      });
}

export enum CareEnvironment {
  home = 2701,
  independentLiving = 2703,
  assistedLiving = 2704,
  nursingHome = 2705,
}

export enum CarePhase {
  earlyCare = 2801,
  moderateCare = 2802,
  fullCare = 2803,
}

export enum ClientStatus {
  active = 1101,
  inactive = 1102,
  archived = 1103,
}

export enum ClientTagDef {
  fillOutTheIntakeForm = 1201,
  algorithmsCurrentlyRunning = 1202,
  algorithmsFinishedRunning = 1203,
  startOnboarding = 1204,
  closingTheCareGap = 1205,
  updateYourCareSupportStructure = 1206,
  completedPlan = 1207,
  algorithmsError = 1208,
  clientStartedReview = 1209,
  clientCompletedReview = 1210,
  reviewCallScheduled = 1211,
  reviewStartedTogether = 1212,
  reviewCompletedTogether = 1213,
}

export enum FundingPolicyType {
  longTermCareInsurance = 2901,
  lifeInsuranceWithRider = 2902,
  hybridLifeInsurance = 2903,
  shortTermCareInsurance = 2904,
  annuityHybrid = 2905,
  jointHybridAssetBased = 2906,
  jointHybridAnnuityBased = 2907,
  lifeWithIndexAccount = 2908,
  allstateHybridPolicy = 2909,
}

export enum AnnuityType {
  basicAnnuity = 3001,
}

export enum SupportProviderContribution {
  physicalCaregiver = 2601,
  careCoordinator = 2602,
  financialCaregiver = 2603,
}

export enum SupportProviderDetailSetSource {
  inferences = 2101,
  client = 2102,
}

export enum SupportProviderType {
  self = 2200,
  professional = 2201,
  spouse = 2202,
  other = 2203,
  child = 2204,
}

export enum SurveyQuestionType {
  text = 2501,
  date = 2502,
  integer = 2503,
  multipleChoice = 2505,
  manyMultipleChoice = 2506,
  ranking = 2507,
  boolean = 2508,
}

export enum SurveyStatus {
  incomplete = 2301,
  complete = 2302,
  abandoned = 2303,
}

export enum SurveySubmitter {
  advisor = 2401,
  client = 2402,
  supportProvider = 2403,
}

export const surveyQuestionTypeByQuestionRef: ObjectMap<SurveyQuestionType> = {
  clientMaritalStatus: SurveyQuestionType.multipleChoice,
  partnerFirstName: SurveyQuestionType.text,
  partnerLastName: SurveyQuestionType.text,
  householdPartnered: SurveyQuestionType.multipleChoice,
  householdHouseholdSize: SurveyQuestionType.integer,
  clientBirthDate: SurveyQuestionType.date,
  clientGenderIdentity: SurveyQuestionType.manyMultipleChoice,
  clientGenderAtBirth: SurveyQuestionType.multipleChoice,
  clientRace: SurveyQuestionType.multipleChoice,
  clientZipCode: SurveyQuestionType.text,
  clientCensusRegion: SurveyQuestionType.multipleChoice,
  clientHighestDegree: SurveyQuestionType.multipleChoice,
  clientLivingSiblingsCount: SurveyQuestionType.integer,
  householdLivingChildrenCount: SurveyQuestionType.integer,
  householdPriorYearIncome: SurveyQuestionType.integer,
  householdTotalAssetsValue: SurveyQuestionType.integer,
  financialAssetTypes: SurveyQuestionType.manyMultipleChoice,
  legalDocuments: SurveyQuestionType.manyMultipleChoice,
  clientHealthPlanList: SurveyQuestionType.manyMultipleChoice,
  clientIndependencePreference: SurveyQuestionType.multipleChoice,
  clientFinancialSecurityPreference: SurveyQuestionType.multipleChoice,
  clientFamilyInvolvementPreference: SurveyQuestionType.multipleChoice,
  clientMentalHealthIssuesList: SurveyQuestionType.manyMultipleChoice,
  clientSmokerEver: SurveyQuestionType.multipleChoice,
  clientSmokedRecently: SurveyQuestionType.multipleChoice,
  clientUsesRegularPrescriptionDrugs: SurveyQuestionType.multipleChoice,
  clientWeightPounds: SurveyQuestionType.integer,
  clientHeightInches: SurveyQuestionType.integer,
  clientPriorTwoYearHospitalizationCount: SurveyQuestionType.integer,
  clientActivitiesWithDifficultyList: SurveyQuestionType.manyMultipleChoice,
  clientActivitiesReceivedAssistanceList: SurveyQuestionType.manyMultipleChoice,
  clientAgeReceivedHelpBathing: SurveyQuestionType.integer,
  clientAgeReceivedHelpEating: SurveyQuestionType.integer,
  clientAgeReceivedHelpDressing: SurveyQuestionType.integer,
  clientAgeReceivedHelpWalking: SurveyQuestionType.integer,
  clientAgeReceivedHelpAccessingBed: SurveyQuestionType.integer,
  clientAgeReceivedHelpToileting: SurveyQuestionType.integer,
  clientDiagnosesList: SurveyQuestionType.manyMultipleChoice,
  clientInjuriesList: SurveyQuestionType.manyMultipleChoice,
  clientCanAnswerForFather: SurveyQuestionType.boolean,
  clientFatherIsAlive: SurveyQuestionType.multipleChoice,
  clientFatherAgeAtSurveyDate: SurveyQuestionType.integer,
  clientFatherAgeOfDeathAtSurveyDate: SurveyQuestionType.integer,
  clientFatherRequiredAssistance: SurveyQuestionType.multipleChoice,
  clientFatherAssistanceDurationMonthCount: SurveyQuestionType.integer,
  clientFatherNursingHomeEver: SurveyQuestionType.multipleChoice,
  clientFatherNursingHomeMonthCount: SurveyQuestionType.integer,
  clientFatherCardiovascularEver: SurveyQuestionType.multipleChoice,
  clientFatherMemoryProblemsEver: SurveyQuestionType.multipleChoice,
  clientFatherMemoryDiagnosesList: SurveyQuestionType.manyMultipleChoice,
  clientCanAnswerForMother: SurveyQuestionType.boolean,
  clientMotherIsAlive: SurveyQuestionType.multipleChoice,
  clientMotherAgeAtSurveyDate: SurveyQuestionType.integer,
  clientMotherAgeOfDeathAtSurveyDate: SurveyQuestionType.integer,
  clientMotherRequiredAssistance: SurveyQuestionType.multipleChoice,
  clientMotherAssistanceDurationMonthCount: SurveyQuestionType.integer,
  clientMotherNursingHomeEver: SurveyQuestionType.multipleChoice,
  clientMotherNursingHomeMonthCount: SurveyQuestionType.integer,
  clientMotherCardiovascularEver: SurveyQuestionType.multipleChoice,
  clientMotherMemoryProblemsEver: SurveyQuestionType.multipleChoice,
  clientMotherMemoryDiagnosesList: SurveyQuestionType.manyMultipleChoice,
  financialSecurityPreference: SurveyQuestionType.multipleChoice,
  independenceandQualityofLifePreference: SurveyQuestionType.multipleChoice,
  careEnvironmentPreference: SurveyQuestionType.multipleChoice,
  familyInvolvementPreference: SurveyQuestionType.multipleChoice,
  riskAssessmentPreference: SurveyQuestionType.multipleChoice,
  flexibilityPreference: SurveyQuestionType.multipleChoice,
  costEffectivenessPreference: SurveyQuestionType.multipleChoice,
  clientOwnPersonalityPerceptions: SurveyQuestionType.manyMultipleChoice,
  stressManagementPreference: SurveyQuestionType.multipleChoice,
  disagreementResolutionPreference: SurveyQuestionType.multipleChoice,
  supportProviderInvolvement: SurveyQuestionType.multipleChoice,
  rankedFactors: SurveyQuestionType.ranking,
  incentiveToAcceptCare: SurveyQuestionType.multipleChoice,
  willingnessToAcceptCare: SurveyQuestionType.multipleChoice,
  trustLevel: SurveyQuestionType.multipleChoice,
  currentCommunnicationFrequency: SurveyQuestionType.multipleChoice,
  emotionalCloseness: SurveyQuestionType.multipleChoice,
  rolePreference: SurveyQuestionType.multipleChoice,
  willingnessToReceiveCare: SurveyQuestionType.multipleChoice,
  clientFinancialStability: SurveyQuestionType.multipleChoice,
  previousExperience: SurveyQuestionType.multipleChoice,
  potentialSupportProvider: SurveyQuestionType.manyMultipleChoice,
  relationshipCloseness: SurveyQuestionType.multipleChoice,
  relationshipChangeComfortLevel: SurveyQuestionType.multipleChoice,
  financialNeedConcernLevel: SurveyQuestionType.multipleChoice,
  receivingCareInterferenceLikelihood: SurveyQuestionType.multipleChoice,
  physicalStressLevel: SurveyQuestionType.multipleChoice,
  emotionalStressLevel: SurveyQuestionType.multipleChoice,
  preferredSupportServices: SurveyQuestionType.manyMultipleChoice,
  endOfLifeComfortLevel: SurveyQuestionType.multipleChoice,
  clientDecisionMakingInvolvement: SurveyQuestionType.multipleChoice,
  setbackImpactLevel: SurveyQuestionType.multipleChoice,
  clientPreparednessForDurationLevel: SurveyQuestionType.multipleChoice,
  financialResponsibilityHandoverPreparedness: SurveyQuestionType.multipleChoice,
  stressCopingEffectivness: SurveyQuestionType.multipleChoice,
  consideredImpactOnOtherRelationships: SurveyQuestionType.multipleChoice,
  personalCareTaskComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderPersonality: SurveyQuestionType.multipleChoice,
  supportProviderStressCopingPreference: SurveyQuestionType.multipleChoice,
  supportProviderRelationshipWithClient: SurveyQuestionType.multipleChoice,
  supportProviderConflictResolutionWithCareRecipientPreferences: SurveyQuestionType.multipleChoice,
  supportProviderToClientTrustLevel: SurveyQuestionType.multipleChoice,
  supportProviderCommunicationFrequency: SurveyQuestionType.multipleChoice,
  supportProviderEmotionalClosenessLevel: SurveyQuestionType.multipleChoice,
  supportProviderRolePreference: SurveyQuestionType.multipleChoice,
  supportProviderFactorPrioritization: SurveyQuestionType.ranking,
  supportProviderValidCircumstancePreferences: SurveyQuestionType.multipleChoice,
  supportProviderCareWillingnessCapabilitiyComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderFinancialStabilityLevel: SurveyQuestionType.multipleChoice,
  supportProviderExperienceLevel: SurveyQuestionType.multipleChoice,
  supportProviderSupportNetwork: SurveyQuestionType.manyMultipleChoice,
  supportProviderRelationshipToCareRecipient: SurveyQuestionType.multipleChoice,
  supportProviderHoursPerWeek: SurveyQuestionType.multipleChoice,
  supportProviderRelationshipForecast: SurveyQuestionType.multipleChoice,
  supportProviderEmotionalStrainForecast: SurveyQuestionType.multipleChoice,
  supportProviderFinancialWorryLevel: SurveyQuestionType.multipleChoice,
  supportProviderCareInterferenceLevel: SurveyQuestionType.multipleChoice,
  supportProviderPhysicalStressLevel: SurveyQuestionType.multipleChoice,
  supportProviderEmotionalStressLevel: SurveyQuestionType.multipleChoice,
  supportProviderDependentFamilyLevel: SurveyQuestionType.multipleChoice,
  supportProviderChronicHealthIssueLevel: SurveyQuestionType.multipleChoice,
  supportProviderMedicalFamiliarityLevel: SurveyQuestionType.multipleChoice,
  supportProviderTrainingComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderSupportServiceComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderEndOfLiveComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderDecisionMakingVoiceLevel: SurveyQuestionType.multipleChoice,
  supportProviderSetbackComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderDurationComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderFinancialResponsibilityComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderAppreciationComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderOverwhelmPreferences: SurveyQuestionType.multipleChoice,
  supportProviderImpactToOtherRelationshipsComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderPersonalCareTasksComfortLevel: SurveyQuestionType.multipleChoice,
  supportProviderHealthcareNavigationComfortLevel: SurveyQuestionType.multipleChoice,
  householdLiquidAssetsValue: SurveyQuestionType.integer,
  householdLiquidAssetsAnnualGrowthRate: SurveyQuestionType.integer,
  clientLtcZipCode: SurveyQuestionType.text,
  clientOtherHealthPlanList: SurveyQuestionType.manyMultipleChoice,
};
