import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface UnsavedChangesDialogProps {
  showWarning: boolean;
  handleCancelClose: () => void;
  handleConfirmClose: () => void;
}

export function UnsavedChangesDialog({
  showWarning,
  handleCancelClose,
  handleConfirmClose,
}: UnsavedChangesDialogProps) {
  return (
    <Transition.Root
      show={showWarning}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-10"
        onClose={handleCancelClose}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 mx-3 flex items-center justify-center">
          <Dialog.Panel className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all">
            <div className="flex items-start gap-4">
              <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-600"
                  aria-hidden="true"
                />
              </div>{' '}
              <div className="flex flex-col">
                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                  You have unsaved changes!
                </Dialog.Title>
                <div className="my-3">
                  <p className="text-sm text-gray-600">
                    If you close this dialog, you will lose all the changes you
                    have made. Are you sure you wish to close?
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4 flex justify-end">
              <button
                type="button"
                className="mr-3 rounded-md bg-white px-4 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={handleCancelClose}
              >
                Don't close
              </button>
              <button
                type="button"
                className="rounded-md bg-red-500 px-4 py-2 text-sm text-white hover:bg-red-700"
                onClick={handleConfirmClose}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
