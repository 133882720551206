
export function Specs() {
  return (
    <div>
      <h1>Browser specifications</h1>
      <p>Please share this information with support for assistance.</p>
      <a href="mailto:hello@joinwaterlily.com?subject=Browser%20specifications">hello@joinwaterlily.com</a>

      <pre>
        Browser window inner dimensions: {window.innerWidth} x {window.innerHeight}
      </pre>
    </div>
  )
}