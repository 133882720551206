import { ChangeEvent, useCallback, useState } from "react";
import { InputAdornment, Button } from "@mui/material";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";
import { GeneralHeader } from "../components/GeneralHeader";
import UserPool from "../util/UserPool";
import mainStyles from "../styles/main.module.css";
import moduleStyles from "./AuthPasswordChange.module.css"
import { CustomizedTextInput, isNullOrUndefined, logAudit } from '@shared';
import { AuthPasswordReset, Home, useNavigateToPage } from "..";
import { CognitoUser } from "amazon-cognito-identity-js";
import classNames from "classnames";

const styles = {
  ...mainStyles,
  ...moduleStyles
};

declare interface AuthPasswordResetChangeProps {
  email: string
}

export function AuthPasswordResetChange({ email }: AuthPasswordResetChangeProps) {
  const [verificationCode, setVerificationCode] = useState("")
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigateToPage = useNavigateToPage()


  if (isNullOrUndefined(email)) {
    navigateToPage(AuthPasswordReset)
  }

  const changePassword = useCallback(() => {
    setErrorMessage("");
    let hasErrors: boolean = false;

    if (newPassword === "") {
      hasErrors = true;
      setNewPasswordError(true);
    }

    if (confirmNewPassword === "") {
      hasErrors = true;
      setConfirmNewPasswordError(true);
    }

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    //const user = UserPool.getCurrentUser();

    if (hasErrors || isNullOrUndefined(user)) {
      return;
    }

    user.confirmPassword(verificationCode, newPassword, {
      onFailure(error) {
        logAudit(
        user.confirmPassword,
        'Change password failure',
        {
          email: email
        }
      );
        setErrorMessage(error.message)
      },
      onSuccess() {
        logAudit(
        user.confirmPassword,
        'Change password success',
        {
          email: email
        }
      );
         navigateToPage(Home)
      },
    });

  }, [setErrorMessage, setNewPasswordError, setConfirmNewPasswordError, UserPool, verificationCode, newPassword, confirmNewPassword])

  const handleChangeVerificationCode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.target.value);
    setVerificationCodeError(false);
  }, [setVerificationCode, setVerificationCodeError, verificationCode, verificationCodeError])

  const handleChangeNewPassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    setNewPasswordError(false);
  }, [setNewPassword, setNewPasswordError, newPassword, newPasswordError])

  const handleChangeConfirmNewPassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setConfirmNewPassword(value);
    setConfirmNewPasswordError(false);
    console.log(confirmNewPassword)
  }, [setConfirmNewPassword, setConfirmNewPasswordError, confirmNewPassword, confirmNewPasswordError])

  return (
    <div>
      <GeneralHeader />
      <div className={styles.main}>
        <div className={styles.h3Text}>Change Password</div>
        <form >
          <div className={styles.errorText}>{errorMessage}</div>
          <CustomizedTextInput
            id="verificationcode"
            type="password"
            placeholder="Enter the verification code that you received"
            variant="outlined"
            fullWidth
            value={verificationCode}
            error={verificationCodeError}
            onChange={handleChangeVerificationCode}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EnhancedEncryptionOutlinedIcon />
                </InputAdornment>
              ),
                classes: {
                  error: styles.textboxError,
                  root: styles.textbox,
                },
            }}
          />
          <CustomizedTextInput
            id="newpassword"
            type="password"
            placeholder="Enter your new password"
            variant="outlined"
            fullWidth
            value={newPassword}
            error={newPasswordError}
            onChange={handleChangeNewPassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EnhancedEncryptionOutlinedIcon />
                </InputAdornment>
              ),
                classes: {
                  error: styles.textboxError,
                  root: styles.textbox,
                }
            }}
          />
          <CustomizedTextInput
            id="confirmpassword"
            type="password"
            placeholder="Confirm your new password"
            variant="outlined"
            fullWidth
            value={confirmNewPassword}
            error={confirmNewPasswordError}
            onChange={handleChangeConfirmNewPassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EnhancedEncryptionIcon />
                </InputAdornment>
              ),
                classes: {
                  error: styles.textboxError,
                  root: styles.textbox,
                },
            }}
          />
          <Button
            className={classNames(styles.buttonType1, styles.accountManagerButton)}
            onClick={changePassword}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}
