import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Switch,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { NumericInput, NumericInputProps, UnsavedChangesDialog } from '..';
import { isNullUndefinedOrEmpty } from '@shared';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react/jsx-runtime';
import classNames from 'classnames';
import React from 'react';

export type FundingSourceFormProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  children: React.ReactNode;
  handleSubmit: () => void;
  formIsValid: boolean | undefined;
  formErrorMessage: React.ReactNode;
  showUnsavedChangesDialog: boolean;
  handleCancelClose: () => void;
  handleConfirmClose: () => void;
};

export function FundingSourceForm({
  open,
  onClose,
  title,
  subTitle,
  children,
  handleSubmit,
  formIsValid,
  formErrorMessage,
  showUnsavedChangesDialog,
  handleCancelClose,
  handleConfirmClose,
}: FundingSourceFormProps) {
  return (
    <div className="flex">
      <Transition
        show={open}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {}}
        >
          <UnsavedChangesDialog
            showWarning={showUnsavedChangesDialog}
            handleCancelClose={handleCancelClose}
            handleConfirmClose={handleConfirmClose}
          />
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 flex w-screen items-center justify-center">
            <div className="flex">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative mx-auto h-[auto] max-h-[750px] max-w-[95%] transform overflow-auto rounded-lg bg-white px-10 pb-4 pt-5 text-left shadow-xl transition-all md:w-[650px]">
                  <div className="flex justify-end">
                    <div>
                      <button
                        type="button"
                        className="relative rounded-md bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="h-7 w-7 "
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                  <div>
                    <DialogTitle
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-darkPurple"
                    >
                      {title}
                    </DialogTitle>
                    <div className="my-1">
                      <p className="text-base text-gray-500">{subTitle}</p>
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col gap-2 pb-7">
                    {children}
                    {!formIsValid && formErrorMessage}
                    <div className="mt-5 flex flex-row-reverse">
                      <button
                        type="button"
                        className="transition-color bottom-8 inline-flex w-full justify-center rounded-md bg-darkPurple px-12 py-2 text-lg font-semibold text-white shadow-sm duration-100 ease-in-out hover:bg-mediumPurple sm:ml-3 sm:w-auto"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

type FormFieldProps = {
  title: string;
  description: string;
  example?: string;
  isLastField?: boolean;
};

type FormFieldContainerProps = FormFieldProps & {
  children: React.ReactNode;
};

FundingSourceForm.FieldContainer = function FundingSourceFormFieldContainer({
  title,
  description,
  example,
  children,
  isLastField,
}: FormFieldContainerProps) {
  return (
    <div
      className={`grid grid-cols-2 gap-x-6 gap-y-2 py-7 ${isLastField ? '' : 'border-b'}`}
    >
      <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
        <h2 className="text-lg leading-5 text-darkPurple">{title}</h2>
        <p className="text-sm text-gray-700 md:text-base">{description}</p>
        {example && (
          <p className="text-xs italic text-gray-700 md:text-sm">{example}</p>
        )}
      </div>
      {React.Children.map(children, (child, index) => (
        <div
          className={`${
            index % 2 === 1
              ? 'md:col-start-2 md:col-end-4'
              : 'md:col-start-1 md:col-end-2'
          } col-start-1 col-end-4`}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

type NumericFormFieldProps = {
  InputField: typeof NumericInput;
  inputProps: NumericInputProps;
  fieldError: boolean | undefined;
};

FundingSourceForm.NumericField = function FundingSourceNumericField({
  InputField,
  inputProps,
  fieldError,
}: NumericFormFieldProps) {
  return (
    <>
      <InputField {...inputProps} />
      <RequiredFieldErrorMessage
        fieldValue={inputProps.value}
        fieldError={fieldError}
      />
    </>
  );
};

type BooleanFormFieldProps = {
  changeHandler: (value: boolean) => void;
  value: boolean;
  enabledLabel: string;
  disabledLabel: string;
};

FundingSourceForm.BooleanToggleField = function FundingSourceBooleanField({
  changeHandler,
  value,
  enabledLabel,
  disabledLabel,
}: BooleanFormFieldProps) {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-xs font-bold leading-[14px] text-[#454446]">
        <span className={value ? '' : 'text-purple underline'}>
          {disabledLabel}{' '}
        </span>
        /{' '}
        <span className={value ? 'text-purple underline' : ''}>
          {' '}
          {enabledLabel}{' '}
        </span>
      </h2>
      <div className="mt-3 flex">
        <FundingSourceToggle
          enabled={value}
          setEnabled={changeHandler}
        />
      </div>
    </div>
  );
};

type RequiredFieldProps = {
  fieldValue: number | null;
  fieldError: boolean | undefined;
};

function RequiredFieldErrorMessage({
  fieldValue,
  fieldError,
}: RequiredFieldProps) {
  if (fieldError && isNullUndefinedOrEmpty(fieldValue)) {
    return <p className="ml-2 text-sm text-red-400">Required field</p>;
  }
  return null;
}

interface ToggleProps {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  disabled?: boolean;
}

export function FundingSourceToggle(props: ToggleProps) {
  return (
    <Switch
      disabled={props.disabled ? true : false}
      checked={props.enabled}
      onChange={props.setEnabled}
      className={classNames(
        props.enabled ? 'bg-purple' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          props.enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      />
    </Switch>
  );
}

export function setFundingSourceStateWithChangedFlag<T>(
  setState: React.Dispatch<React.SetStateAction<T>>,
  setHasChangedField: React.Dispatch<React.SetStateAction<boolean>>,
) {
  return (newState: React.SetStateAction<T>) => {
    setState(prevState => {
      const updatedState =
        typeof newState === 'function'
          ? (newState as (prevState: T) => T)(prevState)
          : newState;
      setHasChangedField(true);
      return updatedState;
    });
  };
}
