import {
  FundingSourceForm,
  setFundingSourceStateWithChangedFlag,
} from './FundingSourceForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  putPolicyFundingSourceForClientByClientIdRequest,
  selectClient,
} from '../model';
import {
  FundingPolicyType,
  checkPolicyCalculationTypeEquals,
  fundingPolicyTypeDefs,
  isNullOrUndefined,
  isNullUndefinedOrEmpty,
} from '@shared';
import { Fragment, useEffect, useState } from 'react';

import {
  CurrencyInput,
  IntegerInput,
  NumericInput,
  PercentInput,
  UnlimitedCurrencyInput,
  VbShopPlanSelection,
} from '..';
import { FormErrorAlert } from './FormErrorAlert';
import {
  PolicyDataExtractor,
  PolicyField,
  ParsedPolicyDataExtractResponse,
} from './PolicyDataExtractor';
import { createGenericContext } from '../util/contextCreator';
import { FaCaretDown } from 'react-icons/fa6';
import React from 'react';
import { getCoupleLtcStartData, CoupleLtcStartData } from '@shared';
import { Switch } from '@headlessui/react';
import produce from 'immer';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
interface InsuranceFormErrors extends ObjectMap<boolean | undefined> {
  policyInflationProtection?: boolean;
}

type PolicyFieldKey = keyof PolicyFundingSource;

type InsuranceFormProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  policyType: FundingPolicyType | null;
  fundingSourceId: string | null;
};

type InsuranceFormContextProps = {
  formErrors: InsuranceFormErrors;
  policyState: PolicyFundingSource;
  handleChangePolicyField: (
    policyKey: PolicyFieldKey,
  ) => (value: number | boolean | null) => void;
};

const [InsuranceFormContextProvider, useInsuranceFormContext] =
  createGenericContext<InsuranceFormContextProps>();

export function InsuranceForm({
  open,
  setOpen,
  policyType,
  fundingSourceId,
}: InsuranceFormProps) {
  const client = useSelector(selectClient);
  const dispatch = useDispatch();
  const {
    intakeSurvey: { clientHasStartedLtc },
    clientId,
    multipleFundingSources,
  } = client;

  const fundingSourceIdExists = !isNullOrUndefined(fundingSourceId);

  // get input fields for the policy type
  const policyInputFields = policyType
    ? PolicyFieldMap[policyType]
    : PolicyFieldMap[FundingPolicyType.longTermCareInsurance];

  // local form state variables
  const [formErrors, setFormErrors] = useState({} as InsuranceFormErrors);

  // get policy by funding source id if it exists, otherwise use default policy
  const getInitialPolicyState = () =>
    fundingSourceIdExists
      ? multipleFundingSources.ltcPolicy[fundingSourceId]
      : ({ policyType, hasPolicyFunding: true } as PolicyFundingSource);

  const initialPolicy = getInitialPolicyState();
  const [policyState, _setPolicyState] = useState(initialPolicy);
  const [formIsValid, setFormIsValid] = useState<boolean | undefined>(true);
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [isPolicyDetailsExpanded, setIsPolicyDetailsExpanded] =
    useState<boolean>(fundingSourceIdExists);
  const [hasChangedField, setHasChangedField] = useState(false);

  const toggleExpand = () => {
    setIsPolicyDetailsExpanded(!isPolicyDetailsExpanded);
  };

  const setPolicyState = setFundingSourceStateWithChangedFlag(
    _setPolicyState,
    setHasChangedField,
  );

  // for aborting fetch requests to the policy data extractor
  useEffect(() => {
    if (!open && abortController) {
      abortController.abort();
    }
  }, [open, abortController]);

  const handleChangePolicyField =
    (policyKey: PolicyFieldKey) => (value: number | boolean | null) => {
      setPolicyState(
        prevState =>
          ({
            ...prevState,
            [policyKey]: value,
          }) as PolicyFundingSource,
      );
    };

  const [showWarning, setShowWarning] = useState(false);

  const isPolicyTypeAnnuityHybrid = checkPolicyCalculationTypeEquals(
    policyType,
    FundingPolicyType.annuityHybrid,
  );

  const validateForm = () => {
    let formIsValid = true;
    const newFormErrors = {} as InsuranceFormErrors;

    if (isNullUndefinedOrEmpty(policyState.policyPremiumStartYear)) {
      newFormErrors.policyPremiumStartYear = true;
      formIsValid = false;
    }

    const isPolicyTypeLifeWithIndexAccount =
      policyType === FundingPolicyType.lifeWithIndexAccount;
    if (!isPolicyTypeAnnuityHybrid && !isPolicyTypeLifeWithIndexAccount) {
      if (isNullUndefinedOrEmpty(policyState.policyInflationProtection)) {
        newFormErrors.policyInflationProtection = true;
        formIsValid = false;
      }
    }

    // on-claim specific checks
    if (clientHasStartedLtc) {
      if (isNullUndefinedOrEmpty(policyState.policyBenefitUtilizedToDate)) {
        newFormErrors.policyBenefitUtilizedToDate = true;
        formIsValid = false;
      }
      if (
        policyState.policyBenefitUtilizedToDate &&
        policyState.policyBenefitUtilizedToDate > 0 &&
        policyState.homeCareWaitingPeriodDays &&
        policyState.homeCareWaitingPeriodDays > 0 &&
        policyState.facilityCareWaitingPeriodDays &&
        policyState.facilityCareWaitingPeriodDays > 0
      ) {
        newFormErrors.waitingPeriodAndBenefitsUtilized = true;
        formIsValid = false;
      }
    }

    if (
      checkPolicyCalculationTypeEquals(
        policyType,
        FundingPolicyType.shortTermCareInsurance,
      )
    ) {
      if (isNullUndefinedOrEmpty(policyState.policyBenefitPeriodMonths)) {
        newFormErrors.policyBenefitPeriodMonths = true;
        formIsValid = false;
      }
    }

    // require premium monthly cost for pre-claim users with non-annuity hybrid policies
    if (
      !clientHasStartedLtc && //pre-claim
      !isPolicyTypeAnnuityHybrid // not hybrid and pre-claim
    ) {
      if (isNullUndefinedOrEmpty(policyState.policyPremiumMonthlyCost)) {
        newFormErrors.policyPremiumMonthlyCost = true;
        formIsValid = false;
      }
    }

    // require lump sum payment for annuity hybrid policies
    if (isPolicyTypeAnnuityHybrid) {
      if (isNullUndefinedOrEmpty(policyState.policyLumpSumPayment)) {
        newFormErrors.policyLumpSumPayment = true;
        formIsValid = false;
      }
    }

    if (policyState.isIndemnityPolicyPayment) {
      if (
        isNullUndefinedOrEmpty(policyState.dailyAssistedLivingBenefitAmount)
      ) {
        newFormErrors.dailyAssistedLivingBenefitAmount = true;
        formIsValid = false;
      }
      if (isNullUndefinedOrEmpty(policyState.dailyHomeBenefitAmount)) {
        newFormErrors.dailyHomeBenefitAmount = true;
        formIsValid = false;
      }
      if (
        isNullUndefinedOrEmpty(policyState.dailyIndependentLivingBenefitAmount)
      ) {
        newFormErrors.dailyIndependentLivingBenefitAmount = true;
        formIsValid = false;
      }
      if (isNullUndefinedOrEmpty(policyState.dailyNursingHomeBenefitAmount)) {
        newFormErrors.dailyNursingHomeBenefitAmount = true;
        formIsValid = false;
      }
    }

    setFormErrors(newFormErrors);
    setFormIsValid(formIsValid);
    return formIsValid;
  };

  // submit handler
  const handleClickSubmit = () => {
    setFormHasBeenSubmitted(true);
    if (!validateForm()) {
      return;
    }

    dispatch(
      putPolicyFundingSourceForClientByClientIdRequest({
        clientId,
        policyFundingSource: policyState,
        fundingSourceId,
      }),
    );
    setOpen(false);
  };

  // import policy data from extracted data
  const handleImport = (
    parsedResponse: ParsedPolicyDataExtractResponse | null,
  ) => {
    const setFieldValue = (
      parsedField: (PolicyField & { parsedFieldValue: any }) | undefined,
      policyFieldKey: PolicyFieldKey,
      transformValue?: (value: any) => any,
    ) => {
      if (parsedField && !isNullOrUndefined(parsedField.parsedFieldValue)) {
        handleChangePolicyField(policyFieldKey)(
          transformValue
            ? transformValue(parsedField.parsedFieldValue)
            : parsedField.parsedFieldValue,
        );
      }
    };

    if (!parsedResponse) {
      return;
    }

    setFieldValue(
      parsedResponse.policyMinimumGuaranteedBenefitAmount,
      'policyMinimumGuaranteedBenefitAmount',
    );
    setFieldValue(parsedResponse.policyLumpSumPayment, 'policyLumpSumPayment');
    setFieldValue(
      parsedResponse.policyInflationProtection,
      'policyInflationProtection',
      (value: number) => value / 100,
    );
    setFieldValue(
      parsedResponse.policyPremiumMonthlyCost,
      'policyPremiumMonthlyCost',
    );
    setFieldValue(
      parsedResponse.policyPremiumStartYear,
      'policyPremiumStartYear',
    );

    setFieldValue(
      parsedResponse.policyMaximumBenefitAmount,
      'policyMaximumBenefitAmount',
    );
    setFieldValue(
      parsedResponse.dailyAssistedLivingBenefitAmount,
      'dailyAssistedLivingBenefitAmount',
    );

    setFieldValue(
      parsedResponse.dailyHomeBenefitAmount,
      'dailyHomeBenefitAmount',
    );

    setFieldValue(
      parsedResponse.dailyIndependentLivingBenefitAmount,
      'dailyIndependentLivingBenefitAmount',
    );

    setFieldValue(
      parsedResponse.dailyNursingHomeBenefitAmount,
      'dailyNursingHomeBenefitAmount',
    );
    setFieldValue(
      parsedResponse.homeCareWaitingPeriodDays,
      'homeCareWaitingPeriodDays',
    );
    setFieldValue(
      parsedResponse.facilityCareWaitingPeriodDays,
      'facilityCareWaitingPeriodDays',
    );
    setFieldValue(
      parsedResponse.policyLimitedPayYears,
      'policyLimitedPayYears',
    );
    setFieldValue(
      parsedResponse.policyBenefitPeriodMonths,
      'policyBenefitPeriodMonths',
    );
    setFieldValue(
      parsedResponse.policyContinuationBenefitAmount,
      'policyContinuationBenefitAmount',
    );

    if (
      parsedResponse.isPrimaryPolicyHolder &&
      parsedResponse.isPrimaryPolicyHolder.fieldValue
    ) {
      handleChangePolicyField('isPrimaryPolicyHolder')(
        parsedResponse.isPrimaryPolicyHolder.fieldValue === 'Yes' ||
          parsedResponse.isPrimaryPolicyHolder.fieldValue === 'yes' ||
          parsedResponse.isPrimaryPolicyHolder.fieldValue === 'YES',
      );
    }

    if (
      parsedResponse.policyPaymentType &&
      parsedResponse.policyPaymentType.fieldValue
    ) {
      handleChangePolicyField('isIndemnityPolicyPayment')(
        parsedResponse.policyPaymentType.fieldValue === 'Indemnity' ||
          parsedResponse.policyPaymentType.fieldValue === 'indemnity',
      );
    }
    if (
      parsedResponse.policyInflationProtectionType &&
      parsedResponse.policyInflationProtectionType.fieldValue
    ) {
      handleChangePolicyField('isSimpleInflationProtection')(
        parsedResponse.policyInflationProtectionType.fieldValue === 'Simple' ||
          parsedResponse.policyInflationProtectionType.fieldValue === 'simple',
      );
    }

    setIsPolicyDetailsExpanded(true);
  };

  useEffect(() => {
    if (formHasBeenSubmitted) {
      validateForm();
    }
  }, [
    policyState.policyBenefitUtilizedToDate,
    policyState.policyInflationProtection,
    policyState.policyMaximumBenefitAmount,
    policyState.policyPremiumStartYear,
  ]);

  const resetForm = () => {
    setPolicyState(getInitialPolicyState());
    setFormHasBeenSubmitted(false);
    setFormIsValid(true);
    setHasChangedField(false);
  };

  useEffect(() => {
    if (open) {
      resetForm();
      setIsPolicyDetailsExpanded(fundingSourceIdExists);
    }
  }, [open, fundingSourceId]);

  const onClose = () => {
    if (hasChangedField) {
      setShowWarning(true);
    } else {
      setOpen(false);
      resetForm();
    }
  };

  const handleConfirmClose = () => {
    setShowWarning(false);
    setOpen(false);
    resetForm();
  };

  const handleCancelClose = () => {
    setShowWarning(false);
  };

  return (
    <FundingSourceForm
      open={open}
      onClose={onClose}
      title={'Policy Details'}
      subTitle={policyType ? fundingPolicyTypeDefs[policyType!].label : ''}
      handleSubmit={handleClickSubmit}
      formIsValid={formIsValid}
      formErrorMessage={<FormErrorMessage formErrors={formErrors} />}
      showUnsavedChangesDialog={showWarning}
      handleCancelClose={handleCancelClose}
      handleConfirmClose={handleConfirmClose}
    >
      <InsuranceFormContextProvider
        value={{
          formErrors,
          policyState,
          handleChangePolicyField,
        }}
      >
        {isNullOrUndefined(fundingSourceId) && (
          <PolicyDataExtractor
            policyType={policyType}
            handleImport={handleImport}
            setAbortController={setAbortController}
          />
        )}
        <VbShopPlanSelection
          setPolicyState={setPolicyState}
          ltcPolicy={initialPolicy}
          policyType={policyType}
        />
        <InsuranceFormCollapsibleSection
          clickHandler={toggleExpand}
          isPolicyDetailsExpanded={isPolicyDetailsExpanded}
          title="Policy Details"
        >
          {clientHasStartedLtc && <BenefitUtilizedToDateField />}
          {policyInputFields}
        </InsuranceFormCollapsibleSection>
      </InsuranceFormContextProvider>
    </FundingSourceForm>
  );
}

const PolicyFieldMap: Record<FundingPolicyType, JSX.Element> = {
  [FundingPolicyType.longTermCareInsurance]: <TraditionalLtcPolicyFields />,
  [FundingPolicyType.shortTermCareInsurance]: <ShortTermCarePolicyFields />,
  [FundingPolicyType.lifeInsuranceWithRider]: <LifeWithRiderFields />,
  [FundingPolicyType.hybridLifeInsurance]: <HybridPolicyFields />,
  [FundingPolicyType.annuityHybrid]: <AnnuityHybridFields />,
  [FundingPolicyType.lifeWithIndexAccount]: <LifeWithIndexOptionsFields />,
  [FundingPolicyType.jointHybridAssetBased]: <HybridPolicyFields />,
  [FundingPolicyType.jointHybridAnnuityBased]: <JointHybridAnnuityFields />,
  [FundingPolicyType.allstateHybridPolicy]: <HybridPolicyFields />,
};

function TraditionalLtcPolicyFields() {
  return (
    <Fragment>
      <WaitingPeriodDaysFieldGroup />
      <MaximumBenefitAmountField />
      <PremiumStartYearField />
      <PremiumMonthlyCostField />
      <LimitedPayYearsField />
      <DailyMaxBenefitsFieldGroup />
      <InflationProtectionFieldGroup />
      <IsIndemnityPolicyPaymentField isLastField={true} />
    </Fragment>
  );
}
function ShortTermCarePolicyFields() {
  return (
    <Fragment>
      <BenefitPeriodMonthsField />
      <MaximumBenefitAmountField />
      <PremiumStartYearField />
      <PremiumMonthlyCostField />
      <LimitedPayYearsField />
      <DailyMaxBenefitsFieldGroup />
      <InflationProtectionFieldGroup />
      <IsIndemnityPolicyPaymentField isLastField={true} />
    </Fragment>
  );
}
function LifeWithRiderFields() {
  return (
    <Fragment>
      <WaitingPeriodDaysFieldGroup />
      <FaceAmountField />
      <MinimumGuaranteedBenefitAmountField />
      <PremiumStartYearField />
      <PremiumMonthlyCostField />
      <LimitedPayYearsField />
      <LumpSumPaymentField />
      <DailyMaxBenefitsFieldGroup />
      <InflationProtectionFieldGroup />
      <IsIndemnityPolicyPaymentField isLastField={true} />
    </Fragment>
  );
}
function HybridPolicyFields() {
  return (
    <Fragment>
      <WaitingPeriodDaysFieldGroup />
      <FaceAmountField />
      <MinimumGuaranteedBenefitAmountField />
      <ContinuationOfBenefitsField />
      <PremiumStartYearField />
      <PremiumMonthlyCostField />
      <LimitedPayYearsField />
      <LumpSumPaymentField />
      <DailyMaxBenefitsFieldGroup />
      <InflationProtectionFieldGroup />
      <IsIndemnityPolicyPaymentField isLastField={true} />
    </Fragment>
  );
}
function AnnuityHybridFields() {
  const {
    inferenceSet: { ltcAtAge },
  } = useSelector(selectClient);
  return (
    <Fragment>
      <WaitingPeriodDaysFieldGroup />
      <TotalLTCBalanceAtAgeField />
      <PremiumStartYearField />
      <PremiumMonthlyCostField />
      <LimitedPayYearsField />
      <LumpSumPaymentField />
      <DailyMaxBenefitsFieldGroup
        title={`Daily Max Benefits at Age ${ltcAtAge}`}
      />
      <IsIndemnityPolicyPaymentField isLastField={true} />
    </Fragment>
  );
}
function LifeWithIndexOptionsFields() {
  const {
    clientFirstName,
    inferenceSet: { ltcAtAge },
  } = useSelector(selectClient);
  return (
    <Fragment>
      <WaitingPeriodDaysFieldGroup />
      <FaceAmountAtAgeField />
      <MinimumGuaranteedBenefitAmountField />
      <PremiumStartYearField />
      <PremiumMonthlyCostField />
      <LimitedPayYearsField />
      <DailyMaxBenefitsFieldGroup
        title={`Daily Max Benefits at Age ${ltcAtAge}`}
        description={`The amount limit the policy will cover for each
                      day of service based on the care setting. This amount is based on the death benefit when ${clientFirstName} is ${ltcAtAge} and the withdrawl rate.`}
      />
      <IsIndemnityPolicyPaymentField isLastField={true} />
    </Fragment>
  );
}
function JointHybridAnnuityFields() {
  const client = useSelector(selectClient);
  const { mutableClientPartner } = client;
  const { policyState } = useInsuranceFormContext();
  const coupleLtcStartData = getCoupleLtcStartData(
    client,
    mutableClientPartner,
  );
  const expectedPolicyHolderAgeAtPolicyActivation = coupleLtcStartData
    ? coupleLtcStartData.getPolicyHolderAgeAtPolicyActivation(
        policyState.isPrimaryPolicyHolder,
      )
    : null;

  return (
    <Fragment>
      <PrimaryPolicyHolderField />
      <WaitingPeriodDaysFieldGroup />
      <JointTotalLTCBalanceAtAgeField
        expectedPolicyHolderAgeAtPolicyActivation={
          expectedPolicyHolderAgeAtPolicyActivation
        }
        coupleLtcStartData={coupleLtcStartData}
      />
      <PremiumStartYearField />
      <PremiumMonthlyCostField />
      <LimitedPayYearsField />
      <LumpSumPaymentField />
      <DailyMaxBenefitsFieldGroup
        title={`Daily Max Benefits at Age ${expectedPolicyHolderAgeAtPolicyActivation}`}
        description={`The amount limit the policy will cover for each day of service based on the care setting. See the "Total LTC Balance" section for details on the policy activation age.`}
      />
      <IsIndemnityPolicyPaymentField isLastField={true} />
    </Fragment>
  );
}
type CollapsibleSectionProps = {
  children: React.ReactNode;
  clickHandler: () => void;
  isPolicyDetailsExpanded: boolean;
  title: string;
};
function InsuranceFormCollapsibleSection({
  clickHandler,
  isPolicyDetailsExpanded,
  title,
  children,
}: CollapsibleSectionProps) {
  return (
    <div className="w-full pb-6 pt-4">
      <button
        onClick={clickHandler}
        className={`flex w-full items-center justify-between border bg-gray-50 px-3 py-2 text-base transition ${isPolicyDetailsExpanded ? 'rounded-b-none rounded-t-md' : 'rounded-md'}`}
      >
        <p className="text-lg text-darkPurple">
          {isPolicyDetailsExpanded ? 'Hide ' : 'Edit '} {title}
        </p>
        <FaCaretDown
          className={classNames(
            isPolicyDetailsExpanded ? 'rotate-180 transform' : '',
            'h-5 w-5 text-purple',
          )}
        />
      </button>
      {isPolicyDetailsExpanded && (
        <div className="rounded-b-md border px-5 py-4">{children}</div>
      )}
    </div>
  );
}

type InsuranceFormFieldProps = {
  policyFieldKey: PolicyFieldKey;
  label: string;
  InputField: typeof NumericInput;
  placeholder: string;
  unit?: string;
};

type IsLastFieldProp = {
  isLastField?: boolean;
};

type TitleDescriptionProp = {
  title?: string;
  description?: string;
  example?: string;
};

function InsuranceFormNumericField({
  InputField,
  placeholder,
  label,
  policyFieldKey,
}: InsuranceFormFieldProps) {
  const { formErrors, policyState, handleChangePolicyField } =
    useInsuranceFormContext();

  return (
    <FundingSourceForm.NumericField
      fieldError={formErrors[policyFieldKey]}
      InputField={InputField}
      inputProps={{
        label,
        placeholder,
        value: policyState[policyFieldKey],
        onChange: handleChangePolicyField(policyFieldKey),
        onPreview: handleChangePolicyField(policyFieldKey),
      }}
    />
  );
}
function WaitingPeriodDaysFieldGroup() {
  const {
    intakeSurvey: { clientHasStartedLtc },
  } = useSelector(selectClient);
  return (
    <FundingSourceForm.FieldContainer
      title={
        clientHasStartedLtc
          ? 'Remaining Waiting/Elimination Period'
          : 'Waiting/Elimination Period'
      }
      description={
        clientHasStartedLtc
          ? `Number of days remaining in the elimination period before the policy begins to pay for services.`
          : `Number of days you receive care before the policy
                              begins to pay for services.`
      }
    >
      <InsuranceFormNumericField
        InputField={IntegerInput}
        placeholder="Enter days"
        label="HOME CARE WAITING PERIOD"
        policyFieldKey="homeCareWaitingPeriodDays"
      />
      <InsuranceFormNumericField
        InputField={IntegerInput}
        placeholder="Enter days"
        label="FACILITY CARE WAITING PERIOD"
        policyFieldKey="facilityCareWaitingPeriodDays"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function PremiumStartYearField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Policy Purchase Year"
      description="The calendar year when the first insurance premium was paid."
    >
      <InsuranceFormNumericField
        InputField={IntegerInput}
        placeholder="Enter year"
        label="PREMIUM START YEAR"
        policyFieldKey="policyPremiumStartYear"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function PremiumMonthlyCostField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Monthly Premium"
      description="The amount paid for the policy every month."
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="MONTHLY PREMIUM"
        policyFieldKey="policyPremiumMonthlyCost"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function LimitedPayYearsField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Limited Pay Period"
      description="Number of years premium payments are required for limited pay policies. Leave blank if N/A."
    >
      <InsuranceFormNumericField
        InputField={IntegerInput}
        placeholder="Enter dollars"
        label="LIMITED PAY YEARS"
        policyFieldKey="policyLimitedPayYears"
        unit="years"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function LumpSumPaymentField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Lump-Sum Payment"
      description="A one-time premium payment made towards the policy. Leave blank if not applicable."
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="LUMP SUM PAYMENT"
        policyFieldKey="policyLumpSumPayment"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function IsIndemnityPolicyPaymentField({ isLastField }: IsLastFieldProp) {
  const { policyState, handleChangePolicyField } = useInsuranceFormContext();
  return (
    <FundingSourceForm.FieldContainer
      title="Payment Type"
      description="The type of payment the policy provides."
      isLastField={isLastField}
    >
      <FundingSourceForm.BooleanToggleField
        changeHandler={handleChangePolicyField('isIndemnityPolicyPayment')}
        value={policyState.isIndemnityPolicyPayment}
        enabledLabel="Indemnity"
        disabledLabel="Reimbursement"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function MaximumBenefitAmountField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Max Policy Benefit"
      description={`This is the maximum dollar amount that will be
                    paid out for long-term care under this policy.
                    Refer to your policy for how your Max Policy
                    Benefit is calculated. For most policies, you
                    multiply your daily benefit amount by the total
                    number of days in the benefit period. The amount
                    will be inflated by the chosen inflation
                    protection`}
      example={`Example: For a 5-year benefit period with a $200
                daily benefit, the maximum benefit would be 5 x
                365 x 200 = $365,000.`}
    >
      <InsuranceFormNumericField
        InputField={UnlimitedCurrencyInput}
        placeholder="Unlimited"
        label="MAX BENEFIT AMOUNT"
        policyFieldKey="policyMaximumBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function TotalLTCBalanceAtAgeField() {
  const {
    clientFirstName,
    intakeSurvey: { clientHasStartedLtc },
    inferenceSet: { ltcAtAge },
  } = useSelector(selectClient);
  return (
    <FundingSourceForm.FieldContainer
      title={`Total LTC Balance at Age ${ltcAtAge}`}
      description={`The expected or guaranteed accumulated value at
                    age ${ltcAtAge}, which is ${clientFirstName}'s
                    ${clientHasStartedLtc ? '' : 'expected  '}start age for long-term care (LTC). This
                    balance is the total amount available to cover
                    qualified LTC expenses.`}
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="TOTAL LTC BALANCE"
        policyFieldKey="policyMaximumBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
type JointLtcFieldProps = {
  expectedPolicyHolderAgeAtPolicyActivation: number | null;
  coupleLtcStartData: CoupleLtcStartData | null;
};
function JointTotalLTCBalanceAtAgeField({
  expectedPolicyHolderAgeAtPolicyActivation,
  coupleLtcStartData,
}: JointLtcFieldProps) {
  const client = useSelector(selectClient);
  const { policyState } = useInsuranceFormContext();
  const { mutableClientPartner, clientFirstName } = client;

  return (
    <FundingSourceForm.FieldContainer
      title={`Total LTC Balance at Age ${expectedPolicyHolderAgeAtPolicyActivation}`}
      description={`This balance is the expected or guaranteed
                    accumulated value when the first partner is
                    expected to begin long-term care. The policy activation age ${expectedPolicyHolderAgeAtPolicyActivation} is determined by two factors.
                    1) the primary policy holder, and 2) the
                    age at which the first partner is expected to
                    start LTC. Since ${policyState.isPrimaryPolicyHolder ? clientFirstName : mutableClientPartner?.clientFirstName} is the primary policy holder, the policy activation age is based on ${policyState.isPrimaryPolicyHolder ? clientFirstName : mutableClientPartner?.clientFirstName}'s age when the first partner (${coupleLtcStartData!.clientStartsLtcFirst ? clientFirstName : mutableClientPartner?.clientFirstName}) is expected to start LTC in year ${coupleLtcStartData!.coupleLtcStartYear}.`}
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="TOTAL LTC BALANCE"
        policyFieldKey="policyMaximumBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function FaceAmountField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Face Amount (Death Benefit)"
      description={`This pool is used for qualified LTC expenses. Unused funds are paid to beneficiaries upon policyholder's death. The amount will be inflated by the chosen inflation protection.`}
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="FACE AMOUNT"
        policyFieldKey="policyMaximumBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function FaceAmountAtAgeField() {
  const {
    inferenceSet: { ltcAtAge },
  } = useSelector(selectClient);
  return (
    <FundingSourceForm.FieldContainer
      title={`Face Amount (Death Benefit) at Age ${ltcAtAge}`}
      description={`The death benefit at age ${ltcAtAge} based
                  projected growth rates. Unused funds are paid to
                    beneficiaries upon policyholder's death.`}
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="FACE AMOUNT"
        policyFieldKey="policyMaximumBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function ContinuationOfBenefitsField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Continuation of Benefits Amount"
      description={`This pool is utilized for qualified long-term
                    care expenses after the Face Amount is depleted.
                    Any funds remaining in this pool will not be
                    paid to beneficiaries. The amount will be
                    inflated by the chosen inflation protection.`}
    >
      <InsuranceFormNumericField
        InputField={UnlimitedCurrencyInput}
        placeholder="Unlimited"
        label="COB AMOUNT"
        policyFieldKey="policyContinuationBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function BenefitPeriodMonthsField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Benefit Period Months"
      description="The maximum length of time your insurance provider will payout benefits. "
    >
      <InsuranceFormNumericField
        InputField={IntegerInput}
        placeholder="Enter months"
        label="BENEFIT PERIOD MONTHS"
        policyFieldKey="policyBenefitPeriodMonths"
        unit="months"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function MinimumGuaranteedBenefitAmountField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Minimum Guaranteed Death Benefit"
      description="The minimum amount that will be paid to
                  beneficiaries upon the policyholder's death
                  regardless of the amount used for LTC expenses.
                  The amount will not be affected by the chosen
                  inflation protection."
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="MINIMUM DEATH BENEFIT"
        policyFieldKey="policyMinimumGuaranteedBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function BenefitUtilizedToDateField() {
  return (
    <FundingSourceForm.FieldContainer
      title="Benefit Utilized To Date"
      description="The dollar amount of benefits used to date to pay for long-term care."
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="BENEFIT UTILIZED TO DATE"
        policyFieldKey="policyBenefitUtilizedToDate"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function DailyMaxBenefitsFieldGroup({
  title,
  description,
  example,
}: TitleDescriptionProp) {
  return (
    <FundingSourceForm.FieldContainer
      title={title || 'Daily Max Benefits'}
      description={
        description ||
        `The amount limit the policy will cover for each day of service based on the care setting. The amounts will be inflated by the chosen inflation protection.`
      }
      example={
        example ||
        `If the benefit amounts are annual or monthly, please convert to the daily equivalent by diving by 30 or 365, respectively.`
      }
    >
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="HOME CARE"
        policyFieldKey="dailyHomeBenefitAmount"
      />
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="INDEPENDENT LIVING"
        policyFieldKey="dailyIndependentLivingBenefitAmount"
      />
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="ASSISTED LIVING"
        policyFieldKey="dailyAssistedLivingBenefitAmount"
      />
      <InsuranceFormNumericField
        InputField={CurrencyInput}
        placeholder="Enter dollars"
        label="NURSING HOME"
        policyFieldKey="dailyNursingHomeBenefitAmount"
      />
    </FundingSourceForm.FieldContainer>
  );
}
function InflationProtectionFieldGroup() {
  const { policyState, handleChangePolicyField } = useInsuranceFormContext();
  return (
    <FundingSourceForm.FieldContainer
      title="Inflation Protection"
      description="The percentage the value of benefits increases to keep up with inflation. Default calculation is compound."
    >
      <InsuranceFormNumericField
        InputField={PercentInput}
        placeholder="Enter %"
        label="INFLATION PROTECTION"
        policyFieldKey="policyInflationProtection"
      />
      <div>
        <FundingSourceForm.BooleanToggleField
          changeHandler={handleChangePolicyField('isSimpleInflationProtection')}
          value={policyState.isSimpleInflationProtection}
          enabledLabel="Simple"
          disabledLabel="Compound"
        />
      </div>
    </FundingSourceForm.FieldContainer>
  );
}
function PrimaryPolicyHolderField() {
  const { policyState, handleChangePolicyField } = useInsuranceFormContext();
  const { mutableClientPartner, clientFullName } = useSelector(selectClient);
  return (
    <FundingSourceForm.FieldContainer
      title="Primary Policy Holder"
      description={`Indicate whether ${clientFullName} is the primary
                                policy holder. If not, we will assume
                                ${mutableClientPartner?.clientFullName} is the
                                primary policy holder.`}
    >
      <FundingSourceForm.BooleanToggleField
        changeHandler={handleChangePolicyField('isPrimaryPolicyHolder')}
        value={policyState.isPrimaryPolicyHolder ?? false}
        enabledLabel={mutableClientPartner?.clientFullName || 'Partner'}
        disabledLabel={clientFullName}
      />
    </FundingSourceForm.FieldContainer>
  );
}

interface FormErrorMessageProps {
  formErrors: InsuranceFormErrors;
}
function FormErrorMessage({ formErrors }: FormErrorMessageProps) {
  const formErrorsCount = Object.keys(formErrors).length;
  const waitingPeriodAndBenefitsUtilizedErrorExists =
    'waitingPeriodAndBenefitsUtilized' in formErrors;
  const requiredFieldErrorExists =
    (waitingPeriodAndBenefitsUtilizedErrorExists && formErrorsCount > 1) ||
    (!waitingPeriodAndBenefitsUtilizedErrorExists && formErrorsCount > 0);
  const message = 'There is an error with your submission';

  return (
    <FormErrorAlert mainErrorMessage={message}>
      {requiredFieldErrorExists && (
        <li>
          <span className="font-bold">Required field:</span> Please fill out all
          required fields.
        </li>
      )}
      {waitingPeriodAndBenefitsUtilizedErrorExists && (
        <li>
          <span className="font-bold">Invalid input:</span> Remaining waiting
          period and benefits utilized cannot both be greater than 0.
        </li>
      )}
    </FormErrorAlert>
  );
}

export function removePolicyDetail(
  fundingSources: FundingSources,
): FundingSources {
  return produce(fundingSources, draftFundingSources => {
    draftFundingSources.ltcPolicy.hasPolicyFunding = false;
    draftFundingSources.ltcPolicy.policyType = null;
    draftFundingSources.ltcPolicy.policyMaximumBenefitAmount = null;
    draftFundingSources.ltcPolicy.policyPremiumMonthlyCost = null;
    draftFundingSources.ltcPolicy.policyPremiumStartYear = null;
    draftFundingSources.ltcPolicy.policyInflationProtection = null;
    draftFundingSources.ltcPolicy.dailyHomeBenefitAmount = null;
    draftFundingSources.ltcPolicy.dailyHomeInflationProtectionPercent = null;
    draftFundingSources.ltcPolicy.dailyIndependentLivingBenefitAmount = null;
    draftFundingSources.ltcPolicy.dailyIndependentLivingInflationProtectionPercent =
      null;
    draftFundingSources.ltcPolicy.dailyAssistedLivingBenefitAmount = null;
    draftFundingSources.ltcPolicy.dailyAssistedLivingInflationProtectionPercent =
      null;
    draftFundingSources.ltcPolicy.dailyNursingHomeBenefitAmount = null;
    draftFundingSources.ltcPolicy.dailyNursingHomeInflationProtectionPercent =
      null;
    draftFundingSources.ltcPolicy.policyBenefitUtilizedToDate = null;
    draftFundingSources.ltcPolicy.policyLumpSumPayment = null;
    draftFundingSources.ltcPolicy.homeCareWaitingPeriodDays = null;
    draftFundingSources.ltcPolicy.facilityCareWaitingPeriodDays = null;
    draftFundingSources.ltcPolicy.isSimpleInflationProtection = false;
    draftFundingSources.ltcPolicy.isIndemnityPolicyPayment = false;
    draftFundingSources.ltcPolicy.policyLimitedPayYears = null;
    draftFundingSources.ltcPolicy.policyBenefitPeriodMonths = null;
    draftFundingSources.ltcPolicy.policyContinuationBenefitAmount = null;
    draftFundingSources.ltcPolicy.policyMinimumGuaranteedBenefitAmount = null;
    draftFundingSources.ltcPolicy.customPolicyId = null;
    draftFundingSources.ltcPolicy.isPrimaryPolicyHolder = false;
  });
}

interface ToggleProps {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  disabled?: boolean;
}

export function InsuranceModalToggle(props: ToggleProps) {
  return (
    <Switch
      disabled={props.disabled ? true : false}
      checked={props.enabled}
      onChange={props.setEnabled}
      className={classNames(
        props.enabled ? 'bg-purple' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          props.enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      />
    </Switch>
  );
}
