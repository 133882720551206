import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { SessionType } from '@shared';

export const initialState = {
  activeApiActions: [] as (Action<string> | PayloadAction<unknown>)[],
  advisor: null as Advisor | null,
  client: {
    data: null as Client | null,
    clientPartner: null as Client | null,
    isLoading: false,
    isInvalidating: false,
  },
  clients: { data: [] as Client[], isLoading: false, isInvalidating: false },
  clientsSearchText: '',
  logTracker: {
    history: [] as string[],
  },
  session: {
    sessionType: SessionType.unknown,
    advisor: null,
    potentialNewAdvisor: null,
    cognitoSession: null,
    isLoading: false,
  } as Session,
  magicLinkSessionByToken: {
    isLoading: false,
    isExpired: undefined,
    hasBeenUsed: undefined,
    hasVerifiedSecurityQuestions: undefined,
    isAccountLocked: undefined,
  } as MagicLink,
  intakeFormEmail: { isLoading: false, failedToSend: false },
  policyOptionsRequestEmail: { isLoading: false },
  onboardingSlideTracker: {
    currentSlideIndex: 0,
    previousSlideIndex: 0,
    slideHistory: [] as number[],
  },
  emailPreferences: {
    email: '',
    optOutReminders: false,
    optOutTransactions: false,
    isLoading: false,
    error: null as string | null,
  },
};
