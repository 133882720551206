import { InputAdornment, Button } from "@mui/material";
import { ChangeEvent, useCallback, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { UnauthenticatedHeader } from "../components/UnauthenticatedHeader";
import { Home, PageLink, useNavigateToPage } from "..";
import UserPool from "../util/UserPool";
import styles from "../styles/main.module.css";
import { AuthPasswordResetChange } from "./AuthPasswordResetChange";
import classNames from "classnames";
import { CustomizedTextInput } from "../../../shared/src/customizedTextField";
import { emailRegex } from '../../../shared/src/constants';
import { logAudit } from "@shared";

export function AuthPasswordReset() {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const navigateToPage = useNavigateToPage()

  const resetPassword = useCallback(() => {
    if (!emailRegex.test(email)) {
      setEmailError(true);
      return;
    }

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        logAudit(
          user.forgotPassword,
          'Forgot password requested',
          {
            email: email
          }
        );
        navigateToPage(AuthPasswordResetChange, { email });

      },
      onFailure: (error) => {
        if (error) {
          logAudit(
            user.forgotPassword,
            'Forgot password verification failure',
            {
              error: error
            }
          );
          setErrorMessage(error.message);
        }
      }
    });
  }, [
    navigateToPage,
    setEmailError,
    setErrorMessage,
    email
  ]
  );

  const handleChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
  }, [setEmail, setEmailError])

  return (
    <>
      <UnauthenticatedHeader />
      <div className={styles.main}>
        <div className={styles.h3Text}>Reset Password</div>
        <form>
          <div className={styles.errorText}>{errorMessage}</div>
          <CustomizedTextInput id="email"
            placeholder="Enter your email"
            variant="outlined"
            fullWidth
            value={email}
            error={emailError}
            onChange={handleChangeEmail}
            className={styles.accountManagerInputText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
              classes: {
                error: styles.textboxError,
                root: styles.textbox,
              }
            }} />

          <Button className={classNames(styles.buttonType1, styles.accountManagerButton)}
            onClick={resetPassword}>
            Send Link
          </Button>

          <div className={styles.accountManagerLinks}>
            <PageLink to={Home}>
              <Button disabled={true} className={styles.buttonType4}>LOGIN</Button>
            </PageLink>
          </div>
        </form>
      </div>
    </>
  );
}
