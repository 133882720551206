import { Draft } from 'immer';
import { calcFutureValue, isNullOrUndefined } from '.';
import { calculateMonthsBetweenYears } from './calcFundingSourceDerivatives';

function calcNetFutureValueOfExistingAssets(
  selfFunding: SelfFundingSource,
  yearsTillLtc: number,
) {
  const { existingAssetsValue, annualRateOfReturn, gainsTaxRate } = selfFunding;

  selfFunding.futureValueOfInitialInvestment =
    isNullOrUndefined(existingAssetsValue) ||
    isNullOrUndefined(annualRateOfReturn)
      ? 0
      : calcFutureValue(existingAssetsValue, annualRateOfReturn, yearsTillLtc);

  const gainsTaxLiabilityOnExistingAssets: number =
    calcCapitalGainsTaxLiability(
      existingAssetsValue,
      gainsTaxRate,
      selfFunding.futureValueOfInitialInvestment,
    );
  return (
    selfFunding.futureValueOfInitialInvestment -
    gainsTaxLiabilityOnExistingAssets
  );
}

function calcNetFutureValueOfMonthlyContributions(
  selfFunding: SelfFundingSource,
) {
  const { monthlyContributionAmount, annualRateOfReturn, gainsTaxRate } =
    selfFunding;

  const futureValueOfMonthlyContributions: number = isNullOrUndefined(
    monthlyContributionAmount,
  )
    ? 0
    : calcFutureValueOfMonthlyContributions(
        monthlyContributionAmount,
        annualRateOfReturn ?? 0,
        selfFunding.contributionMonthCount,
      );

  const gainsTaxLiabilityOnfMonthlyContributions: number =
    calcCapitalGainsTaxLiability(
      (monthlyContributionAmount ?? 0) * selfFunding.contributionMonthCount,
      gainsTaxRate,
      futureValueOfMonthlyContributions,
    );

  return (
    futureValueOfMonthlyContributions - gainsTaxLiabilityOnfMonthlyContributions
  );
}

export function calcSelfFundingProjectedCostCoverage(
  mutableClient: Draft<Client>,
) {
  const {
    fundingSources: {
      selfFunding,
      selfFunding: {
        contributionStartYear,
        contributionEndYear,
        monthlyIncome,
      },
    },
    allPhaseCosts: { allPhaseCareMonthsNeeded },
    inferenceSet,
  } = mutableClient;

  // calc net future value of initial investment
  selfFunding.contributionYearCount =
    contributionEndYear - contributionStartYear;
  selfFunding.contributionMonthCount =
    selfFunding.contributionYearCount > 0
      ? calculateMonthsBetweenYears(contributionStartYear, contributionEndYear)
      : 1;

  const netFutureValueOfInitialInvestment = calcNetFutureValueOfExistingAssets(
    selfFunding,
    inferenceSet.yearsTillLtc,
  );
  // calc net future value of monthly contributions
  const netFutureValueOfMonthlyContributions: number =
    calcNetFutureValueOfMonthlyContributions(selfFunding);

  // calc total value of monthly income
  selfFunding.monthlyIncomeTotal =
    allPhaseCareMonthsNeeded * (monthlyIncome ?? 0);

  // combine all values to get total value
  selfFunding.selfFundingTotalValue =
    netFutureValueOfInitialInvestment +
    netFutureValueOfMonthlyContributions +
    selfFunding.monthlyIncomeTotal;

  selfFunding.selfFundingProjectedCostCoverage =
    selfFunding.selfFundingTotalValue;
}

function calcCapitalGainsTaxLiability(
  principal: number | null,
  gainsTaxRate: number | null,
  investmentFutureValue: number,
) {
  if (isNullOrUndefined(principal) || isNullOrUndefined(gainsTaxRate)) {
    return 0;
  }
  return (investmentFutureValue - principal) * gainsTaxRate;
}

function calcFutureValueOfMonthlyContributions(
  monthlyContributionAmount: number,
  annualRateOfReturn: number,
  contributionMonthCount: number,
): number {
  if (
    isNullOrUndefined(monthlyContributionAmount) ||
    isNullOrUndefined(annualRateOfReturn) ||
    isNullOrUndefined(contributionMonthCount)
  ) {
    return 0;
  }
  if (annualRateOfReturn === 0) {
    return monthlyContributionAmount * contributionMonthCount;
  }

  return (
    monthlyContributionAmount *
    ((Math.pow(1 + annualRateOfReturn / 12, contributionMonthCount) - 1) /
      (annualRateOfReturn / 12))
  );
}

export function calcSelfFundingTotalsOnClaim(mutableClient: Draft<Client>) {
  const {
    fundingSources: {
      selfFunding,
      selfFunding: { monthlyIncome, existingAssetsValue },
    },
    allPhaseCosts: { allPhaseCareMonthsNeeded },
  } = mutableClient;

  selfFunding.monthlyIncomeTotal =
    allPhaseCareMonthsNeeded * (monthlyIncome ?? 0);

  // total value is equal to sum of existing assets and monthly income
  selfFunding.selfFundingTotalValue =
    (existingAssetsValue ?? 0) + selfFunding.monthlyIncomeTotal;

  // set total cost and coverage equal to toal value
  selfFunding.selfFundingTotalCost = selfFunding.selfFundingTotalValue;
  selfFunding.selfFundingProjectedCostCoverage =
    selfFunding.selfFundingTotalValue;

  // assume that all funds can be used for LTC, so this is zero.
  selfFunding.selfFundingNonLtcPayout = 0;
}

export function calcSelfFundingTotalCost(mutableClient: Draft<Client>) {
  const {
    fundingSources: {
      selfFunding,
      selfFunding: {
        existingAssetsValue,
        monthlyContributionAmount,
        monthlyIncomeTotal,
        contributionMonthCount,
      },
    },
  } = mutableClient;
  if (
    isNullOrUndefined(existingAssetsValue) &&
    isNullOrUndefined(monthlyContributionAmount) &&
    isNullOrUndefined(monthlyIncomeTotal)
  ) {
    selfFunding.selfFundingTotalCost = undefined;
    return;
  }

  selfFunding.selfFundingTotalCost =
    (monthlyContributionAmount ?? 0) * contributionMonthCount +
    (existingAssetsValue ?? 0) +
    (monthlyIncomeTotal ?? 0);
}

export function calcSelfFundingProjectedROI(mutableClient: Draft<Client>) {
  const {
    fundingSources: {
      selfFunding: { selfFundingTotalValue, selfFundingTotalCost },
      selfFunding,
    },
  } = mutableClient;

  if (
    isNullOrUndefined(selfFundingTotalValue) ||
    isNullOrUndefined(selfFundingTotalCost)
  ) {
    return;
  }

  // assume that all funds can be used for LTC, so this is zero.
  selfFunding.selfFundingNonLtcPayout = 0;

  selfFunding.selfFundingProjectedROI =
    (selfFundingTotalValue - selfFundingTotalCost) / selfFundingTotalCost;
}
