import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Delete as DeleteIcon } from '@mui/icons-material';
import CreateIcon from '@mui/icons-material/Create';
import { IconButton, Slider, Tooltip } from '@mui/material';
import { isNumber } from 'lodash';
import produce from 'immer';
import {
  coerceZeroToHundred,
  coercePositiveDecimal,
  coercePositiveInteger,
  coerceYear,
  formatCurrency,
  isNullOrUndefined,
  logOnceFatal,
} from '@shared';
import {
  CurrencyInput,
  IntegerInput,
  PercentInput,
  UnlimitedCurrencyInput,
} from '../components';
import {
  previewClientUpdates,
  putFundingSourcesForClientByClientIdRequest,
  selectClient,
  selectSelfFundingContributionYears,
} from '../model';
import slideStyles from './OnboardingLtcFunding.module.css';
import mainStyles from '../styles/main.module.css';
import { removePolicyDetail, useChangeFundingCallback } from '..';
const styles = { ...slideStyles, ...mainStyles };

const fundingSourceDefailFieldCoercers = {
  existingAssetsValue: coercePositiveDecimal,
  monthlyContributionAmount: coercePositiveDecimal,
  annualRateOfReturn: coerceZeroToHundred,
  policyMaximumBenefitAmount: coercePositiveInteger,
  policyPremiumMonthlyCost: coercePositiveDecimal,
  policyPremiumStartYear: coerceYear,
  policyInflationProtection: coerceZeroToHundred,
  policyBenefitUtilizedToDate: coercePositiveInteger,
} as Record<
  keyof SelfFundingSource | keyof PolicyFundingSource,
  (value: string) => undefined | null | number
>;

function useChangeContributionYearsCallback(isPreview: boolean) {
  const client = useSelector(selectClient);
  const dispatch = useDispatch();

  return useCallback(
    function changeContributionYearsCallbackProxy(
      _event: unknown,
      values: number | number[],
      _activeThumb?: number,
    ) {
      if (isNumber(values)) {
        logOnceFatal(
          changeContributionYearsCallbackProxy,
          'Unexpected single value from contribution years slider as onChange callback value',
          {
            values,
          },
        );
        return;
      }

      const updateClient = produce(client, updatingClient => {
        const {
          fundingSources: { selfFunding },
        } = updatingClient;
        selfFunding.contributionStartYear = values[0];
        selfFunding.contributionEndYear = values[1];
      });

      // removing preview mode because it's causing weird behavior
      if (isPreview) {
        dispatch(previewClientUpdates(updateClient));
        return;
      }

      const { clientId, fundingSources } = updateClient;

      dispatch(
        putFundingSourcesForClientByClientIdRequest({
          clientId,
          fundingSources,
        }),
      );
    },
    [client, dispatch],
  );
}

function SelfFundingForm() {
  const client = useSelector(selectClient);
  const contributionYears = useSelector(selectSelfFundingContributionYears);

  const {
    fundingSources: {
      selfFunding: {
        annualRateOfReturn,
        existingAssetsValue,
        monthlyContributionAmount,
      },
    },
    inferenceSet: { ltcAtYear },
    intakeSurvey: { clientBirthYear, clientHasStartedLtc },
  } = client;

  const contributionStartAtAge = contributionYears[0] - clientBirthYear;
  const contributionEndAtAge = contributionYears[1] - clientBirthYear;
  const contributionYearCount = contributionYears[1] - contributionYears[0];

  const handleChangeExistingAssetsValue = useChangeFundingCallback(
    'selfFunding',
    'existingAssetsValue',
    false,
  );
  const handleChangeAnnualRateOfReturn = useChangeFundingCallback(
    'selfFunding',
    'annualRateOfReturn',
    false,
  );
  const handleChangeMonthlyContributionAmount = useChangeFundingCallback(
    'selfFunding',
    'monthlyContributionAmount',
    false,
  );
  const handlePreviewExistingAssetsValue = useChangeFundingCallback(
    'selfFunding',
    'existingAssetsValue',
    true,
  );
  const handlePreviewAnnualRateOfReturn = useChangeFundingCallback(
    'selfFunding',
    'annualRateOfReturn',
    true,
  );
  const handlePreviewMonthlyContributionAmount = useChangeFundingCallback(
    'selfFunding',
    'monthlyContributionAmount',
    true,
  );

  const handleChangeContributionYears =
    useChangeContributionYearsCallback(false);
  const handlePreviewContributionYears =
    useChangeContributionYearsCallback(true);

  return (
    <>
      <div className="col-start-1 col-end-3 row-span-1 text-lg font-semibold">
        Self-funding
      </div>

      <CurrencyInput
        className="col-start-1 row-start-2"
        label="TODAY'S ASSETS FOR LTC"
        value={existingAssetsValue}
        onChange={handleChangeExistingAssetsValue}
        onPreview={handlePreviewExistingAssetsValue}
        placeholder="Enter dollars"
      />

      {clientHasStartedLtc === false && ( // hide if on claim
        <>
          <PercentInput
            className="col-start-2 row-start-2"
            label="ANNUAL RATE OF RETURN"
            value={annualRateOfReturn}
            placeholder="Enter percentage"
            onChange={handleChangeAnnualRateOfReturn}
            onPreview={handlePreviewAnnualRateOfReturn}
          />
          <CurrencyInput
            className="col-start-1 col-end-2 row-start-3"
            label="MONTHLY CONTRIBUTION"
            value={monthlyContributionAmount}
            onChange={handleChangeMonthlyContributionAmount}
            onPreview={handlePreviewMonthlyContributionAmount}
            placeholder="Enter dollars"
          />
          {!isNullOrUndefined(annualRateOfReturn) &&
            !isNullOrUndefined(existingAssetsValue) && (
              <div className="relative col-start-1 col-end-3 row-start-4 pl-2 pt-3 md:pb-3 md:pt-8">
                <div className={styles.contributionsLabel}>
                  CONTRIBUTIONS TIMELINE
                </div>
                <div className={styles.contributionYearsRange}>
                  {contributionYears[0]} - {contributionYears[1]} (Ages:{' '}
                  {contributionStartAtAge} &ndash; {contributionEndAtAge})
                </div>
                <div className={styles.contributionYearsCount}>
                  {contributionYearCount} year
                  {contributionYearCount === 1 ? '' : 's'}
                </div>

                <Slider
                  className={styles.contributionYearsValues}
                  min={new Date().getFullYear()}
                  max={ltcAtYear}
                  value={contributionYears}
                  onChange={handlePreviewContributionYears}
                  onChangeCommitted={handleChangeContributionYears}
                />

                <div className={styles.contributionYearsMinLabel}>Today</div>
                <div className={styles.contributionYearsMaxLabel}>
                  Projected Care Date
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
}

interface PolicyFormProps {
  handleClickEditPolicy(): void;
}

function PolicyForm({ handleClickEditPolicy }: PolicyFormProps) {
  const client = useSelector(selectClient);
  const dispatch = useDispatch();

  const {
    clientId,
    fundingSources,
    fundingSources: {
      ltcPolicy: {
        hasPolicyFunding,
        policyMaximumBenefitAmount,
        policyPremiumMonthlyCost,
        policyPremiumStartYear,
        policyInflationProtection,
        projectedCostCoverage,
        policyBenefitUtilizedToDate,
      },
    },
    intakeSurvey: { clientHasStartedLtc },
  } = client;

  const handleClickDeletePolicy = useCallback(() => {
    const newFundingSources = removePolicyDetail(fundingSources);
    dispatch(
      putFundingSourcesForClientByClientIdRequest({
        clientId,
        fundingSources: newFundingSources,
      }),
    );
  }, [client, dispatch, fundingSourceDefailFieldCoercers]);

  const handleChangeMaximumBenefitAmount = useChangeFundingCallback(
    'ltcPolicy',
    'policyMaximumBenefitAmount',
    false,
  );
  const handleChangePremiumMonthlyCost = useChangeFundingCallback(
    'ltcPolicy',
    'policyPremiumMonthlyCost',
    false,
  );
  const handleChangePremiumStartYear = useChangeFundingCallback(
    'ltcPolicy',
    'policyPremiumStartYear',
    false,
  );
  const handleChangeInflationProtection = useChangeFundingCallback(
    'ltcPolicy',
    'policyInflationProtection',
    false,
  );
  const handleChangeBenefitsUtilizedToDate = useChangeFundingCallback(
    'ltcPolicy',
    'policyBenefitUtilizedToDate',
    false,
  );
  const handlePreviewMaximumBenefitAmount = useChangeFundingCallback(
    'ltcPolicy',
    'policyMaximumBenefitAmount',
    true,
  );
  const handlePreviewPremiumMonthlyCost = useChangeFundingCallback(
    'ltcPolicy',
    'policyPremiumMonthlyCost',
    true,
  );
  const handlePreviewPremiumStartYear = useChangeFundingCallback(
    'ltcPolicy',
    'policyPremiumStartYear',
    true,
  );
  const handlePreviewInflationProtection = useChangeFundingCallback(
    'ltcPolicy',
    'policyInflationProtection',
    true,
  );
  const handlePreviewBenefitsUtilizedToDate = useChangeFundingCallback(
    'ltcPolicy',
    'policyBenefitUtilizedToDate',
    true,
  );

  if (!hasPolicyFunding) {
    return null;
  }

  return (
    <>
      <div className="relative flex font-semibold md:col-start-3 md:col-end-5 md:row-start-1">
        LTC Policy Coverage
        {/* two sets of edit delete buttons. below only appears for mobile only */}
        <div className="flex -translate-y-2 flex-row-reverse md:hidden">
          <Tooltip
            title={<span style={{ fontSize: '16px' }}>Delete Policy</span>}
          >
            <IconButton
              aria-label="delete"
              onClick={handleClickDeletePolicy}
            >
              <DeleteIcon sx={{ fontSize: '2', color: '#6b7280' }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: '16px' }}>
                Additional Policy Details
              </span>
            }
          >
            <IconButton
              aria-label="edit"
              onClick={handleClickEditPolicy}
            >
              <CreateIcon
                sx={{
                  fontSize: '2',
                  color: '#6b7280',
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="hidden flex-row-reverse md:relative md:col-start-4 md:row-start-1 md:flex">
        <Tooltip
          title={<span style={{ fontSize: '16px' }}>Delete Policy</span>}
        >
          <IconButton
            aria-label="delete"
            onClick={handleClickDeletePolicy}
          >
            <DeleteIcon sx={{ fontSize: '2', color: '#6b7280' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            <span style={{ fontSize: '16px' }}>Additional Policy Details</span>
          }
        >
          <IconButton
            aria-label="edit"
            onClick={handleClickEditPolicy}
          >
            <CreateIcon
              sx={{
                fontSize: '2',
                color: '#6b7280',
              }}
            />
          </IconButton>
        </Tooltip>
      </div>

      <UnlimitedCurrencyInput
        className={styles.policyMaximumBenefitAmount}
        placeholder="Unlimited"
        label="MAX BENEFIT AMOUNT"
        disabled={true}
        value={policyMaximumBenefitAmount ?? null}
        onChange={handleChangeMaximumBenefitAmount}
        onPreview={handlePreviewMaximumBenefitAmount}
      />

      <CurrencyInput
        className={styles.policyPremiumMonthlyCost}
        label={
          clientHasStartedLtc ? 'BENEFIT UTILIZED TO DATE' : 'MONTHLY PREMIUM'
        }
        placeholder="Enter dollars"
        value={
          clientHasStartedLtc
            ? policyBenefitUtilizedToDate
            : policyPremiumMonthlyCost
        }
        onChange={
          clientHasStartedLtc
            ? handleChangeBenefitsUtilizedToDate
            : handleChangePremiumMonthlyCost
        }
        onPreview={
          clientHasStartedLtc
            ? handlePreviewBenefitsUtilizedToDate
            : handlePreviewPremiumMonthlyCost
        }
        disabled={true}
      />

      <IntegerInput
        className={styles.policyPremiumStartYear}
        label="PREMIUM START YEAR"
        placeholder="Enter year"
        value={policyPremiumStartYear}
        onChange={handleChangePremiumStartYear}
        onPreview={handlePreviewPremiumStartYear}
        disabled={true}
      />

      <PercentInput
        className={styles.policyInflationProtection}
        label="INFLATION PROTECTION"
        placeholder="Enter percentage"
        value={policyInflationProtection}
        onChange={handleChangeInflationProtection}
        onPreview={handlePreviewInflationProtection}
        disabled={true}
      />

      {clientHasStartedLtc === false &&
        projectedCostCoverage && ( // hide if on claim
          <SubtotalCard
            className={styles.projectedCostCoverage}
            label="POLICY PROJECTED COST COVERAGE:"
            value={projectedCostCoverage}
          />
        )}

      <div className="mt-2 border-t border-gray-200 pb-2 pt-2 md:hidden"></div>
    </>
  );
}

interface SubtotalCardProps {
  className: string;
  label: string;
  value: number | undefined;
}

export function SubtotalCard({ className, label, value }: SubtotalCardProps) {
  return (
    <div className={className}>
      {label}{' '}
      <span className={styles.fundingSubtotalValue}>
        {formatCurrency(value)}
      </span>
    </div>
  );
}
