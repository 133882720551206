import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { ApiExError } from '@shared';

const intakeFormEmailSlice = createSlice({
  name: 'intakeFormEmail',
  initialState: initialState.intakeFormEmail,
  reducers: {
    postIntakeFormEmailRequest(
      state,
      _action: PayloadAction<IntakeFormEmailProps>,
    ) {
      state.isLoading = true;
      state.failedToSend = false;
    },
    postIntakeFormEmailResponse(
      state,
      _action: PayloadAction<IntakeFormEmailProps>,
    ) {
      state.isLoading = false;
      state.failedToSend = false;
    },
    postIntakeFormEmailFailure(state, action: PayloadAction<ApiExError>) {
      state.isLoading = false;
      state.failedToSend = true;
    },
  },
});

export const {
  postIntakeFormEmailRequest,
  postIntakeFormEmailResponse,
  postIntakeFormEmailFailure,
} = intakeFormEmailSlice.actions;

export const intakeFormEmailSliceReducer = intakeFormEmailSlice.reducer;
