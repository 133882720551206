import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon';
import { PropsWithChildren } from 'react';

interface FormErrorAlertProps {
  mainErrorMessage: string;
}

export function FormErrorAlert({
  mainErrorMessage,
  children,
}: PropsWithChildren<FormErrorAlertProps>) {
  return (
    <div className="rounded-md bg-red-50 p-3">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {mainErrorMessage}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">{children}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
