import { Button, InputAdornment } from '@mui/material';
import { CustomizedTextInput, isNullUndefinedOrEmpty } from '@shared';
import classNames from 'classnames';
import mainStyles from '../styles/main.module.css';
import { useState } from 'react';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { useSelector } from 'react-redux';
import { selectSession } from '../model';
import UserPool from '../util/UserPool';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { AuthMFASetup } from './AuthMFASetup';
import { AdvisorDashboard, PageLink } from '..';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { FaCircleCheck } from 'react-icons/fa6';
import { WaterlilyHeaderLink } from '../components/WaterlilyHeaderLink';

const styles = { ...mainStyles };

export function AuthMFASetupExisting() {
  const [password, setPassword] = useState('');
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [softwareToken, setSoftwareToken] = useState<string | null>(null);
  const [totpCode, setTotpCode] = useState('');
  const [hasTotpCodeError, setHasTotpCodeError] = useState(false);
  const [hasMFAEnabled, setHasMFAEnabled] = useState(false);
  const [wasSetupSuccessful, setWasSetupSuccessful] = useState(false);

  const session = useSelector(selectSession);

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleAuthenticationError = (err: unknown) => {
    if (err instanceof Error) {
      setError(err.message);
    }
  };
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!password) {
      setHasPasswordError(true);
      setError('Password is required');
      return;
    }
    setHasPasswordError(false);
    if (!session.advisor) {
      setError('Advisor not found');
      return;
    }
    const user = new CognitoUser({
      Username: session.advisor?.advisorEmail,
      Pool: UserPool,
    });
    setCognitoUser(user);
    const authDetails = new AuthenticationDetails({
      Username: session.advisor?.advisorEmail,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: result => {
        let hasMFAEnabled = false;
        setError(null);
        user.getMFAOptions((err, mfaOptions) => {
          if (err && err instanceof Error) {
            setError(err.message);
          } else {
            if (mfaOptions && mfaOptions.length > 0) {
              hasMFAEnabled = true;
            }
          }
        });
        setHasMFAEnabled(hasMFAEnabled);
        if (!hasMFAEnabled) {
          user?.associateSoftwareToken({
            associateSecretCode(secretCode) {
              // save the secret code & display qr code
              setSoftwareToken(secretCode);
              setError(null);
            },
            onFailure: handleAuthenticationError,
          });
        }
      },
      onFailure: handleAuthenticationError,
      totpRequired: function () {
        setHasMFAEnabled(true);
        setError(null);
      },
    });
  };

  const handleChangeTotpCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotpCode(event.target.value);
  };

  const handleClickVerifyTotpCode = () => {
    if (isNullUndefinedOrEmpty(totpCode)) {
      setError('Verification code is required.');
      setHasTotpCodeError(true);
      return;
    }

    if (!isNullUndefinedOrEmpty(cognitoUser)) {
      cognitoUser.verifySoftwareToken(totpCode, 'MFA Device', {
        onSuccess: () => {
          const totpMfaSettings = {
            PreferredMfa: true,
            Enabled: true,
          };
          cognitoUser.setUserMfaPreference(
            null,
            totpMfaSettings,
            function (err, result) {
              if (err && err instanceof Error) {
                setError(err.message);
              } else {
                setError(null);
                setWasSetupSuccessful(true);
              }
            },
          );
        },
        onFailure: handleAuthenticationError,
      });
    }
  };

  if (softwareToken && session.advisor?.advisorEmail && !wasSetupSuccessful) {
    return (
      <AuthMFASetup
        softwareToken={softwareToken}
        email={session.advisor?.advisorEmail}
        showEnableMfa={false}
        errorMessage={error}
        handleClickVerifyTotpCode={handleClickVerifyTotpCode}
        totpCode={totpCode}
        handleChanceTotpCode={handleChangeTotpCode}
        hasTotpCodeError={hasTotpCodeError}
      />
    );
  }

  let body;
  if (hasMFAEnabled) {
    body = (
      <p className="px-3 py-4 text-center text-2xl">
        Multi-factor authentication is already enabled for this account.
      </p>
    );
  } else if (wasSetupSuccessful) {
    body = (
      <p className="flex flex-col items-center gap-4 py-6 text-center text-2xl">
        <FaCircleCheck className="h-10 w-10 text-green-500" />
        Multi-factor authentication has successfully been enabled for this
        account.
      </p>
    );
  } else {
    body = (
      <>
        <p className="pt-4">Enter your password to enable MFA</p>
        <form
          onSubmit={onSubmit}
          className="flex w-full flex-col items-center justify-center gap-1 px-4 md:px-1"
        >
          <CustomizedTextInput
            id="password"
            type="password"
            placeholder="Enter your password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={handleChangePassword}
            error={hasPasswordError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HttpsOutlinedIcon />
                </InputAdornment>
              ),
              classes: {
                error: styles.textboxError,
                root: styles.textbox,
              },
              tabIndex: 0,
              autoFocus: true,
            }}
          />
          <button
            className="w-full rounded-full border-0 bg-darkPurple px-8 py-2 text-lg text-white hover:bg-mediumPurple focus:outline-none"
            type="submit"
          >
            Enable MFA
          </button>
        </form>
      </>
    );
  }

  return (
    <>
      <WaterlilyHeaderLink toAdvisorDashboard={true} />
      <div className={classNames(styles.main, 'm-auto max-w-md')}>
        <div
          className={`${wasSetupSuccessful || hasMFAEnabled ? `hidden` : `text-4xl text-darkPurple`}`}
        >
          MFA Setup
        </div>
        {error ? <div className={styles.errorText}>{error}</div> : null}
        <div className="grid w-full grid-cols-1 justify-items-center gap-4 text-darkPurple">
          {body}
          {session.advisor?.advisorId ? (
            <PageLink
              to={AdvisorDashboard}
              targetProps={{ advisorId: session.advisor?.advisorId }}
              className={
                'mb-10 ml-2 flex items-center gap-2 rounded-lg px-2 py-1 hover:bg-gray-100'
              }
            >
              <IoArrowBackCircleSharp className="h-6 w-6 text-darkPurple" />
              <span className=" text-base text-darkPurple">
                Back to Dashboard
              </span>
            </PageLink>
          ) : null}
        </div>
      </div>
    </>
  );
}
