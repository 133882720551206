import { toRecord } from './toRecord';
import { FundingPolicyType } from './appModel';

export interface FundingPolicyTypeDef {
  index: number;
  value: FundingPolicyType;
  key:
    | 'longTermCareInsurance'
    | 'lifeInsuranceWithRider'
    | 'hybridLifeInsurance' // asset based
    | 'shortTermCareInsurance'
    | 'annuityHybrid'
    | 'jointHybridAssetBased'
    | 'jointHybridAnnuityBased'
    | 'allstateHybridPolicy';
  label: string;
  isJoint: boolean;
  description: string;
  calculationType: FundingPolicyType;
  listPriority: number;
}

export const fundingPolicyTypeDefList: FundingPolicyTypeDef[] = [
  {
    index: 0,
    value: FundingPolicyType.longTermCareInsurance,
    key: FundingPolicyType[
      FundingPolicyType.longTermCareInsurance
    ] as FundingPolicyTypeDef['key'],
    label: 'Traditional LTC Policy',
    isJoint: false,
    description: 'Maximum benefit amount with possible inflation protection',
    calculationType: FundingPolicyType.longTermCareInsurance,
    listPriority: 1,
  },
  {
    index: 1,
    value: FundingPolicyType.lifeInsuranceWithRider,
    key: FundingPolicyType[
      FundingPolicyType.lifeInsuranceWithRider
    ] as FundingPolicyTypeDef['key'],
    label: 'Life with Benefits Rider',
    isJoint: false,
    description:
      'Single death benefit pool that can be used for long-term care',
    calculationType: FundingPolicyType.lifeInsuranceWithRider,
    listPriority: 3,
  },
  {
    index: 2,
    value: FundingPolicyType.hybridLifeInsurance,
    key: FundingPolicyType[
      FundingPolicyType.hybridLifeInsurance
    ] as FundingPolicyTypeDef['key'],
    label: 'Hybrid Policy',
    isJoint: false,
    description: 'Death benefit and continuation of benefit amounts',
    calculationType: FundingPolicyType.hybridLifeInsurance,
    listPriority: 4,
  },
  {
    index: 3,
    value: FundingPolicyType.shortTermCareInsurance,
    key: FundingPolicyType[
      FundingPolicyType.shortTermCareInsurance
    ] as FundingPolicyTypeDef['key'],
    label: 'Short Term Care Policy',
    isJoint: false,
    description: 'Maxmimum benefit with limited benefit period',
    calculationType: FundingPolicyType.shortTermCareInsurance,
    listPriority: 0,
  },
  {
    index: 4,
    value: FundingPolicyType.annuityHybrid,
    key: FundingPolicyType[
      FundingPolicyType.annuityHybrid
    ] as FundingPolicyTypeDef['key'],
    label: 'Annuity Hybrid Policy',
    isJoint: false,
    description: 'Annuity value plus long-term care benefits',
    calculationType: FundingPolicyType.annuityHybrid,
    listPriority: 3,
  },
  {
    index: 5,
    value: FundingPolicyType.jointHybridAssetBased,
    key: FundingPolicyType[
      FundingPolicyType.jointHybridAssetBased
    ] as FundingPolicyTypeDef['key'],
    label: 'Joint Hybrid Policy',
    isJoint: true,
    description: 'Hybrid Policy with spousal benefits',
    calculationType: FundingPolicyType.hybridLifeInsurance,
    listPriority: 4,
  },
  {
    index: 6,
    value: FundingPolicyType.jointHybridAnnuityBased,
    key: FundingPolicyType[
      FundingPolicyType.jointHybridAnnuityBased
    ] as FundingPolicyTypeDef['key'],
    label: 'Joint Annuity Hybrid Policy',
    isJoint: true,
    description: 'Annuity Hybrid with spousal benefits',
    calculationType: FundingPolicyType.annuityHybrid,
    listPriority: 3,
  },
  {
    index: 7,
    value: FundingPolicyType.lifeWithIndexAccount,
    key: FundingPolicyType[
      FundingPolicyType.lifeWithIndexAccount
    ] as FundingPolicyTypeDef['key'],
    label: 'Life with Index Account',
    isJoint: false,
    description: 'Life insurance index account options',
    calculationType: FundingPolicyType.lifeInsuranceWithRider,
    listPriority: 10,
  },
  {
    index: 9,
    value: FundingPolicyType.allstateHybridPolicy,
    key: FundingPolicyType[
      FundingPolicyType.allstateHybridPolicy
    ] as FundingPolicyTypeDef['key'],
    label: 'Allstate Insurance',
    isJoint: false,
    description: 'Life insurance with LTC benefits',
    calculationType: FundingPolicyType.hybridLifeInsurance,
    listPriority: 10,
  },
];

export const fundingPolicyTypeDefs = Object.assign(
  toRecord(fundingPolicyTypeDefList, policyType => policyType.value),
  toRecord(fundingPolicyTypeDefList, policyType => policyType.key),
  toRecord(fundingPolicyTypeDefList, policyType => policyType.label),
);

export function checkPolicyCalculationTypeEquals(
  policyType: FundingPolicyType | null,
  equalsPolicyType: FundingPolicyType | FundingPolicyType[],
): boolean {
  if (!policyType) {
    return false;
  }

  const policyCalculationType =
    fundingPolicyTypeDefs[policyType].calculationType;

  if (Array.isArray(equalsPolicyType)) {
    return equalsPolicyType.includes(policyCalculationType);
  }

  return policyCalculationType === equalsPolicyType;
}
