import { Draft } from 'immer';
import {
  isNullOrUndefined,
  setCoverageToUndefined,
  calcPolicyCoverageImpl,
  initializeRemainingFields,
} from '.';

function getPolicyActivationYear(mutableClient: Draft<Client>): number {
  const {
    inferenceSet: { ltcAtYear },
  } = mutableClient;

  // for on claim, this is the actual age they started receiving care for one ADL
  return ltcAtYear;
}

export function calcPolicyCoverageOfRemainingLtcCosts(
  mutableClient: Draft<Client>,
) {
  const {
    fundingSources: {
      ltcPolicy: { policyBenefitUtilizedToDate, policyPremiumStartYear },
      ltcPolicy,
    },
  } = mutableClient;
  const coverageType = 'policyCoverageOfRemainingLtcCosts';
  if (
    isNullOrUndefined(policyBenefitUtilizedToDate) ||
    isNullOrUndefined(policyPremiumStartYear)
  ) {
    setCoverageToUndefined(mutableClient, coverageType);
    return;
  }

  initializeRemainingFields(mutableClient);

  ltcPolicy.policyActivationYear = getPolicyActivationYear(mutableClient);

  calcPolicyCoverageImpl(mutableClient, coverageType);
}
