import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Button, InputAdornment, TextFieldProps, SvgIcon } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import {
  BusinessOutlined,
  HttpsOutlined,
  MailOutline,
  PersonOutline,
} from '@mui/icons-material';
import { GeneralHeader } from '../components/GeneralHeader';

import mainStyles from '../styles/main.module.css';
import moduleStyles from './AuthPasswordChangeForm.module.css';
import { CustomizedTextInput, isNullUndefinedOrEmpty } from '@shared';
import { Link } from 'react-router-dom';
import { identity, isBoolean, isEmpty, isString } from 'lodash';
import { isNullOrUndefined } from '@shared';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};

export interface AuthPasswordChangeFormPrompts {
  promptForVerificationCode: boolean;
  promptForCurrentPassword: boolean;
  promptForFirstAndLastName: boolean;
}

interface PasswordChangeFormTextFieldProps
  extends Omit<TextFieldProps<'outlined'>, 'variant'> {
  icon: typeof SvgIcon;
}

export interface AuthPasswordChangeFormProps
  extends AuthPasswordChangeFormPrompts {
  advisorEmail?: string; // passing as prop since it could have different sources
  errorMessage?: string;
  onSubmitChangePassword(
    authPasswordChangeFormInputs: AuthPasswordChangeFormInputs,
  ): void;
}

export interface AuthPasswordChangeFormInputs {
  advisorFirstName: string;
  advisorLastName: string;
  organizationName: string;
  organizationDisplayName: string;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface AuthPasswordChangeFormErrors extends ObjectMap<boolean | undefined> {
  advisorFirstName?: boolean;
  advisorLastName?: boolean;
  organizationName?: boolean;
  organizationDisplayName?: boolean;
  oldPassword?: boolean;
  newPassword?: boolean;
  confirmNewPassword?: boolean;
}

interface AuthPasswordChangePolicyErrors
  extends ObjectMap<boolean | undefined> {
  acceptedPrivacyPolicy?: boolean;
  acceptedNoRealData?: boolean;
}

export function AuthPasswordChangeForm({
  advisorEmail,
  onSubmitChangePassword,
  promptForVerificationCode,
  promptForCurrentPassword,
  promptForFirstAndLastName,
  errorMessage,
}: AuthPasswordChangeFormProps) {
  const [advisorFirstName, setAdvisorFirstName] = useState('');
  const [advisorLastName, setAdvisorLastName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [organizationDisplayName, setOrganizationDisplayName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [acceptedNoRealData, setAcceptedNoRealData] = useState(false);
  const [formErrors, setFormErrors] = useState(
    {} as AuthPasswordChangeFormErrors,
  );
  const [policyErrors, setPolicyErrors] = useState(
    {} as AuthPasswordChangePolicyErrors,
  );

  const handleClickSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const newFormErrors = {} as AuthPasswordChangeFormErrors;
      const newPolicyErrors = {} as AuthPasswordChangePolicyErrors;

      if (promptForFirstAndLastName) {
        checkForErors({
          advisorFirstName,
          advisorLastName,
          organizationName,
        });
        checkForErors(
          {
            acceptedPrivacyPolicy,
            acceptedNoRealData,
          },
          newPolicyErrors,
        );
      }
      if (promptForCurrentPassword) {
        checkForErors({ oldPassword });
      }

      checkForErors({
        newPassword,
        confirmNewPassword,
      });

      setFormErrors(newFormErrors);
      setPolicyErrors(newPolicyErrors);

      if (!isEmpty(newFormErrors) || !isEmpty(newPolicyErrors)) {
        return;
      }

      onSubmitChangePassword({
        advisorLastName,
        advisorFirstName,
        organizationName,
        organizationDisplayName,
        oldPassword,
        newPassword,
        confirmNewPassword,
      });

      function checkForErors(
        values: ObjectMap<string | boolean>,
        newErrors: ObjectMap<boolean | undefined> = newFormErrors,
      ) {
        const theseErrors = Object.entries(values).filter(([_key, value]) =>
          isString(value)
            ? value === ''
            : isBoolean(value)
              ? value === false
              : isNullOrUndefined(value),
        );

        if (theseErrors.length === 0) {
          return;
        }
        theseErrors.forEach(([key, value]) => (newErrors[key] = true));
      }
    },
    [
      advisorFirstName,
      advisorLastName,
      organizationName,
      organizationDisplayName,
      oldPassword,
      newPassword,
      confirmNewPassword,
      acceptedPrivacyPolicy,
      acceptedNoRealData,
      onSubmitChangePassword,
      setFormErrors,
      setPolicyErrors,
    ],
  );

  const handleChangeAdvisorFirstName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAdvisorFirstName(event.target.value);
      setFormErrors({
        ...formErrors,
        advisorFirstName: isNullUndefinedOrEmpty(event.target.value),
      });
    },
    [formErrors, setAdvisorFirstName, setFormErrors],
  );

  const handleChangeAdvisorLastName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAdvisorLastName(event.target.value);
      setFormErrors({
        ...formErrors,
        advisorLastName: isNullUndefinedOrEmpty(event.target.value),
      });
    },
    [formErrors, setAdvisorLastName, setFormErrors],
  );

  const handleChangeOrganizationName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOrganizationName(event.target.value);
      setFormErrors({
        ...formErrors,
        organizationName: isNullUndefinedOrEmpty(event.target.value),
      });
    },
    [formErrors, setOrganizationName, setFormErrors],
  );

  const handleChangeOrganizationDisplayName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOrganizationDisplayName(event.target.value);
    },
    [setOrganizationDisplayName],
  );

  const handleChangeOldPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOldPassword(event.target.value);
      setFormErrors({
        ...formErrors,
        oldPassword: isNullUndefinedOrEmpty(event.target.value),
      });
    },
    [formErrors, setFormErrors, setOldPassword],
  );

  const handleChangeNewPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewPassword(event.target.value);
      setFormErrors({
        ...formErrors,
        newPassword: isNullUndefinedOrEmpty(event.target.value),
      });
    },
    [formErrors, setFormErrors, setNewPassword],
  );

  const handleChangeConfirmNewPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setConfirmNewPassword(event.target.value);
      setFormErrors({
        ...formErrors,
        confirmNewPassword: isNullUndefinedOrEmpty(event.target.value),
      });
    },
    [formErrors, setConfirmNewPassword, setFormErrors],
  );

  const handleChangeAcceptedPrivacyPolicy = useCallback(
    (event: any) => {
      const { checked } = event.target as HTMLInputElement;
      setAcceptedPrivacyPolicy(checked);
      setPolicyErrors({
        ...policyErrors,
        acceptedPrivacyPolicy: !checked,
      });
    },
    [policyErrors, setAcceptedPrivacyPolicy, setPolicyErrors],
  );

  const handleCangeAcceptedNoRealData = useCallback(
    (event: any) => {
      const { checked } = event.target as HTMLInputElement;
      setAcceptedNoRealData(checked);
      setPolicyErrors({
        ...policyErrors,
        acceptedNoRealData: !checked,
      });
    },
    [policyErrors, setAcceptedNoRealData, setPolicyErrors],
  );

  function InputLabel({ label }: { label: string }) {
    return <p className="ml-4 pb-1 text-sm text-gray-800">{label}</p>;
  }

  return (
    <div>
      <GeneralHeader />
      <div className={styles.main}>
        <div className="text-3xl text-darkPurple md:text-4xl">
          Change Password
        </div>
        <form
          className="mx-6 mb-2 mt-8 w-full max-w-lg"
          onSubmit={handleClickSubmit}
        >
          {Object.values(formErrors).some(identity) ? (
            <div className={styles.errorText}>All fields are required.</div>
          ) : null}
          {errorMessage ? (
            <div className={styles.errorText}>{errorMessage}</div>
          ) : null}
          {promptForFirstAndLastName ? (
            <>
              <InputLabel label="Email" />
              <PasswordChangeFormTextField
                id="email"
                disabled
                value={advisorEmail}
                icon={MailOutline}
              />
              <InputLabel label="First name" />
              <PasswordChangeFormTextField
                id="firstname"
                placeholder="First name"
                autoComplete="given-name"
                autoFocus
                value={advisorFirstName}
                error={formErrors.advisorFirstName}
                onChange={handleChangeAdvisorFirstName}
                icon={PersonOutline}
              />
              <InputLabel label="Last name" />
              <PasswordChangeFormTextField
                id="lastname"
                placeholder="Last name"
                autoComplete="family-name"
                value={advisorLastName}
                error={formErrors.advisorLastName}
                onChange={handleChangeAdvisorLastName}
                icon={PersonOutline}
              />
              <InputLabel label="Organization legal name (for consent)" />

              <PasswordChangeFormTextField
                id="organization"
                placeholder="Organization legal name"
                autoComplete="organization"
                value={organizationName}
                error={formErrors.organizationName}
                onChange={handleChangeOrganizationName}
                icon={BusinessOutlined}
              />
              <InputLabel label="Organization display name (for whitelabel)" />

              <PasswordChangeFormTextField
                id="organization-display"
                placeholder="Organization display name"
                autoComplete="organization"
                value={organizationDisplayName}
                error={formErrors.organizationDisplayName}
                onChange={handleChangeOrganizationDisplayName}
                icon={PreviewIcon}
              />
            </>
          ) : null}
          {promptForCurrentPassword ? (
            <>
              <InputLabel label="Current password" />
              <PasswordChangeFormTextField
                id="oldpassword"
                type="password"
                autoFocus
                autoComplete="current-password"
                placeholder="Current Password"
                value={oldPassword}
                error={formErrors.oldPassword}
                onChange={handleChangeOldPassword}
                icon={HttpsOutlined}
              />
            </>
          ) : null}
          <InputLabel label="New password" />
          <PasswordChangeFormTextField
            id="password"
            type="password"
            autoComplete="new-password"
            placeholder="New password"
            value={newPassword}
            error={formErrors.newPassword}
            onChange={handleChangeNewPassword}
            icon={HttpsOutlined}
          />
          <InputLabel label="Confirm new password" />
          <PasswordChangeFormTextField
            id="confirmpassword"
            type="password"
            autoComplete="new-password"
            placeholder="Confirm new password"
            value={confirmNewPassword}
            error={formErrors.confirmNewPassword}
            onChange={handleChangeConfirmNewPassword}
            icon={HttpsOutlined}
          />
          <button
            className="mt-4 w-full rounded-full bg-darkPurple py-2 text-lg text-white hover:bg-mediumPurple"
            type="submit"
          >
            Submit
          </button>
          <p></p>
          {promptForFirstAndLastName ? (
            <div className="pb-16 pt-6">
              {Object.values(policyErrors).some(identity) ? (
                <div className={styles.errorText + ' ' + styles.acceptError}>
                  Click the checkboxes to accept our privacy policy and beta
                  data terms.
                </div>
              ) : null}
              <p className="flex items-center gap-1">
                <input
                  type="checkbox"
                  id="termsAndPrivacy"
                  className="h-4 w-4"
                  onChange={handleChangeAcceptedPrivacyPolicy}
                  onClick={handleChangeAcceptedPrivacyPolicy}
                  defaultChecked={false}
                />
                <label
                  className={styles.checkBoxDescriptors}
                  htmlFor="termsAndPrivacy"
                >
                  {' '}
                  by clicking here, you agree to our{' '}
                </label>
                <Link
                  to="https://www.joinwaterlily.com/terms-of-service"
                  target="_blank"
                  className={styles.checkBoxDescriptors}
                >
                  Terms of Service
                </Link>
                <label
                  className={styles.checkBoxDescriptors}
                  htmlFor="termsAndPrivacy"
                >
                  {' '}
                  and{' '}
                </label>
                <Link
                  to="https://www.joinwaterlily.com/privacy-policy"
                  target="_blank"
                  className={styles.checkBoxDescriptors}
                >
                  Privacy Policy.
                </Link>
              </p>
              <p className="flex items-center gap-1">
                <input
                  type="checkbox"
                  id="recognizeBeta"
                  name="recognizeBeta"
                  defaultChecked={false}
                  className="h-4 w-4"
                  onChange={handleCangeAcceptedNoRealData}
                  onClick={handleCangeAcceptedNoRealData}
                />
                <label
                  className={styles.checkBoxDescriptors}
                  htmlFor="recognizeBeta"
                >
                  {' '}
                  by clicking here you agree to our{' '}
                </label>
                <Link
                  to="https://www.joinwaterlily.com/pilot-agreement"
                  target="_blank"
                  className={styles.checkBoxDescriptors}
                >
                  Pilot Agreement.
                </Link>
              </p>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}

function PasswordChangeFormTextField(props: PasswordChangeFormTextFieldProps) {
  const { icon: Icon, ...passthroughProps } = props;
  return (
    <CustomizedTextInput
      type="text"
      variant="outlined"
      sx={{ border: '0px' }}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        ),
        classes: {
          error: styles.textboxError,
          root: styles.textbox,
        },
      }}
      {...passthroughProps}
    />
  );
}
