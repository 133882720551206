import { useSelector } from 'react-redux';
import {
  EarlyCareSlideOutContent,
  FullCareSlideOutContent,
  LearnMoreSlideOut,
  ModerateCareSlideOutContent,
  OnboardingChrome,
  PageLink,
} from '../components';
import { OnboardingCostFactors } from '.';
import { selectClient } from '../model';
import {
  CarePhaseDef,
  carePhaseDefList,
  CarePhase,
  isNullOrUndefined,
  carePhaseDefs,
  formatCurrency,
  careEnvironmentDefs,
  CareEnvironment,
  formatDurationYears,
  formatInteger,
  isDurationCustom,
} from '@shared';
import { SlideContent } from '../components/SlideContent';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AiFillCheckCircle } from 'react-icons/ai';
import { GoDot } from 'react-icons/go';
import {
  getCareSettingsPageBy,
  getFamilyInvolvementPageBy,
} from '../util/navigationLogic';

import { Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { ClockIcon } from '@heroicons/react/24/outline';

function findEntryByValue(value: CarePhase): CarePhaseDef | undefined {
  return carePhaseDefList.find(entry => entry.value === value);
}

function determineTimelineText(
  client: Client,
  carePhase: CarePhase,
  phaseStartAge: number | null,
) {
  const {
    intakeSurvey: { clientCurrentCarePhase },
  } = client;

  const phaseIndex = carePhaseDefs[carePhase].index;
  const phaseStartAgeText = phaseStartAge ? `${Math.round(phaseStartAge)}` : '';
  // present if current phase is equal to the phase
  if (clientCurrentCarePhase === carePhase) {
    return `Since age ${phaseStartAgeText}`;
  }

  // future if current phase is null or undefined
  if (isNullOrUndefined(clientCurrentCarePhase)) {
    return `Expected age ${phaseStartAgeText} `;
  }

  // past if current phase index is greater than the phase index
  const clientCurrentCarePhaseDef = findEntryByValue(clientCurrentCarePhase);
  if (clientCurrentCarePhaseDef!.index > phaseIndex) {
    return `Past`;
  }

  // future if current phase index is less than the phase index
  return `Expected age ${phaseStartAgeText}`;
}

function determineTimelineTooltip(client: Client, carePhase: CarePhase) {
  const {
    intakeSurvey: {
      clientPhaseStartAges,
      clientCurrentCarePhase,
      clientBirthYear,
    },
    clientPhasePredictedStartYears,
  } = client;

  const phaseIndex = carePhaseDefs[carePhase].index;
  // present if current phase is equal to the phase
  if (clientCurrentCarePhase === carePhase) {
    return `Since ${Math.round(clientPhaseStartAges[carePhase]! + clientBirthYear)}`;
  }

  // future if current phase is null or undefined
  if (isNullOrUndefined(clientCurrentCarePhase)) {
    return `Expected ${Math.round(clientPhasePredictedStartYears[carePhase]!)}`;
  }

  // past if current phase index is greater than the phase index
  const clientCurrentCarePhaseDef = findEntryByValue(clientCurrentCarePhase);
  if (clientCurrentCarePhaseDef!.index > phaseIndex) {
    return `Past`;
  }

  // future if current phase index is less than the phase index
  return `Expected ${Math.round(clientPhasePredictedStartYears[carePhase]!)}`;
}

function determinePreviousSlide(
  client: Client,
  carePhase: CarePhase,
  careEnvironmentSelections: CareEnvironmentSelections,
) {
  const { clientCurrentCarePhase } = client.intakeSurvey;
  if (
    carePhase === CarePhase.earlyCare ||
    carePhase === clientCurrentCarePhase
  ) {
    return OnboardingCostFactors;
  }

  if (
    careEnvironmentSelections[(carePhase - 1) as CarePhase] ===
    CareEnvironment.home
  ) {
    return getFamilyInvolvementPageBy(carePhase - 1);
  }

  return getCareSettingsPageBy(carePhase - 1);
}

interface CarePhaseProps {
  carePhase: CarePhase;
  slidePage: FunctionComponent<ClientIdProps>;
}

function OnboardingCarePhases({ carePhase, slidePage }: CarePhaseProps) {
  const client = useSelector(selectClient)!;
  const {
    clientId,
    clientFirstName,
    intakeSurvey: { clientHasStartedLtc, clientCurrentCarePhase },
    allPhaseCosts: { allPhaseCareMonthsNeeded },
    phaseCosts,
  } = client;

  const ltcDurationYears = allPhaseCareMonthsNeeded / 12;

  const bulletIconProps = {
    fontSize: '1em',
    marginBottom: '2px',
    color: '#0F0D30',
  };
  const carePhaseData = [
    {
      value: CarePhase.earlyCare,
      carePhase: carePhaseDefList[0].label,
      adlRange: '1-2',
      careSettingOptions: 'Home and Independent Living',
      carePhaseLabelIcon: Filter1Icon,
      adlRangeIcon: <SignalCellular1BarIcon sx={bulletIconProps} />,
      slideOutContent: <EarlyCareSlideOutContent />,
      ...getCarePhaseData(client, CarePhase.earlyCare),
    },
    {
      value: CarePhase.moderateCare,
      carePhase: carePhaseDefList[1].label,
      adlRange: '3-4',
      careSettingOptions: 'Home and Assisted Living',
      carePhaseLabelIcon: Filter2Icon,
      adlRangeIcon: <SignalCellular3BarIcon sx={bulletIconProps} />,
      slideOutContent: <ModerateCareSlideOutContent />,

      ...getCarePhaseData(client, CarePhase.moderateCare),
    },
    {
      value: CarePhase.fullCare,
      carePhase: carePhaseDefList[2].label,
      adlRange: '5-6',
      careSettingOptions: 'Home and Nursing Home',
      carePhaseLabelIcon: Filter3Icon,
      adlRangeIcon: <SignalCellular4BarIcon sx={bulletIconProps} />,
      slideOutContent: <FullCareSlideOutContent />,
      ...getCarePhaseData(client, CarePhase.fullCare),
    },
  ];
  return (
    <OnboardingChrome
      slidePage={slidePage}
      title={''}
      nextButtonLabel={`Review ${carePhaseDefs[carePhase].label}`}
      previousSlidePage={determinePreviousSlide(
        client,
        carePhase,
        client.careEnvironmentSelections,
      )}
    >
      <SlideContent
        headerText={`${clientFirstName}'s care timeline`}
        headerSubText={
          <div className="flex items-center gap-2">
            <ClockIcon className="h-5 w-5 md:h-6 md:w-6" />
            <div>
              {clientHasStartedLtc
                ? `${isDurationCustom(phaseCosts) ? 'Custom ' : 'Estimated '} remaining duration: ${formatDurationYears(ltcDurationYears)} years (${formatInteger(ltcDurationYears * 12)} months)`
                : `${isDurationCustom(phaseCosts) ? 'Custom ' : 'Estimated '}  total care duration: ${formatDurationYears(ltcDurationYears)} years (${formatInteger(ltcDurationYears * 12)} months)`}
            </div>
          </div>
        }
      >
        <div className="bg-white">
          <div className="mx-auto max-w-5xl px-4 pb-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-5 overflow-hidden md:mx-0 md:max-w-none lg:grid-cols-3">
              {carePhaseData.map(phase => (
                <div key={phase.carePhase}>
                  {/*  Timeline */}
                  <time className="flex items-center gap-1 text-lg font-semibold leading-6 text-darkPurple">
                    {phase.selectedCareEnvironment ? (
                      <AiFillCheckCircle className="h-[0.9em] text-green-600" />
                    ) : (
                      <GoDot className="text-xl text-gray-500" />
                    )}
                    <SimpleTooltip
                      title={determineTimelineTooltip(client, phase.value)}
                      placement="bottom"
                      children={
                        <p>
                          {determineTimelineText(
                            client,
                            phase.value,
                            phase.phaseStartAge,
                          )}
                        </p>
                      }
                    />
                    <div
                      className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                      aria-hidden="true"
                    />
                  </time>

                  {/*  Phase Cards */}
                  <div
                    key={phase.carePhase}
                    className={`relative mt-6 rounded-lg border border-darkPurple bg-white px-7 pb-3 pt-7 text-darkPurple shadow-sm lg:pb-7 lg:pt-10 ${carePhase === phase.value ? '' : 'opacity-20'}`}
                  >
                    <dl className="flex flex-col gap-1">
                      <dt className="mb-2 -translate-x-1 text-sm">
                        <span className="rounded-2xl border border-gray-300 bg-gray-100 px-2 py-1 font-semibold">
                          Stage {carePhaseDefs[phase.value].index + 1} of 3
                        </span>
                      </dt>
                      <dt className="flex items-center text-xl font-semibold md:text-2xl">
                        {phase.carePhase}
                        <LearnMoreSlideOut
                          slideOutContent={phase.slideOutContent}
                          isModelDescription={false}
                        >
                          <BsFillQuestionCircleFill
                            className="h-[18px] w-[18px] pb-1 pl-1  text-gray-400 hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </LearnMoreSlideOut>
                      </dt>
                      <dd className="mt-1 flex text-base leading-7 text-gray-700">
                        <ul>
                          <li className="mt-1 flex gap-3">
                            <SimpleTooltip
                              title="Self-care activities include bathing, dressing, eating, transferrring (e.g.,from bed to chair), toileting, and continence."
                              disableHoverListener={
                                !(phase.value === carePhase)
                              }
                              children={
                                <div className="flex items-center justify-center gap-3">
                                  {phase.adlRangeIcon}
                                  <div>
                                    {phase.adlRange} self-care activities
                                  </div>
                                </div>
                              }
                            />
                          </li>

                          <li className="border-gray-300/2 mt-2 flex gap-3 border-t">
                            <SimpleTooltip
                              title={
                                phase.isDurationCustom
                                  ? 'Custom Duration'
                                  : `Estimated ${clientHasStartedLtc && phase.value === clientCurrentCarePhase ? 'remaining ' : ''}duration`
                              }
                              disableHoverListener={
                                !(phase.value === carePhase)
                              }
                              children={
                                <div className="flex items-center justify-start gap-3">
                                  <AccessTimeIcon sx={bulletIconProps} />
                                  <div>
                                    {phase.phaseCareMonthsNeeded} months
                                  </div>
                                </div>
                              }
                            />
                          </li>
                          <li className="border-gray-300/2 mt-2 flex gap-3 border-t">
                            <SimpleTooltip
                              title="Selected care setting"
                              disableHoverListener={
                                !(phase.value === carePhase)
                              }
                              children={
                                <div className="flex items-center justify-start gap-3">
                                  <LocationOnIcon sx={bulletIconProps} />
                                  {phase.selectedCareEnvironment
                                    ? `${
                                        careEnvironmentDefs[
                                          phase.selectedCareEnvironment
                                        ].label
                                      }`
                                    : '---'}
                                </div>
                              }
                            />
                          </li>
                          <li className="border-gray-300/2 mt-2 flex gap-3 border-t">
                            <SimpleTooltip
                              title="Estimated cost"
                              disableHoverListener={
                                !(phase.value === carePhase)
                              }
                              children={
                                <div className="flex items-center justify-start gap-3">
                                  <AttachMoneyIcon sx={bulletIconProps} />
                                  {phase.selectedCareEnvironment
                                    ? `${formatCurrency(
                                        phase.phaseInflatedProfessionalShareCost,
                                      ).slice(1)}`
                                    : '---'}
                                </div>
                              }
                            />
                          </li>
                        </ul>
                      </dd>
                    </dl>
                    <div className="mb-2 mt-5 flex flex-row-reverse">
                      {(phase.value === carePhase ||
                        !isNullOrUndefined(phase.selectedCareEnvironment)) &&
                      getCareSettingsPageBy(phase.value) ? (
                        <PageLink
                          to={getCareSettingsPageBy(phase.value)!}
                          targetProps={{ clientId }}
                        >
                          <button className="border-gray-300/2ransparent rounded-3xl border border-darkPurple bg-darkPurple px-4 py-2 text-sm font-semibold text-white hover:border-t hover:bg-purple hover:text-white md:text-base">
                            Review {phase.carePhase}
                          </button>
                        </PageLink>
                      ) : (
                        <button className="border-gray-300/2 transparent cursor-not-allowed rounded-3xl border border-darkPurple bg-darkPurple px-4 py-2 text-sm font-semibold text-white md:text-base">
                          Review {phase.carePhase}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </SlideContent>
    </OnboardingChrome>
  );
}

interface SimpleTooltipProps {
  title: string;
  children: React.ReactElement;
  placement?: 'right-end' | 'top' | 'bottom';
  disableHoverListener?: boolean;
}

function SimpleTooltip({
  title,
  children,
  placement = 'top',
  disableHoverListener = false,
}: SimpleTooltipProps) {
  return (
    <Tooltip
      title={<span style={{ fontSize: '14px' }}>{title}</span>}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      placement={placement}
      disableHoverListener={disableHoverListener}
    >
      {children}
    </Tooltip>
  );
}

function getCarePhaseData(client: Client, carePhase: CarePhase) {
  const {
    intakeSurvey: {
      clientPhaseStartAges,
      clientBirthYear,
      clientCurrentCarePhase,
    },
    phaseCosts,
    careEnvironmentSelections,
    clientPhasePredictedStartYears,
  } = client;

  const carePhaseIndex = carePhaseDefs[carePhase].index;
  const selectedCarePhaseCosts = phaseCosts[carePhaseIndex];
  const phaseCareMonthsNeeded = selectedCarePhaseCosts.phaseCareMonthsNeeded;
  const phaseInflatedProfessionalShareCost =
    selectedCarePhaseCosts.phaseInflatedProfessionalShareCost;
  const predictedPhaseStartYear = clientPhasePredictedStartYears[carePhase];
  const predictedPhaseStartAge = predictedPhaseStartYear
    ? predictedPhaseStartYear - clientBirthYear
    : clientPhaseStartAges[carePhase]!;

  const selectedCareEnvironment = careEnvironmentSelections[carePhase];
  const phaseStartAge =
    clientCurrentCarePhase === carePhase
      ? clientPhaseStartAges[carePhase]
      : predictedPhaseStartAge;

  const isDurationCustom = selectedCarePhaseCosts.isDurationCustom;

  return {
    phaseStartAge,
    phaseCareMonthsNeeded,
    selectedCareEnvironment,
    phaseInflatedProfessionalShareCost,
    isDurationCustom,
  };
}

export function OnboardingCarePhasesPhaseOne({ clientId }: ClientIdProps) {
  return (
    <OnboardingCarePhases
      carePhase={carePhaseDefs.earlyCare.value}
      slidePage={OnboardingCarePhasesPhaseOne}
    />
  );
}

export function OnboardingCarePhasesPhaseTwo({ clientId }: ClientIdProps) {
  return (
    <OnboardingCarePhases
      carePhase={carePhaseDefs.moderateCare.value}
      slidePage={OnboardingCarePhasesPhaseTwo}
    />
  );
}

export function OnboardingCarePhasesPhaseThree({ clientId }: ClientIdProps) {
  return (
    <OnboardingCarePhases
      carePhase={carePhaseDefs.fullCare.value}
      slidePage={OnboardingCarePhasesPhaseThree}
    />
  );
}
