import { Draft } from 'immer';
import { CarePhase } from '@shared';

export function calcFundingAnnuityDerivatives(mutableClient: Draft<Client>) {
  const { fundingSources } = mutableClient;
  const { annuity } = fundingSources;

  // always set annuityExpectedPaymentEndAge equal to client's predicted ltc end year
  const clientPredictedLtcEndAge = Math.ceil(
    calcPredictedLtcEndAge(mutableClient),
  );
  annuity.annuityExpectedPaymentEndAge = clientPredictedLtcEndAge;

  // missing required fields, return
  if (
    !annuity.hasAnnuity ||
    !annuity.annuityExpectedPaymentStartAge ||
    !annuity.annuityPurchasePrice
  ) {
    return;
  }

  const {
    annuityAnnualPayOut,
    annuityExpectedPaymentStartAge,
    annuityAnnualPayoutForLtc,
    annuityAnnualPayoutForLtcPeriodYears,
    annuityExpectedPaymentEndAge,
  } = annuity;

  // annuityTotalCost is the annuityPurchasePrice
  annuity.annuityTotalCost = annuity.annuityPurchasePrice;
  // simple calculation with only annuityAnnualPayout and no annuityAnnualPayoutForLtc
  if (annuityAnnualPayOut && !annuityAnnualPayoutForLtc) {
    calcAnnuityPayouts(
      mutableClient,
      annuityAnnualPayOut,
      annuityExpectedPaymentStartAge,
      annuityExpectedPaymentEndAge,
    );
  } else if (
    annuityAnnualPayoutForLtc &&
    annuityAnnualPayoutForLtc > 0 &&
    annuityAnnualPayoutForLtcPeriodYears
  ) {
    // calculate annuity payouts for LTC benefit
    calcAnnuityPayoutsForLtcBenefit(
      mutableClient,
      annuityExpectedPaymentStartAge,
      annuityExpectedPaymentEndAge,
      annuityAnnualPayoutForLtc,
      annuityAnnualPayoutForLtcPeriodYears,
    );
  }

  calcAnnuityTotalValue(mutableClient, annuityExpectedPaymentEndAge);
  calcAnnuityProjectedROI(mutableClient);
}

type AnnuityPhaseCoverage = {
  coverageForPhase: number;
  annuityPayoutMonths: number;
  coverageSurplusForPhase?: number;
  enhancedLtcPayoutMonths?: number;
};

function calcAnnuityPayouts(
  mutableClient: Draft<Client>,
  annuityAnnualPayOut: number,
  annuityExpectedPaymentStartAge: number,
  annuityExpectedPaymentEndAge: number,
) {
  const {
    fundingSources: { annuity },
  } = mutableClient;
  const { phaseCosts } = mutableClient;
  let annuityLtcPayoutMonths = 0;
  let annuityLtcPaymentSurplus = 0;
  const annuityCostCoverage = phaseCosts.reduce((totalCoverage, phaseCost) => {
    const { coverageForPhase, annuityPayoutMonths, coverageSurplusForPhase } =
      calculatePayoutsForPhase(
        mutableClient,
        phaseCost,
        annuityAnnualPayOut,
        annuityExpectedPaymentStartAge,
        annuityExpectedPaymentEndAge,
      );
    annuityLtcPayoutMonths += annuityPayoutMonths;
    annuityLtcPaymentSurplus += coverageSurplusForPhase ?? 0;
    return totalCoverage + coverageForPhase;
  }, 0);
  annuity.annuityProjectedCostCoverage = annuityCostCoverage;
  annuity.annuityLtcPayoutMonths = annuityLtcPayoutMonths;
  annuity.annuityLtcPaymentSurplus = annuityLtcPaymentSurplus;
}

function calculatePayoutsForPhase(
  client: Draft<Client>,
  phaseCost: SinglePhaseCosts,
  annuityAnnualPayOut: number,
  annuityExpectedPaymentStartAge: number,
  annuityExpectedPaymentEndAge: number,
): AnnuityPhaseCoverage {
  const { carePhase } = phaseCost;
  const phaseEndAge = calcClientAgeAtPhaseEnd(client, carePhase);
  const phaseStartAge = calcClientAgeAtPhaseStart(client, carePhase);
  const annuityPayoutMonths = calculateMonthsOfPayouts(
    phaseStartAge,
    phaseEndAge,
    annuityExpectedPaymentStartAge,
    annuityExpectedPaymentEndAge,
    phaseCost.phaseCareMonthsNeeded,
  );
  if (annuityPayoutMonths === 0) {
    return {
      coverageForPhase: 0,
      annuityPayoutMonths: 0,
      coverageSurplusForPhase: 0,
    };
  }

  const annualPayoutTimesCoverageDuration =
    (annuityAnnualPayOut / 12) * annuityPayoutMonths;

  const coverageSurplusForPhase = Math.max(
    annualPayoutTimesCoverageDuration -
      phaseCost.phaseInflatedProfessionalShareCost,
    0,
  );

  return {
    coverageForPhase: Math.min(
      annualPayoutTimesCoverageDuration,
      phaseCost.phaseInflatedProfessionalShareCost,
    ),
    annuityPayoutMonths,
    coverageSurplusForPhase,
  };
}

function calculateMonthsOfPayouts(
  phaseStartAge: number,
  phaseEndAge: number | null,
  annuityExpectedPaymentStartAge: number,
  annuityExpectedPaymentEndAge: number,
  phaseMonthsNeeded: number,
): number {
  // Phase is in the past or ended before annuity payments start
  if (!phaseEndAge || phaseEndAge < annuityExpectedPaymentStartAge) {
    return 0;
  }

  // Phase starts after annuity payments end
  if (phaseStartAge > annuityExpectedPaymentEndAge) {
    return 0;
  }

  let monthsWithPayouts = 0;
  // phase ends after annuity payments end, need to decrement the months after annuity payments end
  if (phaseEndAge > annuityExpectedPaymentEndAge) {
    const monthsAfterAnnuityPaymentsEnd =
      (phaseEndAge - annuityExpectedPaymentEndAge) * 12;
    monthsWithPayouts += monthsAfterAnnuityPaymentsEnd;
  }

  // phase starts before annuity payments start, need to decrement the months before annuity payments start
  if (phaseStartAge < annuityExpectedPaymentStartAge) {
    const monthsBeforeAnnuityPaymentsStart =
      (annuityExpectedPaymentStartAge - phaseStartAge) * 12;
    monthsWithPayouts += monthsBeforeAnnuityPaymentsStart;
  }

  return Math.max(phaseMonthsNeeded - monthsWithPayouts, 0); // should never be negative but just in case
}

function calcMonthsToSubtractForOneAdl(
  carePhase: CarePhase,
  phaseMonthsNeeded: number,
  annuityExpectedPaymentStartAge: number,
  phaseStartAge: number,
): number {
  // if the phase is not early care, then there is no period of 1 ADL
  if (carePhase !== CarePhase.earlyCare) {
    return 0;
  }
  const oneAdlMonths = phaseMonthsNeeded / 2;
  // How many months between start of phase and start of annuity payments
  const monthsBeforeAnnuityPaymentsStart = Math.max(
    (annuityExpectedPaymentStartAge - phaseStartAge) * 12,
    0,
  );
  // need to make sure that enhanced LTC payouts are not paid during the 1 ADL period
  // if there are monthsBeforeAnnuityPaymentsStart for early care, then that means there are already
  // months of the 1 ADL period that are being deducted. We need to figure out how many additional months of 1 ADL
  // we need to deduct.
  if (monthsBeforeAnnuityPaymentsStart > 0) {
    return Math.max(oneAdlMonths - monthsBeforeAnnuityPaymentsStart, 0);
  }
  return oneAdlMonths;
}

function calcLtcPayoutsForPhase(
  client: Draft<Client>,
  phaseCost: SinglePhaseCosts,
  annuityAnnualPayoutForLtc: number,
  annuityExpectedPaymentStartAge: number,
  annuityExpectedPaymentEndAge: number,
  remainingLtcBenefitPeriodMonths: number,
): AnnuityPhaseCoverage {
  const { carePhase } = phaseCost;
  const {
    fundingSources: {
      annuity: { annuityAnnualPayOut },
    },
  } = client;

  const phaseEndAge = calcClientAgeAtPhaseEnd(client, carePhase);
  const phaseStartAge = calcClientAgeAtPhaseStart(client, carePhase);
  const annuityPayoutMonths = calculateMonthsOfPayouts(
    phaseStartAge,
    phaseEndAge,
    annuityExpectedPaymentStartAge,
    annuityExpectedPaymentEndAge,
    phaseCost.phaseCareMonthsNeeded,
  );
  if (annuityPayoutMonths === 0) {
    return {
      coverageForPhase: 0,
      annuityPayoutMonths: 0,
      enhancedLtcPayoutMonths: 0,
    };
  }
  const monthsToSubtractForOneAdl = calcMonthsToSubtractForOneAdl(
    carePhase,
    phaseCost.phaseCareMonthsNeeded,
    annuityExpectedPaymentStartAge,
    phaseStartAge,
  );
  const enhancedLtcPayoutMonths = Math.min(
    annuityPayoutMonths - monthsToSubtractForOneAdl,
    remainingLtcBenefitPeriodMonths,
  );

  // calculate the ltc benefit payout for the phase
  const ltcBenefitPayOut =
    (annuityAnnualPayoutForLtc / 12) * enhancedLtcPayoutMonths;

  // of the total months, were there any months that were not covered by ltc benefits, then calculate the regular annuity payout
  const regularAnnuityPayOut =
    enhancedLtcPayoutMonths < annuityPayoutMonths
      ? ((annuityAnnualPayOut ?? 0) / 12) *
        (annuityPayoutMonths - enhancedLtcPayoutMonths)
      : 0;

  const totalPayout = ltcBenefitPayOut + regularAnnuityPayOut;
  const coverageSurplusForPhase = Math.max(
    totalPayout - phaseCost.phaseInflatedProfessionalShareCost,
    0,
  );

  return {
    coverageForPhase: Math.min(
      totalPayout,
      phaseCost.phaseInflatedProfessionalShareCost,
    ),
    enhancedLtcPayoutMonths,
    annuityPayoutMonths,
    coverageSurplusForPhase,
  };
}

function calcAnnuityPayoutsForLtcBenefit(
  client: Draft<Client>,
  annuityExpectedPaymentStartAge: number,
  annuityExpectedPaymentEndAge: number,
  annuityAnnualPayoutForLtc: number,
  annuityAnnualPayoutForLtcPeriodYears: number,
): void {
  const {
    fundingSources: { annuity },
    phaseCosts,
  } = client;
  let remainingLtcBenefitPeriodMonths =
    annuityAnnualPayoutForLtcPeriodYears * 12;
  let annuityLtcPayoutMonths = 0;
  let annuityLtcPaymentSurplus = 0;
  const annuityCostCoverage = phaseCosts.reduce((totalCoverage, phaseCost) => {
    const {
      coverageForPhase,
      enhancedLtcPayoutMonths,
      annuityPayoutMonths,
      coverageSurplusForPhase,
    } = calcLtcPayoutsForPhase(
      client,
      phaseCost,
      annuityAnnualPayoutForLtc,
      annuityExpectedPaymentStartAge,
      annuityExpectedPaymentEndAge,
      remainingLtcBenefitPeriodMonths,
    );
    if (enhancedLtcPayoutMonths) {
      remainingLtcBenefitPeriodMonths -= enhancedLtcPayoutMonths;
    }
    annuityLtcPayoutMonths += annuityPayoutMonths;
    annuityLtcPaymentSurplus += coverageSurplusForPhase ?? 0;
    return totalCoverage + coverageForPhase;
  }, 0);
  annuity.annuityProjectedCostCoverage = annuityCostCoverage;
  annuity.annuityLtcPayoutMonths = annuityLtcPayoutMonths;
  annuity.annuityLtcPaymentSurplus = annuityLtcPaymentSurplus;
}

function calcClientAgeAtPhaseEnd(
  mutableClient: Draft<Client>,
  carePhase: CarePhase,
): number | null {
  // returns the client's age at the end of the care phase
  // if the care phase is full care or the phase is in the past, then return null
  const {
    clientYearsTillPhaseEnd,
    intakeSurvey: { clientAge },
  } = mutableClient;

  const yearsTillPhaseEnd = clientYearsTillPhaseEnd[carePhase];
  return yearsTillPhaseEnd ? clientAge + yearsTillPhaseEnd : null;
}

function calcClientAgeAtPhaseStart(
  mutableClient: Draft<Client>,
  carePhase: CarePhase,
): number {
  // calculate the client's age at the start of each care phase
  const {
    clientPhasePredictedStartYears,
    intakeSurvey: { clientBirthYear, clientPhaseStartAges },
  } = mutableClient;
  const predictedStartYear = clientPhasePredictedStartYears[carePhase];
  return predictedStartYear
    ? predictedStartYear - clientBirthYear
    : clientPhaseStartAges[carePhase]!;
}

function calcAnnuityProjectedROI(mutableClient: Draft<Client>) {
  const { fundingSources } = mutableClient;
  const { annuity } = fundingSources;

  if (!annuity || !annuity.annuityTotalCost || !annuity.annuityTotalValue) {
    return;
  }

  annuity.annuityProjectedROI =
    (annuity.annuityTotalValue - annuity.annuityTotalCost) /
    annuity.annuityTotalCost;
}

function calcAnnuityTotalValue(
  mutableClient: Draft<Client>,
  annuityExpectedPaymentEndAge: number,
) {
  const { fundingSources } = mutableClient;
  const { annuity } = fundingSources;

  // missing required fields, return
  if (
    !annuity ||
    !annuity.annuityAnnualPayOut ||
    !annuity.annuityExpectedPaymentStartAge
  ) {
    return;
  }
  const annuityPayoutYears =
    annuityExpectedPaymentEndAge - annuity.annuityExpectedPaymentStartAge;
  const annuityPayoutYearsOutsideOfLtcDuration =
    annuityPayoutYears - annuity.annuityLtcPayoutMonths / 12;

  annuity.annuityNonLtcPayout =
    annuity.annuityAnnualPayOut * annuityPayoutYearsOutsideOfLtcDuration +
    (annuity.annuityLtcPaymentSurplus ?? 0);
  annuity.annuityTotalValue =
    (annuity.annuityProjectedCostCoverage ?? 0) + annuity.annuityNonLtcPayout;
}

export function calcPredictedLtcEndAge(mutableClient: Draft<Client>) {
  const {
    intakeSurvey: { clientBirthYear },
    clientPhasePredictedEndYears,
  } = mutableClient;
  const fullCareEndYear = clientPhasePredictedEndYears[CarePhase.fullCare];
  return fullCareEndYear! - clientBirthYear;
}
