import { MouseEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';

import { isNullUndefinedOrEmpty, logAudit } from '@shared';

import {
  About,
  AdvisorDashboard,
  AuthPasswordChange,
  PageLink,
  closeSession,
  selectMaybeSessionCognitoUser,
  selectSessionAdvisor,
  selectSessionFullName,
  selectSessionInitials,
  useNavigateToPage,
} from '..';
import mainStyles from '../styles/main.module.css';
import moduleStyles from './AuthenticatedHeader.module.css';
import classNames from 'classnames';
import CompanyLogoContainer from './CompanyLogo';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};

export interface AuthenticatedHeaderProps {
  className?: string;
}

export function AuthenticatedHeader({ className }: AuthenticatedHeaderProps) {
  const fullName = useSelector(selectSessionFullName);
  const initials = useSelector(selectSessionInitials);
  const advisor = useSelector(selectSessionAdvisor);
  const cognitoUser = useSelector(selectMaybeSessionCognitoUser);
  const [anchorEl, setAnchorEl] = useState(null as Element | null);
  const navigate = useNavigate();
  const navigatetoPage = useNavigateToPage();
  const dispatch = useDispatch();

  const handleClickMenuOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.target as Element);
    },
    [setAnchorEl],
  );

  const handleClickPasswordChallenge = useCallback(() => {
    navigatetoPage(AuthPasswordChange);
    setAnchorEl(null);
  }, [setAnchorEl, navigatetoPage]);

  const handleClickAbout = useCallback(() => {
    navigatetoPage(About);
    setAnchorEl(null);
  }, [navigatetoPage]);

  const handleClickMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleClickSignOut = useCallback(() => {
    if (!isNullUndefinedOrEmpty(cognitoUser)) {
      logAudit(handleClickSignOut, 'Logout', {
        name: fullName,
      });
      cognitoUser.signOut();
      localStorage.removeItem('DevLocalBypassAuthPayload');
      dispatch(closeSession());
    }
    navigate('/');
  }, [navigate, isNullUndefinedOrEmpty, dispatch, navigatetoPage, cognitoUser]);

  return (
    <AppBar
      position="static"
      className={classNames(styles.appbar, className)}
    >
      <Toolbar disableGutters>
        <Box className={styles.box}>
          <PageLink
            to={AdvisorDashboard}
            targetProps={{ advisorId: '' }}
          >
            <CompanyLogoContainer
              advisor={advisor}
              containerClassName={styles.bannerContainer}
              organizationNameClassName={styles.organizationName}
              subtextClassName={styles.bannerSubText}
            />
          </PageLink>
        </Box>
        <Box>
          <Button
            size="large"
            variant="contained"
            className={styles.menuInitial}
            onClick={handleClickMenuOpen}
          >
            {initials}
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={handleClickMenuClose}
          >
            <div>
              <Button
                onClick={handleClickMenuClose}
                className="box-userMenu-button"
              >
                X
              </Button>
            </div>
            <div className={styles.menuHeader}>
              <div className={`${styles.menuInitial} box-userMenu-menuInitial`}>
                {initials}
              </div>
              <div className={styles.regularText}>{fullName}</div>
            </div>
            <Divider />
            <MenuItem onClick={handleClickMenuClose}>
              <PageLink
                to={AdvisorDashboard}
                targetProps={{ advisorId: '' }}
              >
                <div className={styles.menuItem}>Back to Advisor Dashboard</div>
              </PageLink>
            </MenuItem>
            <MenuItem onClick={handleClickPasswordChallenge}>
              <PageLink to={AuthPasswordChange}>
                <div className={styles.menuItem}>Change Password</div>
              </PageLink>
            </MenuItem>
            <MenuItem onClick={handleClickMenuClose}>
              <Link
                to="https://waterlily.typeform.com/to/B9n9jPAh"
                target="_blank"
              >
                <div className={styles.menuItem}>Contact Support</div>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClickAbout}>
              <PageLink to={About}>
                <div className={styles.menuItem}>About</div>
              </PageLink>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClickSignOut}>
              <div className={styles.menuItem}>Signout</div>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
