import { cloneDeep } from 'lodash';
import { CareEnvironment, CarePhase } from './appModel';
import { isFamily } from './isFamily';
import { calcClientDerivativesImpl, calcPrincipleAmount } from '.';
import { Draft } from 'immer';

export function calcPotentialSavings(mutableClient: Draft<Client>) {
  // logInfo(
  //   calcPotentialSavings,
  //   'Initial Danger Zone calculations:',
  //   mutableClient.dangerZoneCalculations,
  // );
  if (mutableClient.dangerZoneCalculations.isInDangerZone) {
    calcDangerZoneReductionPotentialSavings(mutableClient);
  } else {
    const clientClone = cloneDeep(mutableClient);
    calcEarlyInvestmentPotentialSavings(mutableClient, clientClone);
  }
}

/**
   *  We will try to reduce costs by shifting their care preferences as minimally as possible, taking additional steps until we exit the “danger zone”.
   *  4. Attempts to reduce LTC costs in order (compare the principle investment needed to meet the costs to the danger zone at each step, and stop once we are out of the danger zone)
      1. Set Early Care phase to home
      2. Reduce professional care by 50% of what is currently predicted for Early Care phase
      3. Set Moderate Care phase to home
      4. Reduce professional care by 50% of what is currently predicted for Moderate Care phase
      5. Reduce Early Care phase professional care hours by 50% again (25% of original)
      6. Reduce Moderate Care phase professional care hours by 50% again (25% of original)
      7. Set Full Care phase to home
      8. Reduce professional care by 50% of what is currently predicted for Full Care phase
      9. Reduce Full Care phase professional care hours by 50% again (25% of original)
      */
function calcDangerZoneReductionPotentialSavings(mutableClient: Draft<Client>) {
  const clientClone = cloneDeep(mutableClient);
  const dangerZoneReductionSteps = [
    {
      step: 1,
      carePhase: CarePhase.earlyCare,
      careEnvironment: CareEnvironment.home,
    },
    {
      step: 2,
      carePhase: CarePhase.earlyCare,
      careEnvironment: CareEnvironment.home,
      reductionPercent: 0.5,
    },
    {
      step: 3,
      carePhase: CarePhase.moderateCare,
      careEnvironment: CareEnvironment.home,
    },
    {
      step: 4,
      carePhase: CarePhase.moderateCare,
      careEnvironment: CareEnvironment.home,
      reductionPercent: 0.5,
    },
    {
      step: 5,
      carePhase: CarePhase.earlyCare,
      reductionPercent: 0.5,
    },
    {
      step: 6,
      carePhase: CarePhase.moderateCare,
      reductionPercent: 0.5,
    },
    {
      step: 7,
      carePhase: CarePhase.fullCare,
      careEnvironment: CareEnvironment.home,
    },
    {
      step: 8,
      carePhase: CarePhase.fullCare,
      careEnvironment: CareEnvironment.home,
      reductionPercent: 0.5,
    },
    {
      step: 9,
      carePhase: CarePhase.fullCare,
      reductionPercent: 0.5,
    },
  ];

  // logInfo(
  //   calcDangerZoneReductionPotentialSavings,
  //   `Initial client total inflated costs`,
  //   {
  //     allPhaseInflatedProfessionalShareCost:
  //       mutableClient.allPhaseCosts.allPhaseInflatedProfessionalShareCost,
  //   },
  // );

  for (const step of dangerZoneReductionSteps) {
    const { carePhase, careEnvironment, reductionPercent } = step;
    const phaseCosts = clientClone.phaseCosts.find(
      phaseCost => phaseCost.carePhase === carePhase,
    );
    const { supportProviders } = clientClone.supportProviderSet;
    const familySupportProviders = supportProviders.filter(isFamily);

    if (careEnvironment) {
      clientClone.careEnvironmentSelections[carePhase] = careEnvironment;
    }
    if (reductionPercent && phaseCosts) {
      // reduce professional care hours by reductionPercent
      const professionalCareReductionHours =
        phaseCosts.phaseProfessionalCareHoursProvided * reductionPercent;
      // evenly distribute the reduction among family support providers for the mapped phase
      const professionalCareReductionHoursPerFamilySupportProvider =
        professionalCareReductionHours / familySupportProviders.length;
      familySupportProviders.forEach(familySupportProvider => {
        applyCarePhaseHours(
          familySupportProvider,
          carePhase,
          professionalCareReductionHoursPerFamilySupportProvider,
        );
      });
    }
    calcClientDerivativesImpl(clientClone);
    // logInfo(
    //   calcDangerZoneReductionPotentialSavings,
    //   `Potential client reduced total inflated costs after step ${step.step}`,
    //   {
    //     allPhaseInflatedProfessionalShareCost:
    //       clientClone.allPhaseCosts.allPhaseInflatedProfessionalShareCost,
    //   },
    // );

    // check if we are out of the danger zone
    if (!clientClone.dangerZoneCalculations.isInDangerZone) {
      // we are out of the danger zone, do not complete the remaining steps
      break;
    }
  }

  // calculate early investment potential savings
  calcEarlyInvestmentPotentialSavings(mutableClient, clientClone);

  // logInfo(calcDangerZoneReductionPotentialSavings, 'potential savings', {
  //   potentialSavings: mutableClient.dangerZoneCalculations.potentialSavings,
  // });
  return clientClone;
}

/**  We will also calculate the coverage amount by looking at the reduced cost
 * and solving for the amount of principle investment needed today, growing at the ARR they listed in their intake form.
 * If the principle amount needed is higher than the amount that they currently have in invested assets,
 * then the difference must be covered with assets that are assumed to have 0% ARR growth.
 */
function calcEarlyInvestmentPotentialSavings(
  mutableClient: Draft<Client>,
  clientClone: Draft<Client>,
) {
  const existingLiquidAssets =
    clientClone.intakeSurvey.householdLiquidAssetsValue ??
    clientClone.fundingSources.selfFunding.existingAssetsValue ??
    0;
  const existingAssetsARR =
    clientClone.intakeSurvey.householdLiquidAssetsAnnualGrowthRate;

  const existingAssetsARRDecimal = existingAssetsARR
    ? existingAssetsARR / 100
    : clientClone.fundingSources.selfFunding.annualRateOfReturn ?? 0;

  const { allPhaseInflatedProfessionalShareCost } = clientClone.allPhaseCosts;
  const { yearsTillLtc } = clientClone.inferenceSet;
  const yearsForGrowth = yearsTillLtc;
  let principleInvestmentNeeded = 0;

  // if on claim, set principleInvestmentNeeded to allPhaseInflatedProfessionalShareCost
  if (yearsTillLtc <= 0) {
    principleInvestmentNeeded = allPhaseInflatedProfessionalShareCost;
  } else {
    // solve for the amount of principle investment needed today at existing assets ARR over the years for growth
    principleInvestmentNeeded = calcPrincipleAmount(
      allPhaseInflatedProfessionalShareCost,
      existingAssetsARRDecimal,
      yearsForGrowth,
    );
  }

  // if principleInvestmentNeeded is higher than existingLiquidAssets, cover the difference with 0% ARR assets
  if (principleInvestmentNeeded > existingLiquidAssets) {
    const difference = principleInvestmentNeeded - existingLiquidAssets;
    principleInvestmentNeeded =
      difference +
      calcPrincipleAmount(
        allPhaseInflatedProfessionalShareCost - difference,
        existingAssetsARRDecimal,
        yearsForGrowth,
      );
  }

  // logInfo(calcEarlyInvestmentPotentialSavings, 'principleInvestmentNeeded', {
  //   principleInvestmentNeeded: principleInvestmentNeeded,
  // });

  // assign potential savings to actual client
  mutableClient.dangerZoneCalculations.potentialSavings =
    mutableClient.allPhaseCosts.allPhaseInflatedProfessionalShareCost -
    principleInvestmentNeeded;

  // assign client clone to potential savings client
  mutableClient.potentialSavingsClient = clientClone;
}

function applyCarePhaseHours(
  supportProvider: Draft<SupportProvider>,
  selectedCarePhase: CarePhase,
  value: number | null,
): SupportProvider {
  const updatedValue = value ?? 0;
  switch (selectedCarePhase) {
    case CarePhase.earlyCare:
      supportProvider.supportProviderPhaseOneHours += updatedValue;
      break;
    case CarePhase.moderateCare:
      supportProvider.supportProviderPhaseTwoHours += updatedValue;
      break;
    // Add other cases as necessary
    case CarePhase.fullCare:
      supportProvider.supportProviderPhaseThreeHours += updatedValue;
      break;
  }
  return supportProvider;
}
