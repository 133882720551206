import { DevicePhoneMobileIcon } from '@heroicons/react/20/solid';
import { Button, InputAdornment } from '@mui/material';
import { CustomizedTextInput } from '@shared';
import mainStyles from '../styles/main.module.css';
import classNames from 'classnames';
import { WaterlilyHeaderLink } from '../components/WaterlilyHeaderLink';
const styles = { ...mainStyles };

export function AuthMFASignIn({
  totpSignInCode,
  handleChangeTotpSignInCode,
  hasTotpSignInCodeError,
  handleClickSignInTotpCode,
  errorMessage,
}: {
  totpSignInCode: string;
  handleChangeTotpSignInCode: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  hasTotpSignInCodeError: boolean;
  handleClickSignInTotpCode: () => void;
  errorMessage: string | null;
}) {
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleClickSignInTotpCode();
  };
  return (
    <>
      <WaterlilyHeaderLink toAdvisorDashboard={true} />
      <div className={classNames(styles.main, 'm-auto max-w-md')}>
        <div className="pb-4 text-4xl text-darkPurple">
          Enter one-time passcode
        </div>
        {errorMessage ? (
          <div className={styles.errorText}>{errorMessage}</div>
        ) : null}
        <div className="grid grid-cols-1 gap-4">
          <p className="py-2 text-center text-lg text-darkPurple">
            Open your authenticator app (e.g., Google Authenticator, Authy, Duo)
            for the verification code.
          </p>
          <form onSubmit={onSubmit}>
            <CustomizedTextInput
              id="verificationCode"
              placeholder="Enter the verification code"
              variant="outlined"
              fullWidth
              value={totpSignInCode}
              onChange={handleChangeTotpSignInCode}
              error={hasTotpSignInCodeError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DevicePhoneMobileIcon />
                  </InputAdornment>
                ),
                classes: {
                  error: styles.textboxError,
                  root: styles.textbox,
                },
                tabIndex: 0,
                autoFocus: true,
              }}
            />

            <button
              className="w-full rounded-full border-0 bg-darkPurple px-8 py-2 text-lg text-white hover:bg-mediumPurple focus:outline-none"
              type="submit"
            >
              Verify
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
