import { useSelector } from 'react-redux';
import {
  FactorCareSettingsSlideOutContent,
  FactorFamilyCareGivingSlideOutContent,
  FactorHealthCareInflationSlideOutContent,
  LearnMoreSlideOut,
  OnboardingChrome,
} from '../components';
import { selectClient } from '../model';

import { formatCurrency } from '../../../shared/src/formatCurrency';
import { SlideContent } from '../components/SlideContent';
import { getFirstCarePhasePage } from '../util/navigationLogic';
import { FiTrendingUp } from 'react-icons/fi';
import { RiUserHeartLine } from 'react-icons/ri';
import { TbHomeEdit } from 'react-icons/tb';

export function OnboardingCostFactors({ clientId }: ClientIdProps) {
  const client = useSelector(selectClient)!;
  const {
    clientFirstName,
    allPhaseCosts: { allPhaseInflatedProfessionalShareCost },
    intakeSurvey: { clientHasStartedLtc },
  } = client;

  return (
    <OnboardingChrome
      slidePage={OnboardingCostFactors}
      title={''}
      nextButtonLabel={`See ${clientFirstName}'s Care Timeline`}
      nextSlidePage={getFirstCarePhasePage(client)}
    >
      <SlideContent
        headerText={
          <>
            How we calculated {clientFirstName}&apos;s{' '}
            {clientHasStartedLtc ? 'remaining' : 'estimated'} long-term care
            costs
          </>
        }
      >
        <div className="flex flex-col items-center font-semibold">
          <CostFactorCards />
          <p className="pb-8 text-center text-2xl text-darkPurple md:text-4xl">
            Estimated total costs:
            <span className="text-2xl  text-purple md:text-4xl">
              {' '}
              {formatCurrency(allPhaseInflatedProfessionalShareCost)}
            </span>
          </p>
        </div>
      </SlideContent>
    </OnboardingChrome>
  );
}

export default function CostFactorCards() {
  const client = useSelector(selectClient)!;
  const {
    allPhaseCosts: {
      allPhaseTotalCost,
      allPhaseInflatedFamilyCareCost,
      allPhaseInflatedProfessionalShareCost,
    },
  } = client;

  const allPhaseInflatedCost =
    allPhaseInflatedProfessionalShareCost + allPhaseInflatedFamilyCareCost;
  const healthCareInflationContribution =
    allPhaseInflatedCost - allPhaseTotalCost;

  const costFactorsData = [
    {
      id: 1,
      factorName: 'Care Settings',
      dollarAmount: `+${formatCurrency(allPhaseTotalCost)}`,
      icon: <TbHomeEdit className="h-10 w-10" />,
      changeType: 'increase',
      slideOutContent: <FactorCareSettingsSlideOutContent />,
    },
    {
      id: 2,
      factorName: 'Healthcare Inflation',
      dollarAmount: `+${formatCurrency(healthCareInflationContribution)}`,
      icon: <FiTrendingUp className="h-10 w-10" />,
      changeType: 'increase',
      slideOutContent: <FactorHealthCareInflationSlideOutContent />,
    },
    {
      id: 3,
      factorName: 'Family Caregiving',
      dollarAmount: `-${formatCurrency(allPhaseInflatedFamilyCareCost)}`,
      icon: <RiUserHeartLine className="h-10 w-10" />,
      changeType: 'decrease',
      slideOutContent: <FactorFamilyCareGivingSlideOutContent />,
    },
  ];
  return (
    <ul className="mb-8 grid w-11/12 grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
      {costFactorsData.map(costFactor => (
        <li
          key={costFactor.id}
          className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border bg-white text-center"
        >
          <div className="flex flex-1 flex-col p-8">
            <div className="mx-auto flex h-[60px] w-[60px] items-end justify-center rounded-full">
              {costFactor.icon}
            </div>
            <div className="mt-6 text-center text-lg font-semibold text-gray-900">
              {costFactor.factorName}
            </div>
            <div className="mt-1 flex flex-grow flex-col justify-between pb-4">
              <div className="mt-3">
                {costFactor.changeType === 'decrease' ? (
                  <span className="text-small inline-flex items-center rounded-full bg-green-50 px-2 py-1 font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {costFactor.dollarAmount}
                  </span>
                ) : (
                  <span className="text-small inline-flex items-center rounded-full bg-red-50 px-2 py-1 font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                    {costFactor.dollarAmount}
                  </span>
                )}
              </div>
            </div>
            <LearnMoreSlideOut
              slideOutContent={costFactor.slideOutContent}
              isModelDescription={false}
            >
              <div className="text-sm text-darkPurple underline hover:font-semibold hover:text-purple">
                Learn more
              </div>
            </LearnMoreSlideOut>
          </div>
        </li>
      ))}
    </ul>
  );
}
