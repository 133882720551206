import { useDispatch, useSelector } from 'react-redux';
import {
  AnnuityDetailsModal,
  FundingSourcesAnnuitySlideOutContent,
  FundingSourcesSelfFundingSlideOutContent,
  LearnMoreSlideOut,
  OnboardingChrome,
} from '../components';
import { CgAsterisk } from 'react-icons/cg';
import {
  FundingSourceCostSlideOutContent,
  FundingSourceLtcPayoutSlideOutContent,
  FundingSourceTotalPayoutSlideOutContent,
  FundingSourcesInsuranceSlideOutContent,
  FundingSourcesProjectedROISlideOutContent,
  InsuranceWhiteBoxSlideOutContent,
} from '..';
import {
  putFundingSourcesForClientByClientIdRequest,
  putPolicyFundingSourceForClientByClientIdRequest,
  selectClient,
  selectSessionAdvisor,
} from '../model';
import {
  FundingPolicyType,
  formatPercent,
  fundingPolicyTypeDefList,
  fundingPolicyTypeDefs,
  isJointPolicy,
  isNullOrUndefined,
} from '@shared';
import { TbShieldDollar } from 'react-icons/tb';
import CarePhaseDurationModal from '../components/CarePhaseDurationModal';

import {
  Menu,
  MenuItem,
  MenuItems,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { SlideContent } from '../components/SlideContent';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { OnboardingFundingCoverage, citations, removePolicyDetail } from '..';
import { formatCurrency } from '../../../shared/src/formatCurrency';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import produce from 'immer';
import { SelfFundingDetailsModal } from '../components/SelfFundingDetailsModal';
import { Tooltip } from '@mui/material';
import { InsuranceForm } from '../components/InsuranceForm';
import { orderBy, sortBy } from 'lodash';

const addButtonDimensions = 'h-[44px] md:min-w-[200px] min-w-full';
const selfFundingDescription =
  'Allocating personal savings or investments specifically for covering long-term care expenses.';
const insuranceDescription =
  'Insurance which covers extended medical and custodial expenses beyond standard health insurance.';
const annuitiesDescription =
  'A tax-advantaged financial product providing guaranteed lifelong income that can be used for long-term care.';

type InsuranceState = {
  selectedPolicyType: FundingPolicyType | null;
  selectedPolicyFundingSourceId: string | null;
  openInsuranceDetailsModal: boolean;
};

export function OnboardingFundingSources({ clientId }: ClientIdProps) {
  const client = useSelector(selectClient)!;
  const {
    fundingSources,
    clientFirstName,
    fundingSources: {
      annuity,
      combinedFundingTotalCost,
      combinedProjectedCostCoverage,
      combinedProjectedCostCoveragePercent,
      combinedNonLtcPayout,
      combinedTotalValue,
      combinedProjectedROI,
      selfFunding,
    },
    allPhaseCosts: { allPhaseInflatedProfessionalShareCost },
    intakeSurvey: { clientHasStartedLtc },
    multipleFundingSources,
  } = client;
  const dispatch = useDispatch();

  // derived state
  const applicablePolicyTypes = getApplicablePolicyTypes();

  const areSelfFundingFieldsPopulated =
    (selfFunding.annualRateOfReturn && selfFunding.annualRateOfReturn > 0) ||
    (selfFunding.monthlyContributionAmount &&
      selfFunding.monthlyContributionAmount > 0) ||
    (selfFunding.existingAssetsValue && selfFunding.existingAssetsValue > 0);

  const hasSelfFunding =
    selfFunding.hasSelfFunding || areSelfFundingFieldsPopulated;

  const hasPolicyFunding =
    Object.entries(multipleFundingSources.ltcPolicy).length > 0;

  const [insuranceState, setInsuranceState] = useState<InsuranceState>({
    selectedPolicyType: null,
    selectedPolicyFundingSourceId: null,
    openInsuranceDetailsModal: false,
  });
  const [openSelfFundingModal, setOpenSelfFundingModal] = useState(false);
  const [openAnnuityDetailsModal, setOpenAnnuityDetailsModal] = useState(false);
  const [openStressTestModal, setOpenStressTestModal] = useState(false);

  function setOpenInsuranceDetailsModal() {
    setInsuranceState({
      ...insuranceState,
      openInsuranceDetailsModal: !insuranceState.openInsuranceDetailsModal,
    });
  }

  const handlePolicyTypeSelect = (policyType: FundingPolicyType) => {
    setInsuranceState({
      selectedPolicyType: policyType,
      openInsuranceDetailsModal: true,
      selectedPolicyFundingSourceId: null,
    });
  };

  const handleClickEditPolicy = (
    selectedPolicyType: FundingPolicyType | null,
    selectedPolicyFundingSourceId: string | null,
  ) => {
    setInsuranceState({
      selectedPolicyType: selectedPolicyType,
      openInsuranceDetailsModal: true,
      selectedPolicyFundingSourceId: selectedPolicyFundingSourceId,
    });
  };

  const deleteSelfFundingCallBack = (fundingSources: FundingSources) => {
    const newFundingSources =
      produceFundingSourcesWithoutSelfFunding(fundingSources);
    dispatch(
      putFundingSourcesForClientByClientIdRequest({
        clientId,
        fundingSources: newFundingSources,
      }),
    );
  };

  const deleteAnnuityCallBack = (fundingSources: FundingSources) => {
    const newFundingSources =
      produceFundingSourcesWithoutAnnuity(fundingSources);
    dispatch(
      putFundingSourcesForClientByClientIdRequest({
        clientId,
        fundingSources: newFundingSources,
      }),
    );
  };

  const deletePolicyByFundingSourceIdCallBack = (fundingSourceId: string) => {
    dispatch(
      putPolicyFundingSourceForClientByClientIdRequest({
        clientId,
        policyFundingSource: null,
        fundingSourceId,
      }),
    );
  };

  return (
    <OnboardingChrome
      slidePage={OnboardingFundingSources}
      nextSlidePage={OnboardingFundingCoverage}
      nextButtonLabel={`Next`}
      disableNextButton={
        !hasPolicyFunding && !hasSelfFunding && !annuity.hasAnnuity
      }
      errorModalMessage="Please add a funding source to continue"
      citations={[citations.OnboardingInsuranceDetailsDisclaimer]}
    >
      <SlideContent
        headerText={`${clientFirstName}'s long-term care financing plan`}
        headerSubText={`Add funding sources to estimate how much of ${clientFirstName}'s long-term care costs will be covered.`}
      >
        <div className="flex flex-col gap-4">
          {/* Insurance add CTA */}
          <div className="mt-4">
            <div className="flex w-full flex-col items-center gap-4 md:flex-row md:gap-0">
              <div className="flex flex-grow flex-col gap-1">
                <div className="flex items-center gap-1">
                  <h3 className=" text-lg font-semibold text-gray-900 md:text-2xl">
                    Insurance
                  </h3>
                  <LearnMoreSlideOut
                    isModelDescription={false}
                    slideOutContent={<FundingSourcesInsuranceSlideOutContent />}
                  >
                    <BsFillQuestionCircleFill
                      className="h-5 w-5 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </LearnMoreSlideOut>
                </div>

                <p className="text-base md:max-w-[75%] md:text-lg">
                  {insuranceDescription}
                </p>
              </div>

              <AddTypedFundingSourceButton<FundingPolicyType>
                buttonLabel="Add insurance"
                typeList={applicablePolicyTypes}
                typeSelectHandler={handlePolicyTypeSelect}
              />
            </div>
          </div>

          <InsuranceForm
            open={insuranceState.openInsuranceDetailsModal}
            policyType={insuranceState.selectedPolicyType}
            setOpen={setOpenInsuranceDetailsModal}
            fundingSourceId={insuranceState.selectedPolicyFundingSourceId!}
          />

          {/* Annuity add CTA */}
          {!annuity.hasAnnuity && (
            <div className="mt-4">
              <div className="flex w-full flex-col items-center gap-4 md:flex-row md:gap-0">
                <div className="flex flex-grow flex-col gap-1">
                  <div className="flex items-center gap-1">
                    <h3 className=" text-lg font-semibold text-gray-900 md:text-2xl">
                      Annuity
                    </h3>
                    <LearnMoreSlideOut
                      isModelDescription={false}
                      slideOutContent={<FundingSourcesAnnuitySlideOutContent />}
                    >
                      <BsFillQuestionCircleFill
                        className="h-5 w-5 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </LearnMoreSlideOut>
                  </div>

                  <p className="text-base md:max-w-[75%] md:text-lg">
                    {annuitiesDescription}
                  </p>
                </div>

                <AddFundingSourceButton
                  buttonLabel="Add annuity"
                  setOpenedModal={setOpenAnnuityDetailsModal}
                />
              </div>
            </div>
          )}
          <AnnuityDetailsModal
            open={openAnnuityDetailsModal}
            setOpen={setOpenAnnuityDetailsModal}
          />

          {/* Self funding add CTA */}
          {!hasSelfFunding && (
            <div className="mt-4">
              <div className="flex w-full flex-col items-center gap-4 md:flex-row md:gap-0">
                <div className="flex flex-col  gap-1">
                  <div className="flex items-center gap-1">
                    <h3 className=" text-lg font-semibold text-gray-900 md:text-2xl">
                      Self-funding
                    </h3>
                    <LearnMoreSlideOut
                      isModelDescription={false}
                      slideOutContent={
                        <FundingSourcesSelfFundingSlideOutContent />
                      }
                    >
                      <BsFillQuestionCircleFill
                        className="h-5 w-5 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </LearnMoreSlideOut>
                  </div>

                  <p className="md:max-w-[75%] md:text-lg">
                    {selfFundingDescription}
                  </p>
                </div>

                <AddFundingSourceButton
                  buttonLabel="Add self-funding"
                  setOpenedModal={setOpenSelfFundingModal}
                />
              </div>
            </div>
          )}
          <SelfFundingDetailsModal
            open={openSelfFundingModal}
            setOpen={setOpenSelfFundingModal}
          />
          <CarePhaseDurationModal
            open={openStressTestModal}
            setOpen={setOpenStressTestModal}
          />

          {/* combined summary table */}
          <div
            className={`${annuity.hasAnnuity || hasPolicyFunding || hasSelfFunding ? 'block' : 'hidden'}`}
          >
            <div className="flex w-full flex-row-reverse text-darkPurple hover:text-darkPurple">
              <button
                className="flex items-center gap-2 rounded-full font-semibold underline"
                onClick={() => setOpenStressTestModal(true)}
              >
                <TbShieldDollar className="h-5 w-5" />
                Stress test your plan
              </button>
            </div>
            <div className="mt-4 flow-root">
              <div className="-mx-4 -my-2 overflow-x-scroll sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full pb-6 pt-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 ">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 md:text-base"
                        >
                          Funding Type
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:text-base"
                        >
                          <div className="flex">
                            <p>Cost</p>
                            <LearnMoreSlideOut
                              isModelDescription={false}
                              slideOutContent={
                                <FundingSourceCostSlideOutContent />
                              }
                            >
                              <BsFillQuestionCircleFill
                                className="h-4 w-4 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </LearnMoreSlideOut>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:text-base"
                        >
                          <div className="flex">
                            <p>LTC Payout</p>
                            <LearnMoreSlideOut
                              isModelDescription={false}
                              slideOutContent={
                                <FundingSourceLtcPayoutSlideOutContent />
                              }
                            >
                              <BsFillQuestionCircleFill
                                className="h-4 w-4 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </LearnMoreSlideOut>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:text-base"
                        >
                          <div className="flex">
                            <p>Non-LTC Payout</p>
                            <LearnMoreSlideOut
                              isModelDescription={false}
                              slideOutContent={
                                <FundingSourceLtcPayoutSlideOutContent />
                              }
                            >
                              <BsFillQuestionCircleFill
                                className="h-4 w-4 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </LearnMoreSlideOut>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:text-base"
                        >
                          <div className="flex">
                            <p>Total Payout</p>
                            <LearnMoreSlideOut
                              isModelDescription={false}
                              slideOutContent={
                                <FundingSourceTotalPayoutSlideOutContent />
                              }
                            >
                              <BsFillQuestionCircleFill
                                className="h-4 w-4 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </LearnMoreSlideOut>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:text-base"
                        >
                          <div className="flex">
                            <p>Projected ROI</p>
                            <LearnMoreSlideOut
                              isModelDescription={false}
                              slideOutContent={
                                <FundingSourcesProjectedROISlideOutContent />
                              }
                            >
                              <BsFillQuestionCircleFill
                                className="h-4 w-4 pb-1 pl-1 text-gray-400 hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </LearnMoreSlideOut>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      <tr>
                        <td className="hidden"></td>
                      </tr>

                      {/* Self-funding row */}
                      <FundingSourceRow
                        type="selfFunding"
                        show={hasSelfFunding}
                        fundingSourceLabel="Self-funding"
                        totalCost={
                          fundingSources.selfFunding.selfFundingTotalCost ?? 0
                        }
                        ltcPayOut={
                          fundingSources.selfFunding
                            .selfFundingProjectedCostCoverage ?? 0
                        }
                        ltcCostCoveragePercent={
                          (fundingSources.selfFunding
                            .selfFundingProjectedCostCoverage ?? 0) /
                          allPhaseInflatedProfessionalShareCost
                        }
                        nonLtcPayOut={
                          fundingSources.selfFunding.selfFundingNonLtcPayout ??
                          0
                        }
                        totalPayOut={
                          fundingSources.selfFunding.selfFundingTotalValue ?? 0
                        }
                        projectedROI={
                          fundingSources.selfFunding.selfFundingProjectedROI ??
                          0
                        }
                        openModal={setOpenSelfFundingModal}
                        deleteFundingSourceCallBack={() =>
                          deleteSelfFundingCallBack(fundingSources)
                        }
                      />

                      {/* Insurance rows */}

                      {Object.entries(multipleFundingSources.ltcPolicy).map(
                        ([fundingSourceId, policy]) => (
                          <FundingSourceRow
                            type="insurance"
                            fundingSourceId={fundingSourceId}
                            show={policy.hasPolicyFunding}
                            fundingSourceLabel={
                              policy.policyType
                                ? fundingPolicyTypeDefs[policy.policyType].label
                                : 'Insurance'
                            }
                            totalCost={policy.policyPolicyTotalCost ?? 0}
                            ltcPayOut={
                              clientHasStartedLtc
                                ? (policy.policyCoverageOfRemainingLtcCosts ??
                                  0)
                                : (policy.projectedCostCoverage ?? 0)
                            }
                            partnerLtcPayOut={
                              isJointPolicy(
                                policy.policyType,
                                client.mutableClientPartner,
                              )
                                ? (client.mutableClientPartner?.fundingSources
                                    ?.ltcPolicy?.projectedCostCoverage ??
                                  client.mutableClientPartner?.fundingSources
                                    ?.ltcPolicy
                                    ?.policyCoverageOfRemainingLtcCosts ??
                                  0)
                                : undefined
                            }
                            ltcCostCoveragePercent={
                              (clientHasStartedLtc
                                ? (policy.policyCoverageOfRemainingLtcCosts ??
                                  0)
                                : (policy.projectedCostCoverage ?? 0)) /
                              allPhaseInflatedProfessionalShareCost
                            }
                            nonLtcPayOut={policy.policyNonLtcPayout ?? 0}
                            totalPayOut={policy.policyTotalValue ?? 0}
                            projectedROI={policy.policyProjectedROI ?? 0}
                            openModal={() =>
                              handleClickEditPolicy(
                                policy.policyType,
                                fundingSourceId,
                              )
                            }
                            deleteFundingSourceCallBack={() =>
                              deletePolicyByFundingSourceIdCallBack(
                                fundingSourceId,
                              )
                            }
                          />
                        ),
                      )}

                      {/* Annuity row */}
                      <FundingSourceRow
                        type="annuity"
                        show={annuity.hasAnnuity}
                        fundingSourceLabel="Annuity"
                        totalCost={annuity.annuityTotalCost ?? 0}
                        ltcPayOut={annuity.annuityProjectedCostCoverage ?? 0}
                        ltcCostCoveragePercent={
                          (fundingSources.annuity
                            .annuityProjectedCostCoverage ?? 0) /
                          allPhaseInflatedProfessionalShareCost
                        }
                        nonLtcPayOut={annuity.annuityNonLtcPayout ?? 0}
                        totalPayOut={annuity.annuityTotalValue ?? 0}
                        projectedROI={annuity.annuityProjectedROI ?? 0}
                        openModal={setOpenAnnuityDetailsModal}
                        deleteFundingSourceCallBack={() =>
                          deleteAnnuityCallBack(fundingSources)
                        }
                      />

                      {/* Totals row */}
                      <tr
                        key="totals"
                        className="w-full hover:bg-gray-50"
                      >
                        <td className="whitespace-nowrap py-4 pr-3 text-sm font-semibold text-gray-500 md:text-base">
                          Total
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-red-500 md:text-base">
                          {formatCurrency(combinedFundingTotalCost)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold  text-green-600 md:text-base">
                          {formatCurrency(combinedProjectedCostCoverage)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold  text-gray-500 md:text-base">
                          {formatCurrency(combinedNonLtcPayout)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold  text-gray-500 md:text-base">
                          {formatCurrency(combinedTotalValue)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold  text-gray-500 md:text-base">
                          {formatPercent(combinedProjectedROI, 1)}
                        </td>
                        <td className="">
                          <span className="h-5 w-5"></span>
                        </td>
                      </tr>
                    </tbody>
                    {/* Cost coverage footer */}
                    <tfoot>
                      <tr>
                        <th
                          scope="row"
                          colSpan={5}
                          className="pl-4 pr-20 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0 md:text-base"
                        >
                          Projected Coverage / Cost
                        </th>

                        <td className="whitespace-nowrap pl-1 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-0 md:text-base">
                          {`${formatCurrency(
                            combinedProjectedCostCoverage,
                          )} / ${formatCurrency(allPhaseInflatedProfessionalShareCost)}`}
                        </td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          colSpan={5}
                          className="pl-4 pr-20 pt-4 text-right text-sm font-semibold  text-gray-900 sm:table-cell sm:pl-0 md:text-base"
                        >
                          Projected Coverage Percent
                        </th>

                        <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0 md:text-base">
                          {formatPercent(combinedProjectedCostCoveragePercent)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlideContent>
    </OnboardingChrome>
  );
}

interface FundingSourceRowProps {
  type: 'selfFunding' | 'annuity' | 'insurance';
  name?: string;
  show: boolean | null | 0;
  fundingSourceLabel: string;
  totalCost: number;
  ltcPayOut: number;
  partnerLtcPayOut?: number;
  nonLtcPayOut: number;
  totalPayOut: number;
  projectedROI: number;
  ltcCostCoveragePercent: number;
  openModal: (props: any) => void;
  deleteFundingSourceCallBack: () => void;
  fundingSourceId?: string;
}

function FundingSourceRow(props: FundingSourceRowProps) {
  const label =
    props.type === 'insurance' && !isNullOrUndefined(props.fundingSourceId) ? (
      <LearnMoreSlideOut
        title={`${props.fundingSourceLabel} Summary`}
        isModelDescription={false}
        slideOutContent={
          <InsuranceWhiteBoxSlideOutContent
            fundingSourceId={props.fundingSourceId}
          />
        }
        maxWidth="max-w-2xl"
      >
        <p className="text-left">{props.fundingSourceLabel}</p>
      </LearnMoreSlideOut>
    ) : (
      props.fundingSourceLabel
    );

  return (
    <tr
      className={`${props.show ? 'hover:bg-gray-50' : 'hidden'}`}
      key={props.fundingSourceLabel}
    >
      <td className="max-w-12 py-4 pr-3 text-sm text-gray-500 md:text-base">
        {label}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-red-500 md:text-base">
        {formatCurrency(props.totalCost)}
      </td>

      {/* LTC Payout */}
      <td className="whitespace-nowrap px-3 py-4 text-sm text-green-600 md:text-base">
        {props.partnerLtcPayOut ? (
          <span className="flex items-center gap-1.5">
            <p>{formatCurrency(props.ltcPayOut)}</p>
            <Tooltip
              arrow={true}
              title={<p className="text-sm">Payout for partner</p>}
            >
              <span className="flex items-start text-gray-500">
                ({formatCurrency(props.partnerLtcPayOut)})
                <CgAsterisk className="h-3 w-3" />
              </span>
            </Tooltip>
          </span>
        ) : (
          formatCurrency(props.ltcPayOut)
        )}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 md:text-base">
        {formatCurrency(props.nonLtcPayOut)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 md:text-base">
        {formatCurrency(props.totalPayOut)}
      </td>

      <td className="whitespace-nowrap py-4 pl-3 text-sm text-gray-500 md:text-base">
        {formatPercent(props.projectedROI, 1)}
      </td>
      <td className="whitespace-nowrap py-4 text-sm md:text-base"></td>
      <td className="whitespace-nowrap py-4 pr-3 text-sm md:text-base">
        <FundingSourceActionMenu
          actions={[
            <EditAction onClick={props.openModal} />,
            <DeleteAction onClick={props.deleteFundingSourceCallBack} />,
          ]}
        />
      </td>
    </tr>
  );
}

function produceFundingSourcesWithoutSelfFunding(
  fundingSources: FundingSources,
): FundingSources {
  return produce(fundingSources, draftFundingSources => {
    draftFundingSources.selfFunding.existingAssetsValue = null;
    draftFundingSources.selfFunding.monthlyContributionAmount = null;
    draftFundingSources.selfFunding.annualRateOfReturn = null;
    draftFundingSources.selfFunding.hasSelfFunding = false;
    draftFundingSources.selfFunding.gainsTaxRate = null;
    draftFundingSources.selfFunding.monthlyIncome = null;
  });
}

function produceFundingSourcesWithoutAnnuity(
  fundingSources: FundingSources,
): FundingSources {
  return produce(fundingSources, draftFundingSources => {
    draftFundingSources.annuity.annuityExpectedPaymentStartAge = null;
    draftFundingSources.annuity.annuityExpectedPaymentEndAge = null;
    draftFundingSources.annuity.annuityAnnualPayOut = null;
    draftFundingSources.annuity.hasAnnuity = false;
    draftFundingSources.annuity.annuityPurchasePrice = null;
    draftFundingSources.annuity.annuityAnnualPayoutForLtc = null;
    draftFundingSources.annuity.annuityAnnualPayoutForLtcPeriodYears = null;
  });
}

interface FundingSourceTypeDef<T> {
  key: string | number;
  value: T;
  label: string;
  description: string;
}

interface AddFundingSourceButtonProps {
  buttonClassName?: string;
  buttonLabel: string;
  setOpenedModal: (value: boolean) => void;
}

function AddFundingSourceButton(props: AddFundingSourceButtonProps) {
  return (
    <>
      <button
        className={
          props.buttonClassName ??
          `${addButtonDimensions} rounded-full border border-darkPurple bg-white px-3 py-2 text-center text-base font-semibold text-darkPurple shadow-md hover:bg-gray-100 hover:shadow-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-darkPurple md:text-lg`
        }
        onClick={() => props.setOpenedModal(true)}
      >
        <div className="flex items-center justify-between">
          <PlusIcon className="h-6 pl-1" />
          <p className="flex-grow">{props.buttonLabel}</p>
        </div>
      </button>
    </>
  );
}

interface AddTypedFundingSourceButtonProps<T> {
  buttonClassName?: string;
  // buttonChildren?: React.ReactNode;
  buttonLabel: string;
  typeList: FundingSourceTypeDef<T>[];
  typeSelectHandler: (selectedType: T) => void;
}

function AddTypedFundingSourceButton<T>(
  props: AddTypedFundingSourceButtonProps<T>,
): JSX.Element {
  return (
    <>
      <Popover className="relative w-full md:w-auto">
        <PopoverButton
          className={
            props.buttonClassName ??
            `${addButtonDimensions} rounded-full border border-darkPurple bg-white px-3 py-2 text-center text-base font-semibold text-darkPurple shadow-md hover:bg-gray-100 hover:shadow-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-darkPurple md:text-lg`
          }
        >
          <div className="flex items-center justify-between">
            <PlusIcon className="h-6 pl-1" />
            <p className="flex-grow">{props.buttonLabel}</p>
          </div>
        </PopoverButton>

        <PopoverPanel
          transition
          className="fixed inset-0 left-1/2 z-50 flex w-96 max-w-max -translate-x-[50%] items-center justify-center px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in md:-translate-x-[35%]"

          // className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="w-screen max-w-sm flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            {props.typeList.map(item => (
              <div
                key={item.label}
                className="relative cursor-pointer rounded-lg p-4 hover:bg-gray-50"
                onClick={() => props.typeSelectHandler(item.value)}
              >
                <a className="font-semibold text-gray-900">
                  {item.label}
                  <span className="absolute inset-0" />
                </a>
                <p className="mt-1 text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </PopoverPanel>
      </Popover>
    </>
  );
}

interface EditActionProps {
  onClick: (open: boolean) => void;
}

function EditAction(props: EditActionProps) {
  return (
    <button
      aria-label="edit"
      onClick={() => props.onClick(true)}
      className="flex w-full px-4 py-2 text-left text-sm"
    >
      <span>Edit</span>
    </button>
  );
}

interface DeleteActionProps {
  onClick: (removeFundingSource: any) => void;
}

function DeleteAction(props: DeleteActionProps) {
  return (
    <button
      aria-label="delete"
      onClick={props.onClick}
      className="flex w-full px-4 py-2 text-left text-sm text-red-500"
    >
      <span>Delete</span>
    </button>
  );
}

interface ActionMenuProps {
  actions: JSX.Element[];
  disabled?: boolean;
}
function FundingSourceActionMenu({ actions, disabled }: ActionMenuProps) {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button
          className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-darkPurple disabled:text-gray-200"
          disabled={disabled}
        >
          <span className="sr-only">Open actions</span>
          <EllipsisVerticalIcon
            className="h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {actions.map(action => {
              return (
                <MenuItem>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full',
                      )}
                    >
                      {action}
                    </span>
                  )}
                </MenuItem>
              );
            })}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}

function getApplicablePolicyTypes() {
  const { mutableClientPartner, multipleFundingSources } =
    useSelector(selectClient);
  const { organizationDisplayName } = useSelector(selectSessionAdvisor);

  const alreadyHasJointPolicy = Object.values(
    multipleFundingSources.ltcPolicy,
  ).some(
    policy =>
      policy.policyType && fundingPolicyTypeDefs[policy.policyType].isJoint,
  );

  // Determine if the organization is "VB Shop"
  const isAdvisorOrgVbShop = organizationDisplayName
    ?.toLocaleLowerCase()
    .includes('vb shop');

  const isAdvisorTransamerica = organizationDisplayName
    ?.toLocaleLowerCase()
    .includes('transamerica');

  // Apply filters
  const applicablePolicyTypes = fundingPolicyTypeDefList
    .filter(
      policyType =>
        (mutableClientPartner && !alreadyHasJointPolicy) || !policyType.isJoint,
    )
    .filter(
      policyTypeDef =>
        isAdvisorOrgVbShop ||
        policyTypeDef.value !== FundingPolicyType.allstateHybridPolicy,
    )
    .filter(
      policyTypeDef =>
        isAdvisorTransamerica ||
        policyTypeDef.value !== FundingPolicyType.lifeWithIndexAccount,
    );

  return orderBy(applicablePolicyTypes, 'listPriority', 'desc');
}
