import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import produce from 'immer';
import { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CurrencyInput, PercentInput } from '..';
import {
  selectClient,
  putFundingSourcesForClientByClientIdRequest,
} from '../model';
import { FormErrorAlert } from './FormErrorAlert';
import { isNullOrUndefined } from '@shared';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';

interface SelfFundingDetailsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type SelfFundingKey = keyof SelfFundingSource;

const ErrorMessage = ({ message }: { message?: string }) => {
  if (!message) {
    return null;
  } else {
    return <p className="ml-2 text-sm text-red-400">{message}</p>;
  }
};

export function SelfFundingDetailsModal({
  open,
  setOpen,
}: SelfFundingDetailsModalProps) {
  const client = useSelector(selectClient);
  const dispatch = useDispatch();
  const {
    fundingSources,
    clientId,
    fundingSources: { selfFunding },
    intakeSurvey: { clientHasStartedLtc },
  } = client;

  const requiredKeys: SelfFundingKey[] = clientHasStartedLtc
    ? ['existingAssetsValue']
    : ['existingAssetsValue', 'annualRateOfReturn', 'gainsTaxRate'];
  const [selfFundingState, setSelfFundingState] = useState(selfFunding ?? null);
  const [formErrors, setFormErrors] = useState(
    {} as Record<SelfFundingKey, string>,
  );
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
  const [initialSelfFundingState, setInitialSelfFundingState] = useState(
    selfFunding ?? null,
  );
  const [showWarning, setShowWarning] = useState(false);

  const handleChangeSelfFunding =
    (selfFundingKey: SelfFundingKey) => (value: number) => {
      console.log('value', value);
      setSelfFundingState(prevSelfFundingModalState => {
        return {
          ...prevSelfFundingModalState,
          [selfFundingKey]: value,
        };
      });
    };

  const validateForm = () => {
    let isFormValid = true;
    const errors = {} as Record<SelfFundingKey, string>;
    requiredKeys.forEach(key => {
      const currentValue = selfFundingState[key];
      if (isNullOrUndefined(currentValue)) {
        errors[key] = 'Required Field';
      }
    });
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleClickSubmit = () => {
    setFormHasBeenSubmitted(true);
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }
    const newFundingSources = produce(fundingSources, draftFundingSources => {
      draftFundingSources.selfFunding = {
        ...selfFundingState,
        hasSelfFunding: true,
      };
    });
    dispatch(
      putFundingSourcesForClientByClientIdRequest({
        clientId: clientId,
        fundingSources: newFundingSources,
      }),
    );
    setOpen(false);
  };

  const formIsValid = Object.keys(formErrors).length === 0;
  const hasRequiredFieldsErrors = Object.keys(formErrors).some(key =>
    requiredKeys.includes(key as SelfFundingKey),
  );

  const resetForm = () => {
    setSelfFundingState(selfFunding ?? null);
    setFormErrors({} as Record<SelfFundingKey, string>);
  };

  const onClose = () => {
    if (
      JSON.stringify(selfFundingState) !==
      JSON.stringify(initialSelfFundingState)
    ) {
      setShowWarning(true);
    } else {
      setOpen(false);
      resetForm();
    }
  };

  const handleConfirmClose = () => {
    setShowWarning(false);
    setOpen(false);
    resetForm();
  };

  const handleCancelClose = () => {
    setShowWarning(false);
  };

  // Validate form again when the form has been submitted and inputs change
  // This allows error messages to go away once the user has fixed the error
  useEffect(() => {
    if (formHasBeenSubmitted) {
      validateForm();
    }
  }, [
    selfFundingState.annualRateOfReturn,
    selfFundingState.existingAssetsValue,
    selfFundingState.gainsTaxRate,
  ]);

  useEffect(() => {
    if (open) {
      setSelfFundingState(selfFunding ?? null);
      setFormHasBeenSubmitted(false);
    }
  }, [open]);

  return (
    <>
      <div className="flex">
        <Transition
          show={open}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {}}
          >
            <UnsavedChangesDialog
              showWarning={showWarning}
              handleCancelClose={handleCancelClose}
              handleConfirmClose={handleConfirmClose}
            />
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 flex w-screen items-center justify-center">
              <div className="flex">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative mx-auto h-[700px] max-w-[95%] transform overflow-auto rounded-lg bg-white px-10 pb-4 pt-5 text-left shadow-xl transition-all md:w-[600px]">
                    <div className="flex justify-end">
                      <div>
                        <button
                          type="button"
                          onClick={onClose}
                          className="relative rounded-md bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon
                            className="h-7 w-7 "
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-semibold leading-6 text-darkPurple"
                      >
                        Self-Funding Details
                      </Dialog.Title>
                      <p className="mt-1 text-sm text-gray-700 md:text-base">
                        {}
                      </p>
                      <div className="relative pb-8">
                        <div data-intercom-target="Self Funding Fields">
                          <div
                            className={`grid grid-cols-2 gap-x-6 gap-y-1 py-7 ${clientHasStartedLtc ? '' : 'border-b'}`}
                          >
                            <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                              <h2 className="text-lg leading-5 text-darkPurple">
                                One-Time Contribution
                              </h2>
                              <p className="text-sm text-gray-700 md:text-base">
                                Funds from existing assets designated
                                specifically for future long-term care expenses.
                              </p>
                            </div>
                            <div>
                              <CurrencyInput
                                label="ONE-TIME CONTRIBUTION"
                                placeholder="Enter dollars"
                                value={selfFundingState.existingAssetsValue}
                                onChange={handleChangeSelfFunding(
                                  'existingAssetsValue',
                                )}
                                onPreview={handleChangeSelfFunding(
                                  'existingAssetsValue',
                                )}
                              />
                              <ErrorMessage
                                message={formErrors.existingAssetsValue}
                              />
                            </div>
                          </div>
                          {!clientHasStartedLtc && (
                            <div className="grid grid-cols-2 gap-x-6 gap-y-1 border-b py-7">
                              <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                                <h2 className="text-lg leading-5 text-darkPurple">
                                  Monthly Contribution
                                </h2>
                                <p className="text-sm text-gray-700 md:text-base">
                                  Money added each month to assets or
                                  investments, aimed at increasing the total
                                  value needed to fund long-term care expenses.
                                </p>
                              </div>
                              <div>
                                <CurrencyInput
                                  label="MONTHLY CONTRIBUTION"
                                  placeholder="Enter dollars"
                                  value={
                                    selfFundingState.monthlyContributionAmount
                                  }
                                  onChange={handleChangeSelfFunding(
                                    'monthlyContributionAmount',
                                  )}
                                  onPreview={handleChangeSelfFunding(
                                    'monthlyContributionAmount',
                                  )}
                                />
                                <ErrorMessage
                                  message={formErrors.monthlyContributionAmount}
                                />
                              </div>
                            </div>
                          )}
                          {!clientHasStartedLtc && (
                            <div className="grid grid-cols-2 gap-x-6 gap-y-1 border-b py-7">
                              <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                                <h2 className="text-lg leading-5 text-darkPurple">
                                  Annual Rate of Return
                                </h2>
                                <p className="text-sm text-gray-700 md:text-base">
                                  The expected annual rate of return applied to
                                  both the one-time and monthly contribution(s).
                                </p>
                              </div>
                              <div>
                                <PercentInput
                                  label="ANNUAL RATE OF RETURN"
                                  placeholder="Enter percent"
                                  value={selfFundingState.annualRateOfReturn}
                                  onChange={handleChangeSelfFunding(
                                    'annualRateOfReturn',
                                  )}
                                  onPreview={handleChangeSelfFunding(
                                    'annualRateOfReturn',
                                  )}
                                />
                                <ErrorMessage
                                  message={formErrors.annualRateOfReturn}
                                />
                              </div>
                            </div>
                          )}
                          {!clientHasStartedLtc && (
                            <div className="grid grid-cols-2 gap-x-6 gap-y-1 border-b py-7">
                              <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                                <h2 className="text-lg leading-5 text-darkPurple">
                                  Gains Tax Rate
                                </h2>
                                <p className="text-sm text-gray-700 md:text-base">
                                  This is the tax rate you will pay on the
                                  profit you make from selling your
                                  investment(s). For more information, view this{' '}
                                  <a
                                    href="https://www.irs.gov/taxtopics/tc409"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-purple underline"
                                  >
                                    IRS article
                                  </a>
                                  .
                                </p>
                              </div>
                              <div>
                                <PercentInput
                                  label="GAINS TAX RATE"
                                  placeholder="Enter percent"
                                  value={selfFundingState.gainsTaxRate}
                                  onChange={handleChangeSelfFunding(
                                    'gainsTaxRate',
                                  )}
                                  onPreview={handleChangeSelfFunding(
                                    'gainsTaxRate',
                                  )}
                                />
                                <ErrorMessage
                                  message={formErrors.gainsTaxRate}
                                />
                              </div>
                            </div>
                          )}
                          <div className="grid grid-cols-2 gap-x-6 gap-y-1 py-7">
                            <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                              <h2 className="text-lg leading-5 text-darkPurple">
                                Monthly Income in Retirement
                              </h2>
                              <p className="text-sm text-gray-700 md:text-base">
                                Expected income during long-term care (LTC)
                                years, such as social security, pension, or
                                rental income, that can be used to cover LTC
                                costs. Only include the portion of income that
                                can be allocated to LTC expenses.
                              </p>
                              <p className="text-xs italic text-gray-700 md:text-sm">
                                If the income schedule is not monthly, please
                                calculate and enter the equivalent monthly
                                amount.
                              </p>
                            </div>
                            <div>
                              <CurrencyInput
                                label="MONTHLY INCOME AMOUNT"
                                placeholder="Enter dollars"
                                value={selfFundingState.monthlyIncome}
                                onChange={handleChangeSelfFunding(
                                  'monthlyIncome',
                                )}
                                onPreview={handleChangeSelfFunding(
                                  'monthlyIncome',
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        {!formIsValid && (
                          <FormErrorAlert mainErrorMessage="There is an error with your submission">
                            {hasRequiredFieldsErrors && (
                              <li>
                                <span className="font-bold">
                                  Required field:
                                </span>{' '}
                                Please fill out all required fields.
                              </li>
                            )}
                          </FormErrorAlert>
                        )}
                        <div className="mt-5 flex flex-row-reverse">
                          <button
                            type="button"
                            className="transition-color inline-flex w-full justify-center rounded-md bg-darkPurple px-12 py-2 text-lg font-semibold text-white shadow-sm duration-100 ease-in-out hover:bg-purple sm:ml-3 sm:w-auto"
                            onClick={handleClickSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
}
