import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageLink,
  HrefLink,
  Citations,
  citations,
  onboardingSlideLast,
} from '../components';
import {
  selectClient,
  selectSession,
  selectSessionAdvisor,
  updateOnboardingSlideTracker,
} from '../model';
import {
  AdvisorDashboard,
  OnboardingResultsAreIn,
  OnboardingCostFactors,
  OnboardingCarePhasesSummary,
  OnboardingFundingCoverage,
  OnboardingFundingSources,
} from '../pages';
import { onboardingSlideByIndex } from './OnboardingSlideInfo';
import OnboardingStyles from './OnboardingChrome.module.css';
import mainStyles from '../styles/main.module.css';
import CompanyLogoContainer from './CompanyLogo';
import { SessionType } from '../../../shared/src/SessionType';
import { ExclamationTriangleIcon, CheckIcon } from '@heroicons/react/20/solid';
import CloseIcon from '@mui/icons-material/Close';
import { getFirstCarePhasePage } from '../util/navigationLogic';
import { CgShapeCircle } from 'react-icons/cg';

const styles = {
  ...mainStyles,
  ...OnboardingStyles,
};
interface OnboardingChromeProps {
  slidePage: FunctionComponent<ClientIdProps>;
  title?: ReactNode;
  children: ReactNode;
  citations?: (typeof citations)['value'][];
  nextButtonLabel: string;
  nextSlidePage?: FunctionComponent<ClientIdProps>;
  previousSlidePage?: FunctionComponent<ClientIdProps>;
  disableNextButton?: boolean;
  errorModalMessage?: string;
  shouldHideNextNavigation?: boolean;
}

export function OnboardingChrome({
  slidePage,
  title,
  children,
  citations,
  nextButtonLabel,
  nextSlidePage,
  previousSlidePage,
  disableNextButton,
  errorModalMessage,
  shouldHideNextNavigation,
}: OnboardingChromeProps) {
  const client = useSelector(selectClient)!;
  const advisor = useSelector(selectSessionAdvisor);
  const session = useSelector(selectSession);
  const dispatch = useDispatch();

  const {
    clientFirstName,
    clientLastName,
    clientId,
    clientEmail,
    advisorId,
    surveys,
    intakeSurvey: { clientHasStartedLtc },
  } = client;

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const surveyId = getLastSurveyId(surveys);
  const slideIndex = onboardingSlideByIndex.indexOf(slidePage);

  const feedbackSurveyUrl = `https://waterlily.typeform.com/to/m4H0GpSY#consumer_email=${clientEmail}&consumer_first_name=${clientFirstName}&advisor_id=${advisorId}&survey_id=${surveyId}&organization_name=xxxxx&consumer_last_name=${clientLastName}`;

  const handleNextButtonClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (disableNextButton) {
      e.preventDefault(); // Prevent link navigation
      setIsErrorModalOpen(true);
    }
  };

  useEffect(() => {
    if (isErrorModalOpen) {
      setIsErrorModalOpen(false); // Close the modal
    }
  }, [disableNextButton]);

  useEffect(() => {
    dispatch(updateOnboardingSlideTracker(slideIndex));
  }, [slideIndex]);

  return (
    <div id="top-container">
      <div className="flex min-w-full flex-col px-8 pb-4 pt-4 md:h-screen md:px-20">
        <div className="relative w-full pb-5">
          <div className="inline-block">
            <PageLink
              to={AdvisorDashboard}
              targetProps={{ advisorId }}
            >
              <CompanyLogoContainer advisor={advisor} />
            </PageLink>
          </div>

          {session.sessionType === SessionType.client ? null : (
            <PageLink
              className="absolute right-0 top-0 h-6 w-6 text-darkPurple"
              to={AdvisorDashboard}
              targetProps={{ advisorId }}
            >
              <CloseIcon sx={{ fontSize: '2em' }} />
            </PageLink>
          )}
        </div>
        <ProgressStepper
          client={client}
          slideIndex={slideIndex}
        />
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.content}>{children}</div>
        {citations && (
          <div className="py-4">
            <Citations citations={citations} />
          </div>
        )}
        <ErrorModal
          isOpen={isErrorModalOpen}
          message={errorModalMessage ?? 'Please select an answer to continue'}
        ></ErrorModal>
        <div className="flex flex-col pb-5 md:flex-row-reverse md:justify-between">
          {shouldHideNextNavigation ? null : slidePage ===
            onboardingSlideLast ? (
            <HrefLink
              className="my-3 flex min-w-32 items-center justify-center rounded-full bg-darkPurple px-12 py-4 text-base text-white shadow-md hover:bg-mediumPurple hover:shadow-none md:my-0 md:text-lg"
              to={feedbackSurveyUrl}
              target="_blank"
              rel="noopener"
            >
              {nextButtonLabel}
            </HrefLink>
          ) : (
            <PageLink
              className="my-3 flex min-w-32 items-center justify-center rounded-full bg-darkPurple px-8 py-2 text-base text-white shadow-md hover:bg-mediumPurple hover:shadow-none md:my-0 md:px-12 md:py-4 md:text-lg"
              onClick={handleNextButtonClick}
              style={{ cursor: isErrorModalOpen ? 'not-allowed' : 'pointer' }}
              to={nextSlidePage ?? onboardingSlideByIndex[slideIndex + 1]}
              targetProps={{ clientId }}
            >
              {clientHasStartedLtc && slideIndex === 0
                ? `Let's see ${clientFirstName}'s care needs`
                : nextButtonLabel}
            </PageLink>
          )}
          <div className={styles.navigationSpacer}></div>
          {slideIndex === 0 ? null : (
            <PageLink
              className="md:f flex min-w-32 items-center justify-center rounded-full bg-white px-12 py-4 text-base text-darkPurple underline shadow-md hover:bg-gray-50 hover:shadow-none md:border md:border-darkPurple md:no-underline"
              to={previousSlidePage ?? onboardingSlideByIndex[slideIndex - 1]}
              targetProps={{ clientId }}
            >
              Back
            </PageLink>
          )}
        </div>
      </div>
    </div>
  );
}

function findCurrentStep(steps: Step[], slideNumber: number): Step | undefined {
  return steps.find(
    step =>
      slideNumber >= step.beginningSlideIndex &&
      slideNumber <= step.lastSlideIndex,
  );
}

interface Step {
  name: string;
  beginningSlideIndex: number;
  lastSlideIndex: number;
}

function calcStepPercentComplete(slideIndex: number, step: Step): number {
  if (slideIndex < step.beginningSlideIndex) return 0;
  if (slideIndex > step.lastSlideIndex) return 100;

  return Math.round(
    ((slideIndex - step.beginningSlideIndex) /
      (step.lastSlideIndex - step.beginningSlideIndex + 1)) *
      100,
  );
}

interface ProgressStepperProps {
  client: Client;
  slideIndex: number;
}

interface Step {
  name: string;
  beginningSlideIndex: number;
  lastSlideIndex: number;
}

const ProgressStepper: React.FC<ProgressStepperProps> = ({
  client,
  slideIndex,
}) => {
  const clientFirstCarePhasePage = getFirstCarePhasePage(client);
  const steps: Step[] = [
    {
      name: 'Review Results',
      beginningSlideIndex: onboardingSlideByIndex.indexOf(
        OnboardingResultsAreIn,
      ),
      lastSlideIndex: onboardingSlideByIndex.indexOf(OnboardingCostFactors),
    },
    {
      name: 'Personalize Plan',
      beginningSlideIndex: onboardingSlideByIndex.indexOf(
        clientFirstCarePhasePage,
      ),
      lastSlideIndex: onboardingSlideByIndex.indexOf(
        OnboardingCarePhasesSummary,
      ),
    },
    {
      name: 'Finance Costs',
      beginningSlideIndex: onboardingSlideByIndex.indexOf(
        OnboardingFundingSources,
      ),
      lastSlideIndex: onboardingSlideByIndex.indexOf(OnboardingFundingCoverage),
    },
  ];

  const currentStep = findCurrentStep(steps, slideIndex);

  const renderStep = (step: Step, index: number) => {
    const stepPercentComplete = calcStepPercentComplete(slideIndex, step);
    const isCurrentStep = step === currentStep;
    const isCompleted = stepPercentComplete >= 100 && !isCurrentStep;

    const stepContent = (
      <>
        <ProgressBar percent={isCompleted ? 100 : stepPercentComplete} />
        <div
          className={`flex gap-1 text-xs ${isCurrentStep ? 'font-semibold text-purple hover:text-darkPurple' : 'font-medium text-gray-500'} md:text-sm`}
        >
          {isCompleted && (
            <CheckIcon className="hidden self-center text-green-500 md:flex md:h-4 md:w-4" />
          )}
          {isCurrentStep && (
            <CgShapeCircle className="hidden self-center md:flex" />
          )}
          <span className="hidden md:flex">{`Step ${index + 1}. `}</span>
          <span>{step.name}</span>
        </div>
      </>
    );

    return (
      <li
        key={step.name}
        className="flex-1"
      >
        <PageLink
          to={onboardingSlideByIndex[step.beginningSlideIndex]}
          targetProps={{ clientId: client.clientId }}
          className="group flex flex-col"
        >
          {stepContent}
        </PageLink>
      </li>
    );
  };

  return (
    <nav
      aria-label="Progress"
      className="mx-auto w-full max-w-4xl pb-2"
    >
      <ol className="flex space-x-2 space-y-0 md:space-x-8">
        {steps.map(renderStep)}
      </ol>
    </nav>
  );
};

interface ProgressBarProps {
  percent: number; // Percent of the progress bar to fill
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent }) => {
  return (
    <div className="mb-2 h-1 w-full rounded-md bg-gray-200">
      <div
        className="h-1 rounded-md bg-purple"
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;

interface ErrorModalProps {
  isOpen: boolean;
  message: string;
}

const ErrorModal = ({ isOpen, message }: ErrorModalProps) => {
  if (!isOpen) return null;

  return (
    <div className="my-4 border-l-4 border-yellow-400 bg-yellow-50 p-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-base text-yellow-700">{message}</p>
        </div>
      </div>
    </div>
  );
};

export function getLastSurveyId(surveys: Survey[]) {
  if (surveys && surveys.length > 0) {
    return surveys[surveys.length - 1].surveyId;
  }
}
