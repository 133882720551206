/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';
import {
  Menu,
  Transition,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface ActionMenuProps {
  actions: JSX.Element[];
  disabled?: boolean;
}

export default function ActionMenu({ actions, disabled }: ActionMenuProps) {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <MenuButton
          className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-darkPurple disabled:text-gray-200"
          disabled={disabled}
        >
          <span className="sr-only">Open actions</span>
          <EllipsisVerticalIcon
            className="h-7 w-7"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-50 mt-2 w-60 origin-top-right overflow-visible rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {actions.map(action => {
              return (
                <MenuItem>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full',
                      )}
                    >
                      {action}
                    </span>
                  )}
                </MenuItem>
              );
            })}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
