import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { ApiExError } from '@shared';

const magicLinkSessionSlice = createSlice({
  name: 'magicLinkSessionByToken',
  initialState: initialState.magicLinkSessionByToken,
  reducers: {
    storeMagicLinkSessionByToken(state, { payload }: PayloadAction<MagicLink>) {
      Object.assign(state, payload);
    },
    fetchMagicLinkSessionForClientByTokenForPublicRequest(state, _action: PayloadAction<string>) {
      state.isLoading = true;
    },
    fetchMagicLinkSessionForClientByTokenForPublicResponse(state, action: PayloadAction<MagicLink>) {
      state.isLoading = false;
      Object.assign(state, action.payload);
    },
    fetchMagicLinkSessionForClientByTokenForPublicFailure(state, action: PayloadAction<ApiExError>) {
      if ((action.payload.metadata.response as ApiResponseError)?.body?.errorMessage.includes('Expired token')) {
        state.isExpired = true;
      }
      if ((action.payload.metadata.response as ApiResponseError)?.body?.errorMessage.includes('Token already used')) {
        state.hasBeenUsed = true;
      }
      if ((action.payload.metadata.response as ApiResponseError)?.body?.errorMessage.includes('Account is locked')) {
        state.isAccountLocked = true;
      }
      state.isLoading = false;
    },
    postVerifySecurityQuestionsForClientByClientIdRequest(_state, _action: PayloadAction<VerifySecurityQuestionsBody>) {
    },
    postVerifySecurityQuestionsForClientByClientIdResponse(state, action: PayloadAction<VerifySecurityQuestionsResponse>) {
      state.hasVerifiedSecurityQuestions = action.payload.isValid;
    },
    postVerifySecurityQuestionsForClientByClientIdFailure(_state, _action: PayloadAction<ApiExError>) {
    },
    putMagicLinkSessionByTokenForPublicRequest(_state, _action: PayloadAction<{ token: string }>) {
    },
    putMagicLinkSessionByTokenForPublicResponse(_state, _action: PayloadAction<string>) {
    },
    putMagicLinkSessionByTokenForPublicFailure(_state, _action: PayloadAction<string>) {
    },
  }
});

export const {
  fetchMagicLinkSessionForClientByTokenForPublicRequest,
  fetchMagicLinkSessionForClientByTokenForPublicResponse,
  fetchMagicLinkSessionForClientByTokenForPublicFailure,
  storeMagicLinkSessionByToken,
  putMagicLinkSessionByTokenForPublicRequest,
  putMagicLinkSessionByTokenForPublicResponse,
  putMagicLinkSessionByTokenForPublicFailure,
  postVerifySecurityQuestionsForClientByClientIdResponse,
  postVerifySecurityQuestionsForClientByClientIdRequest,
  postVerifySecurityQuestionsForClientByClientIdFailure,
} = magicLinkSessionSlice.actions;

export const magicLinkSessionByTokenReducer = magicLinkSessionSlice.reducer;
