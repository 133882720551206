import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
  DialogTitle,
} from '@headlessui/react';
import { BsSendPlus } from 'react-icons/bs';

import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postIntakeFormEmailRequest,
  selectIntakeFormEmail,
  selectSessionAdvisor,
} from '..';
import { INTRO_INTAKE_BASE_URL, newUuid } from '@shared';
import { MdOutlineContentCopy } from 'react-icons/md';
import { OrderedSteps } from './OrderedSteps';

import { HiOutlineExternalLink } from 'react-icons/hi';
import { TextareaAutosize, Tooltip } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { isValidEmailFormat } from '../util/isValidEmailFormat';

interface AddClientDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  advisorId: string;
  organizationName: string | undefined;
}

export function AddClientModal({
  onClose,
  onConfirm,
  advisorId,
  organizationName,
}: AddClientDialogProps) {
  const [clientFirstName, setClientFirstName] = useState('');
  const [clientLastName, setClientLastName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [shouldSendClientEmail, setShouldSendClientEmail] = useState<
    boolean | null
  >(null);
  const [advisorProvidedBody, setAdvisorProvidedBody] = useState<
    string | undefined
  >(undefined);
  const [hasSendButtonBeenClicked, setHasSendButtonBeenClicked] =
    useState(false);
  const [isClientEmailValid, setIsClientEmailValid] = useState(false);
  const [copyTooltip, setCopyTooltip] = useState('Copy');
  const [isIntakeFormLinkCopied, setIsIntakeFormLinkCopied] = useState(false);
  const [isIntakeFormLinkOpened, setIsIntakeFormLinkOpened] = useState(false);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<
    'link' | 'email' | null
  >(null);
  const intakeFormEmail = useSelector(selectIntakeFormEmail);

  const dispatch = useDispatch();
  const advisor = useSelector(selectSessionAdvisor);

  const hasSelectedClientResultsEmailOption = shouldSendClientEmail !== null;

  const hasSuppliedFullNameAndResultsLinkOption =
    !!(clientFirstName.trim() && clientLastName.trim()) &&
    hasSelectedClientResultsEmailOption;

  const newClientSurveyId = newUuid();
  const intakeFormUrl = `${INTRO_INTAKE_BASE_URL}advisor_id=${encodeURIComponent(advisorId)}&survey_id=${encodeURIComponent(newClientSurveyId)}&should_send_client_email=${shouldSendClientEmail ? encodeURIComponent(shouldSendClientEmail) : 'false'}&organization_name=${organizationName ? encodeURIComponent(organizationName) : undefined}`;

  const copyLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(intakeFormUrl);
    setIsIntakeFormLinkCopied(true);
    setCopyTooltip('Copied!');
    setTimeout(() => {
      setCopyTooltip('Copy');
    }, 5000);
  }, [intakeFormUrl]);

  function handleOpenIntakeForm() {
    setIsIntakeFormLinkOpened(true);
    setTimeout(() => {
      window.open(intakeFormUrl, '_blank'); // Open the link after setting the state
    }, 100); // Use a short delay to ensure state is set before opening the link
  }

  const handleShouldSendClientEmailChange = (event: {
    target: { value: string };
  }) => {
    setShouldSendClientEmail(event.target.value === 'yes');
  };

  const onSendEmailClick = () => {
    setHasSendButtonBeenClicked(true);
    dispatch(
      postIntakeFormEmailRequest({
        email: clientEmail,
        clientFirstName,
        advisorProvidedBody,
        advisorFirstName: advisor.advisorFirstName,
        intakeFormLink: intakeFormUrl,
        advisorOrganization: organizationName,
      }),
    );
  };

  useEffect(() => {
    if (hasSendButtonBeenClicked && !intakeFormEmail.isLoading) {
      if (intakeFormEmail.failedToSend) {
        // Show modal or error message
        alert('Email failed to send. Please try again or contact support.');
      } else {
        onConfirm();
        onClose();
      }
      setHasSendButtonBeenClicked(false); // Reset the flag after handling the result
    }
  }, [intakeFormEmail, hasSendButtonBeenClicked]);

  const onClientEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = e.target.validity.valid && e.target.value.length > 0;
    setIsClientEmailValid(isValidEmailFormat(e.target.value) && isValid);
    setClientEmail(e.target.value);
  };

  const sendEmailButtonDisabled =
    !hasSuppliedFullNameAndResultsLinkOption || !isClientEmailValid;

  const completeActionTaken = isIntakeFormLinkCopied || isIntakeFormLinkOpened;

  function handleDone(): void {
    if (completeActionTaken) {
      onConfirm();
    }
    onClose();
  }

  const emailClientInviteFieldSet = (
    <fieldset>
      <div
        className={`mt-2 space-y-3 tracking-normal ${shouldSendClientEmail !== null ? 'text-gray-400' : ' text-gray-700'}`}
      >
        <div className="relative flex items-start">
          <div className="flex items-center">
            <label
              htmlFor="yes-client-results"
              className="flex items-center"
            >
              <input
                id="yes-client-results"
                name="yes-client-results"
                type="checkbox"
                disabled={completeActionTaken}
                checked={shouldSendClientEmail === true}
                onChange={handleShouldSendClientEmailChange}
                value="yes"
                aria-describedby="yes-client-results"
                className="custom-radio"
              />
              <span className="ml-3 text-base leading-6">
                <span className="font-semibold ">Yes</span>
                <span className=" ">
                  <span className="sr-only">Yes </span>, give my client access
                  to view their results on their own.
                </span>
              </span>
            </label>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex items-center">
            <label
              htmlFor="no-client-results"
              className="flex items-center"
            >
              <input
                id="no-client-results"
                name="client-results"
                type="radio"
                value="no"
                disabled={completeActionTaken}
                checked={shouldSendClientEmail === false}
                onChange={handleShouldSendClientEmailChange}
                aria-describedby="no-client-results"
                className="custom-radio"
              />
              <span className="ml-3 text-base leading-6">
                <span className="font-semibold ">No</span>
                <span className="">
                  <span className="sr-only">No</span>, I will share the results
                  with my client myself.
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  );

  const deliveryMethodFieldSets = (
    <div className="flex flex-col gap-2">
      <fieldset className={`${shouldSendClientEmail === null ? 'hidden' : ''}`}>
        <div className="mt-4 space-y-3 tracking-normal">
          <div className="relative flex items-start">
            <div className="flex items-center">
              <label
                htmlFor="generate-link"
                className="flex items-center"
              >
                <input
                  id="generate-link"
                  name="generate-link"
                  type="checkbox"
                  checked={selectedDeliveryMethod === 'link'}
                  onChange={() => setSelectedDeliveryMethod('link')}
                  value="link"
                  aria-describedby="generate-link"
                  className="custom-radio"
                />
                <span className="ml-3 text-base leading-6">
                  <span className="font-semibold text-gray-900">
                    Generate a link{' '}
                  </span>
                  <span className=" text-gray-700">to send to my client.</span>
                </span>
              </label>
            </div>
          </div>
          <div className="relative flex items-start">
            <div className="flex items-center">
              <label
                htmlFor="email-invite"
                className="flex items-center"
              >
                <input
                  id="email-invite"
                  name="email-invite"
                  type="radio"
                  value="email"
                  checked={selectedDeliveryMethod === 'email'}
                  onChange={() => setSelectedDeliveryMethod('email')}
                  className="custom-radio"
                />
                <span className="ml-3 text-base leading-6">
                  <span className="font-semibold text-gray-900">
                    Email an invite{' '}
                  </span>
                  <span className="text-gray-700">
                    with the link to my client.
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      {selectedDeliveryMethod === 'link' && (
        <div className="ml-7 mt-5">
          <SectionDescription text="Copy the intake form link to send to your client or open the form directly." />
          <div
            id="intake-link"
            className="mt-2 flex h-10 w-72 justify-between rounded-md border border-gray-100 bg-gray-100 px-2 py-2 text-sm text-gray-900 focus:border-indigo-500 focus:ring-indigo-500"
          >
            <p className="mr-2 overflow-hidden text-gray-700">
              {hasSelectedClientResultsEmailOption ? intakeFormUrl : ''}
            </p>
            <div className="ml-1 flex gap-1">
              <Tooltip
                title={
                  <p className="text-sm">
                    {hasSelectedClientResultsEmailOption
                      ? copyTooltip
                      : 'Complete Permissions section first'}
                  </p>
                }
              >
                <button
                  onClick={copyLinkToClipboard}
                  className={
                    hasSelectedClientResultsEmailOption
                      ? ''
                      : ' cursor-not-allowed text-gray-400'
                  }
                >
                  <MdOutlineContentCopy className="my-auto h-5 w-5" />
                </button>
              </Tooltip>
              <Tooltip
                title={
                  <p className="text-sm">
                    {hasSelectedClientResultsEmailOption
                      ? 'Open'
                      : 'Complete Permissions section first'}
                  </p>
                }
              >
                <button
                  aria-label="Open intake form"
                  onClick={handleOpenIntakeForm}
                  className={
                    hasSelectedClientResultsEmailOption
                      ? ''
                      : ' cursor-not-allowed text-gray-400'
                  }
                >
                  <HiOutlineExternalLink className="my-auto h-6 w-6" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      {selectedDeliveryMethod === 'email' && (
        <div className="ml-7 mt-2 flex flex-col gap-3">
          <div className="flex flex-col space-y-2">
            <div className="mb-2 mt-4 flex gap-2">
              <div className="flex h-10 w-full flex-col gap-0.5">
                <label className="text-xs text-gray-900">First name</label>
                <input
                  type="text"
                  id="firstname"
                  placeholder="Enter first name"
                  name="first-name"
                  disabled={hasSendButtonBeenClicked}
                  value={clientFirstName}
                  autoComplete="given-name"
                  onChange={e => setClientFirstName(e.target.value)}
                  className=" rounded-md border border-gray-100 bg-gray-100 px-2 py-2 text-gray-900 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex h-10 w-full flex-col gap-0.5">
                <label className="text-xs text-gray-900">Last name</label>
                <input
                  type="text"
                  id="lastname"
                  placeholder="Enter last name"
                  name="last-name"
                  value={clientLastName}
                  disabled={hasSendButtonBeenClicked}
                  autoComplete="family-name"
                  onChange={e => setClientLastName(e.target.value)}
                  className="h-10 rounded-md border border-gray-100 bg-gray-100 px-2 py-2 text-gray-900 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-0.5 pt-2">
            <label className="text-xs text-gray-900">Email address</label>
            <input
              type="email"
              id="clientEmail"
              name="email-address"
              autoComplete="email"
              disabled={hasSendButtonBeenClicked}
              placeholder="Enter email address"
              value={clientEmail}
              onChange={onClientEmailChange}
              className="block h-10 w-72 rounded-md border border-gray-100 bg-gray-100 px-2 py-2 text-sm text-gray-900 focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="flex justify-between gap-2">
            <TextareaAutosize
              id="optionalAdvisorEmailBody"
              name="advisor-message"
              minRows={1}
              value={advisorProvidedBody}
              disabled={hasSendButtonBeenClicked}
              onChange={e => setAdvisorProvidedBody(e.target.value)}
              className="block w-full rounded-md border-0 bg-gray-100 px-2 py-1.5 text-sm leading-6  text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600"
              placeholder={'Add a personalized message (optional)'}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Transition
      show={true}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-50 "
        onClose={handleDone}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="z-15 fixed inset-0 flex items-center justify-center overflow-auto">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white px-8 pb-4 pt-5 text-left shadow-xl transition-all">
              <div className="flex justify-end">
                <div>
                  <button
                    type="button"
                    onClick={handleDone}
                    className="relative rounded-md bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  >
                    <span className="sr-only">Close modal</span>
                    <Tooltip title={<p className="text-sm">Cancel</p>}>
                      <XMarkIcon
                        className="h-7 w-7"
                        aria-hidden="true"
                      />
                    </Tooltip>
                  </button>
                </div>
              </div>
              {/* Form content */}
              <div className="pr-3 text-left">
                <DialogTitle
                  as="h3"
                  className="flex items-center gap-3 text-2xl font-medium leading-6 text-gray-900"
                >
                  <BsSendPlus className="h-5 w-5 text-gray-900" />
                  Invite a Client
                </DialogTitle>
                <p className="mt-2 text-base text-gray-700">
                  Long-term care planning begins with Waterlily's intake form.
                </p>

                <div className="my-6 space-y-8">
                  <OrderedSteps
                    steps={[1, 2]}
                    stepTitles={['Permissions', 'Select Delivery Method']}
                    stepDescriptions={[
                      'Email client results link *',
                      'Select how you would like to send the intake form to your client.',
                    ]}
                    stepsCompleted={[
                      shouldSendClientEmail !== null,
                      completeActionTaken,
                    ]}
                    childrens={[
                      emailClientInviteFieldSet,
                      deliveryMethodFieldSets,
                    ]}
                  />
                </div>
              </div>
              {/* Send button */}
              <div
                className={`${selectedDeliveryMethod === 'email' ? 'mb-4 mt-10 flex w-full flex-row-reverse' : 'hidden'}`}
              >
                {sendEmailButtonDisabled ? (
                  <Tooltip
                    title={
                      <p className="text-sm">
                        Complete required fields to send email.
                      </p>
                    }
                  >
                    <div
                      className={`flex cursor-not-allowed items-center space-x-2 rounded-md bg-gray-200 px-8 py-2 text-base text-gray-400`}
                    >
                      Send
                    </div>
                  </Tooltip>
                ) : (
                  <button
                    disabled={sendEmailButtonDisabled}
                    onClick={onSendEmailClick}
                    className={`flex items-center space-x-2 rounded-md bg-darkPurple px-8 py-2 text-base text-white transition-colors duration-300 ease-in-out hover:bg-purple`}
                  >
                    {intakeFormEmail.isLoading ? 'Sending...' : 'Send'}
                  </button>
                )}
              </div>
              {/* Done button */}
              <div
                className={`${selectedDeliveryMethod === 'email' ? 'hidden' : 'mb-4 mt-10 flex w-full flex-row-reverse'}`}
              >
                {completeActionTaken ? (
                  <button
                    onClick={handleDone}
                    className={`flex items-center space-x-2 rounded-md bg-darkPurple px-8 py-2 text-base text-white transition-colors duration-300 ease-in-out hover:bg-purple`}
                  >
                    Done
                  </button>
                ) : (
                  <Tooltip
                    title={
                      <p className="text-sm">
                        Intake form link not copied or opened.
                      </p>
                    }
                  >
                    <div
                      className={`flex cursor-not-allowed items-center space-x-2 rounded-md bg-gray-200 px-8 py-2 text-base text-gray-400`}
                    >
                      Done
                    </div>
                  </Tooltip>
                )}
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

interface TextProps {
  text: string;
}
function SectionLabel({ text }: TextProps) {
  return (
    <label className="text-base font-semibold text-gray-700">{text}</label>
  );
}

function SectionDescription({ text }: TextProps) {
  return <p className="max-w-[90%] text-sm text-gray-700">{text}</p>;
}
