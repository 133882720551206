import { useDispatch, useSelector } from 'react-redux';
import { IntegerInput } from '..';
import {
  selectClient,
  putClientCarePhaseDurationSelectionForClientByClientIdRequest,
} from '../model';
import { FormErrorAlert } from './FormErrorAlert';
import { CarePhase, carePhaseDefList } from '@shared';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@mui/material';

interface PhaseDurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ErrorMessage = ({ message }: { message?: string }) => {
  if (!message) {
    return null;
  } else {
    return <p className="ml-2 text-sm text-red-400">{message}</p>;
  }
};

export default function PhaseDurationModal({
  open,
  setOpen,
}: PhaseDurationModalProps) {
  const client = useSelector(selectClient);
  const dispatch = useDispatch();
  const {
    carePhaseDurationSelections,
    inferenceSet: { ltcDurationYears },
    phaseCosts,
    intakeSurvey: { clientCurrentCarePhase },
  } = client;

  const [
    carePhaseDurationSelectionsState,
    setCarePhaseDurationSelectionsState,
  ] = useState<CarePhaseDurationSelections>(carePhaseDurationSelections ?? {});
  const [formErrors, setFormErrors] = useState({} as Record<CarePhase, string>);
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

  const currentCarePhaseIndex =
    carePhaseDefList.findIndex(
      carePhaseDef => carePhaseDef.value === clientCurrentCarePhase,
    ) ?? -1;
  const isPastPhase = (phaseIndex: number) =>
    phaseIndex < currentCarePhaseIndex;

  const requiredKeys: CarePhase[] = clientCurrentCarePhase
    ? carePhaseDefList
        .slice(currentCarePhaseIndex)
        .map(carePhaseDef => carePhaseDef.value)
    : [CarePhase.earlyCare, CarePhase.moderateCare, CarePhase.fullCare];

  const handleChangeCarePhaseDuration =
    (carePhase: CarePhase) => (value: number) => {
      setCarePhaseDurationSelectionsState(prevState => {
        return {
          ...prevState,
          [carePhase]: value,
        };
      });
    };

  const validateForm = () => {
    let isFormValid = true;

    const errors = {} as Record<CarePhase, string>;
    requiredKeys.forEach(key => {
      if (!carePhaseDurationSelectionsState[key]) {
        errors[key] = 'Required Field';
      }
    });
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleClickSubmit = () => {
    setFormHasBeenSubmitted(true);
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }
    dispatch(
      putClientCarePhaseDurationSelectionForClientByClientIdRequest({
        clientId: client.clientId,
        carePhaseDurationSelections: carePhaseDurationSelectionsState,
      }),
    );

    setOpen(false);
  };

  const handleResetToDefault = () => {
    const predictedCarePhaseDurations = {} as CarePhaseDurationSelections;
    carePhaseDefList.forEach(carePhaseDef => {
      const phaseDurationRatio =
        phaseCosts[carePhaseDef.index].phaseDurationRatio;
      const phaseCareMonthsPredicted = Math.round(
        ltcDurationYears * phaseDurationRatio * 12,
      );
      predictedCarePhaseDurations[carePhaseDef.value] = isPastPhase(
        carePhaseDef.index,
      )
        ? 0
        : phaseCareMonthsPredicted;
    });
    setCarePhaseDurationSelectionsState(predictedCarePhaseDurations);
  };

  const formIsValid = Object.keys(formErrors).length === 0;
  const hasRequiredFieldsErrors = Object.keys(formErrors).some(key =>
    requiredKeys.includes(key as unknown as CarePhase),
  );

  const resetForm = () => {
    setCarePhaseDurationSelectionsState(carePhaseDurationSelections ?? {});
    setFormErrors({} as Record<CarePhase, string>);
  };

  const onClose = () => {
    setOpen(false);
    resetForm();
  };

  useEffect(() => {
    if (formHasBeenSubmitted) {
      validateForm();
    }
  }, [carePhaseDurationSelectionsState]);

  useEffect(() => {
    if (open) {
      setCarePhaseDurationSelectionsState(carePhaseDurationSelections ?? {});
      setFormHasBeenSubmitted(false);
    }
  }, [open]);

  return (
    <>
      <div className="flex">
        <Transition.Root
          show={open}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            onClose={onClose}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 flex w-screen items-center justify-center">
              <div className="flex">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative mx-auto max-w-[95%] transform overflow-auto rounded-lg bg-white px-10 pb-4 pt-5 text-left shadow-xl transition-all md:w-[475px]">
                    <div className="flex justify-end">
                      <div>
                        <button
                          type="button"
                          onClick={onClose}
                          className="relative rounded-md bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon
                            className="h-6 w-6 "
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="pb-4">
                      <Dialog.Title
                        as="h3"
                        className="flex flex-col gap-2 text-2xl font-semibold leading-6 text-darkPurple"
                      >
                        Edit your assumptions
                        <span className="text-base font-normal text-gray-500">
                          Check the robustness of your plan by editing the
                          assumptions of your care plan.
                        </span>
                      </Dialog.Title>
                      <div className="mt-6 flex w-full items-center justify-between">
                        <h4 className="whitespace-nowrap text-xl text-gray-900">
                          Care Durations
                        </h4>
                        <button
                          onClick={handleResetToDefault}
                          className=" rounded-md border border-gray-300 bg-gray-50 px-2 py-2 text-xs text-gray-500"
                        >
                          <Tooltip
                            className="flex items-center"
                            title="Reset to the predicted durations"
                          >
                            <span>Reset durations</span>
                          </Tooltip>
                        </button>
                      </div>

                      {carePhaseDefList.map(carePhaseDef => (
                        <div
                          key={carePhaseDef.index}
                          className={`grid grid-cols-1 gap-x-6 gap-y-1 py-4 ${isPastPhase(carePhaseDef.index) ? 'hidden' : ''}`}
                        >
                          <div className="col-start-1 col-end-3 flex flex-col gap-0 pb-1">
                            <h4 className="text-base text-gray-700">
                              {carePhaseDef.label} is estimated to last for{' '}
                              {Math.round(
                                phaseCosts[carePhaseDef.index]
                                  .phaseDurationRatio *
                                  ltcDurationYears *
                                  12,
                              )}{' '}
                              months
                            </h4>
                          </div>
                          <div>
                            <IntegerInput
                              label={`Length of care`}
                              unit={'months'}
                              placeholder="Enter months"
                              value={
                                isPastPhase(carePhaseDef.index)
                                  ? 0
                                  : carePhaseDurationSelectionsState[
                                      carePhaseDef.value
                                    ] ?? null
                              }
                              onChange={handleChangeCarePhaseDuration(
                                carePhaseDef.value,
                              )}
                            />
                            <ErrorMessage
                              message={formErrors[carePhaseDef.value]}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    {!formIsValid && (
                      <FormErrorAlert mainErrorMessage="There is an error with your submission">
                        {hasRequiredFieldsErrors && (
                          <li>
                            <span className="font-bold">Required field:</span>{' '}
                            Please fill out all required fields.
                          </li>
                        )}
                      </FormErrorAlert>
                    )}
                    <div className="mt-5 flex flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-darkPurple px-12 py-2 text-lg font-semibold text-white shadow-sm hover:bg-darkPurple sm:ml-3 sm:w-auto"
                        onClick={handleClickSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </Dialog.Panel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
