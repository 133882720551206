import { ChangeEvent, useCallback, useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import EnhancedEncryptionOutlinedIcon from '@mui/icons-material/EnhancedEncryptionOutlined';
import { GeneralHeader } from '../components/GeneralHeader';

import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from '../util/UserPool';
import { closeSession, selectSession } from '..';

import mainStyles from '../styles/main.module.css';
import moduleStyles from './AuthPasswordChange.module.css';
import { CustomizedTextInput, isNullOrUndefined, logAudit } from '@shared';
import { useNavigate } from 'react-router';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};

export function AuthPasswordChange() {
  const sessionUser = useSelector(selectSession);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changePassword = useCallback(() => {
    setErrorMessage('');
    let hasErrors: boolean = false;

    if (oldPassword === '') {
      hasErrors = true;
      setOldPasswordError(true);
    }
    if (!sessionUser.advisor?.advisorEmail) {
      hasErrors = true;
      setErrorMessage('User session is missing');
      return;
    }
    const user = new CognitoUser({
      Username: sessionUser.advisor?.advisorEmail,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: sessionUser.advisor?.advisorEmail,
      Password: oldPassword,
    });
    user.authenticateUser(authDetails, {
      onSuccess: result => {
        logAudit(
          user.authenticateUser,
          'User authentication verified succesfully',
          {
            response: result,
          },
        );
        user.changePassword(oldPassword, newPassword, (error, result) => {
          if (error) {
            logAudit(user.changePassword, 'Password change failed', {
              error: error.message,
            });
            setErrorMessage(error.message);
          } else if (result) {
            logAudit(user.changePassword, 'Password changed succesfully', {
              response: result,
            });
            console.log(result);
            user.signOut();
            localStorage.removeItem('DevLocalBypassAuthPayload');
            dispatch(closeSession());
            navigate('/');
          }
        });
        console.log(result);
      },
      onFailure: error => {
        logAudit(user.authenticateUser, 'User authentication failed', {
          error: error.message,
        });
        hasErrors = true;
        setErrorMessage(error.message);
      },
    });

    if (hasErrors || isNullOrUndefined(user)) {
      return;
    }
  }, [setErrorMessage, setOldPasswordError, oldPassword, newPassword]);

  const handleChangeOldPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOldPassword(event.target.value);
      setOldPasswordError(false);
    },
    [setOldPassword, setOldPasswordError],
  );

  const handleChangeNewPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewPassword(event.target.value);
      setNewPasswordError(false);
    },
    [setNewPassword, setNewPasswordError],
  );

  const handleChangeConfirmNewPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setConfirmNewPassword(event.target.value);
      setConfirmNewPasswordError(false);
    },
    [setConfirmNewPassword, setConfirmNewPasswordError],
  );

  return (
    <div>
      <GeneralHeader />
      <div className={styles.main}>
        <div className={styles.h3Text}>Change Password</div>
        <form className={styles.passwordChangeForm}>
          <div className={styles.errorText}>{errorMessage}</div>
          <CustomizedTextInput
            id="oldpassword"
            placeholder="Enter your current password"
            type="password"
            className={styles.textbox}
            sx={{ border: '0px' }}
            fullWidth
            value={oldPassword}
            error={oldPasswordError}
            onChange={handleChangeOldPassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HttpsOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <CustomizedTextInput
            id="newpassword"
            type="password"
            placeholder="Enter your new password"
            variant="outlined"
            fullWidth
            value={newPassword}
            error={newPasswordError}
            onChange={handleChangeNewPassword}
            className={styles.textbox}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EnhancedEncryptionOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <CustomizedTextInput
            id="confirmpassword"
            type="password"
            placeholder="Confirm your new password"
            variant="outlined"
            fullWidth
            value={confirmNewPassword}
            error={confirmNewPasswordError}
            onChange={handleChangeConfirmNewPassword}
            className={styles.textbox}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EnhancedEncryptionIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            className={styles.buttonType1}
            onClick={changePassword}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}
