import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { ApiExError } from '@shared';

const advisorSlice = createSlice({
  name: 'advisor',
  initialState: initialState.advisor,
  reducers: {
    fetchAdvisorByAdvisorIdRequest(_state, _action: PayloadAction<string>) {
      return initialState.advisor;
    },
    fetchAdvisorByAdvisorIdResponse(_state, action: PayloadAction<Advisor>) {
      return action.payload;
    },
    fetchAdvisorByAdvisorIdFailure(_state, _action: PayloadAction<ApiExError>) {
      return initialState.advisor;
    },
    putAdvisorByAdvisorIdRequest(_state, _action: PayloadAction<Advisor>) {
      return initialState.advisor;
    },
    putAdvisorByAdvisorIdResponse(_state, action: PayloadAction<Advisor>) {
      return action.payload;
    },
    putAdvisorByAdvisorIdFailure(_state, _action: PayloadAction<ApiExError>) {
      return initialState.advisor;
    },
  },
});

export const {
  fetchAdvisorByAdvisorIdRequest,
  fetchAdvisorByAdvisorIdResponse,
  fetchAdvisorByAdvisorIdFailure,
  putAdvisorByAdvisorIdRequest,
  putAdvisorByAdvisorIdResponse,
  putAdvisorByAdvisorIdFailure,
} = advisorSlice.actions;

export const advisorReducer = advisorSlice.reducer;
