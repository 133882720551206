import { DevicePhoneMobileIcon } from '@heroicons/react/20/solid';
import { InputAdornment, Button } from '@mui/material';
import { CustomizedTextInput } from '@shared';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSessionForAuthRequest } from '..';
import mainStyles from '../styles/main.module.css';
import moduleStyles from '../pages/AuthLogin.module.css';
import QRCode from 'react-qr-code';
import classNames from 'classnames';
import { WaterlilyHeaderLink } from '../components/WaterlilyHeaderLink';

const styles = { ...mainStyles, ...moduleStyles };

export function AuthMFASetup({
  email,
  softwareToken,
  showEnableMfa,
  errorMessage,
  handleEnableMfa,
  handleClickVerifyTotpCode,
  totpCode,
  handleChanceTotpCode,
  hasTotpCodeError,
}: {
  email: string;
  softwareToken: string | null;
  showEnableMfa: boolean;
  errorMessage: string | null;
  handleClickVerifyTotpCode: () => void;
  totpCode: string;
  handleChanceTotpCode: (event: ChangeEvent<HTMLInputElement>) => void;
  hasTotpCodeError: boolean;
  handleEnableMfa?: () => void;
}) {
  const dispatch = useDispatch();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleClickVerifyTotpCode();
  };

  return (
    <div>
      <WaterlilyHeaderLink toAdvisorDashboard={true} />
      <div
        className={classNames(
          styles.main,
          'm-auto flex max-w-md flex-col space-y-4',
        )}
      >
        {showEnableMfa ? (
          <div className="grid grid-cols-1 gap-4">
            <div className="text-center text-4xl text-darkPurple">
              Keep your account secure
            </div>
            {errorMessage ? (
              <div className={styles.errorText}>{errorMessage}</div>
            ) : null}
            <p className="py-2 text-center text-lg text-darkPurple">
              We recommend you enable Multi-Factor Authentication (MFA) to
              prevent unauthorized access and loss of access to your account.
            </p>
            <button
              className="rounded-full  bg-darkPurple px-8 py-2 text-lg text-white hover:bg-mediumPurple"
              onClick={handleEnableMfa}
            >
              Enable MFA (recommended)
            </button>
            <button
              className="rounded-full  bg-white px-8 py-2 text-lg text-darkPurple hover:underline focus:outline-none"
              onClick={() => {
                dispatch(fetchSessionForAuthRequest());
              }}
            >
              Skip
            </button>
          </div>
        ) : null}
        {softwareToken ? (
          <div className={'grid grid-cols-1 gap-4 px-6 md:px-2'}>
            <div className="pb-2 text-center text-4xl text-darkPurple">
              MFA Setup
            </div>
            <div
              style={{
                height: 'auto',
                margin: '0 auto',
                maxWidth: 400,
                width: '100%',
              }}
            >
              <QRCode
                size={400}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={`otpauth://totp/Waterlily:${email}?secret=${softwareToken}`}
                viewBox={`0 0 300 300`}
              />
            </div>
            <p className="px-2 text-darkPurple">
              Please scan the QR code above with your authenticator app (e.g.
              Google Authenticator, Authy, Duo) and enter the code below.
            </p>
            <form onSubmit={onSubmit}>
              {errorMessage ? (
                <div className="py-1 text-center text-base text-red-500">
                  {errorMessage}
                </div>
              ) : null}
              <CustomizedTextInput
                id="verificationCode"
                placeholder="Enter the verification code"
                variant="outlined"
                fullWidth
                value={totpCode}
                onChange={handleChanceTotpCode}
                error={hasTotpCodeError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DevicePhoneMobileIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    error: styles.textboxError,
                    root: styles.textbox,
                  },
                  tabIndex: 0,
                  autoFocus: true,
                }}
              />

              <button
                className="w-full rounded-full border-0 bg-darkPurple px-8 py-2 text-lg text-white hover:bg-mediumPurple focus:outline-none"
                type="submit"
              >
                Verify
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  );
}
