import { isNumber } from 'lodash';
import { assertNotNull } from './assertNotNull';

const oneDecimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  useGrouping: false,
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});


export function formatDurationYears(years: number): string;
export function formatDurationYears(inferenceSet: InferenceSet): string;
export function formatDurationYears(client: Client): string;
export function formatDurationYears(yearsOrInferenceSetOrClient: number | InferenceSet | Client): string {
  assertNotNull(yearsOrInferenceSetOrClient, 'years | inferenceSet | client');
  const years = isNumber(yearsOrInferenceSetOrClient)
      ? yearsOrInferenceSetOrClient
      : 'ltcDurationYears' in yearsOrInferenceSetOrClient
        ? yearsOrInferenceSetOrClient.ltcDurationYears
      : assertNotNull(yearsOrInferenceSetOrClient.inferenceSet, 'client.inferenceSet').ltcDurationYears;

  return oneDecimalFormatter.format(years);

  // const rawFormatted = numbro(years).format({ mantissa: 1 });

  // return rawFormatted.endsWith('.0') ? rawFormatted.substring(0, rawFormatted.length - 2) : rawFormatted;
}
