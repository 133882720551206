import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import produce from 'immer';
import { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CurrencyInput, IntegerInput } from '..';
import { CarePhase, calcPredictedLtcEndAge, isNullOrUndefined } from '@shared';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';
import {
  selectClient,
  putFundingSourcesForClientByClientIdRequest,
} from '../model';
import { FormErrorAlert } from './FormErrorAlert';

interface AnnuityDetailsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type AnnuityKey = keyof AnnuitySource;

const ErrorMessage = ({ message }: { message?: string }) => {
  if (!message) {
    return null;
  } else {
    return <p className="ml-2 text-sm text-red-400">{message}</p>;
  }
};

const requiredKeys: AnnuityKey[] = [
  'annuityPurchasePrice',
  'annuityExpectedPaymentStartAge',
  'annuityAnnualPayOut',
];

export function AnnuityDetailsModal({
  open,
  setOpen,
}: AnnuityDetailsModalProps) {
  const client = useSelector(selectClient);
  const dispatch = useDispatch();
  const {
    fundingSources,
    clientId,
    fundingSources: { annuity },
    clientFirstName,
  } = client;

  const predictedLtcEndAge = Math.ceil(calcPredictedLtcEndAge(client));
  const [annuityState, setAnnuityState] = useState(annuity ?? null);

  const [initialAnnuityState, setInitialAnnuityState] = useState(
    annuity ?? null,
  );
  const [showWarning, setShowWarning] = useState(false);
  const [pendingClose, setPendingClose] = useState(false);

  const [formErrors, setFormErrors] = useState(
    {} as Record<AnnuityKey, string>,
  );

  useEffect(() => {
    if (open) {
      const annuityState = annuity ?? null;
      setAnnuityState(annuityState);
      setInitialAnnuityState(annuityState);
    }
  }, [open, annuity]);

  const handleChangeAnnuity = (annuityKey: AnnuityKey) => (value: number) => {
    setAnnuityState(prevAnnuityModalState => {
      return {
        ...prevAnnuityModalState,
        [annuityKey]: value,
      };
    });
  };

  const validateForm = () => {
    let isFormValid = true;

    const errors = {} as Record<AnnuityKey, string>;
    requiredKeys.forEach(key => {
      if (isNullOrUndefined(annuityState[key])) {
        errors[key] = 'Required Field';
      }
    });
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleClickSubmit = () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }
    const newFundingSources = produce(fundingSources, draftFundingSources => {
      draftFundingSources.annuity = {
        ...annuityState,
        hasAnnuity: true,
      };
    });
    dispatch(
      putFundingSourcesForClientByClientIdRequest({
        clientId: clientId,
        fundingSources: newFundingSources,
      }),
    );
    setOpen(false);
  };

  const formIsValid = Object.keys(formErrors).length === 0;
  const hasRequiredFieldsErrors = Object.keys(formErrors).some(key =>
    requiredKeys.includes(key as AnnuityKey),
  );

  const resetForm = () => {
    setAnnuityState(annuity ?? null);
    setFormErrors({} as Record<AnnuityKey, string>);
  };

  const onClose = () => {
    if (JSON.stringify(annuityState) !== JSON.stringify(initialAnnuityState)) {
      setShowWarning(true);
    } else {
      setOpen(false);
      resetForm();
    }
  };

  const handleConfirmClose = () => {
    setShowWarning(false);
    setOpen(false);
    resetForm();
  };

  const handleCancelClose = () => {
    setShowWarning(false);
    setPendingClose(false);
  };

  useEffect(() => {
    if (open) {
      setAnnuityState(annuity ?? null);
    }
  }, [open]);

  return (
    <>
      <div className="flex">
        <Transition.Root
          show={open}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {}}
          >
            <UnsavedChangesDialog
              showWarning={showWarning}
              handleCancelClose={handleCancelClose}
              handleConfirmClose={handleConfirmClose}
            />
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 flex w-screen items-center justify-center">
              <div className="flex">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative mx-auto h-[700px] max-w-[95%] transform overflow-auto rounded-lg bg-white px-10 pb-4 pt-5 text-left shadow-xl transition-all md:max-w-[40%]">
                    <div className="flex justify-end">
                      <div>
                        <button
                          type="button"
                          onClick={onClose}
                          className="relative rounded-md bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon
                            className="h-7 w-7 "
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-semibold leading-6 text-darkPurple"
                      >
                        Annuity Details
                      </Dialog.Title>
                      <p className="mt-1 text-sm text-gray-700 md:text-base">
                        Please provide the following details for the annuity.
                      </p>
                      <div className="relative pb-8">
                        <div className="grid grid-cols-2 gap-x-6 gap-y-1 border-b py-7">
                          <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                            <h2 className="text-lg leading-5 text-darkPurple">
                              Purchase Price (Premium)
                            </h2>
                            <p className="text-sm text-gray-700 md:text-base">
                              The lump sum amount paid to purchase the annuity.
                            </p>
                          </div>
                          <div>
                            <CurrencyInput
                              label="PURCHASE PRICE"
                              placeholder="Enter dollars"
                              value={annuityState.annuityPurchasePrice ?? null}
                              onChange={handleChangeAnnuity(
                                'annuityPurchasePrice',
                              )}
                              onPreview={handleChangeAnnuity(
                                'annuityPurchasePrice',
                              )}
                            />
                            <ErrorMessage
                              message={formErrors.annuityPurchasePrice}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-1 border-b py-7">
                          <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                            <h2 className="text-lg leading-5 text-darkPurple">
                              Expected Payment Start Age
                            </h2>
                            <p className="text-sm text-gray-700 md:text-base">
                              The expected age at which the annuity payouts will
                              begin.
                            </p>
                          </div>
                          <div>
                            <IntegerInput
                              unit="years old"
                              label="PAYMENT START AGE"
                              placeholder="Enter years"
                              value={
                                annuityState.annuityExpectedPaymentStartAge ??
                                null
                              }
                              onChange={handleChangeAnnuity(
                                'annuityExpectedPaymentStartAge',
                              )}
                              onPreview={handleChangeAnnuity(
                                'annuityExpectedPaymentStartAge',
                              )}
                            />
                            <ErrorMessage
                              message={
                                formErrors.annuityExpectedPaymentStartAge
                              }
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-1 border-b py-7">
                          <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                            <h2 className="text-lg leading-5 text-darkPurple">
                              Annual Payout
                            </h2>
                            <p className="text-sm text-gray-700 md:text-base">
                              The estimated or guaranteed lifetime annual payout
                              of the annuity. For greater accuracy, estimate the
                              net amount after fees and taxes.
                            </p>
                          </div>
                          <div>
                            <CurrencyInput
                              label="PAYMENT AMOUNT"
                              placeholder="Enter dollars"
                              value={annuityState.annuityAnnualPayOut ?? null}
                              onChange={handleChangeAnnuity(
                                'annuityAnnualPayOut',
                              )}
                              onPreview={handleChangeAnnuity(
                                'annuityAnnualPayOut',
                              )}
                            />
                            <ErrorMessage
                              message={formErrors.annuityAnnualPayOut}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-1 border-b  py-7">
                          <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                            <h2 className="text-lg leading-5 text-darkPurple">
                              Annual Payout for Long-Term Care
                            </h2>
                            <p className="text-sm text-gray-700 md:text-base">
                              For annuities with long-term care features, the
                              annual payout increases when the annuitant
                              requires long-term care. This enhanced payout
                              replaces the standard annual amount for the
                              duration of the eligible period. For greater
                              accuracy, estimate the net amount after fees and
                              taxes.
                            </p>
                          </div>
                          <div>
                            <CurrencyInput
                              label="PAYMENT AMOUNT"
                              placeholder="Enter dollars"
                              value={
                                annuityState.annuityAnnualPayoutForLtc ?? null
                              }
                              onChange={handleChangeAnnuity(
                                'annuityAnnualPayoutForLtc',
                              )}
                              onPreview={handleChangeAnnuity(
                                'annuityAnnualPayoutForLtc',
                              )}
                            />
                          </div>
                          <div>
                            <IntegerInput
                              unit="years"
                              label="PAYMENT PERIOD"
                              placeholder="Enter years"
                              value={
                                annuityState.annuityAnnualPayoutForLtcPeriodYears ??
                                null
                              }
                              onChange={handleChangeAnnuity(
                                'annuityAnnualPayoutForLtcPeriodYears',
                              )}
                              onPreview={handleChangeAnnuity(
                                'annuityAnnualPayoutForLtcPeriodYears',
                              )}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-1 py-7">
                          <div className="col-start-1 col-end-3 flex flex-col gap-2 pb-2 ">
                            <h2 className="text-lg leading-5 text-darkPurple">
                              Expected Payment End Age
                            </h2>
                            <p className="text-sm text-gray-700 md:text-base">
                              The expected age at which the annuity payouts will
                              end. This value is set to the expected end year of{' '}
                              {clientFirstName}'s long-term care and cannot be
                              modified at this time.
                            </p>
                          </div>
                          <div>
                            <IntegerInput
                              unit="years old"
                              label="PAYMENT END AGE"
                              placeholder="Enter years"
                              disabled={true}
                              value={predictedLtcEndAge}
                              onChange={() => {}}
                            />
                          </div>
                        </div>
                        {!formIsValid && (
                          <FormErrorAlert mainErrorMessage="There is an error with your submission">
                            {hasRequiredFieldsErrors && (
                              <li>
                                <span className="font-bold">
                                  Required field:
                                </span>{' '}
                                Please fill out all required fields.
                              </li>
                            )}
                          </FormErrorAlert>
                        )}
                        <div className="mt-5 flex flex-row-reverse">
                          <button
                            type="button"
                            className="transition-color inline-flex w-full justify-center rounded-md bg-darkPurple px-12 py-2 text-lg font-semibold text-white shadow-sm duration-100 ease-in-out hover:bg-purple sm:ml-3 sm:w-auto"
                            onClick={handleClickSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
