import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';
import { MdOutlineScreenSearchDesktop } from 'react-icons/md';

import { BsSendPlus } from 'react-icons/bs';
import { Bars3Icon, HomeIcon } from '@heroicons/react/24/outline';
import { IoSearchOutline } from 'react-icons/io5';
import { IoInformationCircleOutline } from 'react-icons/io5';

import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fullName, isNullUndefinedOrEmpty, logAudit } from '@shared';
import { useNavigate } from 'react-router-dom';
import {
  selectClientIsInvalidating,
  selectMaybeSessionCognitoUser,
  selectSessionAdvisor,
} from '../model/selectors';
import {
  About,
  AuthPasswordChange,
  closeSession,
  PageLink,
  useNavigateToPage,
  AuthMFASetupExisting,
  fetchClientsForAdvisorByAdvisorIdRequest,
} from '..';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import { XMarkIcon } from '@heroicons/react/24/outline';

import manAtComputerImageUrl from '../images/advisorDashboardManAtComputer.png';

import mainStyles from '../styles/main.module.css';
import moduleStyles from '../pages/AdvisorDashboard.module.css';
import { AddClientModal } from '../components/AddClientModal';
import { GenerateIntakeFormCTALinkDialog } from '../components/GenerateIntakeFormCTALinkDialog';
import { AdvisorDashboard, RefreshButton } from '../pages/AdvisorDashboard';
import {
  MdInfoOutline,
  MdLogout,
  MdOutlineContactSupport,
  MdOutlinePhonelinkLock,
} from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { InputAdornment, TextField } from '@mui/material';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface AdvisorDashboardLayoutProps {
  clientsTable: React.ReactNode;
  advisor: Advisor;
  advisorHasClients: boolean;
  clientsIsLoading: boolean;
  clientsSearchText: string;
  handleChangeSearchText: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function AdvisorDashboardShell({
  clientsTable,
  advisor,
  advisorHasClients,
  clientsIsLoading,
  clientsSearchText,
  handleChangeSearchText,
}: AdvisorDashboardLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { advisorId, organizationName } = advisor;
  const dispatch = useDispatch();
  const cognitoUser = useSelector(selectMaybeSessionCognitoUser);
  const navigate = useNavigate();
  const navigatetoPage = useNavigateToPage();
  const [openedIntakeForm, setOpenedIntakeForm] = useState(false);
  const clientsIsInvalidating = useSelector(selectClientIsInvalidating);

  const handleClickSignOut = useCallback(() => {
    if (!isNullUndefinedOrEmpty(cognitoUser)) {
      logAudit(handleClickSignOut, 'Logout', {
        name: fullName,
      });
      cognitoUser.signOut();
      localStorage.removeItem('DevLocalBypassAuthPayload');
      dispatch(closeSession());
    }
    navigate('/');
  }, [navigate, isNullUndefinedOrEmpty, dispatch, navigatetoPage, cognitoUser]);

  const [showIntakeModal, setShowIntakeModal] = useState(false);
  const [showGenerateIntakeCTAModal, setShowGenerateIntakeCTAModal] =
    useState(false);
  const onCloseGenerateIntakeCTAModal = () => {
    setShowGenerateIntakeCTAModal(false);
  };
  const newClientButtonProps = {
    onClick: () => {
      setShowIntakeModal(true);
    },
  };
  const onClose = () => {
    setShowIntakeModal(false);
  };
  const contactSupportUrl = `https://waterlily.typeform.com/to/yXX4wrjU#email=${advisor.advisorEmail}&first_name=${advisor.advisorFirstName}&advisor_id=${advisor.advisorId}`;
  // add a check for enterprise client when we have different tiers
  const isEnterpriseClient = false;

  const noClientsBodyContent = (
    <>
      <div className="flex h-full flex-col items-center justify-center xl:flex-row xl:gap-20">
        <div className="">
          <img
            className="max-w-[600px]"
            src={manAtComputerImageUrl}
          />
        </div>
        <div className="flex flex-col items-center gap-7 xl:mt-0 xl:justify-center">
          <div className="">
            <span className="text-4xl text-darkPurple">{`Welcome, ${advisor.advisorFirstName}!`}</span>
          </div>
          <button
            className="mt-2 flex max-h-24 items-center gap-3 text-nowrap rounded-lg border-b-2 border-darkPurple bg-mediumPurple px-8 py-4 text-xl text-white shadow-lg transition-colors duration-100 ease-in-out  hover:border-mediumPurple hover:bg-lightPurple hover:shadow-none "
            {...newClientButtonProps}
          >
            <BsSendPlus className="h-6 w-6" />
            {' Invite your first client'}
          </button>
          <div className="flex items-center justify-center gap-1 pb-8 text-base text-gray-700">
            <p className="">Not seeing your client?</p>
            <NoClientsRefreshButton />
          </div>
        </div>
      </div>
    </>
  );
  const sideNavClientsLink = (
    <PageLink
      to={AdvisorDashboard}
      targetProps={{ advisorId: '' }}
      className={classNames(
        true
          ? 'bg-mediumPurple text-white'
          : 'text-gray-400 hover:bg-mediumPurple hover:text-white',
        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
      )}
    >
      <HomeIcon
        className={classNames(
          true ? 'text-white' : 'text-gray-400 group-hover:text-white',
          'h-6 w-6 shrink-0',
        )}
        aria-hidden="true"
      />
      Clients
    </PageLink>
  );
  const sideNavAddClientsButton = (
    <button
      className={classNames(
        false
          ? 'bg-gray-50 text-purple'
          : 'w-full text-gray-400 hover:bg-mediumPurple hover:text-white',
        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
      )}
      onClick={() => setShowIntakeModal(true)}
    >
      <BsSendPlus
        className={classNames(
          false ? 'text-gray-400' : 'text-gray-400 group-hover:text-white',
          'h-5 w-5 shrink-0',
        )}
        aria-hidden="true"
      />
      Invite Client
    </button>
  );
  const sideNavContactSupportLink = (
    <a
      className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-mediumPurple hover:text-white"
      href={contactSupportUrl}
      target="_blank"
    >
      <MdOutlineContactSupport
        className={classNames(
          'text-gray-400 group-hover:text-white',
          'h-6 w-6 shrink-0',
        )}
        aria-hidden="true"
      />
      Contact Support
    </a>
  );
  const sideNavHelpDeskLink = (
    <a
      className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-mediumPurple hover:text-white"
      href={'https://help.joinwaterlily.com'}
      target="_blank"
    >
      <MdOutlineScreenSearchDesktop
        className={classNames(
          'text-gray-400 group-hover:text-white',
          'h-6 w-6 shrink-0',
        )}
        aria-hidden="true"
      />
      Help Desk
    </a>
  );

  const sideNavAboutLink = (
    <PageLink
      className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-mediumPurple hover:text-white"
      to={About}
    >
      <MdInfoOutline
        className={classNames(
          'text-gray-400 group-hover:text-white',
          'h-6 w-6 shrink-0',
        )}
        aria-hidden="true"
      />
      About
    </PageLink>
  );
  const sideNavAdvisorInitialAvatar = (
    <li className=" mt-auto flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-mediumPurple ">
      <UserOptionsMenu
        handleClickSignOut={handleClickSignOut}
        children={
          <div className="flex items-center gap-3  hover:text-white">
            <div className="inline-block h-10 w-10 rounded-full border border-white bg-white text-center text-darkPurple ring-2 ring-inset ring-darkPurple">
              <p className="mt-[7px]">
                {advisor.advisorFirstName.charAt(0) +
                  advisor.advisorLastName.charAt(0)}
              </p>
            </div>
            <p className="text-base ">
              {advisor.advisorFirstName + ' ' + advisor.advisorLastName}
            </p>
          </div>
        }
      />
    </li>
  );
  const sideNavWaterlilyLogo = (
    <a
      href={'https://joinwaterlily.com'}
      target="_blank"
      className="flex h-16 shrink-0 items-center gap-3"
    >
      <div className="inline-block h-9 w-9 rounded-md bg-white text-center text-darkPurple">
        <p className="mt-1.5">W</p>
      </div>
      <p className="text-lg text-gray-100">Waterlily</p>
    </a>
  );

  return (
    <>
      <div className="h-screen">
        <Dialog
          className="relative z-50 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-100 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-100 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-100 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, mobile */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-darkPurple px-6 pb-4">
                {sideNavWaterlilyLogo}
                <nav className="flex flex-1 flex-col">
                  <ul
                    role="list"
                    className="flex flex-1 flex-col gap-y-7"
                  >
                    <li>
                      <ul
                        role="list"
                        className="-mx-2 space-y-1"
                      >
                        <li key="clients">{sideNavClientsLink}</li>
                        <li key="add-client">{sideNavAddClientsButton}</li>
                      </ul>
                    </li>
                    <li>
                      <div className="text-sm font-semibold leading-6 text-gray-400">
                        Support
                      </div>
                      <ul
                        role="list"
                        className="-mx-2 mt-2 space-y-1"
                      >
                        <li key="contact-support">
                          {sideNavContactSupportLink}
                        </li>
                        <li key="help-desk">{sideNavHelpDeskLink}</li>
                        <li key="about">{sideNavAboutLink}</li>
                      </ul>
                    </li>
                    {sideNavAdvisorInitialAvatar}
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar,  desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-darkPurple px-6 pb-4">
            {sideNavWaterlilyLogo}
            <nav className="flex flex-1 flex-col">
              <ul
                role="list"
                className="flex flex-1 flex-col gap-y-7"
              >
                <li>
                  <ul
                    role="list"
                    className="-mx-2 space-y-1"
                  >
                    <li key="clients">{sideNavClientsLink}</li>
                    <li key="add-client">{sideNavAddClientsButton}</li>
                  </ul>
                </li>
                <li>
                  <div className="text-sm font-semibold leading-6 text-gray-400">
                    Support Links
                  </div>
                  <ul
                    role="list"
                    className="-mx-2 mt-2 space-y-1"
                  >
                    <li key="contact-support">{sideNavContactSupportLink}</li>
                    <li key="help-desk">{sideNavHelpDeskLink}</li>
                    <li key="about">{sideNavAboutLink}</li>
                  </ul>
                </li>
                {sideNavAdvisorInitialAvatar}
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-28 shrink-0 items-center gap-x-4 bg-white px-4 pb-1 sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>

            <div className="mt-5 flex w-full gap-4">
              <TextField
                className="flex-grow"
                value={clientsSearchText}
                onChange={handleChangeSearchText}
                label="Search by name"
                size="small"
                margin="dense"
                variant="filled"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoSearchOutline />
                    </InputAdornment>
                  ),
                }}
              />
              <button
                type="button"
                onClick={() => setShowIntakeModal(true)}
                className="mt-2 flex max-h-12 items-center gap-3 text-nowrap rounded-lg border-b-2 border-darkPurple bg-mediumPurple px-4 py-3 text-white shadow-lg transition-colors duration-100 ease-in-out hover:border-b-0 hover:border-mediumPurple hover:bg-lightPurple hover:shadow-none md:px-7"
              >
                <BsSendPlus
                  className="h-5 w-5"
                  aria-hidden="true"
                />
                <p className="semibold hidden md:block md:text-xl">
                  Invite Client
                </p>
              </button>
            </div>
          </div>

          <main className="px-4 pt-3 sm:px-6 lg:px-8">
            {(!advisorHasClients && clientsSearchText.length > 0) ||
            advisorHasClients ||
            clientsIsLoading ||
            clientsIsInvalidating
              ? clientsTable
              : noClientsBodyContent}
          </main>
        </div>
      </div>
      {showGenerateIntakeCTAModal && (
        <GenerateIntakeFormCTALinkDialog
          onClose={onCloseGenerateIntakeCTAModal}
          advisorId={advisorId}
        />
      )}
      {showIntakeModal && (
        <AddClientModal
          onClose={onClose}
          onConfirm={() => {
            setOpenedIntakeForm(true);
          }}
          advisorId={advisorId}
          organizationName={organizationName}
        />
      )}
      <IntakeFormCreatedNotification
        open={openedIntakeForm}
        setOpen={setOpenedIntakeForm}
      />
    </>
  );
}

interface IntakeFormCreatedNotificationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
function IntakeFormCreatedNotification({
  open,
  setOpen,
}: IntakeFormCreatedNotificationProps) {
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 mb-12 flex items-end px-4 py-6 sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition show={open}>
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Intake form created
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Your client will not appear on the dashboard until they
                      have submitted the intake form.
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

export function NoClientsRefreshButton() {
  const dispatch = useDispatch();
  const advisor = useSelector(selectSessionAdvisor);

  const handleClickRefresh = useCallback(
    function handleClickRefreshImpl() {
      dispatch(
        fetchClientsForAdvisorByAdvisorIdRequest({
          advisorId: advisor.advisorId,
          isInvalidation: false,
        }),
      );
    },
    [advisor.advisorId, dispatch],
  );
  return (
    <button
      onClick={handleClickRefresh}
      className="flex items-center gap-2 font-semibold text-gray-700"
    >
      Click here to refresh
    </button>
  );
}

interface OptionsMenuProps {
  handleClickSignOut: () => void;
  children: React.ReactNode;
}

function UserOptionsMenu({ children, handleClickSignOut }: OptionsMenuProps) {
  const changePasswordLink = (
    <PageLink
      className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-darkPurple hover:bg-gray-200"
      to={AuthPasswordChange}
    >
      <RiLockPasswordLine
        className={classNames('text-darkPurple', 'h-6 w-6 shrink-0')}
        aria-hidden="true"
      />
      Change Password
    </PageLink>
  );
  const setUpMfaLink = (
    <PageLink
      className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-darkPurple hover:bg-gray-200"
      to={AuthMFASetupExisting}
    >
      <MdOutlinePhonelinkLock
        className={classNames('text-darkPurple', '-ml-1 h-6 w-6 shrink-0')}
        aria-hidden="true"
      />
      Setup MFA
    </PageLink>
  );
  const signOutButton = (
    <button
      className="group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-darkPurple hover:bg-gray-200 "
      onClick={handleClickSignOut}
    >
      <MdLogout
        className={classNames('text-darkPurple ', 'h-6 w-6 shrink-0')}
        aria-hidden="true"
      />
      Sign Out
    </button>
  );
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <MenuButton className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none ">
          {children}
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="z-100 absolute bottom-10 mb-4 w-60 origin-bottom-left overflow-visible rounded-md bg-white px-1 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <MenuItem>{changePasswordLink}</MenuItem>
          <MenuItem>{setUpMfaLink}</MenuItem>
          <MenuItem>{signOutButton}</MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
