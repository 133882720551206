import { CareEnvironment } from './appModel';
import { SupportProviderRatePeriod } from './SupportProviderRatePeriod';
import zipCodeCostData from '../data/zipcodeCareEnvironmentCost.json';
import { isNullOrUndefined } from '.';

type CostAndRatePeriod = {
  rateAmount: number;
  ratePeriod: 'hourly' | 'monthly';
};

type CareEnvironmentData = {
  [careEnvironment: string]: CostAndRatePeriod;
};

type ZipCodeData = {
  [zipcode: string]: CareEnvironmentData;
};

function nationalAverageFor(
  careEnvironment: CareEnvironment,
): CostAndRatePeriod {
  const nationalAverages = {
    assistedLiving: 5136.69,
    homeCare: 27.15,
    nursingHome: 9979.31,
    independentLiving: 3132.82,
  };
  const monthlyRatePeriod =
    SupportProviderRatePeriod.monthly.toLocaleLowerCase() as
      | 'monthly'
      | 'hourly';
  const hourlyRatePeriod =
    SupportProviderRatePeriod.hourly.toLocaleLowerCase() as
      | 'monthly'
      | 'hourly';
  switch (careEnvironment) {
    case CareEnvironment.assistedLiving:
      return {
        rateAmount: nationalAverages.assistedLiving,
        ratePeriod: monthlyRatePeriod,
      };
    case CareEnvironment.nursingHome:
      return {
        rateAmount: nationalAverages.nursingHome,
        ratePeriod: monthlyRatePeriod,
      };
    case CareEnvironment.home:
      return {
        rateAmount: nationalAverages.homeCare,
        ratePeriod: hourlyRatePeriod,
      };
    case CareEnvironment.independentLiving:
      return {
        rateAmount: nationalAverages.independentLiving,
        ratePeriod: monthlyRatePeriod,
      };
  }
}

function convertCareEnvironmentToString(
  careEnvironment: CareEnvironment,
): string {
  switch (careEnvironment) {
    case CareEnvironment.assistedLiving:
      return 'assistedLiving';
    case CareEnvironment.nursingHome:
      return 'nursingHome';
    case CareEnvironment.home:
      return 'homeCare';
    case CareEnvironment.independentLiving:
      return 'independentLiving';
  }
}

export function isValidZipCode(zipCode: string): boolean {
  // check if zipcode is valid format
  const zipCodeTrimmed = zipCode.trim();
  const zipCodePattern = /^\d{5}$/;
  const zipCodePatternWithLeadingZero = /^0\d{4}$/;
  const isValidZipCodePattern =
    zipCodePattern.test(zipCodeTrimmed) ||
    zipCodePatternWithLeadingZero.test(zipCodeTrimmed);

  if (!isValidZipCodePattern) {
    return false;
  }
  // check if zipcode without leading zero exists within the data
  if (zipCodePatternWithLeadingZero.test(zipCodeTrimmed)) {
    const leadingZeroZipCodeEntry = (zipCodeCostData as ZipCodeData)[
      zipCodeTrimmed.slice(1)
    ];
    return !isNullOrUndefined(leadingZeroZipCodeEntry);
  }

  // check if zipcode exists within the data
  return !isNullOrUndefined((zipCodeCostData as ZipCodeData)[zipCodeTrimmed]);
}

export function zipCodeRateFor(
  zipCode: string,
  careEnvironment: CareEnvironment,
): CostAndRatePeriod {
  const careEnvironmentString = convertCareEnvironmentToString(careEnvironment);
  let costAndRateData = (zipCodeCostData as ZipCodeData)[zipCode.trim()]?.[
    careEnvironmentString
  ];

  // if the zip code is not found, try removing the leading 0
  if (isNullOrUndefined(costAndRateData) && zipCode.charAt(0) === '0') {
    costAndRateData = (zipCodeCostData as ZipCodeData)[zipCode.slice(1)]?.[
      careEnvironmentString
    ];
  }

  return costAndRateData
    ? roundRateToNearestDollar(costAndRateData)
    : roundRateToNearestDollar(nationalAverageFor(careEnvironment));
}

function roundRateToNearestDollar(
  costAndRateData: CostAndRatePeriod,
): CostAndRatePeriod {
  return {
    rateAmount: Math.round(costAndRateData.rateAmount),
    ratePeriod: costAndRateData.ratePeriod,
  };
}
