import { useSelector } from 'react-redux';
import { selectClient } from '../model';
import { FundingPolicyType, formatCurrency } from '@shared';
import { useState } from 'react';

import { FundingSourceForm } from '..';
import { Radio, RadioGroup } from '@headlessui/react';
import { getBiWeeklyPremiumRate } from '@shared';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type VbShopPlanSelectionProps = {
  setPolicyState: React.Dispatch<React.SetStateAction<PolicyFundingSource>>;
  ltcPolicy: PolicyFundingSource;
  policyType: FundingPolicyType | null;
};

export function VbShopPlanSelection({
  setPolicyState,
  ltcPolicy,
  policyType,
}: VbShopPlanSelectionProps) {
  if (policyType !== FundingPolicyType.allstateHybridPolicy) {
    return null;
  }

  const client = useSelector(selectClient);
  const {
    intakeSurvey: { clientHasStartedLtc, clientAge, clientSmokedRecently },
  } = client;

  const [selectedBenefitAmount, setSelectedBenefitAmount] = useState<
    number | undefined
  >(
    ltcPolicy.customPolicyId
      ? Number.parseInt(ltcPolicy.customPolicyId)
      : undefined,
  );

  const benefitAmountOptions = [
    '50000',
    '75000',
    '100000',
    '150000',
    '200000',
    '250000',
  ];

  const benefitAmountPlans: BenefitAmountPlan[] = benefitAmountOptions.map(
    amount => {
      const biWeeklyPremiumRate = getBiWeeklyPremiumRate(
        clientSmokedRecently === 'Yes',
        clientAge.toString(),
        amount,
      );
      return {
        benefitAmount: Number.parseInt(amount),
        premiumBiWeekly: biWeeklyPremiumRate ?? 0,
      };
    },
  );

  const handleChangeSelectedBenefitAmount = (value: number) => {
    setSelectedBenefitAmount(value);
    setPolicyState(
      prevState =>
        ({
          ...prevState,
          ['policyType']: FundingPolicyType.allstateHybridPolicy,
          ['policyPremiumStartYear']: new Date().getFullYear(),
          ['policyInflationProtection']: 0,
          ['homeCareWaitingPeriodDays']: 90,
          ['facilityCareWaitingPeriodDays']: 90,
          ['isIndemnityPolicyPayment']: true,
          ['customPolicyId']: value.toString(),
          ['policyBenefitUtilizedToDate']: clientHasStartedLtc ? 0 : null,

          ['policyContinuationBenefitAmount']: value,
          ['policyMaximumBenefitAmount']: value,
          ['policyMinimumGuaranteedBenefitAmount']: value,
          ['dailyHomeBenefitAmount']: (value * 0.06) / 30,
          ['dailyIndependentLivingBenefitAmount']: (value * 0.06) / 30,
          ['dailyAssistedLivingBenefitAmount']: (value * 0.06) / 30,
          ['dailyNursingHomeBenefitAmount']: (value * 0.06) / 30,
          ['policyPremiumMonthlyCost']:
            (getBiWeeklyPremiumRate(
              clientSmokedRecently === 'Yes',
              clientAge.toString(),
              value.toString(),
            ) ?? 0) * 2,
        }) as PolicyFundingSource,
    );
  };
  return (
    <FundingSourceForm.FieldContainer
      title="Select Policy Details"
      description="For more information about the policy, click on 'Show Policy Details'. Other benefit amount options may be available upon request."
    >
      <div className="col-start-1 col-end-3 mt-2">
        <PlanSelectionRadioOptions
          plans={benefitAmountPlans}
          selectedBenefitAmount={selectedBenefitAmount}
          setSelectedBenefitAmount={handleChangeSelectedBenefitAmount}
        />
        {/* {requiredFieldMessage(
          null,
          formErrors.selectedBenefitAmount,
        )} */}
      </div>
    </FundingSourceForm.FieldContainer>
  );
}

type BenefitAmountPlan = {
  benefitAmount: number;
  premiumBiWeekly: number;
};

interface PlanSelectionRadioOptionsProps {
  plans: BenefitAmountPlan[];
  selectedBenefitAmount?: number;
  setSelectedBenefitAmount: (value: number) => void;
}

function PlanSelectionRadioOptions(props: PlanSelectionRadioOptionsProps) {
  return (
    <fieldset aria-label="Pricing plans">
      <RadioGroup
        value={props.selectedBenefitAmount}
        onChange={props.setSelectedBenefitAmount}
        className="relative -space-y-px rounded-md bg-white"
      >
        {props.plans.map((plan, planIdx) => {
          const planName = formatCurrency(plan.benefitAmount);
          const premiumMonthly = formatCurrency(plan.premiumBiWeekly * 2);
          return (
            <Radio
              key={planName}
              value={plan.benefitAmount}
              aria-label={planName}
              aria-description={`${premiumMonthly} monthly`}
              className={classNames(
                planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                planIdx === props.plans.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                'group relative flex cursor-pointer flex-col gap-3 border border-gray-200 p-4 focus:outline-none data-[checked]:z-10 data-[checked]:border-indigo-200 data-[checked]:bg-indigo-50 md:grid md:grid-cols-3 md:gap-2 md:pl-4 md:pr-6',
              )}
            >
              <span className="flex items-center text-sm">
                <span
                  aria-hidden="true"
                  className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-300 bg-white group-data-[checked]:border-transparent group-data-[checked]:bg-purple group-data-[focus]:ring-2 group-data-[focus]:ring-purple group-data-[focus]:ring-offset-2"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                </span>
                <span className="ml-3 flex flex-col font-medium text-gray-900 group-data-[checked]:text-indigo-900">
                  <p className="font-semibold">{planName}</p>
                  {` Guaranteed benefit`}
                </span>
              </span>
              <span className="ml-6 pl-1 text-sm md:ml-0 md:flex md:items-center md:justify-center md:pl-0 md:text-center">
                <span className="font-semibold text-gray-900 group-data-[checked]:text-purple">
                  {plan.premiumBiWeekly > 0 ? (
                    `${premiumMonthly} / mo`
                  ) : (
                    <span className="text-red-500">Unavailable</span>
                  )}
                </span>{' '}
              </span>
              <span className="ml-6 pl-1 text-sm text-gray-500  md:ml-0 md:pl-0 md:text-right">
                <p className="font-semibold">
                  {formatCurrency(plan.benefitAmount * 2)}{' '}
                </p>
                {` Max LTC Benefit`}
              </span>
            </Radio>
          );
        })}
      </RadioGroup>
    </fieldset>
  );
}
