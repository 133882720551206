import {
  OnboardingChrome,
  OnboardingCostFactors,
  OnboardingReducedCostConfirmation,
  PageLink,
} from '..';
import { SlideContent } from '../components/SlideContent';
import { AiFillCheckCircle } from 'react-icons/ai';
import { AiFillCloseCircle } from 'react-icons/ai';

const options = [
  {
    label: 'Yes, reduce my care costs',
    value: 'yes',
    nextSlide: OnboardingReducedCostConfirmation,
  },
  {
    label: 'No, keep my preferences',
    value: 'no',
    nextSlide: OnboardingCostFactors,
  },
];

export function OnboardingReduceLtcCosts({ clientId }: ClientIdProps) {
  return (
    <OnboardingChrome
      slidePage={OnboardingReduceLtcCosts}
      shouldHideNextNavigation={true}
      nextButtonLabel=""
    >
      <SlideContent
        headerText={
          'By aging at home with more care coming from family, your predicted costs would drop by $XXX,XXX'
        }
      >
        <div className="mx-auto flex flex-col gap-2 md:mt-6 md:w-[70%] ">
          <div className="md:mb-3">
            <p className="text-center text-2xl">Would you like to do that?</p>
          </div>
          <div className="flex w-full flex-col gap-8 md:flex-row">
            {options.map(option => (
              <PageLink
                to={option.nextSlide}
                targetProps={{ clientId }}
                key={option.value}
                className="flex w-full cursor-pointer items-center justify-center rounded-2xl py-4 pb-4 text-center ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:ring-2 hover:ring-darkPurple focus:z-10 md:flex-col md:py-12"
              >
                <div className="flex gap-1 md:flex-col md:gap-4">
                  <span className={`mx-5 my-auto md:mx-auto`}>
                    {option.value === 'no' ? (
                      <AiFillCloseCircle className="text-xl text-red-400 md:text-4xl" />
                    ) : (
                      <AiFillCheckCircle className="text-xl text-green-600  md:text-4xl " />
                    )}
                  </span>
                  <h3 className="my-auto w-[70%] text-left text-lg font-semibold text-darkPurple md:mx-auto md:text-center md:text-xl">
                    {option.label}
                  </h3>
                </div>
              </PageLink>
            ))}
          </div>
        </div>
      </SlideContent>
    </OnboardingChrome>
  );
}
