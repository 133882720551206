import { createSlice } from '@reduxjs/toolkit';
import type {
  Action,
  AnyAction,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  ApiExError,
  calcClientDerivatives,
  isDefined,
  isNullOrUndefined,
} from '@shared';
import { initialState } from './initialState';
import { AppState, fetchClientsForAdvisorByAdvisorIdRequest } from '.';
import { createSimpleMiddleware } from './createSimpleMiddleware';

const clientSlice = createSlice({
  name: 'client',
  initialState: initialState.client,
  reducers: {
    previewClientUpdates(state, { payload }: PayloadAction<Client>) {
      return {
        ...state,
        data: calcClientDerivatives(payload, state.clientPartner),
      };
    },
    fetchClientByClientIdRequest(state, action: PayloadAction<string>) {
      return action.payload === state.data?.clientId
        ? state
        : initialState.client;
    },
    fetchClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {
      // the catch-all reducer will get it below..
    },
    fetchClientByClientIdFailure(_state, _action: PayloadAction<ApiExError>) {
      return initialState.client;
    },
    putClientByClientIdRequest(_state, action: PayloadAction<Client>) {},
    putClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    putClientByClientIdFailure(_state, _action: PayloadAction<ApiExError>) {
      return initialState.client;
    },

    putFundingSourcesForClientByClientIdRequest(
      state,
      {
        payload: { clientId, fundingSources },
      }: PayloadAction<PutFundingSourcesBodyProps>,
    ) {
      if (isDefined(state.data) && state.data.clientId === clientId) {
        state.data.fundingSources = fundingSources;
        state.data = calcClientDerivatives(state.data, state.clientPartner);
      }
    },
    putFundingSourcesForClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    putFundingSourcesForClientByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {
      return initialState.client;
    },

    putPolicyFundingSourceForClientByClientIdRequest(
      state,
      {
        payload: { clientId, fundingSourceId, policyFundingSource },
      }: PayloadAction<PutPolicyFundingSourceByIdBodyProps>,
    ) {
      // TODO: implement
      // if (isDefined(state.data) && state.data.clientId === clientId) {
      //   state.data.fundingSources = fundingSources;
      //   state.data = calcClientDerivatives(state.data, state.clientPartner);
      // }
    },
    putPolicyFundingSourceForClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},

    putPolicyFundingSourceForClientByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {
      return initialState.client;
    },

    putSupportProviderSetForClientByClientIdRequest(
      state,
      {
        payload: { supportProviderSet },
      }: PayloadAction<PutSupportProviderSetBodyProps>,
    ) {
      return {
        ...state!,
        supportProviderSet,
      };
    },
    putSupportProviderSetForClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    putSupportProviderSetForClientByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {},

    deleteSupportProviderBySupportProviderIdForClientByClientIdRequest(
      _state,
      _action: PayloadAction<DeleteSupportProviderProps>,
    ) {},
    deleteSupportProviderBySupportProviderIdForClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    deleteSupportProviderBySupportProviderIdForClientByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {},

    postSurveysBySurveyIdRequest(_state, _action: PayloadAction<Survey[]>) {},
    postSurveysBySurveyIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    postSurveysBySurveyIdFailure(_state, _action: PayloadAction<ApiExError>) {},

    putCareEnvironmentSelectionsForClientByClientIdRequest(
      _state,
      _action: PayloadAction<PutCareEnvironmentSelectionsBodyProps>,
    ) {},
    putCareEnvironmentSelectionsForClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    putCareEnvironmentSelectionsForClientByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {},

    putClientCareEnvironmentCostsForClientByClientIdRequest(
      _state,
      _action: PayloadAction<PutClientCareEnvironmentCostsBodyProps>,
    ) {},
    putClientCareEnvironmentCostsForClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    putClientCareEnvironmentCostsForClientByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {},

    putClientCarePhaseDurationSelectionForClientByClientIdRequest(
      _state,
      _action: PayloadAction<PutCarePhaseDurationSelectionBodyProps>,
    ) {},
    putClientCarePhaseDurationSelectionForClientByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    putClientCarePhaseDurationSelectionForClientByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {},

    putClientOnboardingSlideProgressByClientIdRequest(
      _state,
      _action: PayloadAction<ClientOnboardingSlideProgress>,
    ) {},
    putClientOnboardingSlideProgressByClientIdResponse(
      _state,
      _action: PayloadAction<ClientContainer>,
    ) {},
    putClientOnboardingSlideProgressByClientIdFailure(
      _state,
      _action: PayloadAction<ApiExError>,
    ) {},
    deleteClientByClientIdRequest(
      _state,
      _action: PayloadAction<DeleteClientProps>,
    ) {
      return initialState.client;
    },
    deleteClientByClientIdResponse(_state, action: PayloadAction<Advisor>) {},
    deleteClientByClientIdFailure(_state, _action: PayloadAction<ApiExError>) {
      return initialState.client;
    },
    putClientPartnerLinkForClientByClientIdRequest(
      _state,
      action: PayloadAction<PutClientPartnerLinkBodyProps>,
    ) {},
    putClientPartnerLinkForClientByClientIdResponse(
      state,
      { payload: { client, clientPartner } }: PayloadAction<ClientContainer>,
    ) {
      if (client) {
        const newClientPartner = clientPartner
          ? calcClientDerivatives(clientPartner, null)
          : null;
        state.data = calcClientDerivatives(client, newClientPartner);
        state.clientPartner = newClientPartner;
      }
      state.isLoading = false;
    },
    putClientPartnerLinkForClientByClientIdFailure(
      _state,
      action: PayloadAction<ApiExError>,
    ) {
      return initialState.client;
    },
  },

  extraReducers(builder) {
    builder.addMatcher(
      (action: PayloadAction<ClientContainer>) => {
        const { payload } = action;
        return (
          !action.type.toLocaleLowerCase().includes('clientpartner') &&
          action.type.endsWith('Response') &&
          isDefined(payload) &&
          'client' in payload &&
          isDefined(payload.client)
        );
      },
      (
        state,
        { payload: { client, clientPartner } }: PayloadAction<ClientContainer>,
      ) => {
        if (client) {
          const newClientPartner = clientPartner
            ? calcClientDerivatives(clientPartner, null)
            : null;
          state.data = calcClientDerivatives(client, newClientPartner);
          state.clientPartner = newClientPartner;
        }

        state.isLoading = false;
      },
    );
  },
});

/**
 * Middleware to invalidate & refetch clients list when a client is updated from the advisor dashboard
 */
function invalidateClientsMiddleWareImpl(
  dispatch: Dispatch<AnyAction>,
  getStore: () => AppState,
  next: Dispatch<AnyAction>,
  action: Action<string>,
) {
  next(action);

  if (
    action.type === putClientOnboardingSlideProgressByClientIdResponse.type ||
    action.type === putClientByClientIdResponse.type ||
    action.type === deleteClientByClientIdResponse.type ||
    action.type === putClientPartnerLinkForClientByClientIdResponse.type
  ) {
    setTimeout(() => fetchNewClients(dispatch, getStore));
  }
}

function fetchNewClients(
  dispatch: Dispatch<AnyAction>,
  getStore: () => AppState,
) {
  const advisor = getStore().session.advisor;
  if (isNullOrUndefined(advisor?.advisorId)) {
    return;
  }
  dispatch(
    fetchClientsForAdvisorByAdvisorIdRequest({
      advisorId: advisor!.advisorId,
      isInvalidation: true,
    }),
  );
}

export const invalidateClientsMiddleWare = createSimpleMiddleware(
  invalidateClientsMiddleWareImpl,
);

export const {
  previewClientUpdates,
  fetchClientByClientIdRequest,
  fetchClientByClientIdResponse,
  fetchClientByClientIdFailure,
  putClientByClientIdRequest,
  putClientByClientIdResponse,
  putClientByClientIdFailure,
  putFundingSourcesForClientByClientIdRequest,
  putFundingSourcesForClientByClientIdResponse,
  putFundingSourcesForClientByClientIdFailure,
  putPolicyFundingSourceForClientByClientIdRequest,
  putPolicyFundingSourceForClientByClientIdResponse,
  putPolicyFundingSourceForClientByClientIdFailure,
  putSupportProviderSetForClientByClientIdRequest,
  putSupportProviderSetForClientByClientIdResponse,
  putSupportProviderSetForClientByClientIdFailure,
  deleteSupportProviderBySupportProviderIdForClientByClientIdRequest,
  deleteSupportProviderBySupportProviderIdForClientByClientIdResponse,
  deleteSupportProviderBySupportProviderIdForClientByClientIdFailure,
  postSurveysBySurveyIdRequest,
  postSurveysBySurveyIdFailure,
  postSurveysBySurveyIdResponse,
  putCareEnvironmentSelectionsForClientByClientIdRequest,
  putCareEnvironmentSelectionsForClientByClientIdResponse,
  putCareEnvironmentSelectionsForClientByClientIdFailure,
  putClientCareEnvironmentCostsForClientByClientIdRequest,
  putClientCareEnvironmentCostsForClientByClientIdResponse,
  putClientCareEnvironmentCostsForClientByClientIdFailure,
  putClientCarePhaseDurationSelectionForClientByClientIdRequest,
  putClientCarePhaseDurationSelectionForClientByClientIdResponse,
  putClientCarePhaseDurationSelectionForClientByClientIdFailure,
  putClientOnboardingSlideProgressByClientIdRequest,
  putClientOnboardingSlideProgressByClientIdResponse,
  putClientOnboardingSlideProgressByClientIdFailure,
  deleteClientByClientIdRequest,
  deleteClientByClientIdResponse,
  deleteClientByClientIdFailure,
  putClientPartnerLinkForClientByClientIdRequest,
  putClientPartnerLinkForClientByClientIdResponse,
  putClientPartnerLinkForClientByClientIdFailure,
} = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
