import { Draft } from 'immer';
import { isNullOrUndefined } from './isNullOrUndefined';
import {
  setCoverageToUndefined,
  calcPolicyCoverageImpl,
  initializeRemainingFields,
} from '.';

export function calcPolicyProjectedCostCoverage(
  mutableClient: Draft<Client>,
): void {
  const coverageType = 'projectedCostCoverage';
  const {
    fundingSources: {
      ltcPolicy: { policyPremiumStartYear },
    },
  } = mutableClient;

  if (isNullOrUndefined(policyPremiumStartYear)) {
    setCoverageToUndefined(mutableClient, coverageType);
    return;
  }

  initializeRemainingFields(mutableClient);

  calcPolicyCoverageImpl(mutableClient, coverageType);
}
