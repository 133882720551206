import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  createTheme,
} from '@mui/material';

import { memoize } from 'lodash';
import {
  calcFutureValue,
  careEnvironmentDefs,
  careEnvironmentsApplicableToPhase,
  carePhaseDefs,
  formatCurrency,
  isNullOrUndefined,
  CareEnvironment,
  CarePhase,
} from '@shared';
import {
  ChartDataPoint,
  HorizontalStackedLineChart,
  SupportProviderBoxes,
  CarePhaseDetailsAccordion,
} from '../components';
import {
  putCareEnvironmentSelectionsForClientByClientIdRequest,
  selectClient,
} from '../model';
import { defaultChartColors } from '../util';
import { getCareSettingsPageBy } from '../util/navigationLogic';
import mainStyles from '../styles/main.module.css';
import slideStyles from './OnboardingProjectedCosts.module.css';

import {
  nationalAverageLtcCost,
  yearOfLtcCostNationalStatistic,
} from '../components/Citations';
import { annualHealthcareInflationRate } from '../../../shared/src/healthCareInflationRate';
import { ThousandsIntegerInput, useNavigateToPage } from '..';
import produce from 'immer';

const styles = {
  ...mainStyles,
  ...slideStyles,
};

function isCarePhaseInThePast(
  compareCarePhase: CarePhase | null,
  clientCurrentCarePhase: CarePhase | null,
) {
  if (compareCarePhase && clientCurrentCarePhase) {
    return (
      carePhaseDefs[compareCarePhase].index <
      carePhaseDefs[clientCurrentCarePhase].index
    );
  }
  return false;
}

type CarePhaseDataPoint = ChartDataPoint & { carePhase: CarePhase | null };

const memoizedCalcChartData = memoize(function convertEachPhaseCostsToChartData(
  client: Client,
): CarePhaseDataPoint[] {
  const {
    phaseCosts,
    allPhaseCosts: { allPhaseInflatedProfessionalShareCost },
    inferenceSet: { ltcAtYear },
    intakeSurvey: { clientCurrentCarePhase },
  } = client;

  const inflatedCostStatistic = calcFutureValue(
    nationalAverageLtcCost,
    annualHealthcareInflationRate,
    ltcAtYear - yearOfLtcCostNationalStatistic,
  );

  return [
    ...phaseCosts.map(
      ({
        phaseInflatedProfessionalShareCost,
        carePhase,
        appliedCareEnvironment,
      }) => ({
        label: `${carePhaseDefs[carePhase].tooltip}: ${isCarePhaseInThePast(carePhase, clientCurrentCarePhase) ? 'In the Past' : careEnvironmentDefs[appliedCareEnvironment].label}`,
        value: phaseInflatedProfessionalShareCost,
        carePhase,
      }),
    ),
    {
      value: allPhaseInflatedProfessionalShareCost,
      subtext: (
        <>
          US Average LTC Cost: {formatCurrency(inflatedCostStatistic)}
          <sup>1</sup>
        </>
      ),
      barColor: '#ffffff',
      carePhase: null,
    },
  ];
});
interface OnboardingProjectedCostsProps {
  startWithSelectedPhase: CarePhase;
}

export function OnboardingProjectedCosts({
  startWithSelectedPhase,
}: OnboardingProjectedCostsProps) {
  const client = useSelector(selectClient)!;
  const {
    phaseCosts,
    intakeSurvey: { clientCurrentCarePhase },
  } = client;
  const [selectedCarePhase, setSelectedCarePhase] = useState(
    startWithSelectedPhase as CarePhase,
  );
  const rawChartData = memoizedCalcChartData(client);
  const navigateToPage = useNavigateToPage();

  const chartData =
    selectedCarePhase === null
      ? rawChartData
      : rawChartData.map((dataPoint, index) => ({
          isSelected: dataPoint.carePhase === selectedCarePhase,
          barColor:
            dataPoint.carePhase === selectedCarePhase
              ? undefined
              : defaultChartColors[index] + '35',
          ...dataPoint,
        }));

  const selectedCarePhaseCosts =
    phaseCosts.find(phaseCost => phaseCost.carePhase === selectedCarePhase) ??
    null;

  const navigateToSelectedCarePhasePage = useCallback(
    (carePhase: CarePhase) => {
      const page = getCareSettingsPageBy(carePhase);
      if (page) {
        navigateToPage(page, { clientId: client.clientId });
      }
    },
    [client.clientId, navigateToPage],
  );

  const handleSelectCarePhase = useCallback(
    (carePhase: CarePhase) => {
      if (
        isNullOrUndefined(carePhase) ||
        isCarePhaseInThePast(carePhase, clientCurrentCarePhase)
      ) {
        return;
      }
      setSelectedCarePhase(carePhase);
      navigateToSelectedCarePhasePage(carePhase);
    },
    [navigateToSelectedCarePhasePage],
  );

  return (
    <>
      <div className={styles.chartRow}>
        <HorizontalStackedLineChart
          className={styles.chart}
          data={chartData}
          formatter={formatCurrency}
          onClickChartSegment={(dataPoint: any) =>
            handleSelectCarePhase(dataPoint.carePhase)
          }
        />
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.editContainer}>
          <div className={styles.careEnvironmentSelect}>
            <SelectionInstructions
              selectedCarePhase={selectedCarePhase}
              clientFirstName={client.clientFirstName}
            />
            <EditForm
              client={client}
              selectedCarePhaseCosts={selectedCarePhaseCosts}
            />
          </div>
          {selectedCarePhaseCosts?.appliedCareEnvironment ===
            CareEnvironment.home && (
            <>
              <div className={styles.supportProvidersTitle}>
                {`${client.clientFirstName}'s family involvement`}
              </div>
              <SupportProviderBoxes selectedCarePhase={selectedCarePhase} />
            </>
          )}
        </div>
        <div className={styles.additionalDetails}>
          <CarePhaseDetailsAccordion
            client={client}
            selectedCarePhaseCosts={selectedCarePhaseCosts}
          />
        </div>
      </div>
    </>
  );
}

interface SelectionInstructionsProps {
  selectedCarePhase: CarePhase;
  clientFirstName: string;
}

function SelectionInstructions({
  clientFirstName,
  selectedCarePhase,
}: SelectionInstructionsProps) {
  const { index } = carePhaseDefs[selectedCarePhase];
  const selectedPhaseColor = defaultChartColors[index];

  return (
    <div className={styles.selectionInstructions}>
      {`Where will ${clientFirstName} age during `}
      <span style={{ color: selectedPhaseColor }}>
        {carePhaseDefs[selectedCarePhase].label}?
      </span>
    </div>
  );
}

interface EditFormProps {
  client: Client;
  selectedCarePhaseCosts: SinglePhaseCosts | null;
}

function EditForm({ client, selectedCarePhaseCosts }: EditFormProps) {
  const dispatch = useDispatch();

  const { carePhase, appliedCareEnvironment, recommendedCareEnvironment } =
    selectedCarePhaseCosts ?? {};

  const handleSelectCareEnvironment = useCallback(
    (event: SelectChangeEvent<CareEnvironment>) => {
      const newCareEnvironment = event.target.value;

      if (appliedCareEnvironment === newCareEnvironment) {
        return;
      }

      dispatch(
        putCareEnvironmentSelectionsForClientByClientIdRequest({
          clientId: client.clientId,
          careEnvironmentSelections: {
            ...(client.careEnvironmentSelections ?? {}),
            [selectedCarePhaseCosts!.carePhase]: newCareEnvironment,
          },
        }),
      );
    },
    [appliedCareEnvironment, client, dispatch, selectedCarePhaseCosts],
  );

  if (isNullOrUndefined(selectedCarePhaseCosts)) {
    return null;
  }

  const { index } = carePhaseDefs[selectedCarePhaseCosts.carePhase];

  const color = defaultChartColors[index];
  const predictedChipTooltipContent = (
    <div>
      {`Based on your preferences, we predict that you'll age
      ${careEnvironmentDefs[recommendedCareEnvironment!].facilityType} during this phase of your care.`}
    </div>
  );
  const predictedChip = (
    <>
      <Tooltip title={predictedChipTooltipContent}>
        <span
          className={styles.recommendedMarker}
          style={{ backgroundColor: color }}
        >
          Predicted
        </span>
      </Tooltip>
    </>
  );

  return (
    <div className={styles.editContainer}>
      <div className={styles.editInputRow}>
        <Select
          className={styles.careEnvironmentDropdown}
          value={appliedCareEnvironment}
          onChange={handleSelectCareEnvironment}
        >
          {careEnvironmentsApplicableToPhase[carePhase!].map(
            ({ dbId, label }) => (
              <MenuItem
                key={dbId}
                className={styles.careEnvironmentItem}
                value={dbId}
              >
                {label}
                {dbId === recommendedCareEnvironment ? predictedChip : null}
              </MenuItem>
            ),
          )}
        </Select>
      </div>
    </div>
  );
}
