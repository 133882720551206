import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import {
  Citations,
  OnboardingChrome,
  citations,
  LikelihoodSlideOutContent,
  LtcAtAgeSlideOutContent,
} from '../components';
import { selectClient, selectInferenceSet, selectIntakeSurvey } from '../model';
import { possessivePronoun } from '../util';
import moduleStyles from './OnboardingOurPredictions.module.css';
import mainStyles from '../styles/main.module.css';
import { SlideContent, LearnMoreSlideOut } from '../components';

import { BsFillQuestionCircleFill } from 'react-icons/bs';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};

export function OnboardingOurPredictions(_props: ClientIdProps) {
  const {
    clientFirstName,
    inferenceSet: { ltcAtAgeShowRange },
  } = useSelector(selectClient);

  return (
    <OnboardingChrome
      slidePage={OnboardingOurPredictions}
      title={''}
      nextButtonLabel={`See ${clientFirstName}'s Costs`}
    >
      <SlideContent headerText={`Our predictions for ${clientFirstName}`}>
        <div className="flex flex-col gap-12 pb-12 pt-8 lg:flex-row lg:gap-32">
          <ColumnLtcLikelihoodEver />
          <ColumnLtcAtAge />
          <ColumnLtcAgeFallBack />
        </div>
        <div className="py-8 md:py-16">
          <Citations
            citations={
              ltcAtAgeShowRange
                ? [
                    citations.onboardingOurPredictionsLikelihoodNationalAverage,
                    citations.onboardingOurPredictionsLtcAtAgeRange,
                  ]
                : [citations.onboardingOurPredictionsLikelihoodNationalAverage]
            }
          />
        </div>
      </SlideContent>
    </OnboardingChrome>
  );
}

function ColumnLtcLikelihoodEver() {
  const { clientFirstName } = useSelector(selectClient);
  const intakeSurvey = useSelector(selectIntakeSurvey);
  const { ltcLikelihoodEver } = useSelector(selectInferenceSet);
  return (
    <div className="flex-grow-1 basis-1/2 text-center">
      <div className={styles.subtitle}>
        {startCase(possessivePronoun(intakeSurvey))} likelihood of needing LTC
        <LearnMoreSlideOut
          slideOutContent={<LikelihoodSlideOutContent />}
          isModelDescription={true}
        >
          <BsFillQuestionCircleFill
            className="h-5 w-5 pb-1 pl-1 text-gray-400 hover:text-gray-500"
            aria-hidden="true"
          />
        </LearnMoreSlideOut>
      </div>
      <div className="text-7xl font-bold text-purple md:text-9xl">
        {Math.round(ltcLikelihoodEver * 100)}
        <span className={styles.percentSymbol}>%</span>
      </div>
      <div className="text-lg font-normal text-gray-700 md:text-xl">
        National Average: 52%<sup>1</sup>
      </div>
    </div>
  );
}

function ColumnLtcAtAge() {
  const { clientFirstName } = useSelector(selectClient);
  const intakeSurvey = useSelector(selectIntakeSurvey);
  const { ltcAtAge, ltcAtAgeShowRange } = useSelector(selectInferenceSet);

  if (ltcAtAgeShowRange) {
    return null;
  }

  return (
    <div className="flex-grow-1 basis-1/2 text-center">
      <div className={styles.subtitle}>
        {startCase(possessivePronoun(intakeSurvey))} care is expected to begin
        at
        <LearnMoreSlideOut
          slideOutContent={<LtcAtAgeSlideOutContent />}
          isModelDescription={true}
        >
          <BsFillQuestionCircleFill
            className="h-5 w-5 pb-1 pl-1 text-gray-400 hover:text-gray-500"
            aria-hidden="true"
          />
        </LearnMoreSlideOut>
      </div>
      <div className="text-7xl font-bold text-purple md:text-9xl">
        {ltcAtAge}
      </div>
      <div className="text-lg font-normal text-gray-700 md:text-xl">
        years old
      </div>
    </div>
  );
}

function ColumnLtcAgeFallBack() {
  const intakeSurvey = useSelector(selectIntakeSurvey);
  const { ltcAtAge, ltcAtAgeShowRange, ltcAtAgeUpperBound } =
    useSelector(selectInferenceSet);

  if (!ltcAtAgeShowRange) {
    return null;
  }

  return (
    <div className="flex-grow-1 basis-1/2 text-center">
      <div className={styles.subtitle}>
        {startCase(possessivePronoun(intakeSurvey))} care is expected to begin
        at <sup className="font-normal text-gray-600">2</sup>
      </div>
      <div className="text-7xl font-bold text-purple md:text-9xl">
        {ltcAtAge}
      </div>
    </div>
  );
}
