import { Draft } from 'immer';
import {
  CareEnvironment,
  calcFutureValue,
  isNullOrUndefined,
  calcSelfFundingTotalCost,
  calcPolicyProjectedCostCoverage,
  calcPolicyCoverageOfRemainingLtcCosts,
  calcSelfFundingProjectedCostCoverage,
  CarePhase,
  FundingPolicyType,
  calcFundingAnnuityDerivatives,
  calcSelfFundingProjectedROI,
  calcFutureValueSimpleInflation,
  calcSelfFundingTotalsOnClaim,
  carePhaseDefs,
  isCarePhasePast,
  fundingPolicyTypeDefs,
  checkPolicyCalculationTypeEquals,
} from '.';
import { cloneDeep } from 'lodash';
import { calcClientPhaseStartYears } from './clientPhaseStartYears';

export const unlimitedMaxBenefitMagicNumber = 999999999;

export function calcFundingSourceDerivatives(mutableClient: Draft<Client>) {
  const {
    intakeSurvey: { clientHasStartedLtc },
  } = mutableClient;

  const selfFundingCalculations = clientHasStartedLtc
    ? [calcSelfFundingTotalsOnClaim]
    : [
        calcSelfFundingProjectedCostCoverage,
        calcSelfFundingTotalCost,
        calcSelfFundingProjectedROI,
      ];

  const commonCalculations = [
    calcFundingAnnuityDerivatives,
    calcCombinedFundingTotalCost,
    calcCombinedProjectedCostCoverage,
    calcCombinedNonLtcPayout,
    calcCombinedTotalValue,
    calcCombinedROI,
    calcDangerZoneDerivatives,
  ];

  calcSourceDefaults(mutableClient);
  runPolicyCalculations(mutableClient);
  selfFundingCalculations.forEach(calc => calc(mutableClient));
  commonCalculations.forEach(calc => calc(mutableClient));
}

function runPolicyCalculations(mutableClient: Draft<Client>) {
  const {
    intakeSurvey: { clientHasStartedLtc },
    multipleFundingSources,
  } = mutableClient;

  const calcProjectedCostCoverageFn = clientHasStartedLtc
    ? calcPolicyCoverageOfRemainingLtcCosts
    : calcPolicyProjectedCostCoverage;

  mutableClient.policyPhaseCalculations = {};

  Object.entries(multipleFundingSources.ltcPolicy).forEach(
    ([fundingSourceId, policyFundingSource]) => {
      // initialize ltcPolicy and phaseCalculations
      mutableClient.fundingSources.ltcPolicy = cloneDeep(policyFundingSource);
      mutableClient.phaseCalculations = [];

      calcPolicyTotalCost(mutableClient);
      calcProjectedCostCoverageFn(mutableClient);
      calcPolicyProjectedROI(mutableClient);

      multipleFundingSources.ltcPolicy[fundingSourceId] =
        mutableClient.fundingSources.ltcPolicy;
      mutableClient.policyPhaseCalculations[fundingSourceId] =
        mutableClient.phaseCalculations;
    },
  );
}

export function calcDangerZoneDerivatives(mutableClient: Draft<Client>) {
  const {
    allPhaseCosts: { allPhaseInflatedProfessionalShareCost },
    intakeSurvey: {
      householdTotalAssetsValue,
      clientHasStartedLtc,
      householdLiquidAssetsAnnualGrowthRate,
      householdLiquidAssetsValue,
    },
    inferenceSet: { yearsTillLtc },
    fundingSources: {
      selfFunding: { existingAssetsValue, annualRateOfReturn },
    },
  } = mutableClient;
  /** Calculate the danger zone by taking their net worth and applying 2% compound interest on it
   *  until they reach their predicted LTC onset age. If this value is less than their predicted costs,
   *  we are in the danger zone. If this value is more than their predicted costs,
   *  we are outside of the danger zone. */
  const defaultARR = 0.02;
  const inflatedHouseholdAssetsValue = clientHasStartedLtc
    ? householdTotalAssetsValue
    : Math.max(
        calcFutureValue(householdTotalAssetsValue, defaultARR, yearsTillLtc),
        calcFutureValue(
          householdLiquidAssetsValue ?? 0,
          householdLiquidAssetsAnnualGrowthRate / 100 ?? 0,
          yearsTillLtc,
        ),
        calcFutureValue(
          existingAssetsValue ?? 0,
          annualRateOfReturn ?? 0,
          yearsTillLtc,
        ),
      );

  mutableClient.dangerZoneCalculations = {
    inflatedHouseholdAssetsValue: inflatedHouseholdAssetsValue,
    isInDangerZone:
      inflatedHouseholdAssetsValue < allPhaseInflatedProfessionalShareCost,
    // get's calculated in calcPotentialSavings
    potentialSavings: 0,
  };
}

function calcSourceDefaults(mutableClient: Draft<Client>) {
  const {
    inferenceSet: { ltcAtYear },
    intakeSurvey: {},
    fundingSources: { selfFunding },
  } = mutableClient;

  calcDetailDefaults(selfFunding as NumberInputSelfFundingSource, {
    contributionStartYear: new Date().getFullYear(),
    contributionEndYear: ltcAtYear,
  });
}

function calcDetailDefaults<T extends {}>(source: T, defaults: Partial<T>) {
  (Object.entries(defaults) as [keyof T, number][]).forEach(
    ([key, defaultValue]) =>
      (source[key] = (source[key] ?? defaultValue) as any),
  );
}

export function calculateMonthsBetweenYears(
  startYear: number,
  endYear: number,
): number {
  const currentYear: number = new Date().getFullYear();
  const monthsInYear: number = 12;

  // Check for invalid input
  if (startYear > endYear) {
    return 0;
  }

  // Calculate the difference in years
  const yearDifference: number = endYear - startYear;

  if (startYear === currentYear) {
    // If the start year is the current year, calculate months passed in the current year
    const monthsPassedInCurrentYear: number = new Date().getMonth() + 1;
    return yearDifference * monthsInYear - monthsPassedInCurrentYear;
  } else {
    // Calculate the total months based on the year difference
    const totalMonths: number = yearDifference * monthsInYear;
    return totalMonths;
  }
}

function getKeyByValue(enumObj: any, value: number): string | undefined {
  for (const key in enumObj) {
    if (enumObj[key] === value) {
      return key;
    }
  }
  return undefined; // Return undefined if no match is found
}

function capitalizeFirstLetter(inputString: string): string {
  if (inputString.length === 0) {
    return inputString; // Return the input string as is if it's empty
  }

  const firstLetter = inputString.charAt(0).toUpperCase();
  const restOfString = inputString.slice(1);

  return firstLetter + restOfString;
}

type PolicyCoverage = {
  portionOfPhaseCostBenefitEligible: number;
  maxPayoutAccordingToDailyMaximum?: number;
  phaseMonthlyCost: number;
  phaseCostPerDayNeeded: number;
  phaseCareDaysNeeded: number;
  daysWhereLessThanTwoADLs?: number;
  applicableWaitingPeriodDays?: number;
  phaseBenefitEligibleCareDays?: number;
  phaseEligibleCareDaysAfter2AdlRequirement?: number;
  phaseEligibleCareDaysAfterWaitingPeriod?: number;
  dailyBenefitAmount?: number;
  inflationProtection?: number | null;
  inflationPeriodYears?: number;
  inflatedDailyBenefitAmount?: number;
};

export function calcSinglePhasePolicyCoverage(
  mutableClient: Draft<Client>,
  appliedCareEnvironmentKey: string,
  phaseCosts: SinglePhaseCosts,
): PolicyCoverage {
  const {
    fundingSources: {
      ltcPolicy,
      ltcPolicy: { policyInflationProtection, policyPremiumStartYear },
    },
    clientPhasePredictedEndYears,
  } = mutableClient;

  const {
    phaseCareMonthsNeeded,
    phaseProfessionalShareCost,
    phaseInflatedProfessionalShareCost,
  } = phaseCosts;

  // Calculate amount of phase cost that is benefit eligible
  const daysInMonth = 30;
  const phaseCareDaysNeeded = phaseCareMonthsNeeded * daysInMonth;
  const phaseMonthlyCost =
    phaseInflatedProfessionalShareCost / phaseCareMonthsNeeded;
  const phaseCostPerDayNeeded =
    phaseInflatedProfessionalShareCost / phaseCareDaysNeeded;

  // Return 0 if cost of phase is 0
  // Should only be 0 in the case that the phase is in the past, therefore no costs
  if (phaseProfessionalShareCost === 0) {
    return {
      portionOfPhaseCostBenefitEligible: 0,
      phaseCareDaysNeeded,
      phaseMonthlyCost,
      phaseCostPerDayNeeded,
    };
  }

  // Calculate daily benefit amount and inflation protection
  const dailyBenefitAmountVar = `daily${appliedCareEnvironmentKey}BenefitAmount`;
  const dailyBenefitAmount = ltcPolicy[dailyBenefitAmountVar];
  if (dailyBenefitAmount === 0) {
    return {
      portionOfPhaseCostBenefitEligible: 0,
      phaseCareDaysNeeded,
      phaseMonthlyCost,
      phaseCostPerDayNeeded,
    };
  }

  const {
    phaseEligibleCareDays: phaseBenefitEligibleCareDays,
    daysWhereLessThanTwoADLs,
    applicableWaitingPeriodDays,
    phaseEligibleCareDaysAfter2AdlRequirement,
    phaseEligibleCareDaysAfterWaitingPeriod,
  } = handlePhaseBenefitEligibleCareDays(mutableClient, phaseCosts);
  const portionOfPhaseCostBenefitEligible =
    phaseBenefitEligibleCareDays * phaseCostPerDayNeeded;

  // no daily max benefit amount, return portion of phase cost that is benefit eligible
  if (isNullOrUndefined(dailyBenefitAmount)) {
    return {
      portionOfPhaseCostBenefitEligible,
      phaseCareDaysNeeded,
      phaseMonthlyCost,
      phaseCostPerDayNeeded,
      daysWhereLessThanTwoADLs,
      applicableWaitingPeriodDays,
      phaseEligibleCareDaysAfter2AdlRequirement,
      phaseEligibleCareDaysAfterWaitingPeriod,
    };
  }

  // Calculate inflated daily max benefit amount
  const careEnvironmentInflationProtectionVar = `daily${appliedCareEnvironmentKey}InflationProtectionPercent`;
  const inflationProtection = ltcPolicy[careEnvironmentInflationProtectionVar]
    ? ltcPolicy[careEnvironmentInflationProtectionVar]
    : policyInflationProtection;
  const inflationPeriodYears = Math.max(
    clientPhasePredictedEndYears[phaseCosts.carePhase]! -
      policyPremiumStartYear!,
    0,
  );

  // If daily benefit exists for care environment, then get inflated daily benefit amount times the number of care days.
  // return minimum of that and remaining max benefit
  const inflatedDailyBenefitAmount = calcInflationProtectionFutureValue(
    mutableClient,
    dailyBenefitAmount,
    inflationPeriodYears,
    inflationProtection ?? 0,
  );

  const maxPayoutAccordingToDailyMaximum =
    inflatedDailyBenefitAmount * phaseBenefitEligibleCareDays;
  return {
    maxPayoutAccordingToDailyMaximum,
    portionOfPhaseCostBenefitEligible,
    phaseMonthlyCost,
    phaseCostPerDayNeeded,
    phaseCareDaysNeeded,
    phaseBenefitEligibleCareDays,
    daysWhereLessThanTwoADLs,
    applicableWaitingPeriodDays,
    phaseEligibleCareDaysAfter2AdlRequirement,
    phaseEligibleCareDaysAfterWaitingPeriod,
    inflationProtection,
    inflationPeriodYears,
    inflatedDailyBenefitAmount,
    dailyBenefitAmount,
  };
}

function handlePhaseBenefitEligibleCareDays(
  mutableClient: Draft<Client>,
  phaseCosts: SinglePhaseCosts,
) {
  const {
    intakeSurvey: { clientAdlReceivedAssistanceCount },
    fundingSources: {
      ltcPolicy: { policyBenefitPeriodMonthsRemaining, policyType },
      ltcPolicy,
    },
  } = mutableClient;
  const { phaseCareMonthsNeeded } = phaseCosts;

  const daysInMonth = 30;
  const oneAdlDurationMultiplier = 0.5;
  const phaseCareDaysNeeded = phaseCareMonthsNeeded * daysInMonth;

  const policyBenefitPeriodDaysRemaining =
    (policyBenefitPeriodMonthsRemaining ?? 0) * daysInMonth;

  const policyActivatesAtADLCount = 2;
  const daysWhereLessThanTwoADLs =
    phaseCosts.carePhase === CarePhase.earlyCare &&
    clientAdlReceivedAssistanceCount < policyActivatesAtADLCount
      ? phaseCareDaysNeeded * oneAdlDurationMultiplier
      : 0;
  const phaseEligibleCareDaysAfter2AdlRequirement =
    phaseCareDaysNeeded - daysWhereLessThanTwoADLs;

  const applicableWaitingPeriodDays =
    calcApplicableWaitingPeriodDaysAndDecrement(
      mutableClient,
      phaseCosts,
      phaseEligibleCareDaysAfter2AdlRequirement,
    );

  if (
    checkPolicyCalculationTypeEquals(
      policyType,
      FundingPolicyType.shortTermCareInsurance,
    )
  ) {
    const phaseEligibleCareDays = Math.min(
      phaseCareDaysNeeded -
        (daysWhereLessThanTwoADLs + applicableWaitingPeriodDays),
      policyBenefitPeriodDaysRemaining,
    );
    ltcPolicy.policyBenefitPeriodMonthsRemaining! -=
      phaseEligibleCareDays / daysInMonth;

    const phaseEligibleCareDaysAfterWaitingPeriod = Math.min(
      phaseEligibleCareDays,
      phaseEligibleCareDaysAfter2AdlRequirement - applicableWaitingPeriodDays,
    );
    return {
      phaseEligibleCareDays,
      daysWhereLessThanTwoADLs,
      applicableWaitingPeriodDays,
      phaseEligibleCareDaysAfter2AdlRequirement,
      phaseEligibleCareDaysAfterWaitingPeriod,
    };
  }
  const phaseEligibleCareDays =
    phaseCareDaysNeeded -
    (daysWhereLessThanTwoADLs + applicableWaitingPeriodDays);

  const phaseEligibleCareDaysAfterWaitingPeriod =
    phaseEligibleCareDaysAfter2AdlRequirement - applicableWaitingPeriodDays;

  return {
    phaseEligibleCareDays,
    daysWhereLessThanTwoADLs,
    applicableWaitingPeriodDays,
    phaseEligibleCareDaysAfter2AdlRequirement,
    phaseEligibleCareDaysAfterWaitingPeriod,
  };
}

function calcApplicableWaitingPeriodDaysAndDecrement(
  client: Draft<Client>,
  phaseCost: SinglePhaseCosts,
  phaseDaysNeeded: number,
): number {
  const {
    fundingSources: {
      ltcPolicy,
      ltcPolicy: {
        homeCareWaitingPeriodDaysRemaining,
        facilityCareWaitingPeriodDaysRemaining,
      },
    },
    careEnvironmentSelections,
    appliedCareEnvironments,
  } = client;

  const selectedCareSetting = careEnvironmentSelections?.[phaseCost.carePhase]
    ? careEnvironmentSelections[phaseCost.carePhase]
    : appliedCareEnvironments[phaseCost.carePhase];

  // calculate cost of waiting period based on care setting and corresponding waiting period
  if (
    selectedCareSetting === CareEnvironment.home &&
    homeCareWaitingPeriodDaysRemaining
  ) {
    const applicableWaitingDays = Math.min(
      homeCareWaitingPeriodDaysRemaining,
      phaseDaysNeeded,
    );

    ltcPolicy.homeCareWaitingPeriodDaysRemaining! -= applicableWaitingDays;
    // Decrement remaining waiting period days for facility as well. Waiting periods are not independent
    if (facilityCareWaitingPeriodDaysRemaining) {
      ltcPolicy.facilityCareWaitingPeriodDaysRemaining = Math.max(
        0,
        facilityCareWaitingPeriodDaysRemaining - applicableWaitingDays,
      );
    }
    return applicableWaitingDays;
  }

  if (
    selectedCareSetting !== CareEnvironment.home &&
    facilityCareWaitingPeriodDaysRemaining
  ) {
    const applicableWaitingDays = Math.min(
      facilityCareWaitingPeriodDaysRemaining,
      phaseDaysNeeded,
    );
    ltcPolicy.facilityCareWaitingPeriodDaysRemaining! -= applicableWaitingDays;
    // Decrement remaining waiting period days for home as well. Waiting periods are not independent
    if (homeCareWaitingPeriodDaysRemaining) {
      ltcPolicy.homeCareWaitingPeriodDaysRemaining = Math.max(
        0,
        homeCareWaitingPeriodDaysRemaining - applicableWaitingDays,
      );
    }
    return applicableWaitingDays;
  }
  return 0;
}

export function setCoverageToUndefined<
  T extends keyof CalculatedPolicyFundingSource,
>(mutableClient: Draft<Client>, coverageType: T): void {
  mutableClient.fundingSources.ltcPolicy[coverageType] = undefined;
}

export function getAppliedCareEnvironmentKeys(
  mutableClient: Draft<Client>,
): string[] {
  const { appliedCareEnvironments } = mutableClient;

  return Object.values(appliedCareEnvironments)
    .map(value => getKeyByValue(CareEnvironment, value))
    .filter(key => key !== undefined) // Filter out undefined values
    .map(key => capitalizeFirstLetter(key!)); // Use non-null assertion operator
}

function sumPropertyForAllPolicies(
  mutableClient: Draft<Client>,
  property: keyof PolicyFundingSource,
): number | undefined {
  const { multipleFundingSources } = mutableClient;

  // check if all values are undefined
  if (
    Object.values(multipleFundingSources.ltcPolicy).every(
      policy => policy[property] === undefined,
    )
  ) {
    return undefined;
  }

  return Object.values(multipleFundingSources.ltcPolicy).reduce(
    (acc, policy) => {
      return acc + (policy[property] ?? 0);
    },
    0,
  );
}

export function calcCombinedFundingTotalCost(mutableClient: Draft<Client>) {
  const {
    fundingSources,
    fundingSources: {
      selfFunding: { selfFundingTotalCost },
      annuity: { annuityTotalCost },
    },
  } = mutableClient;

  const policyPolicyTotalCost = sumPropertyForAllPolicies(
    mutableClient,
    'policyPolicyTotalCost',
  );

  if (
    isNullOrUndefined(selfFundingTotalCost) &&
    isNullOrUndefined(policyPolicyTotalCost) &&
    isNullOrUndefined(annuityTotalCost)
  ) {
    fundingSources.combinedFundingTotalCost = undefined;
    return;
  }

  fundingSources.combinedFundingTotalCost =
    (selfFundingTotalCost ?? 0) +
    (policyPolicyTotalCost ?? 0) +
    (annuityTotalCost ?? 0);
}

function calcCombinedNonLtcPayout(mutableClient: Draft<Client>) {
  const {
    fundingSources,
    fundingSources: {
      selfFunding: { selfFundingNonLtcPayout },
      annuity: { annuityNonLtcPayout },
    },
  } = mutableClient;

  const policyNonLtcPayout = sumPropertyForAllPolicies(
    mutableClient,
    'policyNonLtcPayout',
  );

  fundingSources.combinedNonLtcPayout =
    (selfFundingNonLtcPayout ?? 0) +
    (annuityNonLtcPayout ?? 0) +
    (policyNonLtcPayout ?? 0);
}

export function roundDownToNearestHundredth(value: number): number {
  return Math.floor(value * 100) / 100;
}

export function calcCombinedProjectedCostCoverage(
  mutableClient: Draft<Client>,
) {
  const {
    intakeSurvey: { clientHasStartedLtc },
    allPhaseCosts: { allPhaseInflatedProfessionalShareCost },
    fundingSources,
    fundingSources: {
      selfFunding: { selfFundingProjectedCostCoverage },
      annuity: { annuityProjectedCostCoverage },
    },
  } = mutableClient;

  const projectedCostCoverageKey = clientHasStartedLtc
    ? 'policyCoverageOfRemainingLtcCosts'
    : 'projectedCostCoverage';

  const projectedCostCoverage = sumPropertyForAllPolicies(
    mutableClient,
    projectedCostCoverageKey,
  );

  if (
    isNullOrUndefined(selfFundingProjectedCostCoverage) &&
    isNullOrUndefined(projectedCostCoverage) &&
    isNullOrUndefined(allPhaseInflatedProfessionalShareCost)
  ) {
    fundingSources.combinedProjectedCostCoverage = undefined;
    fundingSources.combinedProjectedCostCoveragePercent = undefined;
    return;
  }

  fundingSources.combinedProjectedCostCoverage =
    (selfFundingProjectedCostCoverage ?? 0) +
    (projectedCostCoverage ?? 0) +
    (annuityProjectedCostCoverage ?? 0);

  fundingSources.combinedProjectedCostCoveragePercent =
    allPhaseInflatedProfessionalShareCost > 0
      ? roundDownToNearestHundredth(
          (fundingSources.combinedProjectedCostCoverage ?? 0) /
            allPhaseInflatedProfessionalShareCost,
        )
      : 1;
}

export function calcPolicyInflatedMaxBenefitToEndOfPhase(
  client: Client,
  carePhase: CarePhase,
  policyPremiumStartYear: number,
  policyMaximumBenefitAmount: number,
) {
  const {
    clientPhasePredictedEndYears,
    fundingSources: {},
  } = client;

  if (isCarePhasePast(carePhase, client)) {
    return policyMaximumBenefitAmount;
  }

  const inflationPeriod = Math.max(
    clientPhasePredictedEndYears[carePhase]! - policyPremiumStartYear,
    0,
  );
  client.fundingSources.ltcPolicy.policyInflationPeriodYears = inflationPeriod;
  return calcInflationProtectionFutureValue(
    client,
    policyMaximumBenefitAmount,
    inflationPeriod,
  );
}

function calcInflationProtectionFutureValue(
  client: Client,
  principle: number,
  inflationPeriodYears: number,
  inflationRate?: number,
) {
  const {
    fundingSources: {
      ltcPolicy: { policyInflationProtection, isSimpleInflationProtection },
    },
  } = client;
  const effectiveInflationRate = inflationRate ?? policyInflationProtection;
  return isSimpleInflationProtection
    ? calcFutureValueSimpleInflation(
        principle,
        inflationPeriodYears,
        effectiveInflationRate ?? 0,
      )
    : calcFutureValue(
        principle,
        effectiveInflationRate ?? 0,
        inflationPeriodYears,
      );
}

export function calcPolicyCoverageImpl(
  mutableClient: Draft<Client>,
  coverageType: string,
): void {
  const {
    fundingSources: {
      ltcPolicy: { policyType },
      ltcPolicy,
    },
    phaseCosts,
    mutableClientPartner,
  } = mutableClient;

  const appliedCareEnvironmentKeys =
    getAppliedCareEnvironmentKeys(mutableClient);

  let policyDataAccumulator:
    | PolicyDataAccumulator
    | JointPolicyDataAccumulator = {
    indemnitySurplus: 0,
    phaseCalculations: [],
    projectedCostCoverage: 0,
    cumulativePolicyPayOut: 0, // may be different than projected cost coverage for indemnity policies
  };

  if (isJointPolicy(policyType!, mutableClientPartner)) {
    copyJointPolicyToClientPartner(mutableClient);
    const combinedSortedPhaseCosts =
      combineSortClientPartnerPhaseCosts(mutableClient);

    policyDataAccumulator = {
      ...policyDataAccumulator,
      partnerPhaseCalculations: [],
      partnerProjectedCostCoverage: 0,
    };

    combinedSortedPhaseCosts.forEach(clientSinglePhaseCost => {
      const phaseIndex =
        carePhaseDefs[clientSinglePhaseCost.phaseCosts.carePhase].index;
      processSinglePhase<JointPolicyDataAccumulator>(
        mutableClient.clientId,
        clientSinglePhaseCost.client,
        policyDataAccumulator as JointPolicyDataAccumulator,
        appliedCareEnvironmentKeys[phaseIndex],
        clientSinglePhaseCost.phaseCosts,
      );
    });
  } else {
    phaseCosts.forEach((singePhaseCosts, index) => {
      processSinglePhase<PolicyDataAccumulator>(
        mutableClient.clientId,
        mutableClient,
        policyDataAccumulator,
        appliedCareEnvironmentKeys[index],
        singePhaseCosts,
      );
    });
  }

  calcPolicySummaryStats(mutableClient, policyDataAccumulator);

  ltcPolicy[coverageType] = policyDataAccumulator.projectedCostCoverage;
  const lastPhaseCalculations =
    policyDataAccumulator.phaseCalculations[
      policyDataAccumulator.phaseCalculations.length - 1
    ];
  ltcPolicy.policyRemainingBenefitAmount =
    lastPhaseCalculations.remainingMaxBenefitAfterPhaseReduction ?? 0;
  ltcPolicy.indemnitySurplus = policyDataAccumulator.indemnitySurplus;
  mutableClient.phaseCalculations = policyDataAccumulator.phaseCalculations;

  if (
    isJointPolicy(policyType!, mutableClientPartner) &&
    isJointPolicyDataAccumulator(policyDataAccumulator)
  ) {
    mutableClientPartner.phaseCalculations =
      policyDataAccumulator.partnerPhaseCalculations;
    mutableClientPartner.fundingSources.ltcPolicy[coverageType] =
      policyDataAccumulator.partnerProjectedCostCoverage;
  }
}

export function isJointPolicy(
  policyType: FundingPolicyType | null,
  mutableClientPartner: Client | null,
): mutableClientPartner is NonNullable<Client> {
  if (!policyType || !mutableClientPartner) {
    return false;
  }
  return fundingPolicyTypeDefs[policyType].isJoint === true;
}

type PolicyDataAccumulatorBase = {
  indemnitySurplus: number;
  phaseCalculations: SinglePhaseCalculations[];
  projectedCostCoverage: number;
  cumulativePolicyPayOut: number;
};

type PolicyDataAccumulator = PolicyDataAccumulatorBase;

type JointPolicyDataAccumulator = PolicyDataAccumulatorBase & {
  partnerPhaseCalculations: SinglePhaseCalculations[];
  partnerProjectedCostCoverage: number;
};

function isJointPolicyDataAccumulator(
  accumulator: PolicyDataAccumulatorBase,
): accumulator is JointPolicyDataAccumulator {
  return (
    (accumulator as JointPolicyDataAccumulator).partnerPhaseCalculations !==
    undefined
  );
}

function processSinglePhase<AccumlatorType extends PolicyDataAccumulatorBase>(
  sessionClientId: string,
  mutableClient: Draft<Client>,
  policyDataAccumulator: AccumlatorType,
  appliedCareEnvironmentKey: string,
  phaseCosts: SinglePhaseCosts,
): void {
  const {
    fundingSources: {
      ltcPolicy: { isIndemnityPolicyPayment, policyPremiumStartYear },
      ltcPolicy,
    },
  } = mutableClient;

  // is the client being processed the session client (if false, it is the parter client)
  const isClientSessionClient = sessionClientId === mutableClient.clientId;

  let { indemnitySurplus, cumulativePolicyPayOut } = policyDataAccumulator;
  if (isNullOrUndefined(policyPremiumStartYear)) {
    throw new Error('Policy premium start year is not defined');
  }

  const inflatedMaxBenefitAmount = calcPolicyTotalLtcLimitForPhase(
    mutableClient,
    phaseCosts.carePhase,
    unlimitedMaxBenefitMagicNumber,
    policyPremiumStartYear,
  );

  const remainingMaxBenefitBeforePhaseReduction =
    inflatedMaxBenefitAmount -
    cumulativePolicyPayOut -
    (ltcPolicy.policyBenefitUtilizedToDate ?? 0);

  const phaseIndex = carePhaseDefs[phaseCosts.carePhase].index;

  let costCoverageAddedToTotal;
  const phaseHomeCareWaitingPeriodDaysRemaining =
    ltcPolicy.homeCareWaitingPeriodDaysRemaining ?? 0;
  const phaseFacilityCareWaitingPeriodDaysRemaining =
    ltcPolicy.facilityCareWaitingPeriodDaysRemaining ?? 0;

  const {
    portionOfPhaseCostBenefitEligible,
    maxPayoutAccordingToDailyMaximum,
    phaseMonthlyCost,
    phaseCostPerDayNeeded,
    phaseCareDaysNeeded,
    daysWhereLessThanTwoADLs,
    applicableWaitingPeriodDays,
    phaseEligibleCareDaysAfter2AdlRequirement,
    phaseEligibleCareDaysAfterWaitingPeriod,
    dailyBenefitAmount,
    inflationProtection,
    inflationPeriodYears,
    inflatedDailyBenefitAmount,
  } = calcSinglePhasePolicyCoverage(
    mutableClient,
    appliedCareEnvironmentKey,
    phaseCosts,
  );

  // coverage for phase cost is capped by whichever is less
  // 1) the portion of the phase cost that is benefit eligible
  // 2) the remaining max benefit
  // 3) the max payout according to daily maximum, if applicable
  const phaseCostCoverage = Math.min(
    portionOfPhaseCostBenefitEligible,
    remainingMaxBenefitBeforePhaseReduction,
    maxPayoutAccordingToDailyMaximum ?? Infinity,
  );
  let remainingMaxBenefitAfterPhaseReduction;

  // handle updates for reimbursement policy
  const isReimbursementPolicyPayment = !isIndemnityPolicyPayment;
  if (isReimbursementPolicyPayment) {
    // handle reimbursement policy
    costCoverageAddedToTotal = phaseCostCoverage;

    updateProjectedCostCoverage(
      policyDataAccumulator,
      isClientSessionClient,
      phaseCostCoverage,
    );
    // update remaining max benefit
    policyDataAccumulator.cumulativePolicyPayOut += phaseCostCoverage;
    remainingMaxBenefitAfterPhaseReduction = Math.max(
      remainingMaxBenefitBeforePhaseReduction - phaseCostCoverage,
      0,
    );
  }

  let surplusAppliedToCoverage;
  let indemnitySurplusForPhase;

  // handle updates for indemnity policy
  if (isIndemnityPolicyPayment) {
    // calculate indemnity payout
    const indemnityPayout = maxPayoutAccordingToDailyMaximum
      ? Math.min(
          maxPayoutAccordingToDailyMaximum,
          remainingMaxBenefitBeforePhaseReduction,
        )
      : phaseCostCoverage; // there should always be daily max, but just in case

    // handle coverage update when there is surplus (funds leftover from previous phases)
    if (
      indemnitySurplus > 0 &&
      phaseCostCoverage < portionOfPhaseCostBenefitEligible
    ) {
      // if coverage is less than the benefit eligible cost, apply surplus to coverage
      const remainingCostToCover =
        portionOfPhaseCostBenefitEligible - phaseCostCoverage;
      surplusAppliedToCoverage = Math.min(
        indemnitySurplus,
        remainingCostToCover,
      );
      costCoverageAddedToTotal = phaseCostCoverage + surplusAppliedToCoverage;
      updateProjectedCostCoverage(
        policyDataAccumulator,
        isClientSessionClient,
        costCoverageAddedToTotal,
      );
      policyDataAccumulator.indemnitySurplus -= surplusAppliedToCoverage;
    } else {
      // if no surplus or phase cost is greater than the benefit eligible cost, update coverage normally
      costCoverageAddedToTotal = phaseCostCoverage;
      updateProjectedCostCoverage(
        policyDataAccumulator,
        isClientSessionClient,
        costCoverageAddedToTotal,
      );
      indemnitySurplusForPhase = Math.max(
        indemnityPayout - phaseCostCoverage,
        0,
      ); // should not be negative but just in case
      policyDataAccumulator.indemnitySurplus += indemnitySurplusForPhase;
    }
    policyDataAccumulator.cumulativePolicyPayOut += indemnityPayout;
    remainingMaxBenefitAfterPhaseReduction = Math.max(
      remainingMaxBenefitBeforePhaseReduction - indemnityPayout,
      0,
    );
  }

  // update phase calculations for whitebox explanations
  const phaseCalculations = {
    phase: phaseCosts.carePhase,
    phaseMonthlyCost: phaseMonthlyCost ?? 0,
    phaseCostPerDayNeeded: phaseCostPerDayNeeded ?? 0,
    phaseCareDaysNeeded: phaseCareDaysNeeded ?? 0,
    daysWhereLessThanTwoADLs: daysWhereLessThanTwoADLs ?? 0,
    applicableWaitingPeriodDays: applicableWaitingPeriodDays ?? 0,
    phaseEligibleCareDaysAfter2AdlRequirement:
      phaseEligibleCareDaysAfter2AdlRequirement ?? 0,
    phaseEligibleCareDaysAfterWaitingPeriod:
      phaseEligibleCareDaysAfterWaitingPeriod ?? 0,
    phaseFacilityCareWaitingPeriodDaysRemaining:
      phaseFacilityCareWaitingPeriodDaysRemaining ?? 0,
    phaseHomeCareWaitingPeriodDaysRemaining:
      phaseHomeCareWaitingPeriodDaysRemaining ?? 0,
    dailyBenefitAmount: dailyBenefitAmount ?? 0,
    inflationProtection: inflationProtection ?? 0,
    inflationPeriodYears: inflationPeriodYears ?? 0,
    inflatedDailyBenefitAmount: inflatedDailyBenefitAmount ?? 0,
    inflatedMaxBenefitAmount: inflatedMaxBenefitAmount ?? 0,
    maxPayoutAccordingToDailyMaximum: maxPayoutAccordingToDailyMaximum ?? 0,
    surplusAppliedToCoverage: surplusAppliedToCoverage ?? 0,
    indemnitySurplusForPhase: indemnitySurplusForPhase ?? 0,
    phaseCostCoverage: phaseCostCoverage ?? 0,
    costCoverageAddedToTotal: costCoverageAddedToTotal ?? 0,
    portionOfPhaseCostBenefitEligible: portionOfPhaseCostBenefitEligible ?? 0,
    remainingMaxBenefitBeforePhaseReduction:
      remainingMaxBenefitBeforePhaseReduction ?? 0,
    remainingMaxBenefitAfterPhaseReduction:
      remainingMaxBenefitAfterPhaseReduction ?? 0,
    cumulativePolicyPayOut: cumulativePolicyPayOut ?? 0,
  };

  updatePhaseCalculations(
    policyDataAccumulator,
    isClientSessionClient,
    phaseIndex,
    phaseCalculations,
  );
}

function updateProjectedCostCoverage(
  policyDataAccumulator: PolicyDataAccumulatorBase,
  isClientSessionClient: boolean,
  coverageToAdd: number,
): void {
  if (isClientSessionClient) {
    policyDataAccumulator.projectedCostCoverage += coverageToAdd;
  } else if (isJointPolicyDataAccumulator(policyDataAccumulator)) {
    policyDataAccumulator.partnerProjectedCostCoverage += coverageToAdd;
  } else {
    throw new Error(
      'Accumulator is not a JointPolicyDataAccumulator when expected',
    );
  }
}

function updatePhaseCalculations(
  policyDataAccumulator: PolicyDataAccumulatorBase,
  isClientSessionClient: boolean,
  phaseIndex: number,
  phaseCalculations: SinglePhaseCalculations,
): void {
  if (isClientSessionClient) {
    policyDataAccumulator.phaseCalculations[phaseIndex] = phaseCalculations;
  } else if (isJointPolicyDataAccumulator(policyDataAccumulator)) {
    policyDataAccumulator.partnerPhaseCalculations[phaseIndex] =
      phaseCalculations;
  } else {
    throw new Error(
      'Accumulator is not a JointPolicyDataAccumulator when expected',
    );
  }
}

export function calcPolicySummaryStats(
  mutableClient: Draft<Client>,
  policyDataAccumulator: PolicyDataAccumulator | JointPolicyDataAccumulator,
) {
  const {
    fundingSources: {
      ltcPolicy: {
        policyType,
        isIndemnityPolicyPayment,
        policyPremiumStartYear,
        policyMaximumBenefitAmount,
        policyMinimumGuaranteedBenefitAmount,
        policyBenefitIsUnlimited,
      },
      ltcPolicy,
    },
  } = mutableClient;

  const { projectedCostCoverage, indemnitySurplus } = policyDataAccumulator;
  const totalProjectedCostCoverage = isJointPolicyDataAccumulator(
    policyDataAccumulator,
  )
    ? projectedCostCoverage + policyDataAccumulator.partnerProjectedCostCoverage
    : projectedCostCoverage;

  // For now, only traditional ltci, stci, annuity hybrids, the total value is the projected coverage amount
  if (
    checkPolicyCalculationTypeEquals(policyType, [
      FundingPolicyType.longTermCareInsurance,
      FundingPolicyType.shortTermCareInsurance,
      FundingPolicyType.annuityHybrid,
    ])
  ) {
    ltcPolicy.policyNonLtcPayout = isIndemnityPolicyPayment
      ? indemnitySurplus
      : 0;
    ltcPolicy.policyTotalValue =
      totalProjectedCostCoverage +
      (isIndemnityPolicyPayment ? indemnitySurplus : 0);
  }

  // For other policy types, the policy total value is the maximum benefit amount assuming that
  // whatever is leftover will be paid out in some form of death benefit to beneficiaries
  if (
    checkPolicyCalculationTypeEquals(policyType, [
      FundingPolicyType.hybridLifeInsurance,
      FundingPolicyType.lifeInsuranceWithRider,
    ])
  ) {
    ltcPolicy.policyInflatedDeathBenefitAmount =
      calcPolicyInflatedMaxBenefitToEndOfPhase(
        mutableClient,
        CarePhase.fullCare,
        policyPremiumStartYear!,
        policyMaximumBenefitAmount ?? 0,
      );

    // unlimited policy
    if (policyBenefitIsUnlimited) {
      ltcPolicy.policyTotalValue =
        totalProjectedCostCoverage +
        (policyMinimumGuaranteedBenefitAmount ?? 0) +
        indemnitySurplus;
    } else if (
      // coverage is greater than death benefit
      totalProjectedCostCoverage >= ltcPolicy.policyInflatedDeathBenefitAmount
    ) {
      ltcPolicy.policyTotalValue =
        totalProjectedCostCoverage +
        (policyMinimumGuaranteedBenefitAmount ?? 0) +
        indemnitySurplus;
    } else {
      // coverage is less than death benefit amount
      const policyCoveragePlusIndemnitySurplus =
        totalProjectedCostCoverage + indemnitySurplus; // how much the policy has paid out so far
      ltcPolicy.policyTotalValue =
        totalProjectedCostCoverage +
        Math.max(
          policyMinimumGuaranteedBenefitAmount ?? 0,
          ltcPolicy.policyInflatedDeathBenefitAmount -
            policyCoveragePlusIndemnitySurplus,
          0,
        ) +
        indemnitySurplus;
    }
    // calculate non ltc payout.
    // simplifying assumption: assuming that this is the max benefit minus the projected coverage for hybrid and life with rider
    ltcPolicy.policyNonLtcPayout =
      (ltcPolicy.policyTotalValue ?? 0) - totalProjectedCostCoverage;
  }
}

function calcPolicyTotalLtcLimitForPhase(
  mutableClient: Draft<Client>,
  carePhase: CarePhase,
  unlimitedMaxBenefitMagicNumber: number,
  policyPremiumStartYear: number,
) {
  const {
    fundingSources: {
      ltcPolicy,
      ltcPolicy: {
        policyMaximumBenefitAmount,
        policyContinuationBenefitAmount,
        policyType,
        policyInflationProtection,
      },
    },
  } = mutableClient;

  if (
    checkPolicyCalculationTypeEquals(
      policyType,
      FundingPolicyType.hybridLifeInsurance,
    )
  ) {
    if (isNullOrUndefined(policyContinuationBenefitAmount)) {
      ltcPolicy.policyBenefitIsUnlimited = true;
      return unlimitedMaxBenefitMagicNumber;
    }
    const inflatedContinuationBenefitAmount =
      calcPolicyInflatedMaxBenefitToEndOfPhase(
        mutableClient,
        carePhase,
        policyPremiumStartYear,
        policyContinuationBenefitAmount,
      );
    const inflatedDeathBenefitAmount = calcPolicyInflatedMaxBenefitToEndOfPhase(
      mutableClient,
      carePhase,
      policyPremiumStartYear,
      policyMaximumBenefitAmount ?? 0,
    );
    return inflatedContinuationBenefitAmount + inflatedDeathBenefitAmount;
  }

  if (isNullOrUndefined(policyMaximumBenefitAmount)) {
    ltcPolicy.policyBenefitIsUnlimited = true;
    return unlimitedMaxBenefitMagicNumber;
  }

  return policyInflationProtection
    ? calcPolicyInflatedMaxBenefitToEndOfPhase(
        mutableClient,
        carePhase,
        policyPremiumStartYear,
        policyMaximumBenefitAmount,
      )
    : policyMaximumBenefitAmount;
}

export function initializeRemainingFields(mutableClient: Draft<Client>) {
  const {
    fundingSources: { ltcPolicy },
  } = mutableClient;

  ltcPolicy.homeCareWaitingPeriodDaysRemaining =
    ltcPolicy.homeCareWaitingPeriodDaysRemaining ??
    ltcPolicy.homeCareWaitingPeriodDays;
  ltcPolicy.facilityCareWaitingPeriodDaysRemaining =
    ltcPolicy.facilityCareWaitingPeriodDaysRemaining ??
    ltcPolicy.facilityCareWaitingPeriodDays;
  ltcPolicy.policyBenefitPeriodMonthsRemaining =
    ltcPolicy.policyBenefitPeriodMonthsRemaining ??
    ltcPolicy.policyBenefitPeriodMonths;
}

export function calcCombinedTotalValue(mutableClient: Draft<Client>) {
  const {
    fundingSources,
    fundingSources: {
      selfFunding: { selfFundingTotalValue },
      annuity: { annuityTotalValue },
    },
  } = mutableClient;

  const policyTotalValue = sumPropertyForAllPolicies(
    mutableClient,
    'policyTotalValue',
  );

  fundingSources.combinedTotalValue =
    (selfFundingTotalValue ?? 0) +
    (policyTotalValue ?? 0) +
    (annuityTotalValue ?? 0);
}

function calcPolicyProjectedROI(mutableClient: Draft<Client>) {
  const {
    fundingSources: {
      ltcPolicy: {
        policyPolicyTotalCost,
        policyCoverageOfRemainingLtcCosts,
        projectedCostCoverage,
      },
      ltcPolicy,
    },
    intakeSurvey: { clientHasStartedLtc },
  } = mutableClient;

  const coverageAmount = clientHasStartedLtc
    ? policyCoverageOfRemainingLtcCosts
    : projectedCostCoverage;

  if (
    isNullOrUndefined(coverageAmount) ||
    isNullOrUndefined(policyPolicyTotalCost)
  ) {
    return;
  }

  // calculate projected roi
  mutableClient.fundingSources.ltcPolicy.policyProjectedROI =
    (ltcPolicy.policyTotalValue! - policyPolicyTotalCost) /
    policyPolicyTotalCost;
}

export function calcCombinedROI(mutableClient: Draft<Client>) {
  const { combinedTotalValue, combinedFundingTotalCost } =
    mutableClient.fundingSources;
  const { fundingSources } = mutableClient;

  if (!combinedFundingTotalCost || !combinedTotalValue) {
    return;
  }
  fundingSources.combinedProjectedROI =
    (combinedTotalValue - combinedFundingTotalCost) / combinedFundingTotalCost;
}

export function calcPolicyTotalCost(mutableClient: Draft<Client>) {
  const {
    fundingSources: {
      ltcPolicy,
      ltcPolicy: {
        policyPremiumMonthlyCost,
        policyPremiumStartYear,
        policyLimitedPayYears,
      },
    },
    inferenceSet: { ltcAtYear },
  } = mutableClient;

  if (isNullOrUndefined(policyPremiumStartYear)) {
    ltcPolicy.policyPolicyTotalCost = 0;
    return;
  }

  const premiumInvestmentPeriodMonths = policyLimitedPayYears
    ? policyLimitedPayYears * 12
    : calculateMonthsBetweenYears(policyPremiumStartYear, ltcAtYear);

  ltcPolicy.premiumInvestmentPeriodMonths = premiumInvestmentPeriodMonths;

  ltcPolicy.monthlyPremiumTotalCost = Math.max(
    premiumInvestmentPeriodMonths * (policyPremiumMonthlyCost ?? 0),
    0,
  );

  ltcPolicy.policyPolicyTotalCost =
    ltcPolicy.monthlyPremiumTotalCost +
    (isNullOrUndefined(ltcPolicy.policyLumpSumPayment) ||
    ltcPolicy.policyLumpSumPayment < 0
      ? 0
      : ltcPolicy.policyLumpSumPayment);
}

function copyJointPolicyToClientPartner(mutableClient: Draft<Client>): void {
  const {
    fundingSources: { ltcPolicy },
    mutableClientPartner,
  } = mutableClient;

  if (!isJointPolicy(ltcPolicy.policyType, mutableClientPartner)) {
    return;
  }
  const ltcPolicyCopy = cloneDeep(ltcPolicy);
  mutableClientPartner.fundingSources.ltcPolicy = ltcPolicyCopy;
}

type ClientSinglePhaseCosts = {
  client: Client;
  phaseStartYear: number | null;
  phaseCosts: SinglePhaseCosts;
};

export function combineSortClientPartnerPhaseCosts(
  mutableClient: Draft<Client>,
): ClientSinglePhaseCosts[] {
  const { phaseCosts } = mutableClient;

  if (!mutableClient.mutableClientPartner) {
    return [];
  }
  // extract partner data
  const mutableClientPartner = mutableClient.mutableClientPartner;
  const partnerPhaseCosts = mutableClientPartner.phaseCosts;

  // phase start years
  const clientPhaseStartYears = calcClientPhaseStartYears(mutableClient);
  const partnerPhaseStartYears =
    calcClientPhaseStartYears(mutableClientPartner);

  // order client and partner phases chronologically
  const combinedPhaseCosts: Array<ClientSinglePhaseCosts> = [
    ...phaseCosts.map(pc => ({
      client: mutableClient,
      phaseStartYear: clientPhaseStartYears[pc.carePhase],
      phaseCosts: pc,
    })),
    ...partnerPhaseCosts.map(pc => ({
      client: mutableClientPartner,
      phaseStartYear: partnerPhaseStartYears[pc.carePhase],
      phaseCosts: pc,
    })),
  ];

  combinedPhaseCosts.sort((a, b) => {
    const startYearA = a.phaseStartYear ?? Infinity;
    const startYearB = b.phaseStartYear ?? Infinity;

    return startYearA - startYearB;
  });

  return combinedPhaseCosts.filter(
    clientPhaseCost =>
      !isCarePhasePast(
        clientPhaseCost.phaseCosts.carePhase,
        clientPhaseCost.client,
      ),
  );
}
