import { useSelector } from 'react-redux';
import { selectClient } from '../model';
import {
  carePhaseDefs,
  formatCurrency,
  formatInteger,
  formatThousands,
  formatPercent,
  fundingPolicyTypeDefs,
  isNullOrUndefined,
  combineSortClientPartnerPhaseCosts,
  isJointPolicy,
  checkPolicyCalculationTypeEquals,
  careEnvironmentDefs,
} from '@shared';
import { possessivePronoun, pronoun } from '../util';
import { CareEnvironment, CarePhase, FundingPolicyType } from '@shared';

import React, { createContext, useContext } from 'react';

const WhiteBoxContext = createContext<
  | {
      ltcPolicy: PolicyFundingSource;
      phaseCalculations: SinglePhaseCalculations[];
    }
  | undefined
>(undefined);

type LtcPolicyProviderProps = {
  children: React.ReactNode;
  ltcPolicy: PolicyFundingSource;
  phaseCalculations: SinglePhaseCalculations[];
};

const WhiteBoxContextProvider = ({
  children,
  ltcPolicy,
  phaseCalculations,
}: LtcPolicyProviderProps) => {
  return (
    <WhiteBoxContext.Provider value={{ ltcPolicy, phaseCalculations }}>
      {children}
    </WhiteBoxContext.Provider>
  );
};

const useWhiteBoxContext = () => {
  const context = useContext(WhiteBoxContext);
  if (context === undefined) {
    throw new Error('context must be used within a WhiteBoxContextProvider');
  }
  return context;
};

type InsuranceWhiteBoxSlideOutContentProps = {
  fundingSourceId: string;
};

function boldCurrency(amount: number | null | undefined): string {
  return `<b>${formatCurrencyTwoDecimals(amount)}</b>`;
}

function boldUnderlineCurrency(amount: number | null | undefined): string {
  return `<u><b>${formatCurrencyTwoDecimals(amount)}</b></u>`;
}

function boldInteger(amount: number | null | undefined): string {
  return `<b>${formatInteger(amount ?? 0)}</b>`;
}

function boldUnderlineInteger(amount: number | null | undefined): string {
  return `<b><u>${formatInteger(amount ?? 0)}</u></b>`;
}

export function InsuranceWhiteBoxSlideOutContent({
  fundingSourceId,
}: InsuranceWhiteBoxSlideOutContentProps) {
  const client = useSelector(selectClient);
  const {
    clientFullName,
    clientFirstName,
    intakeSurvey: { clientHasStartedLtc },
    intakeSurvey,
    inferenceSet: { ltcAtYear },
    allPhaseCosts: { allPhaseCareMonthsNeeded },
    policyPhaseCalculations,
    multipleFundingSources,
  } = client;

  const ltcPolicy = multipleFundingSources.ltcPolicy[fundingSourceId];
  const phaseCalculations = policyPhaseCalculations[fundingSourceId];
  const {
    policyLumpSumPayment,
    policyPremiumMonthlyCost,
    policyType,
    policyLimitedPayYears,
    policyPremiumStartYear,
    premiumInvestmentPeriodMonths,
    monthlyPremiumTotalCost,
    policyPolicyTotalCost,
    projectedCostCoverage,
    policyBenefitPeriodMonths,
    policyCoverageOfRemainingLtcCosts,
    indemnitySurplus: totalIndemnitySurplus,
  } = ltcPolicy;

  const getTotalInvestedIntoPolicyBody = () => {
    let totalInvestedIntoPolicyBody: string = '';
    const policyTypeDescription = policyType
      ? fundingPolicyTypeDefs[policyType].label
      : 'Insurance';

    if (policyLumpSumPayment && !policyPremiumMonthlyCost) {
      totalInvestedIntoPolicyBody = `Total investment into the <b>${policyTypeDescription}</b> is the single lump sum payment: ${boldUnderlineCurrency(policyLumpSumPayment)}.<br/>`;
    }

    const nonNegativePremiumInvestmentMonths = Math.max(
      0,
      premiumInvestmentPeriodMonths ?? 0,
    );

    if (policyPremiumMonthlyCost) {
      if (
        !policyLimitedPayYears &&
        policyPremiumStartYear &&
        premiumInvestmentPeriodMonths
      ) {
        totalInvestedIntoPolicyBody += `Monthly premiums: ${boldCurrency(policyPremiumMonthlyCost)}.<br/>`;
        totalInvestedIntoPolicyBody += `Months between policy purchase and ${clientHasStartedLtc ? 'predicted' : ''} activation: <b>${nonNegativePremiumInvestmentMonths} months.</b> (${policyPremiumStartYear}-${ltcAtYear}).<br/>`;
        totalInvestedIntoPolicyBody += `Total monthly premiums: <b>${nonNegativePremiumInvestmentMonths}</b> months x ${boldCurrency(policyPremiumMonthlyCost)} = ${boldCurrency(monthlyPremiumTotalCost)}.<br/>`;
      } else if (
        policyLimitedPayYears &&
        policyPremiumStartYear &&
        premiumInvestmentPeriodMonths
      ) {
        totalInvestedIntoPolicyBody += `Monthly premiums: ${boldCurrency(policyPremiumMonthlyCost)}.<br/>`;
        totalInvestedIntoPolicyBody += `Months in limited pay period (<b>${policyLimitedPayYears} years</b>): <b>${nonNegativePremiumInvestmentMonths} months.</b><br/>`;
        totalInvestedIntoPolicyBody += `Total monthly premiums:  <b>${nonNegativePremiumInvestmentMonths} months</b> x ${boldCurrency(policyPremiumMonthlyCost)} = ${boldCurrency(monthlyPremiumTotalCost)}.<br/>`;
      }
      if (policyLumpSumPayment) {
        totalInvestedIntoPolicyBody += `Lump-sum payment: ${boldCurrency(policyLumpSumPayment)}.<br/> `;
      }
    }

    if (policyLumpSumPayment && policyPremiumMonthlyCost) {
      totalInvestedIntoPolicyBody += `Total monthly premiums plus lump-sum: ${boldCurrency(monthlyPremiumTotalCost)} + ${boldCurrency(policyLumpSumPayment)} = ${boldUnderlineCurrency(policyPolicyTotalCost)}.`;
    }

    return (
      <div>
        <p
          className="pb-1"
          dangerouslySetInnerHTML={{ __html: totalInvestedIntoPolicyBody }}
        ></p>
      </div>
    );
  };

  const getPayoutOfPolicyBody = () => {
    const policyTypeDescription = policyType
      ? fundingPolicyTypeDefs[policyType].label
      : 'Insurance';
    const totalPolicyCoverage = clientHasStartedLtc
      ? policyCoverageOfRemainingLtcCosts
      : projectedCostCoverage;

    let shortTermCareBenefitPeriodSubtitle = `Short-term care benefit period`;
    let shortTermCareBenefitPeriodBody = `Short Term Care policies have a benefit period, which is the maximum amount of time that the policy will make payments towards eligible long-term care costs.
       ${clientFirstName}'s Short Term Care policy has a maximum benefit period of <b>${policyBenefitPeriodMonths} months${clientHasStartedLtc ? ' remaining' : ''}</b>.`;
    if (
      policyBenefitPeriodMonths &&
      allPhaseCareMonthsNeeded > policyBenefitPeriodMonths
    ) {
      shortTermCareBenefitPeriodBody += ` By narrowing our calculations to only cover the first <b>${policyBenefitPeriodMonths} months</b> of ${possessivePronoun(intakeSurvey)} ${
        clientHasStartedLtc ? 'remaining ' : ''
      }care, we find that the total expected payout would be <b>${formatCurrencyTwoDecimals(totalPolicyCoverage)}</b>.`;
    } else {
      shortTermCareBenefitPeriodBody += ` Since this maximum benefit period is greater than ${clientFirstName}'s predicted ${clientHasStartedLtc ? 'remaining ' : ''}long-term care, we would not expect it to restrict the policy's payout, so the total expected payout would remain as <b>${formatCurrencyTwoDecimals(totalPolicyCoverage)}</b>.`;
    }

    return (
      <>
        <CarePhasesPayout
          client={client}
          policyTypeDescription={policyTypeDescription}
        />
        <LtcPayoutSummary
          client={client}
          policyTypeDescription={policyTypeDescription}
        />

        {policyBenefitPeriodMonths ? (
          <>
            <h3 className="flex flex-grow flex-col gap-3 pb-2 text-lg font-semibold">
              {shortTermCareBenefitPeriodSubtitle}
            </h3>
            <p
              className="pb-1"
              dangerouslySetInnerHTML={{
                __html: shortTermCareBenefitPeriodBody,
              }}
            ></p>
          </>
        ) : null}
        <IndemnitySurplusSection
          client={client}
          totalIndemnitySurplus={totalIndemnitySurplus}
        />
        {checkPolicyCalculationTypeEquals(policyType, [
          FundingPolicyType.hybridLifeInsurance,
          FundingPolicyType.lifeInsuranceWithRider,
        ]) ? (
          <DeathBenefitSection
            client={client}
            ltcPolicy={ltcPolicy}
          />
        ) : null}
      </>
    );
  };

  return (
    <WhiteBoxContextProvider
      ltcPolicy={ltcPolicy}
      phaseCalculations={phaseCalculations}
    >
      <h1 className="-mt-12 mb-8 w-32 rounded-full bg-darkPurple px-2 py-1 text-center text-xl text-white">
        BETA
      </h1>
      <div className="-mt-4 flex flex-grow flex-col gap-4 ">
        <div>
          <h2 className="flex flex-grow flex-col gap-3 pb-2 text-xl font-semibold">
            Total Invested Into Policy
          </h2>
          {getTotalInvestedIntoPolicyBody()}
        </div>
        <div>
          <h2 className="flex flex-grow flex-col gap-3 pb-2 text-xl font-semibold">
            Calculating the Policy Payout
          </h2>
          {getPayoutOfPolicyBody()}
        </div>
      </div>
    </WhiteBoxContextProvider>
  );
}

function DeathBenefitSection({
  client,
  ltcPolicy,
}: {
  client: Client;
  ltcPolicy: PolicyFundingSource;
}) {
  const {
    intakeSurvey: { clientHasStartedLtc },
  } = client;
  const {
    policyInflatedDeathBenefitAmount,
    policyCoverageOfRemainingLtcCosts,
    policyMinimumGuaranteedBenefitAmount,
    indemnitySurplus: totalIndemnitySurplus,
    projectedCostCoverage,
  } = ltcPolicy;

  const totalPolicyCoverage = clientHasStartedLtc
    ? policyCoverageOfRemainingLtcCosts
    : projectedCostCoverage;

  let deathBenefitSubtitle = `Death benefit`;
  let deathBenefitBody = `Initial death benefit amount (plus any inflation protection): ${boldCurrency(policyInflatedDeathBenefitAmount)}.<br/> `;
  const policyCoveragePlusIndemnitySurplus =
    (totalPolicyCoverage ?? 0) + totalIndemnitySurplus;
  const remainingDeathBenefit =
    policyInflatedDeathBenefitAmount && policyCoveragePlusIndemnitySurplus
      ? policyInflatedDeathBenefitAmount - policyCoveragePlusIndemnitySurplus
      : 0;

  deathBenefitBody += `Total policy payout: ${boldCurrency(policyCoveragePlusIndemnitySurplus)}.<br/>`;
  deathBenefitBody += `Remaining death benefit amount: ${boldCurrency(Math.max(remainingDeathBenefit, 0))}.<br/>`;
  deathBenefitBody += `Minimum guaranteed death benefit: ${boldCurrency(policyMinimumGuaranteedBenefitAmount ?? 0)}.<br/>`;

  let amountPaidToBeneficiaries = 0;
  let rationale = '';
  if (
    policyInflatedDeathBenefitAmount &&
    policyCoveragePlusIndemnitySurplus &&
    policyCoveragePlusIndemnitySurplus >= policyInflatedDeathBenefitAmount
  ) {
    amountPaidToBeneficiaries = policyMinimumGuaranteedBenefitAmount ?? 0;
    rationale += `Total policy payout is greater than or equal to the Death Benefit amount, so only pay out is the policy's minimum guaranteed death benefit.`;
    deathBenefitBody += `Total policy payout is greater than or equal to the Death Benefit amount, so only pay out is the policy's minimum guaranteed death benefit.`;
  } else {
    rationale += `Since the total policy payout is less than the Death Benefit amount, the amount that will be paid to beneficiaries is `;

    if (
      policyMinimumGuaranteedBenefitAmount &&
      policyMinimumGuaranteedBenefitAmount > remainingDeathBenefit
    ) {
      amountPaidToBeneficiaries = policyMinimumGuaranteedBenefitAmount;
      rationale += ` the policy's minimum guaranteed death benefit, since this amount is greater than the remaining Death Benefit amount `;
    } else {
      amountPaidToBeneficiaries = remainingDeathBenefit;
      rationale += ` the remaining Death Benefit amount, since this amount is greater than the policy's minimum guaranteed death benefit. `;
    }
    deathBenefitBody += `Expected death benefit payout: ${boldUnderlineCurrency(amountPaidToBeneficiaries)} (added to the <b>Non-LTC Payout</b>).<br/>`;
    deathBenefitBody += `<i>${rationale}</i>`;
  }

  return (
    <>
      <h3 className="flex flex-grow flex-col gap-3 pb-2 pt-3 text-lg font-semibold">
        {deathBenefitSubtitle}
      </h3>
      <p
        className="pb-1"
        dangerouslySetInnerHTML={{
          __html: deathBenefitBody,
        }}
      ></p>
    </>
  );
}

type IndemnitySurplusProps = {
  client: Client;
  totalIndemnitySurplus: number | null;
};

function IndemnitySurplusSection({
  client,
  totalIndemnitySurplus,
}: IndemnitySurplusProps) {
  if (!totalIndemnitySurplus) {
    return null;
  }
  let indemnityTotalSurplusSubtitle = `Indemnity Policy Surplus Funds`;
  let indemnityTotalSurplusBody = `Indemnity surplus after Full Care Phase: ${boldUnderlineCurrency(totalIndemnitySurplus)}.<br/>
   These are funds paid out by the policy that were not used to to cover projected LTC costs. This amount is added to the <b>Non-LTC Payout total</b>.`;
  return (
    <>
      <h3 className="mt-2 flex flex-grow flex-col gap-3 pb-2 text-lg font-semibold">
        {indemnityTotalSurplusSubtitle}
      </h3>
      <p
        className="pb-1"
        dangerouslySetInnerHTML={{
          __html: indemnityTotalSurplusBody,
        }}
      ></p>
    </>
  );
}

interface CarePhasePayoutProps {
  client: Client;
  policyTypeDescription: string;
}

function LtcPayoutSummary({ client }: CarePhasePayoutProps) {
  const { clientFirstName } = client;

  const { ltcPolicy, phaseCalculations } = useWhiteBoxContext();
  const {
    policyCoverageOfRemainingLtcCosts,
    projectedCostCoverage,
    policyType,
  } = ltcPolicy;

  const jointPolicy = isJointPolicy(policyType, client.mutableClientPartner);

  let totalPolicyPayoutSubtitle = `Long-Term Care Payout Summary`;
  let totalPolicyPayoutBody = `The long-term care payout is the total projected coverage all care phases for ${clientFirstName} ${jointPolicy ? `and ${client.mutableClientPartner?.clientFirstName}` : ''}. Based on our calculations, the policy terms indicate that this policy is expected to pay out:`;

  if (jointPolicy) {
    const combinedClientPhaseCosts = combineSortClientPartnerPhaseCosts(client);

    let phasePayoutSummary = combinedClientPhaseCosts.map(clientPhaseCosts => {
      const currentClient = clientPhaseCosts.client;
      const carePhase = clientPhaseCosts.phaseCosts.carePhase;
      const carePhaseIndex = carePhaseDefs[carePhase].index;
      const isClientPartner = currentClient.clientId !== client.clientId;
      const singlePhaseCalculations = isClientPartner
        ? client.mutableClientPartner!.phaseCalculations[carePhaseIndex]
        : phaseCalculations[carePhaseIndex];

      if (isNullOrUndefined(singlePhaseCalculations.costCoverageAddedToTotal)) {
        return null;
      }
      return ` <br/><b>${formatCurrencyTwoDecimals(singlePhaseCalculations.costCoverageAddedToTotal)}</b> for ${currentClient.clientFirstName}'s ${carePhaseDefs[carePhase].label} phase${clientPhaseCosts.phaseStartYear ? ` (expected start year ${Math.round(clientPhaseCosts.phaseStartYear)})` : ''}.`;
    });
    totalPolicyPayoutBody += phasePayoutSummary.join('');
  } else {
    let phasePayoutSummary = phaseCalculations
      // .filter(phaseCalc => phaseCalc.costCoverageAddedToTotal)
      .map(
        phaseCalc =>
          ` <br/><b>${formatCurrencyTwoDecimals(phaseCalc.costCoverageAddedToTotal)}</b> in the ${carePhaseDefs[phaseCalc.phase].label} phase`,
      );

    if (phasePayoutSummary.length > 1) {
      const lastDetail = phasePayoutSummary.pop();
      totalPolicyPayoutBody += ` ${phasePayoutSummary.join('')} ${lastDetail}`;
    } else if (phasePayoutSummary.length === 1) {
      totalPolicyPayoutBody += ` ${phasePayoutSummary[0]}.`;
    }
  }

  const policyCoverage = projectedCostCoverage
    ? projectedCostCoverage
    : policyCoverageOfRemainingLtcCosts;

  let totalPolicyCoverage;

  if (jointPolicy) {
    const partnerProjectedCostCoverage =
      client.mutableClientPartner?.fundingSources.ltcPolicy
        .projectedCostCoverage;
    const partnerPolicyCoverageOfRemainingLtcCosts =
      client.mutableClientPartner?.fundingSources.ltcPolicy
        .policyCoverageOfRemainingLtcCosts;
    const partnerPolicyCoverage =
      (partnerProjectedCostCoverage
        ? partnerProjectedCostCoverage
        : partnerPolicyCoverageOfRemainingLtcCosts) ?? 0;

    totalPolicyCoverage = (policyCoverage ?? 0) + (partnerPolicyCoverage ?? 0);
  } else {
    totalPolicyCoverage = policyCoverage ?? 0;
  }

  totalPolicyPayoutBody += ` <br/><b>${formatCurrencyTwoDecimals(totalPolicyCoverage)}</b> total policy coverage.`;
  return (
    <>
      <h3 className="flex flex-grow flex-col gap-3 pb-2 pt-3 text-lg font-semibold">
        {totalPolicyPayoutSubtitle}
      </h3>
      <p
        className="pb-1"
        dangerouslySetInnerHTML={{
          __html: totalPolicyPayoutBody,
        }}
      ></p>
    </>
  );
}

function CarePhasesPayout({ client }: CarePhasePayoutProps) {
  const {
    clientFirstName,
    phaseCosts,
    intakeSurvey: { clientHasStartedLtc, clientCurrentCarePhase },
    intakeSurvey,
    allPhaseCosts: { allPhaseCareMonthsNeeded },
    clientId,
  } = client;

  const { ltcPolicy, phaseCalculations } = useWhiteBoxContext();
  const {
    projectedCostCoverage,
    policyBenefitPeriodMonths,
    policyCoverageOfRemainingLtcCosts,
    policyType,
  } = ltcPolicy;

  const totalPolicyCoverage = clientHasStartedLtc
    ? policyCoverageOfRemainingLtcCosts
    : projectedCostCoverage;

  let shortTermCareBenefitPeriodBody = `Short Term Care policies have a benefit period, which is the maximum amount of time that the policy will make     payments towards eligible long-term care costs.
       ${clientFirstName}'s Short Term Care policy has a maximum benefit period of <b>${policyBenefitPeriodMonths} months${clientHasStartedLtc ? ' remaining' : ''}</b>.`;
  if (
    policyBenefitPeriodMonths &&
    allPhaseCareMonthsNeeded > policyBenefitPeriodMonths
  ) {
    shortTermCareBenefitPeriodBody += ` By narrowing our calculations to only cover the first <b>${policyBenefitPeriodMonths} months</b> of ${possessivePronoun(intakeSurvey)} ${
      clientHasStartedLtc ? 'remaining ' : ''
    }care, we find that the total expected payout would be <b>${formatCurrencyTwoDecimals(totalPolicyCoverage)}</b>.`;
  } else {
    shortTermCareBenefitPeriodBody += ` Since this maximum benefit period is greater than ${clientFirstName}'s predicted ${clientHasStartedLtc ? 'remaining ' : ''}long-term care, we would not expect it to restrict the policy's payout, so the total expected payout would remain as <b>${formatCurrencyTwoDecimals(totalPolicyCoverage)}</b>.`;
  }

  if (isJointPolicy(policyType, client.mutableClientPartner)) {
    const combinedClientPhaseCosts = combineSortClientPartnerPhaseCosts(client);
    return (
      <>
        <span className="py-1">
          Since this is a <span className="font-semibold">joint policy </span>
          the below section will explain the payouts for both {clientFirstName}
          's and {client.mutableClientPartner.clientFirstName}'s care phases.{' '}
        </span>

        {combinedClientPhaseCosts.map((clientPhaseCosts, keyIndex) => {
          const carePhase = clientPhaseCosts.phaseCosts.carePhase;
          const carePhaseIndex = carePhaseDefs[carePhase].index;

          const singlePhaseCalculations =
            clientPhaseCosts.client.clientId === clientId
              ? phaseCalculations[carePhaseIndex]
              : clientPhaseCosts.client.phaseCalculations[carePhaseIndex];

          const currentCarePhase =
            clientPhaseCosts.client.intakeSurvey.clientCurrentCarePhase;

          const isPastPhase = currentCarePhase
            ? carePhaseDefs[currentCarePhase].index >
              carePhaseDefs[carePhase].index
            : null;

          if (isPastPhase) {
            return null;
          }

          return (
            <CarePhasePayoutSections
              client={clientPhaseCosts.client}
              phaseCosts={clientPhaseCosts.phaseCosts}
              phaseCalculations={singlePhaseCalculations}
              isJointPolicy={true}
            />
          );
        })}
      </>
    );
  } else {
    return (
      <>
        {phaseCosts.map((singlePhaseCosts, keyIndex) => {
          const carePhase = singlePhaseCosts.carePhase;
          const carePhaseIndex = carePhaseDefs[carePhase].index;
          const singlePhaseCalculations = phaseCalculations[carePhaseIndex];
          const isPastPhase = clientCurrentCarePhase
            ? carePhaseDefs[clientCurrentCarePhase].index >
              carePhaseDefs[carePhase].index
            : null;

          if (isPastPhase) {
            return null;
          }

          return (
            <CarePhasePayoutSections
              client={client}
              phaseCosts={singlePhaseCosts}
              phaseCalculations={singlePhaseCalculations}
            />
          );
        })}
      </>
    );
  }
}

function getCareEnvironmentDescription(
  client: Client,
  carePhase: CarePhase,
): string {
  const careLocation = client.appliedCareEnvironments?.[
    carePhase
  ] as CareEnvironment;
  let careLocationDescription;

  switch (careLocation) {
    case CareEnvironment.home:
      careLocationDescription = 'at home';
      break;
    case CareEnvironment.assistedLiving:
      careLocationDescription = 'at an assisted living facility';
      break;
    case CareEnvironment.nursingHome:
      careLocationDescription = 'at a nursing home';
      break;
    case CareEnvironment.independentLiving:
      careLocationDescription = 'at an independent living facility';
      break;
    default:
      throw new Error('Invalid care location');
  }
  return careLocationDescription;
}

interface ClientPhaseProps {
  client: Client;
  phaseCosts: SinglePhaseCosts;
  phaseCalculations: SinglePhaseCalculations;
  isJointPolicy?: boolean;
}

function CarePhasePayoutSections({
  client,
  phaseCosts,
  phaseCalculations,
  isJointPolicy,
}: ClientPhaseProps): JSX.Element {
  const phaseComponents = [
    PhaseCostBreakdown,
    PhaseMaxBenefitRemaining,
    PolicyEligibleCareDays,
    PolicyDailyMaximumBenefit,
    PhaseCostCoverageAndRemainingBenefit,
    IndemnitySurplus,
  ];
  return (
    <div>
      <div className="ml-2">
        {phaseComponents.map((Component, index) => (
          <Component
            key={index}
            isJointPolicy={isJointPolicy}
            client={client}
            phaseCosts={phaseCosts}
            phaseCalculations={phaseCalculations}
          />
        ))}
      </div>
    </div>
  );
}

function PhaseMaxBenefitRemaining({
  client,
  phaseCosts,
  phaseCalculations,
}: ClientPhaseProps) {
  const { clientFirstName, clientPhasePredictedEndYears } = client;

  const { ltcPolicy } = useWhiteBoxContext();
  const {
    policyMaximumBenefitAmount,
    policyType,
    policyInflationProtection,
    policyBenefitUtilizedToDate,
    isSimpleInflationProtection,
    policyContinuationBenefitAmount,
    policyBenefitIsUnlimited,
    policyPremiumStartYear,
  } = ltcPolicy;
  const {
    inflationPeriodYears,
    inflatedMaxBenefitAmount,
    remainingMaxBenefitBeforePhaseReduction,
    cumulativePolicyPayOut,
  } = phaseCalculations;

  const maxBenefitSubtitle = 'Policy benefit available for this phase';

  const predictedPhaseEndYear =
    clientPhasePredictedEndYears[phaseCosts.carePhase];

  let maxBenefitBody = '';
  const inflatonProtectionText = policyInflationProtection
    ? `Inflation protection: <b>${formatPercent(policyInflationProtection)}</b> ${isSimpleInflationProtection ? 'simple' : 'compound'}.<br/>
    Inflated benefit amount: ${boldCurrency(inflatedMaxBenefitAmount)}.<br/>

    <i>Amount calculated by applying ${isSimpleInflationProtection ? 'simple' : 'compound'} inflation from the year the policy was purchased ${boldInteger(policyPremiumStartYear)} to the year the year ${clientFirstName}'s ${carePhaseDefs[phaseCosts.carePhase].label} is expected to end ${formatInteger(predictedPhaseEndYear)}, a total of <b>${formatThousands(inflationPeriodYears, '0', 2)} years</b>.</i><br/>`
    : '';
  const benefitsUtilizedToDateText = policyBenefitUtilizedToDate
    ? `Benefit amount already utilized: ${boldCurrency(policyBenefitUtilizedToDate)}.<br/>`
    : '';

  const policyPayOutText = `Amount paid out in previous phases: ${boldCurrency(cumulativePolicyPayOut)}.<br/>`;

  if (policyBenefitIsUnlimited) {
    maxBenefitBody += `The LTC benefit is <b>unlimited</b> for this policy.`;
  } else {
    if (
      checkPolicyCalculationTypeEquals(
        policyType,
        FundingPolicyType.hybridLifeInsurance,
      ) &&
      policyContinuationBenefitAmount
    ) {
      maxBenefitBody += `Death benefit amount: ${boldCurrency(policyMaximumBenefitAmount)}.<br/>
        Continuation of benefits amount: ${boldCurrency(policyContinuationBenefitAmount)}.<br/>`;
      maxBenefitBody += inflatonProtectionText;
      maxBenefitBody += benefitsUtilizedToDateText;
      maxBenefitBody += policyPayOutText;
    } else {
      if (policyMaximumBenefitAmount) {
        maxBenefitBody += `${
          checkPolicyCalculationTypeEquals(
            policyType,
            FundingPolicyType.lifeInsuranceWithRider,
          )
            ? 'Initial death benefit amount'
            : 'Initial max benefit amount'
        }: ${boldCurrency(policyMaximumBenefitAmount)}.<br/> `;
        maxBenefitBody += inflatonProtectionText;
        maxBenefitBody += benefitsUtilizedToDateText;
        maxBenefitBody += policyPayOutText;
      }
    }
  }

  if (!policyBenefitIsUnlimited) {
    maxBenefitBody += `Policy benefit available for phase: ${boldUnderlineCurrency(remainingMaxBenefitBeforePhaseReduction)}.<br/>`;
  }
  return (
    <>
      <h3 className="flex flex-grow flex-col gap-3 pb-2 text-base font-semibold">
        {maxBenefitSubtitle}
      </h3>
      <p
        className="pb-1"
        dangerouslySetInnerHTML={{
          __html: maxBenefitBody,
        }}
      ></p>
    </>
  );
}

function PhaseCostCoverageAndRemainingBenefit({
  client,
  phaseCosts,
  phaseCalculations,
}: ClientPhaseProps): JSX.Element | null {
  const { ltcPolicy } = useWhiteBoxContext();
  const { isIndemnityPolicyPayment, policyBenefitIsUnlimited } = ltcPolicy;

  const maxPayoutAccordingToDailyMaximum =
    phaseCalculations.maxPayoutAccordingToDailyMaximum;

  const carePhase = phaseCosts.carePhase;
  const carePhaseLabel = carePhaseDefs[carePhase].label;

  let subtitle = policyBenefitIsUnlimited
    ? `Cost coverage for ${carePhaseDefs[carePhase].label} Phase`
    : `Cost covereage for ${carePhaseDefs[carePhase].label} Phase and remaining benefit`;

  let body = '';

  const portionOfPhaseCostBenefitEligible =
    phaseCalculations.portionOfPhaseCostBenefitEligible;
  const eligibleCareDays =
    phaseCalculations.phaseEligibleCareDaysAfterWaitingPeriod;
  const inflatedDailyMaximumBenefit =
    phaseCalculations.inflatedDailyBenefitAmount;

  if (policyBenefitIsUnlimited) {
    body += `The total cost that is eligible for benefit payout is ${boldCurrency(portionOfPhaseCostBenefitEligible)}.<br/> `;
  } else {
    const remainingMaxBenefitBeforePhaseReduction =
      phaseCalculations.remainingMaxBenefitBeforePhaseReduction;
    body += `Remaining benefit amount: ${boldCurrency(remainingMaxBenefitBeforePhaseReduction)}.<br/>`;
    body += phaseCalculations.surplusAppliedToCoverage
      ? `Indemnity surplus applied to coverage: ${boldCurrency(phaseCalculations.surplusAppliedToCoverage)}.<br/> `
      : '';
    body += `Eligible care cost: ${boldCurrency(portionOfPhaseCostBenefitEligible)}.<br/><i> This amount is calculated by multiplying eligible days by the average daily cost of care for this phase ${boldInteger(eligibleCareDays)} days x ${boldCurrency(phaseCalculations.phaseCostPerDayNeeded)}</i>.<br/>`;
  }

  const indemnitySurplusAddedToCoverage =
    phaseCalculations.surplusAppliedToCoverage;

  if (maxPayoutAccordingToDailyMaximum) {
    body += `Max coverage based on daily maximum ${indemnitySurplusAddedToCoverage ? `(plus any indemnity surplus)` : ``}: ${boldCurrency(maxPayoutAccordingToDailyMaximum + indemnitySurplusAddedToCoverage)}.<br/><i> This amount is calculated by multiplying eligible days by (inflated) daily max ${boldInteger(eligibleCareDays)} days x ${boldCurrency(inflatedDailyMaximumBenefit)} ${indemnitySurplusAddedToCoverage ? `plus indemnity surplus ${boldCurrency(indemnitySurplusAddedToCoverage)}` : ``}</i>.<br/>`;
  }

  if (policyBenefitIsUnlimited) {
    body += `Total cost coverage: ${boldUnderlineCurrency(phaseCalculations.costCoverageAddedToTotal)}.<br/> `;
  } else {
    const remainingMaxBenefitAfterPhaseReduction =
      phaseCalculations.remainingMaxBenefitAfterPhaseReduction;

    const costCoverageAddedToTotal = phaseCalculations.costCoverageAddedToTotal;
    body += `Total cost coverage: ${boldUnderlineCurrency(costCoverageAddedToTotal)}.<i> The minimum of the aforementioned amounts.</i>
    <br/>`;

    body += `Remaining benefit amount: ${boldCurrency(remainingMaxBenefitAfterPhaseReduction)} after reducing by the ${carePhaseLabel} payout. `;
    body += `${isIndemnityPolicyPayment && maxPayoutAccordingToDailyMaximum > portionOfPhaseCostBenefitEligible ? `<i>Indemnity policies pay out the full daily max benefit, which in this case is more than the cost of care.</i>` : ``}`;
  }

  return (
    <>
      <h4 className="text-md flex flex-grow flex-col gap-3 pb-2 font-semibold">
        {subtitle}
      </h4>
      <p
        className="pb-1"
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      ></p>
    </>
  );
}

function IndemnitySurplus({
  client,
  phaseCosts,
  phaseCalculations,
}: ClientPhaseProps): JSX.Element | null {
  const {
    intakeSurvey: { clientCurrentCarePhase },
  } = client;

  const carePhase = phaseCosts.carePhase;
  const isCurrentPhase = clientCurrentCarePhase === carePhase;
  const maxPayoutAccordingToDailyMaximum =
    phaseCalculations.maxPayoutAccordingToDailyMaximum;
  const phaseCostCoverage = phaseCalculations.phaseCostCoverage;
  const carePhaseLabel = carePhaseDefs[carePhase].label;
  let indemnitySurplusSubtitle = `Indemnity surplus for phase`;
  let indemnitySurplusBody = '';

  const indemnitySurplus = phaseCalculations.indemnitySurplusForPhase;

  if (indemnitySurplus) {
    indemnitySurplusBody = `Expected surplus: ${boldCurrency(indemnitySurplus)}. <br/><i>This amount is calculated by subtracting the amount paid by the policy by the predicted phase cost by ${boldCurrency(maxPayoutAccordingToDailyMaximum)} - ${boldCurrency(phaseCostCoverage)}. The surplus is applied to subsequent care phases where indemnity coverage falls short. If all phases are fully funded, any remaining surplus will be added to the Non-LTC Payout.</i><br/>

    `;
  }

  return (
    <>
      {indemnitySurplus ? (
        <>
          <h4 className="text-md flex flex-grow flex-col gap-3 pb-2 font-semibold">
            {indemnitySurplusSubtitle}
          </h4>
          <p
            className="pb-1"
            dangerouslySetInnerHTML={{
              __html: indemnitySurplusBody,
            }}
          ></p>
        </>
      ) : null}
    </>
  );
}

function PolicyDailyMaximumBenefit({
  client,
  phaseCosts,
  phaseCalculations,
}: ClientPhaseProps): JSX.Element | null {
  const {
    clientFirstName,
    intakeSurvey: { clientCurrentCarePhase },
    intakeSurvey,
    clientPhasePredictedEndYears,
  } = client;

  const { ltcPolicy } = useWhiteBoxContext();
  const {
    policyPremiumStartYear,
    isIndemnityPolicyPayment,
    isSimpleInflationProtection,
    policyType,
  } = ltcPolicy;

  const policyTypeDescription = policyType
    ? fundingPolicyTypeDefs[policyType].label
    : 'Insurance';
  const carePhase = phaseCosts.carePhase;
  const carePhaseLabel = carePhaseDefs[carePhase].label;
  const isCurrentPhase = clientCurrentCarePhase === carePhase;

  const dailyMaximumBenefitForCareEnvironment =
    phaseCalculations.dailyBenefitAmount;
  const inflationProtection = phaseCalculations.inflationProtection;
  const inflationPeriodYears = phaseCalculations.inflationPeriodYears;
  const careLocationDescription = getCareEnvironmentDescription(
    client,
    carePhase,
  );
  const phaseEligibleCareDaysAfterWaitingPeriod =
    phaseCalculations.phaseEligibleCareDaysAfterWaitingPeriod;
  const inflatedDailyMaximumBenefit =
    phaseCalculations.inflatedDailyBenefitAmount;
  const policyPhaseEndYear = clientPhasePredictedEndYears[carePhase];
  const maxPayoutAccordingToDailyMaximum =
    phaseCalculations.maxPayoutAccordingToDailyMaximum;
  const phaseCostCoverage = phaseCalculations.phaseCostCoverage;
  const surplusAppliedToCoverage = phaseCalculations.surplusAppliedToCoverage;
  const costCoverageAddedToTotal = phaseCalculations.costCoverageAddedToTotal;
  const phaseCostPerDayNeeded = phaseCalculations.phaseCostPerDayNeeded;

  let dailyMaximumBenefitSubtitle = `Daily maximum benefit`;
  let dailyMaximumBenefitBody = '';

  if (dailyMaximumBenefitForCareEnvironment) {
    dailyMaximumBenefitBody += `Daily max benefit for care ${careLocationDescription}: ${boldCurrency(dailyMaximumBenefitForCareEnvironment)}.</br>
    `;
    if (inflationProtection) {
      dailyMaximumBenefitBody += `Inflation protection: <b>${formatPercent(inflationProtection)} ${isSimpleInflationProtection ? 'simple' : 'compound'}</b>.<br/>
      Inflated daily max benefit: ${inflatedDailyMaximumBenefit >= phaseCostPerDayNeeded && !isIndemnityPolicyPayment ? boldCurrency(inflatedDailyMaximumBenefit) : boldUnderlineCurrency(inflatedDailyMaximumBenefit)}.<br/> <i>Amount calculated by applying ${isSimpleInflationProtection ? 'simple' : 'compound'} inflation from the year the policy was purchased (<b>${policyPremiumStartYear}</b>) to the year LTC is expected to end for ${clientFirstName} (<b>${formatInteger(policyPhaseEndYear)}</b>), a total of <b>${formatThousands(inflationPeriodYears, '0', 2)} years</b></i><br/>`;
    }
    if (!isIndemnityPolicyPayment) {
      // reimbursement policy

      const expectedDailyPayout =
        inflatedDailyMaximumBenefit >= phaseCostPerDayNeeded
          ? phaseCostPerDayNeeded
          : inflatedDailyMaximumBenefit;

      if (inflatedDailyMaximumBenefit >= phaseCostPerDayNeeded) {
        dailyMaximumBenefitBody += `Expected daily payout: ${boldUnderlineCurrency(expectedDailyPayout)}. `;
        dailyMaximumBenefitBody += `<i>The daily max is more than the expected average daily cost of care (${boldCurrency(phaseCostPerDayNeeded)}) for ${clientFirstName}, so a <b>reimbursement</b> policy will only pay up to the actual cost incurred.</i>
        `;
      }

      // dailyMaximumBenefitBody += `Max payout according to daily maximum: ${boldUnderlineCurrency(maxPayoutAccordingToDailyMaximum)}.
      // <i>Amount calculated by multiplying <b>${phaseEligibleCareDaysAfterWaitingPeriod} eligible days</b> x ${boldCurrency(expectedDailyPayout)}</b></i></br>`;
    } else {
      // indemnity policy
      dailyMaximumBenefitBody += `<i>This is an <b>indemnity</b> policy, which means that the policy will pay the full ${boldCurrency(inflatedDailyMaximumBenefit)} per day regardless of the actual cost of care. Any excess funds will be used in future care phases or added to the <b>Non-LTC Payout</b> amount.</i>`;
      // <br/>Because ${clientFirstName}'s policy is <b>indemnity</b> based, ${possessivePronoun(intakeSurvey)} policy is expected to pay ${possessivePronoun(intakeSurvey)} a flat <b>${formatCurrencyTwoDecimals(inflatedDailyMaximumBenefit)}</b> per day that ${pronoun(intakeSurvey)} is receiving care ${careLocationDescription},
      //            regardless of whether the costs associated with ${possessivePronoun(intakeSurvey)} care are higher or lower than that amount.`;
      // dailyMaximumBenefitBody += `<br/>This means that ${clientFirstName}'s policy is expected to pay <b>${formatCurrencyTwoDecimals(inflatedDailyMaximumBenefit)}</b> per day in ${possessivePronoun(intakeSurvey)} ${isCurrentPhase ? 'remaining ' : ''}${carePhaseLabel} Phase.
      //            Since there are <b>${phaseEligibleCareDaysAfterWaitingPeriod}</b> ${isCurrentPhase ? 'remaining ' : ''}eligible days in this care phase,
      //            that means that ${clientFirstName}'s policy is expected to pay <b>${phaseEligibleCareDaysAfterWaitingPeriod}</b> * <b>${formatCurrencyTwoDecimals(inflatedDailyMaximumBenefit)}</b> = <b>${formatCurrencyTwoDecimals(maxPayoutAccordingToDailyMaximum)}</b> for ${possessivePronoun(intakeSurvey)} ${isCurrentPhase ? 'remaining ' : ''}${carePhaseLabel} Phase.`;

      // if (surplusAppliedToCoverage) {
      //   dailyMaximumBenefitBody += `<br/>In addition to this, we have <b>${formatCurrencyTwoDecimals(surplusAppliedToCoverage)}</b> of indemnity surplus left over from the prior care phase. Adding that to our total gives us <b>${formatCurrencyTwoDecimals(phaseCostCoverage)}</b> + <b>${formatCurrencyTwoDecimals(surplusAppliedToCoverage)}</b> = <b>${formatCurrencyTwoDecimals(costCoverageAddedToTotal)}</b>.`;
      // }
    }
  }
  return (
    <>
      {dailyMaximumBenefitForCareEnvironment ? (
        <>
          <h4 className="text-md flex flex-grow flex-col gap-3 pb-2 font-semibold">
            {dailyMaximumBenefitSubtitle}
          </h4>
          <p
            className="pb-1"
            dangerouslySetInnerHTML={{
              __html: dailyMaximumBenefitBody,
            }}
          ></p>
        </>
      ) : null}
    </>
  );
}

function PolicyEligibleCareDays({
  client,
  phaseCosts,
  phaseCalculations,
}: ClientPhaseProps): JSX.Element | null {
  const {
    clientFirstName,
    intakeSurvey: { clientAdlReceivedAssistanceCount, clientCurrentCarePhase },
    appliedCareEnvironments,
  } = client;
  const { ltcPolicy } = useWhiteBoxContext();

  const { homeCareWaitingPeriodDays, facilityCareWaitingPeriodDays } =
    ltcPolicy;
  const carePhase = phaseCosts.carePhase;
  const carePhaseIndex = carePhaseDefs[carePhase].index;
  const isCurrentPhase = clientCurrentCarePhase === carePhase;

  const phaseCareDaysNeeded = phaseCalculations.phaseCareDaysNeeded;
  const halfPhaseCareDaysNeeded = phaseCalculations.daysWhereLessThanTwoADLs;
  const selectedCareSetting = appliedCareEnvironments?.[carePhase];
  const waitingPeriodDaysForCareEnvironment =
    selectedCareSetting === CareEnvironment.home
      ? homeCareWaitingPeriodDays
      : facilityCareWaitingPeriodDays;
  const applicableWaitingPeriodDays =
    phaseCalculations.applicableWaitingPeriodDays;
  const applicablePhaseCareDaysNeeded = halfPhaseCareDaysNeeded
    ? halfPhaseCareDaysNeeded
    : phaseCareDaysNeeded;
  const applicablePhaseCareDaysNeededAfterWaitingPeriod =
    phaseCalculations.phaseEligibleCareDaysAfterWaitingPeriod;

  const phaseHomeWaitingPeriodDaysRemaining =
    phaseCalculations.phaseHomeCareWaitingPeriodDaysRemaining;

  const phaseFacilityWaitingPeriodDaysRemaining =
    phaseCalculations.phaseFacilityCareWaitingPeriodDaysRemaining;

  const phaseWaitingPeriodDaysRemaining =
    selectedCareSetting === CareEnvironment.home
      ? phaseHomeWaitingPeriodDaysRemaining
      : phaseFacilityWaitingPeriodDaysRemaining;

  let nonEligibleDaysPayoutSubtitle = `Eligible care days`;
  let nonEligibleDaysPayoutBody = ``;

  const isEarlyCareWithLessThen2Adls =
    carePhaseIndex === 0 && clientAdlReceivedAssistanceCount < 2;
  const hasWaitingPeriodDaysForCareEnvironment = !isNullOrUndefined(
    waitingPeriodDaysForCareEnvironment,
  );

  let earlyCare2AdlEligibilitySubtitle = `2 ADL claim eligibility requirement`;
  let earlyCare2AdlEligibilityBody = `
  Estimated ${isCurrentPhase ? 'remaining ' : ''}days in Early Care Phase: <b>${phaseCareDaysNeeded} days</b>.<br/>
  Eligible days associated with 2-ADLs: ${hasWaitingPeriodDaysForCareEnvironment ? boldInteger(halfPhaseCareDaysNeeded) : boldUnderlineInteger(halfPhaseCareDaysNeeded)} <b>days</b>.<br/>
  <i>Most long-term care insurance policies <b>won't be claim-eligible</b> until the policyholder is receiving help with at least <b>2 Activities of Daily Living (ADLs)</b>.
  We refer to these ADLs as self-care activities earlier in the Personalize Plan section.

    <br/>Early Care Phase is a prediction for when ${clientFirstName} will need help with 1-2 ADLs.
    We assume the first half of ${isCurrentPhase ? 'the remainder of ' : ''} this care phase is spent receiving help with 1 ADL (and is not covered by the policy).</i>`;

  let waitingPeriodDaysSubtitle = `Waiting period`;
  let waitingPeriodDaysBody = '';

  waitingPeriodDaysBody += `Remaininig ${selectedCareSetting === CareEnvironment.home ? 'home care' : 'facility'} waiting period: <b>${phaseWaitingPeriodDaysRemaining} (out of ${selectedCareSetting === CareEnvironment.home ? homeCareWaitingPeriodDays : facilityCareWaitingPeriodDays}) days</b>.<br/>`;
  waitingPeriodDaysBody += `Eligible days after waiting period: <u><b>${applicablePhaseCareDaysNeededAfterWaitingPeriod} days</b></u>.
  <i>This is calculated by subtracting eligible days in the care phase by the remaining waiting period days (${applicablePhaseCareDaysNeeded} days - ${applicableWaitingPeriodDays} days).</i><br/>`;

  if (isEarlyCareWithLessThen2Adls || hasWaitingPeriodDaysForCareEnvironment) {
    return (
      <>
        <h4 className="mt-1 flex flex-grow flex-col gap-3 pb-2 text-base font-semibold">
          {nonEligibleDaysPayoutSubtitle}
        </h4>
        <p
          className="pb-1"
          dangerouslySetInnerHTML={{ __html: nonEligibleDaysPayoutBody }}
        ></p>
        {isEarlyCareWithLessThen2Adls ? (
          <div className="">
            <h4 className="flex flex-grow flex-col gap-3 pb-2 text-sm font-semibold">
              {earlyCare2AdlEligibilitySubtitle}
            </h4>
            <p
              className="pb-2"
              dangerouslySetInnerHTML={{
                __html: earlyCare2AdlEligibilityBody,
              }}
            ></p>
          </div>
        ) : null}
        {hasWaitingPeriodDaysForCareEnvironment && (
          <div>
            <h4 className="flex flex-grow flex-col gap-3 pb-2 text-sm font-semibold">
              {waitingPeriodDaysSubtitle}
            </h4>
            <p
              className="pb-1"
              dangerouslySetInnerHTML={{ __html: waitingPeriodDaysBody }}
            ></p>
          </div>
        )}
      </>
    );
  }
  return null;
}

function PhaseCostBreakdown({
  client,
  phaseCosts,
  phaseCalculations,
  isJointPolicy,
}: ClientPhaseProps): JSX.Element | null {
  const {
    clientFullName,
    clientFirstName,
    intakeSurvey: { clientCurrentCarePhase },
    intakeSurvey,
    appliedCareEnvironments,
    fundingSources: {},
  } = client;

  const carePhase = phaseCosts.carePhase;
  const carePhaseLabel = carePhaseDefs[carePhase].label;
  const carePhaseIndex = carePhaseDefs[carePhase].index;

  const phaseCostBreakDownSubtitle = isJointPolicy
    ? `${clientFirstName}'s Payout for the ${carePhaseLabel} Phase`
    : `${carePhaseIndex + 1}. Payout for the ${carePhaseLabel} Phase`;

  const isCurrentPhase = clientCurrentCarePhase === carePhase;
  const isPastPhase = clientCurrentCarePhase
    ? carePhaseDefs[clientCurrentCarePhase].index >
      carePhaseDefs[carePhase].index
    : null;

  if (isPastPhase) {
    return null;
  }

  const inflatedProfessionalPhaseCosts =
    phaseCosts.phaseInflatedProfessionalShareCost;
  const phaseCareMonthsNeeded = phaseCosts.phaseCareMonthsNeeded;
  const phaseMonthlyCost = phaseCalculations.phaseMonthlyCost;
  const phaseCostPerDayNeeded = phaseCalculations.phaseCostPerDayNeeded;

  // early care
  const careLocation = appliedCareEnvironments?.[carePhase] as CareEnvironment;

  let careLocationDescription;

  switch (careLocation) {
    case CareEnvironment.home:
      careLocationDescription = 'at home';
      break;
    case CareEnvironment.assistedLiving:
      careLocationDescription = 'at an assisted living facility';
      break;
    case CareEnvironment.nursingHome:
      careLocationDescription = 'at a nursing home';
      break;
    case CareEnvironment.independentLiving:
      careLocationDescription = 'at an independent living facility';
      break;
    default:
      throw new Error('Invalid care location');
  }

  const phaseCostBreakDownBody = `${clientFullName} is planning to live <b>${careEnvironmentDefs[careLocation].facilityType}</b> for ${isCurrentPhase ? 'the remainder of ' : ''}${possessivePronoun(intakeSurvey)} ${carePhaseLabel} Phase. Below are some predicted details about this phase:<br/>
   Total phase cost: ${boldCurrency(inflatedProfessionalPhaseCosts)}.<br/>
   Phase duration: <b>${phaseCareMonthsNeeded} months (${formatInteger(phaseCareMonthsNeeded * 30)} days) </b>.<br/>
   Average monthly cost: ${boldCurrency(phaseMonthlyCost)}.<br/>
   Average daily cost: ${boldCurrency(phaseCostPerDayNeeded)}.<br/>`;
  return (
    <>
      <h3 className="-ml-2 flex flex-grow flex-col gap-3 pb-2 pt-3 text-lg font-semibold">
        {phaseCostBreakDownSubtitle}
      </h3>
      <p
        className="pb-1"
        dangerouslySetInnerHTML={{ __html: phaseCostBreakDownBody }}
      ></p>
    </>
  );
}

interface MaxBenefitSectionProps {
  client: Client;
  policyTypeDescription: string;
}

function formatCurrencyTwoDecimals(value: number | undefined | null): string {
  return formatCurrency(value, 2);
}
