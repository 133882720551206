import { useSelector } from 'react-redux';
import { selectClient, selectMagicLinkSession, selectSession } from '../model';
import {
  FundingPolicyType,
  builtEnvironment,
  checkPolicyCalculationTypeEquals,
  fundingPolicyTypeDefs,
  isJointPolicy,
  isNullOrUndefined,
  isRunningLocal,
  roundDownToNearestHundredth,
} from '@shared';
import { FormEvent, useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { BiSolidErrorCircle } from 'react-icons/bi';

import { XCircleIcon } from '@heroicons/react/20/solid';

import { MdOutlineSmartButton } from 'react-icons/md';
import { MdLightbulb } from 'react-icons/md';
import PulseLoader from 'react-spinners/PulseLoader';
import { FaCheck, FaFileImport } from 'react-icons/fa6';
import { getCoupleLtcStartData } from '@shared';
import { OrderedSteps } from './OrderedSteps';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface PolicyDataExtractorProps {
  policyType: FundingPolicyType | null;
  handleImport: (PolicyField: ParsedPolicyDataExtractResponse | null) => void;
  setAbortController: (controller: AbortController | null) => void;
}

export function PolicyDataExtractor({
  policyType,
  handleImport,
  setAbortController,
}: PolicyDataExtractorProps) {
  if (!policyType || policyType === FundingPolicyType.allstateHybridPolicy) {
    return null;
  }

  const [policyTextAreaValue, setPolicyTextAreaValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { cognitoSession } = useSelector(selectSession);
  const { token: magicLinkToken } = useSelector(selectMagicLinkSession);
  const client = useSelector(selectClient);
  const {
    clientId,
    intakeSurvey: { clientBirthYear, clientGenderAtBirth },
    inferenceSet: { ltcAtAge },
    clientFullName,
    mutableClientPartner,
  } = client;
  const [policyDataExtractResponse, setPolicyDataExtractResponse] =
    useState<ParsedPolicyDataExtractResponse | null>(null);
  const [enhancedExtractionEnabled, setEnhancedExtractionEnabled] =
    useState(false);
  // state for progress bar
  const [latestRunIsComplete, setLatestRunIsComplete] = useState(false);
  const [latestRunApiCallCount, setLatestRunApiCallCount] = useState(0);
  const [latestRunCurrentApiCall, setLatestRunCurrentApiCall] = useState(0);
  const [importClicked, setImportClicked] = useState<boolean>(false);

  const enhancedExtractRuns = 5;
  const policyTypeKey = fundingPolicyTypeDefs[policyType].key;
  const policyTextPasted = policyTextAreaValue.trim().length > 100;

  const normalizeParsedFieldValue = (
    parsedFieldValue: number | string | null | undefined,
  ) => {
    // This is to handle cases where the same value is represented in slightly different ways
    if (isNullOrUndefined(parsedFieldValue)) {
      return 'null';
    }
    if (typeof parsedFieldValue === 'string') {
      return parsedFieldValue;
    }
    if (parsedFieldValue > 1000) {
      return Math.round(parsedFieldValue / 2) * 2; // Bucketing values by rounding to the nearest 2
    }
    return parsedFieldValue;
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (loading) return; // prevent multiple submissions
    setErrors([]);
    setPolicyDataExtractResponse(null);
    setLoading(true);
    setLatestRunIsComplete(false);
    setLatestRunCurrentApiCall(1);
    setImportClicked(false);

    const numberOfRequests = enhancedExtractionEnabled
      ? enhancedExtractRuns
      : 1;
    setLatestRunApiCallCount(numberOfRequests);
    const newPolicyDataExtractResponseSet: ParsedPolicyDataExtractResponse[] =
      [];
    const singleRunErrors = []; // collect errors from each run. do something with this later for better error handling

    for (let i = 0; i < numberOfRequests; i++) {
      setLatestRunCurrentApiCall(i + 1);
      try {
        const parsedPolicyDataExtractResponse =
          await getParsedPolicyDataExtractResponse();
        newPolicyDataExtractResponseSet.push(parsedPolicyDataExtractResponse);
      } catch (error: any) {
        singleRunErrors.push(error.message);
      }
    }

    if (newPolicyDataExtractResponseSet.length === 0) {
      setErrors([
        'We ran into a technical issue. Please try again or contact support.',
      ]);
      setLoading(false);
      return;
    }

    let finalPolicyDataExtractResponse: ParsedPolicyDataExtractResponse;

    if (newPolicyDataExtractResponseSet.length === 1) {
      // Post-process the aggregated response
      finalPolicyDataExtractResponse = newPolicyDataExtractResponseSet[0];
    } else {
      // Aggregate the responses
      const fieldCountMap: {
        [key in PolicyFieldId]?: { [value: string]: number };
      } = {};

      newPolicyDataExtractResponseSet.forEach(response => {
        Object.entries(response).forEach(([fieldId, field]) => {
          const id = fieldId as PolicyFieldId;
          const normalizedValue = normalizeParsedFieldValue(
            field?.parsedFieldValue,
          );
          if (!fieldCountMap[id]) {
            fieldCountMap[id] = {};
          }
          fieldCountMap[id]![normalizedValue] =
            (fieldCountMap[id]![normalizedValue] || 0) + 1;
        });
      });

      // Construct a response that contains the mode value of each field
      const modeResponse: ParsedPolicyDataExtractResponse = {};
      for (const [fieldId, valueCountMap] of Object.entries(fieldCountMap)) {
        const id = fieldId as PolicyFieldId;

        // Calculate the mode value
        const modeEntry = Object.entries(valueCountMap!).reduce((a, b) =>
          b[1] > a[1] ? b : a,
        );
        // value gets turned into a string during the reduce, so we need to convert it back to the original type
        const modeValue = parseFloat(modeEntry[0])
          ? parseFloat(modeEntry[0])
          : modeEntry[0];
        const modeCount = modeEntry[1];

        // Calculate the total count of all values for this field
        const totalCount = Object.values(valueCountMap!).reduce(
          (sum, count) => sum + count,
          0,
        );

        // Calculate the prevalence ratio of the mode value
        const modePrevalenceRatio = modeCount / totalCount;

        // Find the index of a response that has the mode value for this field
        const matchingFieldIndex = newPolicyDataExtractResponseSet.findIndex(
          response =>
            normalizeParsedFieldValue(response[id]?.parsedFieldValue) ===
            modeValue,
        );

        // If a matching field is found, add it to the aggregated response
        if (matchingFieldIndex !== -1) {
          const matchingField =
            newPolicyDataExtractResponseSet[matchingFieldIndex];
          // not type safe, to do later
          (modeResponse as any)[id] = {
            ...matchingField[id],
            modePrevalenceRatio, // Adding the modePrevalenceRatio to the response
          };
        } else {
          console.log(
            `field ${id} was ommitted from the modeResponse. modeValue: ${modeValue}`,
          );
        }
      }

      if (Object.keys(modeResponse).length === 0) {
        setErrors([
          'Unable to aggregate responses. Please try again or contact support.',
        ]);
        setLoading(false);
        return;
      }
      finalPolicyDataExtractResponse = modeResponse;
    }

    // Post-process the aggregated response
    const postProcessedPolicyDataExtractResponse = postProcessParsedResponse(
      finalPolicyDataExtractResponse,
      policyType,
    );

    setPolicyDataExtractResponse(postProcessedPolicyDataExtractResponse);
    setLoading(false);
    setLatestRunIsComplete(true);
  }

  async function getParsedPolicyDataExtractResponse(): Promise<ParsedPolicyDataExtractResponse> {
    const authHeader = magicLinkToken
      ? {
          Authorization: `Bearer ${magicLinkToken}`,
        }
      : cognitoSession
        ? {
            Authorization: `Bearer ${cognitoSession.idToken.jwtToken}`,
          }
        : undefined;

    const origin = new URL(document.URL).origin;
    const env = isRunningLocal() ? 'dev' : builtEnvironment;
    const policyAnalysisApiUrl = `${origin}/${env}/api/policy-upload-text-analysis`;

    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const coupleLtcStartData = isJointPolicy(policyType, mutableClientPartner)
      ? getCoupleLtcStartData(client, mutableClientPartner)
      : null;

    const policyAnalysisRequestBody = JSON.stringify({
      policyDocumentText: policyTextAreaValue,
      policyType: policyTypeKey,
      clientId,
      ltcAtAge,
      clientBirthYear,
      clientFullName,
      clientGenderAtBirth,
      policyActivationAgeClientIsPolicyHolder: coupleLtcStartData
        ? coupleLtcStartData.getPolicyHolderAgeAtPolicyActivation(true)
        : null,
      policyActivationAgePartnerIsPolicyHolder: coupleLtcStartData
        ? coupleLtcStartData.getPolicyHolderAgeAtPolicyActivation(false)
        : null,
    });

    const policyAnalysisRequestIdResponse = await fetch(policyAnalysisApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader,
      },
      body: policyAnalysisRequestBody,
    });

    if (!policyAnalysisRequestIdResponse.ok) {
      throw new Error(
        'An error occurred while processing your request. Please try again later or contact support.',
      );
    }

    const { requestId } = await policyAnalysisRequestIdResponse.json();
    const policyAnalysisFetchUrl = `${origin}/${env}/api/client/${requestId}/policy-extracted-data`;

    const policyExtractResponseDbRecord = await pollForResponse(
      policyAnalysisFetchUrl,
      authHeader,
      newAbortController,
    );

    if (!policyExtractResponseDbRecord) {
      throw new Error(
        'An error occurred while fetching the response. Please try again or contact support.',
      );
    }

    if (
      policyExtractResponseDbRecord.status === 'error' ||
      isNullOrUndefined(policyExtractResponseDbRecord.results)
    ) {
      if (
        policyExtractResponseDbRecord.error &&
        policyExtractResponseDbRecord.error.includes('rate_limit_exceeded')
      ) {
        throw new Error(
          'High usage is preventing us from processing your request. Please try again in a few minutes or try reducing the text length as key policy details are usually early in the document.',
        );
      } else {
        console.log(policyExtractResponseDbRecord.error || 'Unknown error');
        throw new Error(
          'An error occurred while processing your request. Please try again later or contact support.',
        );
      }
    }

    let policyAnalysisString = policyExtractResponseDbRecord.results;

    if (!containsBrackets(policyAnalysisString)) {
      console.log('invalid response : ', policyAnalysisString);
      throw new Error(
        `An error occurred. Invalid response format. Please try again later or contact support.`,
      );
    }

    policyAnalysisString = stripBeforeAndAfterBrackets(policyAnalysisString);

    let policyAnalysisObject;
    try {
      policyAnalysisObject = JSON.parse(policyAnalysisString);
    } catch (error) {
      throw new Error(
        `An error occurred. Unable to parse response. Please try again later or contact support.`,
      );
    }

    return parsePolicyData(policyAnalysisObject);
  }

  async function pollForResponse(
    url: string,
    authHeader: any,
    abortController: AbortController,
  ): Promise<PolicyExtractResponseDbRecord> {
    const startTime = Date.now();

    return new Promise((resolve, reject) => {
      const poll = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              ...authHeader,
            },
            signal: abortController.signal,
          });
          if (!response.ok) {
            throw new Error('An error occurred while fetching the response.');
          }
          const data: PolicyExtractResponseDbRecord = await response.json();
          if (data.status !== 'processing') {
            resolve(data);
          } else if (Date.now() - startTime < 2.5 * 60 * 1000) {
            // Check if less than 2.5 minutes have passed
            setTimeout(poll, 5000);
          } else {
            throw new Error(
              'The request took too long to process. Please try again later.',
            );
          }
        } catch (error: any) {
          reject(new Error(error.message));
        }
      };

      // Initial delay before starting to poll
      setTimeout(poll, 15000);
    });
  }

  const handleImportClick = () => {
    handleImport(policyDataExtractResponse);
    setImportClicked(true);
  };

  const policyDocumentTextArea = (
    <>
      <textarea
        id="policy-text"
        name="policy-text"
        rows={2}
        value={policyTextAreaValue}
        onChange={e => setPolicyTextAreaValue(e.target.value)}
        className="block w-full rounded-md border-0 bg-white px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
      <div className="mt-2 flex items-baseline gap-1 md:items-center md:pl-1">
        <MdLightbulb className="h-4 w-4 pt-1 text-gray-400 md:pt-0" />
        <p className="">
          <span className="font-semibold">Tip: </span> use{' '}
          <span className="rounded-md bg-gray-200 px-[3px] py-[1px]">
            ctrl+a
          </span>{' '}
          to select all of the text and then{' '}
          <span className="rounded-md bg-gray-200 px-[3px] py-[1px]">
            ctrl+c
          </span>{' '}
          to copy it.
        </p>
      </div>
    </>
  );

  const policyRunExtractorContent = (
    <>
      <div className="mt-2 flex justify-between gap-10">
        <div>
          <p className="text-base text-gray-700 ">Enhanced extraction</p>
          <p className="text-sm text-gray-400">
            Runs multiple extractions to provide greater accuracy.
          </p>
        </div>
        <Switch
          checked={enhancedExtractionEnabled}
          onChange={setEnhancedExtractionEnabled}
          className={classNames(
            enhancedExtractionEnabled ? 'bg-darkPurple' : 'bg-gray-200',
            'relative my-auto inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enhancedExtractionEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </div>
      <div className="mt-5 flex w-full flex-row-reverse">
        <div className="flex flex-col gap-1">
          {policyTextPasted && (
            // enabled button
            <button
              type="submit"
              className={`${loading ? 'cursor-progress' : 'hover:bg-purple'} w-[170px] rounded-lg bg-darkPurple px-5 py-2 text-base text-white shadow-md `}
            >
              {loading ? (
                <div className="flex items-center justify-center gap-2">
                  <p>Extracting</p>
                  <span className="mt-0.5">
                    <PulseLoader
                      color="#ffffff"
                      loading={true}
                      size={4}
                      margin={2}
                    />
                  </span>
                </div>
              ) : enhancedExtractionEnabled ? (
                'Enhanced Extract'
              ) : (
                'Basic Extract'
              )}
            </button>
          )}
          {loading || latestRunIsComplete ? (
            <ProgressBar
              numberOfIntervals={latestRunApiCallCount}
              currentInterval={latestRunCurrentApiCall}
              isComplete={latestRunIsComplete}
              expectedIntervalDuration={45}
              error={errors.length > 0}
            />
          ) : (
            <p className="mx-2 text-center text-sm  text-gray-400">
              Estimated time {enhancedExtractionEnabled ? ' 3-4 min' : ' 45s'}
            </p>
          )}
        </div>
      </div>
      <div>
        <PolicyAnalysisErrorAlert errors={errors} />
        <TableLoadingSkeleton loading={loading} />
        <PolicyAnalysisTable
          policyDataExtractResponse={policyDataExtractResponse}
        />
      </div>
    </>
  );

  return (
    <div className="mt-3 w-full">
      <div className="flex items-center gap-2 text-lg leading-5 text-darkPurple">
        <MdOutlineSmartButton className="h-7 w-7" />
        <p>Policy Data Extractor</p>
        <p className="rounded-full bg-purple px-2 py-1 text-xs leading-tight text-white">
          Beta
        </p>
      </div>
      <p className="mt-1 text-sm text-gray-700 md:text-base">
        Automatically extract key fields from your insurance policy documents
        and get precise page numbers and locations for verification.
      </p>
      <div className="w-full pb-2 pt-1">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 text-sm text-gray-700 md:ml-3"
        >
          <OrderedSteps
            steps={[1, 2]}
            stepTitles={[
              'Copy and Paste Policy Document',
              'Run Policy Extractor',
            ]}
            stepDescriptions={[
              'Paste your policy document text below.',
              'Run the extractor to get key policy details.',
            ]}
            childrens={[policyDocumentTextArea, policyRunExtractorContent]}
            stepsCompleted={[
              policyTextPasted,
              policyDataExtractResponse !== null,
            ]}
          />
        </form>

        <div
          className={`${policyDataExtractResponse ? 'mt-8 flex w-full flex-col justify-between px-2 text-gray-900' : 'hidden'}`}
        >
          <h2 className="flex items-center gap-2 pb-1 text-base font-semibold">
            <FaFileImport className="h-4 w-4" />
            Field Import
          </h2>
          <p className="pb-6 text-sm">
            By clicking “Import”, you confirm that you have reviewed and
            verified the accuracy of the extracted fields. You can edit the
            fields after importing the data.
          </p>
          <button
            type="button"
            disabled={importClicked}
            onClick={handleImportClick}
            className={`${importClicked ? `border border-green-600 bg-green-50 font-semibold text-green-600` : `bg-darkPurple text-white hover:bg-purple`} text-centershadow-md my-auto flex flex-grow items-center justify-center gap-4 whitespace-nowrap  rounded-lg px-3 py-2 `}
          >
            {importClicked ? 'Imported' : 'Import'}
            {importClicked && <FaCheck className="h-4 w-4" />}
          </button>
        </div>
      </div>
    </div>
  );
}

function containsBrackets(str: string): boolean {
  const hasOpeningBracket = str.includes('{');
  const hasClosingBracket = str.includes('}');
  return hasOpeningBracket && hasClosingBracket;
}

function stripBeforeAndAfterBrackets(str: string): string {
  const start = str.indexOf('{');
  const end = str.lastIndexOf('}');

  if (start === -1 || end === -1 || start > end) {
    // If no brackets are found or they are in the wrong order, return an empty string
    return '';
  }

  // Extract the substring between the first '[' and the last ']'
  return str.substring(start, end + 1);
}
export interface PolicyField {
  fieldTitle: string;
  fieldValue: any; // Assuming field values are returned as strings based on your example
  pageNumber: any;
  locationDescription: any;
}

export type PolicyFieldId =
  | 'homeCareWaitingPeriodDays'
  | 'facilityCareWaitingPeriodDays'
  | 'policyMaximumBenefitAmount'
  | 'policyMinimumGuaranteedBenefitAmount'
  | 'policyContinuationBenefitAmount'
  | 'policyTotalLTCBenefitAmount'
  | 'policyPremiumStartYear'
  | 'policyPremiumMonthlyCost'
  | 'policyLimitedPayYears'
  | 'policyLumpSumPayment'
  | 'dailyHomeBenefitAmount'
  | 'dailyIndependentLivingBenefitAmount'
  | 'dailyAssistedLivingBenefitAmount'
  | 'dailyNursingHomeBenefitAmount'
  | 'monthlyHomeBenefitAmount'
  | 'monthlyIndependentLivingBenefitAmount'
  | 'monthlyAssistedLivingBenefitAmount'
  | 'monthlyNursingHomeBenefitAmount'
  | 'policyInflationProtection'
  | 'policyInflationProtectionType'
  | 'policyPaymentType'
  | 'policyBenefitPeriodMonths'
  | 'isPrimaryPolicyHolder';

type PolicyDataExtractResponse = {
  [key in PolicyFieldId]?: PolicyField;
};

type PolicyFieldTypeMap = {
  homeCareWaitingPeriodDays?: number;
  facilityCareWaitingPeriodDays?: number;
  policyMaximumBenefitAmount?: number;
  policyMinimumGuaranteedBenefitAmount?: number;
  policyContinuationBenefitAmount?: number;
  policyTotalLTCBenefitAmount?: number;
  policyPremiumStartYear?: number;
  policyPremiumMonthlyCost?: number;
  policyLimitedPayYears?: number;
  policyLumpSumPayment?: number;
  dailyHomeBenefitAmount?: number;
  dailyIndependentLivingBenefitAmount?: number;
  dailyAssistedLivingBenefitAmount?: number;
  dailyNursingHomeBenefitAmount?: number;
  monthlyHomeBenefitAmount?: number;
  monthlyIndependentLivingBenefitAmount?: number;
  monthlyAssistedLivingBenefitAmount?: number;
  monthlyNursingHomeBenefitAmount?: number;
  policyInflationProtection?: number;
  policyInflationProtectionType?: 'Compound' | 'Simple';
  policyPaymentType?: 'Reimbursement' | 'Indemnity';
  policyBenefitPeriodMonths?: number;
  isPrimaryPolicyHolder?: 'Yes' | 'No';
};

export type ParsedPolicyDataExtractResponse = {
  [key in PolicyFieldId]?: PolicyField & {
    parsedFieldValue: PolicyFieldTypeMap[key];
    modePrevalenceRatio?: number;
  };
};

function isFieldAndParsedValueDefined(
  policyField:
    | (PolicyField & {
        parsedFieldValue: number | undefined;
      })
    | undefined,
): policyField is PolicyField & { parsedFieldValue: number } {
  return (
    policyField !== undefined && policyField.parsedFieldValue !== undefined
  );
}

function imputeMissingMonthlyMaxValues(
  parsedResponse: ParsedPolicyDataExtractResponse,
): ParsedPolicyDataExtractResponse {
  const {
    monthlyHomeBenefitAmount,
    monthlyAssistedLivingBenefitAmount,
    monthlyIndependentLivingBenefitAmount,
    monthlyNursingHomeBenefitAmount,
  } = parsedResponse;

  const firstNonZeroFacilityMonthlyBenefit =
    monthlyAssistedLivingBenefitAmount?.parsedFieldValue ||
    monthlyIndependentLivingBenefitAmount?.parsedFieldValue ||
    monthlyNursingHomeBenefitAmount?.parsedFieldValue;

  // impute home care monthly max if it is missing with facility monthly max
  if (isFieldAndParsedValueDefined(monthlyHomeBenefitAmount)) {
    if (
      firstNonZeroFacilityMonthlyBenefit &&
      !monthlyHomeBenefitAmount.parsedFieldValue
    ) {
      monthlyHomeBenefitAmount.fieldValue =
        firstNonZeroFacilityMonthlyBenefit.toString();
      monthlyHomeBenefitAmount.parsedFieldValue =
        firstNonZeroFacilityMonthlyBenefit;
      monthlyHomeBenefitAmount.locationDescription =
        'Imputed from first non-zero facility monthly benefit';
    }
  }

  const imputeBenefitAmount = (
    targetField: (PolicyField & { parsedFieldValue: any }) | undefined,
    fillValue: number | undefined,
    fallbackValue: number | undefined,
    description: string,
    fallBackDescription: string,
  ) => {
    if (targetField) {
      if (fillValue) {
        targetField.fieldValue = fillValue.toString();
        targetField.parsedFieldValue = fillValue;
        targetField.locationDescription = description;
      } else if (fallbackValue) {
        targetField.fieldValue = fallbackValue.toString();
        targetField.parsedFieldValue = fallbackValue;
        targetField.locationDescription = fallBackDescription;
      }
    }
  };

  imputeBenefitAmount(
    monthlyAssistedLivingBenefitAmount,
    firstNonZeroFacilityMonthlyBenefit,
    monthlyHomeBenefitAmount?.parsedFieldValue,
    'Imputed from other facility monthly max',
    'Imputed from Home Care monthly max',
  );

  imputeBenefitAmount(
    monthlyIndependentLivingBenefitAmount,
    firstNonZeroFacilityMonthlyBenefit,
    monthlyHomeBenefitAmount?.parsedFieldValue,
    'Imputed from other facility monthly max',
    'Imputed from Home Care monthly max',
  );

  imputeBenefitAmount(
    monthlyNursingHomeBenefitAmount,
    firstNonZeroFacilityMonthlyBenefit,
    monthlyHomeBenefitAmount?.parsedFieldValue,
    'Imputed from other facility monthly max',
    'Imputed from Home Care monthly max',
  );

  return parsedResponse;
}

function calculateDailyMaxFromMonthlyMax(
  parsedResponse: ParsedPolicyDataExtractResponse,
): ParsedPolicyDataExtractResponse {
  const {
    dailyHomeBenefitAmount,
    dailyAssistedLivingBenefitAmount,
    dailyIndependentLivingBenefitAmount,
    dailyNursingHomeBenefitAmount,
    monthlyHomeBenefitAmount,
    monthlyAssistedLivingBenefitAmount,
    monthlyIndependentLivingBenefitAmount,
    monthlyNursingHomeBenefitAmount,
  } = parsedResponse;

  const calculateDailyMax = (
    targetField: (PolicyField & { parsedFieldValue: any }) | undefined,
    monthlyMaxField: (PolicyField & { parsedFieldValue: any }) | undefined,
    description: string,
  ) => {
    const daysInMonth = 30;
    if (
      isFieldAndParsedValueDefined(targetField) &&
      isFieldAndParsedValueDefined(monthlyMaxField) &&
      monthlyMaxField.parsedFieldValue > 0
    ) {
      const dailyMax = roundDownToNearestHundredth(
        monthlyMaxField.parsedFieldValue / daysInMonth,
      );
      targetField.fieldValue = dailyMax.toString();
      targetField.parsedFieldValue = dailyMax;
      targetField.locationDescription = description;
    }
  };

  calculateDailyMax(
    dailyHomeBenefitAmount,
    monthlyHomeBenefitAmount,
    'Calculated from Monthly Max Benefit for Home Care',
  );

  calculateDailyMax(
    dailyAssistedLivingBenefitAmount,
    monthlyAssistedLivingBenefitAmount,
    'Calculated from Monthly Max Benefit for Assisted Living Facility Care',
  );

  calculateDailyMax(
    dailyIndependentLivingBenefitAmount,
    monthlyIndependentLivingBenefitAmount,
    'Calculated from Monthly Max Benefit for Independent Living Facility Care',
  );

  calculateDailyMax(
    dailyNursingHomeBenefitAmount,
    monthlyNursingHomeBenefitAmount,
    'Calculated from Monthly Max Benefit for Nursing Home Facility Care',
  );

  return parsedResponse;
}

function imputeMissingDailyMaxValues(
  parsedResponse: ParsedPolicyDataExtractResponse,
): ParsedPolicyDataExtractResponse {
  const {
    dailyHomeBenefitAmount,
    dailyAssistedLivingBenefitAmount,
    dailyIndependentLivingBenefitAmount,
    dailyNursingHomeBenefitAmount,
  } = parsedResponse;

  const firstNonZeroFacilityDailyBenefit =
    dailyAssistedLivingBenefitAmount?.parsedFieldValue ||
    dailyIndependentLivingBenefitAmount?.parsedFieldValue ||
    dailyNursingHomeBenefitAmount?.parsedFieldValue;

  if (dailyHomeBenefitAmount && !dailyHomeBenefitAmount.parsedFieldValue) {
    if (firstNonZeroFacilityDailyBenefit) {
      dailyHomeBenefitAmount.fieldValue =
        firstNonZeroFacilityDailyBenefit.toString();
      dailyHomeBenefitAmount.parsedFieldValue =
        firstNonZeroFacilityDailyBenefit;
      dailyHomeBenefitAmount.locationDescription =
        'Imputed from first non-zero facility daily benefit';
    }
  }

  const imputeBenefitAmount = (
    targetField: (PolicyField & { parsedFieldValue: any }) | undefined,
    fillValue: number | undefined,
    fallbackValue: number | undefined,
    description: string,
    fallBackDescription: string,
  ) => {
    if (targetField && !targetField.parsedFieldValue) {
      if (fillValue) {
        targetField.fieldValue = fillValue.toString();
        targetField.parsedFieldValue = fillValue;
        targetField.locationDescription = description;
      } else if (fallbackValue) {
        targetField.fieldValue = fallbackValue.toString();
        targetField.parsedFieldValue = fallbackValue;
        targetField.locationDescription = fallBackDescription;
      }
    }
  };

  imputeBenefitAmount(
    dailyAssistedLivingBenefitAmount,
    firstNonZeroFacilityDailyBenefit,
    dailyHomeBenefitAmount?.parsedFieldValue,
    'Imputed from other facility daily max',
    'Imputed from Home Care daily max',
  );

  imputeBenefitAmount(
    dailyIndependentLivingBenefitAmount,
    firstNonZeroFacilityDailyBenefit,
    dailyHomeBenefitAmount?.parsedFieldValue,
    'Imputed from other facility daily max',
    'Imputed from Home Care daily max',
  );

  imputeBenefitAmount(
    dailyNursingHomeBenefitAmount,
    firstNonZeroFacilityDailyBenefit,
    dailyHomeBenefitAmount?.parsedFieldValue,
    'Imputed from other facility daily max',
    'Imputed from Home Care daily max',
  );

  return parsedResponse;
}

function calculateCOB(
  parsedResponse: ParsedPolicyDataExtractResponse,
  policyType: FundingPolicyType | null,
) {
  const {
    policyContinuationBenefitAmount,
    policyTotalLTCBenefitAmount,
    policyMaximumBenefitAmount,
  } = parsedResponse;

  // if COB is unlimited, do not calculate
  if (
    checkPolicyCalculationTypeEquals(
      policyType,
      FundingPolicyType.hybridLifeInsurance,
    ) &&
    policyContinuationBenefitAmount &&
    typeof policyContinuationBenefitAmount.fieldValue === 'string' &&
    policyContinuationBenefitAmount.fieldValue
      .toLowerCase()
      .includes('unlimited')
  ) {
    return parsedResponse;
  }

  const calculatedCOB =
    (policyTotalLTCBenefitAmount?.parsedFieldValue ?? 0) -
    (policyMaximumBenefitAmount?.parsedFieldValue ?? 0);

  // if COB is null and total LTC benefit amount is imported, calculate COB
  if (
    checkPolicyCalculationTypeEquals(
      policyType,
      FundingPolicyType.hybridLifeInsurance,
    ) &&
    isFieldAndParsedValueDefined(policyContinuationBenefitAmount) &&
    isFieldAndParsedValueDefined(policyTotalLTCBenefitAmount)
  ) {
    policyContinuationBenefitAmount.fieldValue = calculatedCOB.toString();
    policyContinuationBenefitAmount.parsedFieldValue = calculatedCOB;
    policyContinuationBenefitAmount.locationDescription =
      'Calculated from Total Long-Term Care Benefits Amount and Face Amount (Death Benefit)';
  }

  // if COB and total LTC benefit amount are imported, check if calculated COB is different from imported COB
  if (
    checkPolicyCalculationTypeEquals(
      policyType,
      FundingPolicyType.hybridLifeInsurance,
    ) &&
    isFieldAndParsedValueDefined(policyContinuationBenefitAmount) &&
    isFieldAndParsedValueDefined(policyTotalLTCBenefitAmount) &&
    policyContinuationBenefitAmount.parsedFieldValue !== calculatedCOB
  ) {
    policyContinuationBenefitAmount.fieldValue = calculatedCOB.toString();
    policyContinuationBenefitAmount.parsedFieldValue = calculatedCOB;
    policyContinuationBenefitAmount.locationDescription =
      'Calculated from Total Long-Term Care Benefits Amount and Face Amount (Death Benefit)';
  }
  return parsedResponse;
}

function checkLimitedPayPeriodAgainstPremiums(
  parsedResponse: ParsedPolicyDataExtractResponse,
): ParsedPolicyDataExtractResponse {
  const {
    policyLimitedPayYears,
    policyPremiumMonthlyCost,
    policyLumpSumPayment,
  } = parsedResponse;

  // if lump sum payment is greater than 0 and monthly premium is 0 or undefined, limited pay period should be null
  if (
    isFieldAndParsedValueDefined(policyLumpSumPayment) &&
    policyLumpSumPayment.parsedFieldValue > 0 &&
    policyLimitedPayYears &&
    (policyPremiumMonthlyCost === undefined ||
      !policyPremiumMonthlyCost?.parsedFieldValue)
  ) {
    policyLimitedPayYears.fieldValue = null;
    policyLimitedPayYears.parsedFieldValue = undefined;
    policyLimitedPayYears.locationDescription = null;
  }

  return parsedResponse;
}

const postProcessParsedResponse = (
  parsedResponse: ParsedPolicyDataExtractResponse,
  policyType: FundingPolicyType | null,
): ParsedPolicyDataExtractResponse => {
  // Impute missing monthly max values if necessary
  parsedResponse = imputeMissingMonthlyMaxValues(parsedResponse);

  // Calculate daily max from monthly max if necessary
  parsedResponse = calculateDailyMaxFromMonthlyMax(parsedResponse);

  // Impute missing daily max values if necessary
  parsedResponse = imputeMissingDailyMaxValues(parsedResponse);

  // Calculate COB if necessary
  parsedResponse = calculateCOB(parsedResponse, policyType);

  // Check limited pay period against premiums
  parsedResponse = checkLimitedPayPeriodAgainstPremiums(parsedResponse);

  return parsedResponse;
};

function parseUnlimitedField(fieldValue: string): number | null {
  return fieldValue.toLowerCase().includes('unlimited')
    ? null
    : parseFloat(fieldValue);
}

function parseFieldValue(fieldId: PolicyFieldId, fieldValue: string): any {
  switch (fieldId) {
    case 'policyInflationProtectionType':
    case 'policyPaymentType':
    case 'isPrimaryPolicyHolder':
      return fieldValue as PolicyFieldTypeMap[typeof fieldId];
    case 'policyContinuationBenefitAmount':
      return parseUnlimitedField(fieldValue);
    case 'policyMaximumBenefitAmount':
      return parseUnlimitedField(fieldValue);
    default:
      return parseFloat(removeCommas(fieldValue));
  }
}

function parsePolicyData(
  response: PolicyDataExtractResponse,
): ParsedPolicyDataExtractResponse {
  const parsedResponse: Partial<ParsedPolicyDataExtractResponse> = {};

  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const fieldId = key as PolicyFieldId;
      const field = response[fieldId];
      if (field) {
        parsedResponse[fieldId] = {
          ...field,
          parsedFieldValue: isNullish(field.fieldValue)
            ? null
            : typeof field.fieldValue === 'string'
              ? parseFieldValue(fieldId, field.fieldValue)
              : field.fieldValue,
        };
      }
    }
  }

  return parsedResponse as ParsedPolicyDataExtractResponse;
}

const removeCommas = (value: string) => value.replace(/,/g, '');

function PolicyAnalysisTable({
  policyDataExtractResponse,
}: {
  policyDataExtractResponse: ParsedPolicyDataExtractResponse | null;
}) {
  if (!policyDataExtractResponse) {
    return null;
  }

  const isModePrevalencePresent = Object.values(policyDataExtractResponse).some(
    field => !isNullOrUndefined(field?.modePrevalenceRatio),
  );

  const categorizeConsensusScore = (score: number | null | undefined) => {
    if (isNullOrUndefined(score)) {
      return '';
    }
    const highScoreThreshold = 0.75;
    const mediumScoreThreshold = 0.55;
    if (score >= highScoreThreshold) {
      return <p className="text-green-700">High</p>;
    } else if (score >= mediumScoreThreshold) {
      return <p className="text-yellow-700">Medium</p>;
    } else {
      return <p className="text-red-400">Low</p>;
    }
  };

  function roundValue(value: string | number): string | number {
    if (typeof value === 'number') {
      return roundDownToNearestHundredth(value);
    } else if (typeof value === 'string') {
      const parsedValue = parseFloat(removeCommas(value));
      if (!isNaN(parsedValue)) {
        return roundDownToNearestHundredth(parsedValue);
      }
    }
    return value;
  }

  return (
    <div>
      <div className="w-full px-2 py-3">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Policy Analysis Results
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              The below table contains the fields that were extracted from the
              policy document. Any fields that were not found are not displayed.
            </p>
            {isModePrevalencePresent && (
              <p className="mt-3 text-sm text-gray-700">
                <span className="font-semibold">Consensus Score</span>: Measures
                the prevalence of an extracted value across multiple,
                independent runs. A higher rating indicates a greater degree of
                agreement. This rating does not necessarily reflect the accuracy
                of the extraction but rather the consistency of the value based
                on the collective output.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 flow-root overflow-auto">
        <div className="px-2">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr className="border-gray-200' border-b">
                <th
                  scope="col"
                  className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Field
                  <div className="absolute inset-y-0 right-full -z-10 w-full " />
                  <div className="absolute inset-y-0 left-0 -z-10 w-full " />
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Value
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Page
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Location
                </th>
                {isModePrevalencePresent && (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Consensus
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {Object.entries(policyDataExtractResponse).map(
                ([
                  fieldId,
                  {
                    fieldTitle,
                    fieldValue,
                    pageNumber,
                    locationDescription,
                    modePrevalenceRatio,
                  },
                ]) => (
                  <tr
                    key={fieldTitle}
                    className={`${isNullish(fieldValue) ? 'hidden' : 'border-b border-gray-100'}`}
                  >
                    <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                      {fieldTitle}
                      <div className="absolute bottom-0 right-full h-px w-full " />
                      <div className="absolute bottom-0 left-0 h-px w-full " />
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {roundValue(fieldValue)}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {isNullish(pageNumber) ? '' : pageNumber}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {locationDescription}
                    </td>
                    {isModePrevalencePresent && (
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {categorizeConsensusScore(modePrevalenceRatio)}
                      </td>
                    )}
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
interface PolicyAnalysisErrorAlertProps {
  errors: string[];
}

function PolicyAnalysisErrorAlert(props: PolicyAnalysisErrorAlertProps) {
  const errorCount = props.errors.length;
  if (errorCount === 0) {
    return null;
  }
  const errorMessage = `We're sorry. ${props.errors[0]}`;
  return (
    <div className="my-2 rounded-md bg-red-50 p-3">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
        </div>
      </div>
    </div>
  );
}
interface LoadingAnimationProps {
  loading: boolean;
}

function TableLoadingSkeleton(props: LoadingAnimationProps) {
  return (
    <div
      className={`${props.loading ? 'my-4 flex flex-col items-center justify-center gap-4 text-center' : 'hidden'}`}
    >
      <div
        role="status"
        className="w-full animate-pulse space-y-4 divide-y divide-gray-200 rounded border border-l-0 border-r-0 border-gray-200 p-4  md:p-6 "
      >
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className={`flex items-center justify-between pt-${index === 0 ? 0 : 4}`}
            >
              <div>
                <div className="mb-2.5 h-2 w-24 rounded-full bg-gray-300 dark:bg-gray-500"></div>
                <div className="h-2 w-32 rounded-full bg-gray-200 dark:bg-gray-500"></div>
              </div>
              <div className="h-2 w-12 rounded-full bg-gray-300 dark:bg-gray-500"></div>
            </div>
          ))}
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export const isNullish = (value: any) =>
  value === null ||
  value === undefined ||
  value === 'None' ||
  value === '' ||
  value === 'null' ||
  value === 'undefined' ||
  value === 'NULL';

interface ProgressBarProps {
  numberOfIntervals: number;
  currentInterval: number;
  isComplete: boolean;
  expectedIntervalDuration: number;
  error?: boolean;
}

function ProgressBar({
  numberOfIntervals,
  currentInterval,
  isComplete,
  expectedIntervalDuration,
  error,
}: ProgressBarProps) {
  const [progress, setProgress] = useState(0);
  const [intervalStartTime, setIntervalStartTime] = useState(Date.now());

  useEffect(() => {
    if (isComplete || error) {
      setProgress(100);
      return;
    }

    // Update the start time when the currentInterval changes
    if (currentInterval > 0) {
      setIntervalStartTime(Date.now());
    }

    const completedIntervals = currentInterval - 1;
    const completedIntervalsProgress =
      (completedIntervals / numberOfIntervals) * 100;
    const intervalPercentage = 100 / numberOfIntervals;
    const maxIntervalFillPercentage = intervalPercentage * 0.95;

    const updateProgress = () => {
      const elapsedIntervalTime = Date.now() - intervalStartTime;
      const elapsedIntervalPercentage =
        (elapsedIntervalTime / (expectedIntervalDuration * 1000)) * 95;
      const remainingIntervalProgress = Math.min(
        maxIntervalFillPercentage,
        elapsedIntervalPercentage / numberOfIntervals,
      );
      const totalProgress =
        completedIntervalsProgress + remainingIntervalProgress;
      setProgress(totalProgress);
    };

    updateProgress(); // Initial call to set progress

    const timer = setInterval(updateProgress, 1000);

    return () => clearInterval(timer);
  }, [
    currentInterval,
    numberOfIntervals,
    isComplete,
    expectedIntervalDuration,
    intervalStartTime,
    error,
  ]);

  return (
    <div className="mt-1 flex flex-col gap-1">
      <div className="h-2.5 w-full overflow-hidden rounded-full bg-gray-200">
        <div
          className={`h-full rounded-full transition-all duration-500 ease-in-out ${error ? 'bg-red-500' : 'bg-green-500'}`}
          style={{ width: `${progress}%` }}
        />
      </div>
      {!isComplete && !error && (
        <div className="flex">
          <p className="text-xs text-gray-400">
            {Math.round(progress)}% complete
          </p>
        </div>
      )}
      {isComplete && !error && (
        <div className="flex flex-row-reverse justify-between">
          <FaRegCheckCircle className="h-4 w-4 text-right text-green-500" />
          <p className="text-right text-xs text-gray-400">
            Complete, results below
          </p>
        </div>
      )}
      {error && (
        <div className="flex flex-row-reverse justify-between">
          <BiSolidErrorCircle className="h-4 w-4 text-right text-red-500" />
          <p className="text-xs text-gray-400">Error</p>
        </div>
      )}
    </div>
  );
}
