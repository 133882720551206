import { useSelector } from 'react-redux';
import { OnboardingChrome } from '../components';
import {
  selectClient,
} from '../model';
import moduleStyles from './OnboardingPredictedTimeline.module.css';
import mainStyles from '../styles/main.module.css';
import CarePhaseTimeline from '../components/CarePhaseTimeline';

import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import CheckroomOutlinedIcon from '@mui/icons-material/CheckroomOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import AirlineSeatIndividualSuiteOutlinedIcon from '@mui/icons-material/AirlineSeatIndividualSuiteOutlined';
import SoapOutlinedIcon from '@mui/icons-material/SoapOutlined';
import { SvgIconProps, Tooltip } from '@mui/material';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};

export function OnboardingPredictedTimeline(_props: ClientIdProps) {
  const client = useSelector(selectClient);
  const { clientFirstName } = client;

  return (
    <OnboardingChrome
      slidePage={OnboardingPredictedTimeline}
      title={`${ clientFirstName }'s predicted care timeline`}
      nextButtonLabel={`What will this cost?`}
    >
      <div className={styles.content}>

        <ColumnTimeline client={client} />
        <ColumnExplainerSection />

      </div>
    </OnboardingChrome>
  );
}

interface ColumnTimelineProps {
  client: Client;
}

function ColumnTimeline({ client }: ColumnTimelineProps) {
  return (
    <div className={styles.column}>
      <CarePhaseTimeline client={client} />
    </div>
  );
}

interface FormattedIconProps {
  Icon: React.ComponentType<SvgIconProps>;
  tooltipDescription: string;
}

const FormattedIcon = ({ Icon, tooltipDescription }: FormattedIconProps) => {
  return <Tooltip title={tooltipDescription} classes={{ tooltip: styles.tooltipContainer }}>
    <Icon sx={{ color: '#454446', marginRight: '5px' }} />
  </Tooltip>;
};


function ColumnExplainerSection() {
  return (
    <div className={styles.column}>
      <div className={styles.explainerSection}>
        <div className={styles.carePhase}>
          <div className={styles.subtitle}>
            What is a Care Phase?
          </div>
          <div className={styles.subtext}>
            A period of time in long-term care defined by the number of basic self-care tasks an individual requires help with. Each phase indicates a higher level of care needed as a person progresses through their long-term care journey.
          </div>
        </div>
        <div className={styles.basicSelfCareTasks}>
          <div className={styles.subtitle}>
            Basic Self-Care Tasks
          </div>
          <div className={styles.subtext}>
            Activities individuals perform on a daily basis that are fundamental to caring for oneself.
          </div>
          <div className={styles.iconRow}>
            <FormattedIcon Icon={BathtubOutlinedIcon} tooltipDescription="Bathing" />
            <FormattedIcon Icon={CheckroomOutlinedIcon} tooltipDescription="Dressing" />
            <FormattedIcon Icon={DirectionsWalkOutlinedIcon} tooltipDescription="Walking" />
            <FormattedIcon Icon={RestaurantOutlinedIcon} tooltipDescription="Eating" />
            <FormattedIcon Icon={AirlineSeatIndividualSuiteOutlinedIcon} tooltipDescription="Getting in/out of bed" />
            <FormattedIcon Icon={SoapOutlinedIcon} tooltipDescription="Toileting" />
          </div>
        </div>

      </div>
    </div>
  );
}