import { Dialog, DialogTitle, DialogContent, Stack, FormGroup, FormControlLabel, Button, } from '@mui/material';
import { builtEnvironment, getBaseUrl, isRunningLocal, } from '@shared';
import mainStyles from '../styles/main.module.css';
import dialogStyles from './AddClientDialog.module.css'
import ThemedSwitch from './ThemedSwitch';
import { useCallback, useState } from 'react';
import { Link } from '@mui/icons-material';

const styles = {
    ...mainStyles,
    ...dialogStyles
}


interface GenerateIntakeFormCTALinkDialogProps {
    onClose: () => void;
    advisorId: string;
}

export function GenerateIntakeFormCTALinkDialog(props: GenerateIntakeFormCTALinkDialogProps) {
    const [shouldSendClientEmail, setShouldSendClientEmail] = useState(true);
    const [copyOptionSelected, setCopyOptionSelected] = useState(false);

    const { onClose, advisorId } = props;

    const baseUrl = getBaseUrl()
    const env = isRunningLocal() ? 'dev' : builtEnvironment;
    const ctaLink = `${baseUrl}/${env}/api/public/intake-form/${encodeURIComponent(advisorId)}?should_send_client_email=${encodeURIComponent(shouldSendClientEmail)}`;

    const copyLinkToClipboard = useCallback(
        () => {
            navigator.clipboard.writeText(ctaLink);
            setCopyOptionSelected(true);
            setTimeout(() => {
                setCopyOptionSelected(false);
                onClose();
            }, 1000)
        },
        [ctaLink]
    );

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle className={styles.dialogTitle}>Generate Intake Form CTA Link</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <p className={styles.captionText}>
                        Copy this link and add it to your website for self service intake form completion
                    </p>
                    <p className={styles.regularText}>
                        Do you want us to send this client an email with a link to their results once the intake form is submitted?
                    </p>
                    <FormGroup sx={{ width: 'max-content' }}>
                        <FormControlLabel className={styles.labelStyle} control={<ThemedSwitch defaultChecked value={shouldSendClientEmail
                        } onChange={(e) => setShouldSendClientEmail(e.target.checked)
                        } />} label="Email client results link" />
                    </FormGroup>
                </Stack>
            </DialogContent>
            <Stack direction={'row'} spacing={4} paddingY={'10px'} justifyContent={'space-between'} paddingX={'1rem'}>
                <span>
                    <Button onClick={onClose} className={styles.buttonType4}>
                        Close
                    </Button>
                </span>
                <span>
                    <Button onClick={copyLinkToClipboard} className={styles.buttonType1} startIcon={<Link />}>{copyOptionSelected ? 'Copied!' : 'Copy'}</Button>
                </span>
            </Stack>
        </Dialog >
    )
}