import { useSelector } from 'react-redux';
import { OnboardingChrome } from '../components';
import { selectClient } from '../model';
import { formatCurrency } from '@shared';
import React from 'react';
import { SlideContent } from '../components/SlideContent';
import {
  LearnMoreSlideOut,
  LtcCostsSlideOutContent,
  ReducedCostSlideOutContent,
  possessivePronoun,
} from '..';
import { OnboardingResultsAreIn } from '../pages';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import classNames from 'classnames';

export function OnboardingTotalCost({ clientId }: ClientIdProps) {
  const client = useSelector(selectClient)!;
  const {
    allPhaseCosts: {
      allPhaseInflatedProfessionalShareCost,
      allPhaseProfessionalShareCost,
    },
  } = client;
  const {
    clientFirstName,
    intakeSurvey: { clientHasStartedLtc },
    inferenceSet: { ltcAtAge },
    dangerZoneCalculations: {
      potentialSavings: potentialDangerZoneSavings,
      isInDangerZone,
    },
  } = client;

  return (
    <OnboardingChrome
      slidePage={OnboardingTotalCost}
      nextButtonLabel={`Explore ${clientFirstName}'s Cost Factors`}
      previousSlidePage={
        clientHasStartedLtc ? OnboardingResultsAreIn : undefined
      }
    >
      <SlideContent
        headerText={
          <>
            <span className="flex items-center">
              {clientFirstName}&apos;s estimated{' '}
              {clientHasStartedLtc ? 'remaining' : ''} long-term care costs
            </span>
          </>
        }
      >
        <div
          className={classNames(
            'grid grid-cols-1 gap-8',
            potentialDangerZoneSavings > 0
              ? 'md:grid-cols-2'
              : 'md:grid-cols-1 ',
          )}
        >
          <div
            className={classNames(
              potentialDangerZoneSavings > 0
                ? ''
                : 'relative mb-8 flex flex-col rounded-2xl border-2 border-green-600 bg-white p-8 shadow-lg',
            )}
          >
            <p className="mx-2 my-4 text-base text-gray-700  md:text-xl/8">
              {clientHasStartedLtc ? (
                <>
                  {clientFirstName}&apos;s expected costs for the remainder of{' '}
                  {possessivePronoun(client.intakeSurvey)} long-term care:
                </>
              ) : (
                <>
                  By the time {clientFirstName} is expected to receive long-term
                  care at{' '}
                  {<span className="font-semibold">age {ltcAtAge}</span>},{' '}
                  {possessivePronoun(client.intakeSurvey)} estimated care costs
                  will be:
                </>
              )}
            </p>

            <div className="my-2 flex items-baseline text-darkPurple">
              <div className="flex text-3xl font-semibold tracking-tight md:text-5xl ">
                {formatCurrency(allPhaseInflatedProfessionalShareCost)}
                <LearnMoreSlideOut
                  slideOutContent={
                    <LtcCostsSlideOutContent
                      allPhaseInflatedProfessionalShareCost={
                        allPhaseInflatedProfessionalShareCost
                      }
                      clientHasStartedLtc={clientHasStartedLtc}
                      allPhaseProfessionalShareCost={
                        allPhaseProfessionalShareCost
                      }
                    />
                  }
                  isModelDescription={true}
                >
                  <BsFillQuestionCircleFill
                    className="h-4 w-4 -translate-y-1 pl-1 text-gray-400 hover:text-gray-500 md:h-5 md:w-5 md:-translate-y-3"
                    aria-hidden="true"
                  />
                </LearnMoreSlideOut>
              </div>
            </div>
          </div>
          {potentialDangerZoneSavings > 0 ? (
            <div className="mt-2 max-w-2xl flex-1 space-y-10 md:max-w-[90%] lg:grid lg:max-w-2xl lg:grid-cols-1 lg:gap-x-8 lg:space-y-0 lg:px-0">
              <div
                key="1"
                className="relative mb-8 flex flex-col rounded-2xl border-2 border-green-600 bg-white p-8 shadow-lg"
              >
                <div className="flex-1">
                  <p className="mx-2 mb-4 text-base text-gray-700  md:text-xl/8">
                    {isInDangerZone
                      ? `With some changes to ${possessivePronoun(client.intakeSurvey)}
                  care plan, ${clientFirstName} could cover
                  ${possessivePronoun(client.intakeSurvey)} predicted LTC needs today by setting aside:`
                      : `Using your investment assumptions, ${clientFirstName} could cover
                  ${possessivePronoun(client.intakeSurvey)} predicted LTC needs today by setting aside:`}
                  </p>

                  <p className="mx-2 my-2 flex items-baseline text-darkPurple">
                    <span className="text-3xl font-semibold tracking-tight md:text-5xl ">
                      {formatCurrency(
                        allPhaseInflatedProfessionalShareCost -
                          potentialDangerZoneSavings,
                      )}
                      <LearnMoreSlideOut
                        slideOutContent={
                          <ReducedCostSlideOutContent
                            potentialDangerZoneSavings={
                              potentialDangerZoneSavings
                            }
                            allPhaseInflatedProfessionalShareCost={
                              allPhaseInflatedProfessionalShareCost
                            }
                            clientFirstName={clientFirstName}
                          />
                        }
                        isModelDescription={true}
                      >
                        <BsFillQuestionCircleFill
                          className="ml-1 h-3 w-3 -translate-y-3 text-gray-400 hover:text-gray-500 md:ml-2 md:h-4 md:w-4 md:-translate-y-5"
                          aria-hidden="true"
                        />
                      </LearnMoreSlideOut>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </SlideContent>
    </OnboardingChrome>
  );
}
