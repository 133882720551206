import { Link } from 'react-router-dom';
import { TextField, InputAdornment, Button } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { UnauthenticatedHeader } from '../components/UnauthenticatedHeader';
import { CustomizedTextInput } from '../../../shared/src/customizedTextField';
import mainStyles from '../styles/main.module.css';
import moduleStyles from '../pages/AuthLogin.module.css';
import { ChangeEvent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useNavigateToPage } from '..';
import { AuthPasswordReset } from '../pages';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};

export interface AuthLoginComponentProps {
  username: string;
  password: string;
  hasUsernameError: boolean;
  hasPasswordError: boolean;
  errorMessage: string;
  onChangeUsername: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmitLogin: () => void;
}

export function AuthLoginComponent(props: AuthLoginComponentProps) {
  const {
    username,
    password,
    hasUsernameError,
    hasPasswordError,
    errorMessage,
    onChangeUsername,
    onChangePassword,
    onSubmitLogin,
  } = props;

  const navigateToPage = useNavigateToPage();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleClickForgotPassword = useCallback(() => {
    navigateToPage(AuthPasswordReset);
    setShowForgotPassword(true);
  }, [setShowForgotPassword]);

  const handleSubmitLogin = useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmitLogin();
    },
    [onSubmitLogin],
  );

  return (
    <>
      <UnauthenticatedHeader />
      <div className={styles.main}>
        <div className="py-2 text-4xl text-darkPurple">Welcome Back</div>
        <div className={styles.captionText}>
          Enter your credentials to access your account
        </div>
        <div>
          <form
            className="max-w-lg"
            onSubmit={handleSubmitLogin}
          >
            <div className={styles.errorText}>{errorMessage}</div>
            <CustomizedTextInput
              id="username"
              placeholder="Enter your email"
              variant="outlined"
              autoFocus
              fullWidth
              value={username}
              error={hasUsernameError}
              onChange={onChangeUsername}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
                classes: {
                  error: styles.textboxError,
                  root: styles.textbox,
                },
              }}
            />
            <CustomizedTextInput
              id="password"
              type="password"
              placeholder="Enter your password"
              variant="outlined"
              fullWidth
              value={password}
              error={hasPasswordError}
              onChange={onChangePassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HttpsOutlinedIcon />
                  </InputAdornment>
                ),
                classes: {
                  error: styles.textboxError,
                  root: styles.textbox,
                },
              }}
            />
            <button
              className="w-full rounded-full border-0 bg-darkPurple px-8 py-2 text-lg text-white hover:bg-mediumPurple focus:outline-none"
              type="submit"
            >
              Sign in
            </button>
          </form>
          <div
            className={(styles.captionText, styles.captionTextForgotPassword)}
          >
            <Link
              to="https://waterlily.typeform.com/to/HMfXp9zw"
              target="_blank"
            >
              <Button
                className={`${styles.buttonType4} ${styles.createAccountButton}`}
              >
                CREATE ACCOUNT
              </Button>
            </Link>
            {showForgotPassword ? null : (
              <Button
                className={styles.buttonType4}
                onClick={handleClickForgotPassword}
              >
                FORGOT PASSWORD
              </Button>
            )}
          </div>
          {showForgotPassword ? (
            <div className={styles.errorText}>
              Please contact{' '}
              <a
                href="mailto:hello@joinwaterlily.com?subject=Help+resetting+application+password"
                target="_blank"
              >
                hello@joinwaterlily.com
              </a>
              .
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
