import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  findClientPartner,
  fullName,
  isDefined,
  isNullOrUndefined,
} from '@shared';
import classnames from 'classnames';
import { ActionTags } from '../components/ActionTags';
import { IoMdRefresh } from 'react-icons/io';
import { CiLink } from 'react-icons/ci';

import { MdLaunch } from 'react-icons/md';

import { IoDocumentTextOutline } from 'react-icons/io5';

import { MdDelete } from 'react-icons/md';

import {
  selectClientsIsLoading,
  selectSessionAdvisor,
  selectSessionPotentialNewAdvisor,
} from '../model/selectors';
import {
  ALGORITHMS_CURRENTLY_RUNNING,
  ALGORITHMS_FINISHED_RUNNING,
  CLOSING_THE_CARE_GAP,
  COMPLETED_PLAN,
  FILL_OUT_THE_INTAKE_FORM,
  OBSOLETE_FINISHED_ONBOARDING,
  OBSOLETE_INTAKE_FORM_IN_PROGRESS,
  OBSOLETE_PENDING_ONBOARDING,
  OBSOLETE_PROGRESS_ON_ONBOARDING,
  OBSOLETE_PROGRESS_ON_OPPORTUNITIES,
  OnboardingResultsAreIn,
  PageLink,
  START_ONBOARDING,
  UPDATE_YOUR_CARE_SUPPORT_STRUCTURE,
  fetchClientsForAdvisorByAdvisorIdRequest,
  fetchSessionForAuthRequest,
  selectClientsSearchResults,
  selectClientsSearchText,
  updateClientsSearchText,
  CLIENT_STARTED_REVIEW,
  CLIENT_COMPLETED_REVIEW,
  ALGORITHMS_ERROR,
  OnboardingDownloadReport,
  REVIEW_CALL_SCHEDULED,
  COMPLETED_REVIEW_TOGETHER,
  STARTED_REVIEW_TOGETHER,
  deleteClientByClientIdRequest,
} from '..';

import { Fragment, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import LoadingAnimation from '../components/MoonLoaderAnimation';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tooltip,
} from '@mui/material';

import mainStyles from '../styles/main.module.css';
import moduleStyles from './AdvisorDashboard.module.css';
import ActionMenu from '../components/ActionMenu';
import AdvisorDashboardShell from '../components/AdvisorDashboardShell';
import { LinkClientPartnerDialog } from '../components/LinkClientPartnerDialog';
import { DeleteClientDialog } from '../components/DeleteClientDialog';

const styles = {
  ...mainStyles,
  ...moduleStyles,
};
const COLORS = {
  sideBar: '#120f3a',
  appBar: '#ffffff',
  appBarBorder: '#9e9e9e',
};

interface ClientsTableProps {
  clients: Client[];
  clientsIsLoading: boolean;
  clientsSearchText: string;
  handleChangeSearchText: (event: ChangeEvent<HTMLInputElement>) => void;
}

function ClientsTable({ clients, clientsIsLoading }: ClientsTableProps) {
  const getClientPartnerFullName = (client: Client) => {
    const partner = findClientPartner(client, clients);
    return partner ? fullName(partner) : '';
  };
  return (
    <>
      <div className="flex flex-col overflow-auto px-4 pb-36">
        <Table className={styles.table}>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell className={styles.tableHeaderText}>
                Client Name
              </TableCell>
              <TableCell className={styles.tableHeaderText}>Status</TableCell>
              <TableCell className={styles.tableHeaderText}>
                <div className="flex gap-10">
                  <span className="mt-1 flex-grow">Actions</span>
                  <RefreshButton />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <LoadingAnimation loading={clientsIsLoading} />
          <TableBody>
            {clients.map(client => (
              <AdvisorDashboardClientRow
                key={client.clientId}
                client={client}
                clientPartnerFullName={getClientPartnerFullName(client)}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
export function RefreshButton() {
  const dispatch = useDispatch();
  const advisor = useSelector(selectSessionAdvisor);

  const handleClickRefresh = useCallback(
    function handleClickRefreshImpl() {
      dispatch(
        fetchClientsForAdvisorByAdvisorIdRequest({
          advisorId: advisor.advisorId,
          isInvalidation: false,
        }),
      );
    },
    [advisor.advisorId, dispatch],
  );
  return (
    <button
      className="mt-0.5 flex items-center justify-end gap-1 rounded-lg  bg-white px-2 py-1 text-sm text-gray-700 shadow-sm ring-1 ring-inset ring-darkPurple transition-colors duration-100 ease-in-out hover:bg-gray-100 hover:shadow-none"
      onClick={handleClickRefresh}
    >
      <IoMdRefresh
        className="h-4 w-4"
        aria-hidden="true"
      />
      <span className="">Refresh table</span>
    </button>
  );
}

export function AdvisorDashboard({ advisorId }: AdvisorIdProps) {
  const clients = useSelector(selectClientsSearchResults);
  const clientsIsLoading = useSelector(selectClientsIsLoading);
  const clientsSearchText = useSelector(selectClientsSearchText);
  const advisor = useSelector(selectSessionAdvisor);
  const potentialNewAdvisor = useSelector(selectSessionPotentialNewAdvisor);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchClientsForAdvisorByAdvisorIdRequest({
        advisorId: advisor.advisorId,
        isInvalidation: false,
      }),
    );
  }, [dispatch, advisor.advisorId]);

  useEffect(() => {
    if (
      isDefined(potentialNewAdvisor?.organizationName) &&
      isNullOrUndefined(advisor.organizationName)
    ) {
      // HACKY HACKY CRAPPY CODE, DON'T FOLLOW THIS EXAMPLE
      // DELETE AS SOON AS POSSIBLE, WHEN WE HAVE USER MANAGEMENT
      // AND ARE CREATING USERS IN THE APP
      //
      // When a new user gets here the organization may not have been assigned yet and if we have
      // it cached then let's reload the advisor.
      dispatch(fetchSessionForAuthRequest());
    }
  }, [advisor, dispatch, potentialNewAdvisor]);

  const handleChangeSearchText = useCallback(
    function handleChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
      dispatch(updateClientsSearchText(event.target.value));
    },
    [dispatch],
  );

  const clientsTable = (
    <ClientsTable
      clients={clients}
      clientsIsLoading={clientsIsLoading}
      clientsSearchText={clientsSearchText}
      handleChangeSearchText={handleChangeSearchText}
    />
  );

  return (
    <AdvisorDashboardShell
      advisor={advisor}
      clientsTable={clientsTable}
      advisorHasClients={clients.length > 0}
      clientsIsLoading={clientsIsLoading}
      clientsSearchText={clientsSearchText}
      handleChangeSearchText={handleChangeSearchText}
    />
  );
}

interface AdvisorDashboardClientRowProps {
  client: Client;
  clientPartnerFullName?: string;
}

function AdvisorDashboardClientRow({
  client,
  clientPartnerFullName,
}: AdvisorDashboardClientRowProps) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLinkPartnerDialog, setOpenLinkPartnerDialog] = useState(false);

  const viewResultsAction = (
    <PageLink
      className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm"
      to={OnboardingResultsAreIn}
      targetProps={{ clientId: client.clientId }}
    >
      <MdLaunch className="h-4 w-4" />
      View Results
    </PageLink>
  );

  const skipToDownloadReportAction = (
    <PageLink
      className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm"
      to={OnboardingDownloadReport}
      targetProps={{ clientId: client.clientId }}
    >
      <IoDocumentTextOutline className="h-4 w-4" />
      Skip to Download Report
    </PageLink>
  );

  const addEditPartnerAction = (
    <button
      className="justify-right flex w-full items-center gap-2 px-4 py-2 text-left text-sm"
      onClick={() => setOpenLinkPartnerDialog(true)}
    >
      <CiLink className="h-4 w-4" />
      Link/Edit Partner
    </button>
  );

  const deleteClientAction = (
    <button
      className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-red-500"
      onClick={() => setOpenDeleteDialog(true)}
    >
      <MdDelete className="h-4 w-4" />
      Delete Client
    </button>
  );

  return (
    <>
      <TableRow
        key={client.clientId}
        className={classnames(styles.tableRow, styles.nonSelectedRow)}
      >
        <TableCell>
          <div className="flex items-center gap-2">
            <ClientName
              client={client}
              clientPartnerFullName={clientPartnerFullName}
            ></ClientName>
          </div>
        </TableCell>
        <TableCell>
          <ActionTags
            clientTags={client.clientTags}
            client={client}
          />
        </TableCell>
        <TableCell>
          <ActionMenu
            actions={
              client.clientTags?.[0] === ALGORITHMS_ERROR
                ? [deleteClientAction]
                : [
                    viewResultsAction,
                    skipToDownloadReportAction,
                    addEditPartnerAction,
                    deleteClientAction,
                  ]
            }
          />
          <DeleteClientDialog
            client={client}
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
          />
          <LinkClientPartnerDialog
            client={client}
            open={openLinkPartnerDialog}
            setOpen={setOpenLinkPartnerDialog}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

interface ClientNameProps {
  client: Client;
  clientPartnerFullName: string | null | undefined;
}

function ClientName({ client, clientPartnerFullName }: ClientNameProps) {
  const tag = client?.clientTags?.[0];

  switch (tag) {
    case FILL_OUT_THE_INTAKE_FORM:
    case OBSOLETE_INTAKE_FORM_IN_PROGRESS:
    case ALGORITHMS_CURRENTLY_RUNNING:
    case ALGORITHMS_ERROR:
    default:
      return (
        <Button
          className={styles.tableButton}
          type="button"
          variant="text"
          size="large"
          disabled
        >
          {fullName(client)}
        </Button>
      );
    case ALGORITHMS_FINISHED_RUNNING:
    case START_ONBOARDING:
    case OBSOLETE_PENDING_ONBOARDING:
    case OBSOLETE_PROGRESS_ON_ONBOARDING:
    case CLOSING_THE_CARE_GAP:
    case UPDATE_YOUR_CARE_SUPPORT_STRUCTURE:
    case COMPLETED_PLAN:
    case OBSOLETE_PROGRESS_ON_OPPORTUNITIES:
    case OBSOLETE_FINISHED_ONBOARDING:
    case CLIENT_COMPLETED_REVIEW:
    case CLIENT_STARTED_REVIEW:
    case REVIEW_CALL_SCHEDULED:
    case STARTED_REVIEW_TOGETHER:
    case COMPLETED_REVIEW_TOGETHER:
      return (
        <div className="flex items-center gap-2">
          <PageLink
            to={OnboardingResultsAreIn}
            targetProps={{ clientId: client.clientId }}
            className=""
          >
            <Button
              className={styles.tableButton}
              type="button"
              variant="text"
              size="large"
              color="inherit"
            >
              <span style={{ color: '#120f3a' }}>{fullName(client)}</span>
            </Button>
          </PageLink>
          {clientPartnerFullName && (
            <Tooltip
              title={
                <p className="text-sm font-normal">
                  Partner: {clientPartnerFullName}
                </p>
              }
            >
              <span>
                <CiLink className="h-5 w-5" />
              </span>
            </Tooltip>
          )}
        </div>
      );
  }
}
