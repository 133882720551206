import { FunctionComponent } from 'react';
import {
  OnboardingCostFactors,
  OnboardingCareSettingsPhaseOne,
  OnboardingCareSettingsPhaseTwo,
  OnboardingCareSettingsPhaseThree,
  OnboardingFamilyCaregivingPhaseOne,
  OnboardingFamilyCaregivingPhaseTwo,
  OnboardingFamilyCaregivingPhaseThree,
  OnboardingTotalCost,
  OnboardingCarePhasesPhaseOne,
  OnboardingCarePhasesPhaseTwo,
  OnboardingCarePhasesPhaseThree,
  OnboardingCarePhasesSummary,
  OnboardingOurPredictions,
  OnboardingPredictedCareNeeds,
  OnboardingFundingCoverage,
  OnboardingFundingSources,
  OnboardingResultsAreIn,
  OnboardingDownloadReport,
  // OnboardingReduceLtcCosts,
  // OnboardingReducedCostConfirmation,
  OnboardingEvaluatePolicyCoverage,
} from '../pages';

export const onboardingSlideByIndex: FunctionComponent<ClientIdProps>[] = [
  OnboardingResultsAreIn,
  OnboardingOurPredictions,
  OnboardingPredictedCareNeeds,
  OnboardingTotalCost,
  // OnboardingReduceLtcCosts,
  // OnboardingReducedCostConfirmation,
  OnboardingCostFactors,
  OnboardingCarePhasesPhaseOne,
  OnboardingCareSettingsPhaseOne,
  OnboardingFamilyCaregivingPhaseOne,
  OnboardingCarePhasesPhaseTwo,
  OnboardingCareSettingsPhaseTwo,
  OnboardingFamilyCaregivingPhaseTwo,
  OnboardingCarePhasesPhaseThree,
  OnboardingCareSettingsPhaseThree,
  OnboardingFamilyCaregivingPhaseThree,
  OnboardingCarePhasesSummary,
  OnboardingFundingSources,
  OnboardingFundingCoverage,
  OnboardingEvaluatePolicyCoverage,
  OnboardingDownloadReport,
];

export const onboardingSlideFirst = onboardingSlideByIndex[0];
export const onboardingSlideLast =
  onboardingSlideByIndex[onboardingSlideByIndex.length - 1];
